/* eslint-disable no-unused-vars */
export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      leftSide: {
        height: '',
        scroolHeight: '',
      },
      boxesStyle: {
        height: '',
        scroolHeight: '',
        innerScroolHeight: '',
      },
      rightSide: {
        scroolHeight: '',
        height: '',
      },
      content: {
        height: '',
        scroolHeight: '',
      },
      dimensionContent: {
        height: '',
        scroolHeight: '',
      },
      shippingItemsContentHeight: 0,
    }
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      // Batches content height options
      this.leftSide.height = `${(window.innerHeight - this.contentItemSize())}px`
      this.leftSide.scroolHeight = `${(window.innerHeight - this.contentItemSize() - 112)}px`
      // Boxes content height options
      this.boxesStyle.height = `${(window.innerHeight - this.contentItemSize() - 293)}px`
      this.boxesStyle.scroolHeight = `${(window.innerHeight - this.contentItemSize() - 287)}px`
      this.boxesStyle.innerScroolHeight = `${(window.innerHeight - this.contentItemSize() - 339)}px`
      // Item editing content height options
      this.rightSide.height = `${(window.innerHeight - this.contentItemSize() - 77)}px`
      this.rightSide.scroolHeight = `${(window.innerHeight - this.contentItemSize() - 113)}px`
      this.content.height = `${(window.innerHeight - this.contentItemSize() - 110)}px`
      this.content.scroolHeight = `${(window.innerHeight - this.contentItemSize() - 110)}px`
      this.dimensionContent.height = `${(window.innerHeight - this.contentItemSize() - 36)}px`
      this.dimensionContent.scroolHeight = `${(window.innerHeight - this.contentItemSize() - 146)}px`
    },
    getDimensionContentScroolHeight() {
      return this.dimensionContent.scroolHeight
    },
    batchItemGridHeight() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      return window.innerHeight - this.contentItemSize() - 113
    },
    setShippingItemsContentHeight() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      this.shippingItemsContentHeight = window.innerHeight - this.contentItemSize() - 293
    },
    contentItemSize() {
      const header = document.getElementById('app-navbar')
      const headerHeight = header.clientHeight
      const footer = document.getElementById('app-footer')
      const footerHeight = footer.clientHeight
      const breadcrumb = document.getElementById('app-breadcrumb')
      const breadcrumbHeight = breadcrumb.clientHeight
      return headerHeight + footerHeight + breadcrumbHeight
    },
  },
}
