import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const shipmentTypeEnum = Object.freeze({
  SP: { key: 'SP', value: 'SP', text: 'Small parcel delivery (SPD)' },
  LTL: { key: 'LTL', value: 'LTL', text: 'Less than truckload (LTL)' },
})

function getShipmentTypeEnum(predicate, predicateKey = 'key') {
  const result = find(shipmentTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getShipmentTypeEnumList() {
  const options = []
  Object
    .keys(shipmentTypeEnum)
    .map(key => options.push(
      {
        value: shipmentTypeEnum[key].value,
        text: shipmentTypeEnum[key].text,
      },
    ))
  return options
}

export default shipmentTypeEnum

export {
  getShipmentTypeEnum,
  getShipmentTypeEnumList,
}
