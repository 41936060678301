/**
 * @name SettingsAutoPriceUnitEnum
 * @description
 * An enumeration of the auto price unit settings.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const SettingsAutoPriceUnitEnum = Object.freeze({
  CURRENCY: {
    key: 'currency', value: 'currency', text: '$',
  },
  PERCENTAGE: {
    key: 'percentage', value: 'percentage', text: '%', target: 'amazon',
  },
  list: (key, query) => {
    if (key && query) {
      return Object.values(SettingsAutoPriceUnitEnum)
        .filter(value => typeof value === 'object' && value[key] === query)
        .map(value => ({
          value: value.value,
          text: value.text,
        }))
    }
    return Object.values(SettingsAutoPriceUnitEnum)
      .filter(value => typeof value === 'object')
      .map(value => ({
        value: value.value,
        text: value.text,
      }))
  },
  find: query => Object.values(SettingsAutoPriceUnitEnum)
    .find(value => typeof value === 'object' && (value.key === query || value.value === query)),
})

export default SettingsAutoPriceUnitEnum
