/* eslint-disable no-unused-vars */
import {
  onMounted, computed, ref, watch, toRef,
} from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import packingTypeEnum from '@/enums/packingTypeEnum'
import settingsService from '@/http/requests/common/settings'
import isNumber from 'lodash/isNumber'
import toString from 'lodash/toString'
import find from 'lodash/find'
import { Notify } from '@robustshell/utils/index'
import useCurrentUser from '@/libs/app/current-user'
import listPriceEnum from '@/enums/listPrice.enum'
import SettingsAutoPriceUnitEnum from '@/enums/settings-auto-price-unit.enum'
import pricingBasesEnum from '@/enums/pricingBases.enum'
import { ReferenceTypeEnum, SettingsTypeEnum } from '~/enums'
import CreateItemTypeEnum from './createItemType'
import { createMsku } from './createMsku'

export default function useFBAShipment() {
  const { isServingTenant } = useCurrentUser()

  const userData = JSON.parse(localStorage.getItem('userData'))
  const warehouse = computed(() => userData.warehouse)
  const batchInfo = computed(() => store.getters['fbaShipment/getSelectedBactInfo'])
  const isCasePacked = computed(() => batchInfo.value.packingType === packingTypeEnum.case_packed.value)

  const fbaBatchParentItemWrapperFormData = ref({})
  const fbaBatchParentItemExistingCurrents = ref({})

  function setInitialItemWrapperFormData() {
    fbaBatchParentItemWrapperFormData.value = {
      id: null,
      companyId: batchInfo.value.companyId,
      warehouseId: warehouse.value.id,
      storeId: batchInfo.value.storeId,
      batchParentId: batchInfo.value.batchId,
      quantity: 0,
      quantityPerCase: 0,
      missingItemQuantity: 0,
      countPerBundle: 0,
      productId: null,
      mskuFnskuPairId: null,
      cost: 0,
      salePrice: 0,
      supplier: '',
      title: '',
      upc: '',
      asin: '',
      msku: '',
      fnsku: '',
      itemCondition: 'NEW',
      planId: null,
      expirationDate: null,
      taxCode: 'A_GEN_TAX',
      listingItem: '',
      notes: '',
      packType: 'individual',
      isMultipleItem: false,
      productType: '',
      createType: '',
      updateDefaultCharges: false,
      shipmentDate: null,
      listOnAmazon: true,
      channel: '',
      orderNumber: '',
      trackingNumber: '',
      palletId: '',
    }
  }

  function setExistingParentItem(item) {
    let quantity = item.quantity
    let quantityPerCase = item.quantityPerCase

    if (isCasePacked.value) {
      quantity = item.quantityPerCase
      quantityPerCase = item.quantity / item.quantityPerCase
    }

    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      id: item.id,
      batchParentId: item.batchParentId,
      quantity: quantity,
      quantityPerCase: quantityPerCase,
      missingItemQuantity: 0,
      countPerBundle: item.countPerBundle,
      productId: item.productId,
      mskuFnskuPairId: item.mskuFnskuPairId,
      cost: item.cost,
      salePrice: item.salePrice,
      supplier: item.supplier,
      title: item.title,
      upc: item.upc,
      asin: item.asin,
      msku: item.msku,
      fnsku: item.fnsku,
      itemCondition: item.itemCondition,
      planId: item.planId,
      expirationDate: item.expirationDate,
      taxCode: item.taxCode,
      listingItem: '',
      notes: item.notes,
      packType: item.packType,
      productType: '',
    }
  }

  function setExistingParentItemCurrents(item) {
    let quantity = item.quantity
    let quantityPerCase = item.quantityPerCase

    if (isCasePacked.value) {
      quantity = item.quantityPerCase
      quantityPerCase = item.quantity / item.quantityPerCase
    }

    fbaBatchParentItemExistingCurrents.value = {
      currentQuantity: quantity,
      currentQuantityPerCase: quantityPerCase,
    }
  }

  function setExistingParentItemCreateType(type) {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      createType: type,
    }
  }

  function setListOnAmazon(data) {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      listOnAmazon: data,
    }
  }

  function setQuantityItemWrapperFormData(quantity) {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      quantity: quantity,
    }
  }

  function setExpirationDateItemWrapperFormData(expDate) {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      expirationDate: expDate,
    }
  }

  function setCreateTypeItemWrapperFormData(createType) {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      createType: createType,
    }
  }

  function setReplenisMissingItemWrapperFormData(productId, supplier, title, upc, asin, msku, fnsku, itemCondition, productType) {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      quantity: 0,
      quantityPerCase: 0,
      missingItemQuantity: 0,
      countPerBundle: 0,
      productId: productId,
      mskuFnskuPairId: null,
      cost: 0,
      salePrice: 0,
      supplier: supplier,
      title: title,
      upc: upc,
      asin: asin,
      msku: msku,
      fnsku: fnsku,
      itemCondition: itemCondition,
      planId: null,
      expirationDate: null,
      taxCode: 'A_GEN_TAX',
      listingItem: '',
      notes: '',
      productType: productType,
    }
  }

  function setListingItemWrapperFormData() {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      supplierDeclaredDgHzRegulation: 'not_applicable',
      batteriesRequired: false,
      itemCondition: 'NEW',
      taxCode: 'A_GEN_TAX',
      packType: 'individual',
    }
  }

  function setListingMissingItemWrapperFormData(asin, manufacturer, productType, upc, title) {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      asin: asin,
      supplier: manufacturer,
      productType: productType,
      upc: upc,
      title: title,
    }
  }

  function setPackTypeItemWrapperFormData(packType, countPerBundle, isMultipleItem) {
    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      packType: packType,
      countPerBundle: countPerBundle,
      isMultipleItem: isMultipleItem,
    }
  }

  const currentMSKUPatternText = ref('')
  const currentMSKUDefaultNotVaraible = ref(true)
  const currentMSKUCount = ref(0)
  const currentMSKUPattern = ref({})
  const currentMSKU = ref('')
  const tempCurrentMSKU = ref('')
  const useManualCreatedMskuValue = ref(false)

  function setCurrentMSKUDefaultNotVaraible(msku) {
    if (msku) {
      currentMSKUDefaultNotVaraible.value = null
      currentMSKU.value = toString(msku)
      tempCurrentMSKU.value = toString(msku)
    }
  }

  function setReplenisItemWrapperFormData(msku, fnsku) {
    currentMSKUDefaultNotVaraible.value = null
    currentMSKU.value = toString(msku)
    tempCurrentMSKU.value = toString(msku)

    fbaBatchParentItemWrapperFormData.value = {
      ...fbaBatchParentItemWrapperFormData.value,
      fnsku: fnsku,
      msku: msku,
    }
  }

  function getMskuNextCount() {
    settingsService
      .getNextMskuId(batchInfo.value.storeId)
      .then(result => {
        currentMSKUDefaultNotVaraible.value = null
        currentMSKUCount.value = result.data
      })
  }

  function getCurrentMSKUSettigs(storeId = 0) {
    const id = storeId !== 0 ? storeId : batchInfo.value.storeId
    settingsService
      .getByReference(
        ReferenceTypeEnum.STORE.value,
        id,
        SettingsTypeEnum.MSKUFORMULA.value,
      )
      .then(result => {
        if (result.data && result.data.id) {
          currentMSKUPatternText.value = ''
          const data = { ...result.data }
          const patternArr = JSON.parse(data.value)
          currentMSKUPattern.value = patternArr
          if (typeof (patternArr) !== 'undefined' && patternArr !== null) {
            currentMSKUDefaultNotVaraible.value = false
            patternArr.forEach(item => {
              currentMSKUPatternText.value += item.msku.value + item.seperatorValue
            })
            currentMSKUPatternText.value = currentMSKUPatternText.value.slice(0, -1)
          }
        }
      })
      .then(() => {
        getMskuNextCount()
      })
  }

  function setMskuValue(data) {
    if (useManualCreatedMskuValue.value) {
      return
    }
    if (currentMSKUDefaultNotVaraible.value === null) {
      currentMSKU.value = toString(currentMSKUCount.value)
      tempCurrentMSKU.value = `#${currentMSKUCount.value}#`
    }
    if (!useManualCreatedMskuValue.value) {
      const formatedDate = data.shipmentDate ? moment(data.shipmentDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
      const pattern = currentMSKUPattern.value
      const patternData = {
        count: currentMSKUCount.value,
        cost: data.cost,
        asin: data.asin,
        itemCondition: data.itemCondition,
        salePrice: data.salePrice,
        purchaseDate: formatedDate,
        rank: data.rank,
        supplier: data.supplier,
      }
      currentMSKU.value = createMsku(pattern, patternData)
      const tempPatternData = {
        count: `#${currentMSKUCount.value}#`,
        cost: data.cost,
        asin: data.asin,
        itemCondition: data.itemCondition,
        salePrice: data.salePrice,
        purchaseDate: formatedDate,
        rank: data.rank,
        supplier: data.supplier,
      }
      tempCurrentMSKU.value = createMsku(pattern, tempPatternData)
    }
  }

  watch(fbaBatchParentItemWrapperFormData, current => {
    if (current.createType === CreateItemTypeEnum.list.value || current.createType === CreateItemTypeEnum.list_create_items.value) {
      setMskuValue(current)
    }
  })

  // #region Bundle Item
  const bundleItems = ref([])
  const bundleItemQuantity = ref(0)
  const bundleItemRemind = ref(0)
  function addBundleItems(item) {
    if (!item.msku) {
      Notify.warning('The item to be bundle or multi-pack must have a MSKU.')
      return
    }
    const existItem = find(bundleItems.value, p => p.msku === item.msku)
    if (!existItem) {
      bundleItems.value.push(item)
      return
    }
    Notify.info(`${item.msku} - This item has been added previously.`)
  }
  function normalizeBundleItems() {
    const bundleQuantityArr = []
    let tempBundleItemQuantity = 0
    bundleItems.value.forEach(item => {
      const remain = item.itemQuantity % item.quantity
      bundleItemRemind.value = remain
      const bundleQuantity = remain === 0
        ? item.itemQuantity / item.quantity
        : (item.itemQuantity - remain) / item.quantity

      const newItem = {
        ...item,
        bundleQuantity: bundleQuantity,
        isValid: false,
      }
      bundleQuantityArr.push(newItem)
    })

    tempBundleItemQuantity = Math.max(...bundleQuantityArr.map(p => p.bundleQuantity))
    bundleItemQuantity.value = tempBundleItemQuantity

    const normalizeBundleQuantity = []
    bundleQuantityArr.forEach(item => {
      const isValid = (item.quantity * tempBundleItemQuantity) === item.itemQuantity
      const newItem = {
        ...item,
        isValid: isValid,
      }
      normalizeBundleQuantity.push(newItem)
    })
    bundleItems.value = normalizeBundleQuantity
  }

  function removeBundleItems(index) {
    bundleItems.value.splice(index, 1)
  }
  function clearBundleItems() {
    bundleItems.value = []
  }

  // #endregion

  function validateItemWrapperFormData(data) {
    let isValid = true
    if (data.createType === CreateItemTypeEnum.list.value
      || data.createType === CreateItemTypeEnum.list_create_items.value
      || data.createType === CreateItemTypeEnum.replenish.value
      || data.createType === CreateItemTypeEnum.replenish_create_items.value) {
      if (data.msku && data.msku.length > 40) {
        const mskuText = data.msku.replace(/#/g, '')
        if (mskuText.length > 40) {
          isValid = false
          Notify.danger('MSKU value cannot be greater then 40 charcters.')
        }
      }
      if (typeof data.msku === 'undefined' || data.msku === null || data.msku === '') {
        isValid = false
        Notify.danger('MSKU value cannot be empty.')
      }
      if (!isNumber(data.salePrice) || data.salePrice === 0) {
        isValid = false
        Notify.danger('Sale Price value cannot be zero (0).')
      }
      if (!isNumber(data.cost) || data.cost === 0) {
        isValid = false
        Notify.danger('Item Cost cannot be 0. Please enter the item cost.')
      }
      if (data.planId === null && isServingTenant.value) {
        isValid = false
        Notify.danger('Please select a plan.')
      }
      if ((data.packType !== 'individual' && !isNumber(data.countPerBundle)) || (data.packType !== 'individual' && data.countPerBundle === 0)) {
        isValid = false
        Notify.danger('Count Per Bundle value cannot be zero (0).')
      }
      if (!data.quantity || !isNumber(data.quantity) || data.quantity <= 0) {
        isValid = false
        Notify.danger('Quantity value cannot be zero (0).')
      }
      if (data.itemCondition !== 'NEW' && (data.notes === '' || data.notes === null)) {
        isValid = false
        Notify.danger(`Please enter Offer Condition Notes for condition type: ${data.itemCondition}`)
      }
      if (data.bundleItems && data.bundleItems.itemInfo && data.bundleItems.itemInfo.length > 0 && bundleItemRemind.value > 0) {
        isValid = false
        Notify.danger(`You could add ${bundleItemRemind.value} items for bundle or multipack.`)
      }
    }
    if (data.createType === CreateItemTypeEnum.create_and_add_missing_items.value) {
      if (!data.quantity || !isNumber(data.quantity) || data.quantity <= 0) {
        isValid = false
        Notify.danger('Quantity value cannot be zero (0).')
      }
    }
    return isValid
  }

  function calculatePriceFormula(data, margin, unit, base) {
    let price = 0
    if (unit === SettingsAutoPriceUnitEnum.CURRENCY.value) {
      if (base === pricingBasesEnum.HIGHER_THAN_BUY_BOX.value) {
        price = data + margin
      }
      if (base === pricingBasesEnum.LESS_THAN_BUY_BOX.value) {
        price = data - margin
      }
    }

    if (unit === SettingsAutoPriceUnitEnum.PERCENTAGE.value) {
      if (base === pricingBasesEnum.HIGHER_THAN_BUY_BOX.value) {
        price = data + (data * (margin / 100))
      }
      if (base === pricingBasesEnum.LESS_THAN_BUY_BOX.value) {
        price = data - (data * (margin / 100))
      }
    }
    return price
  }
  const priceSettingListPriceType = ref()
  function loadCalculationPriceSettings() {
    const priceSetting = localStorage.getItem('autoPricingSettings')
    if (priceSetting && typeof priceSetting === 'object') {
      const priceSettingData = JSON.parse(priceSetting)
      priceSettingListPriceType.value = priceSettingData.listPrice.type
    } else {
      priceSettingListPriceType.value = null
    }
  }
  // #region Price Calculation
  function calculatePrice(data) {
    if (!data) {
      return 0
    }
    let salePrice = 0
    let priceSetting = localStorage.getItem('autoPricingSettings')
    priceSetting = JSON.parse(priceSetting)
    if (priceSetting && typeof priceSetting === 'object') {
      switch (priceSettingListPriceType.value) {
        case listPriceEnum.PRICE_FORMULA.value: {
          salePrice = calculatePriceFormula(data, priceSetting.listPrice.margin, priceSetting.listPrice.unit, priceSetting.listPrice.base)
          break
        }
        case listPriceEnum.MATCH_BUY_BOX.value:
          salePrice = data
          break
        default:
          salePrice = data
          break
      }
    }
    return salePrice
  }
  // #endregion

  return {
    warehouse,
    batchInfo,
    isCasePacked,
    fbaBatchParentItemWrapperFormData,
    fbaBatchParentItemExistingCurrents,
    currentMSKUDefaultNotVaraible,
    currentMSKU,
    tempCurrentMSKU,
    currentMSKUPatternText,
    useManualCreatedMskuValue,

    setInitialItemWrapperFormData,
    setExistingParentItem,
    setExistingParentItemCurrents,
    setExistingParentItemCreateType,
    setQuantityItemWrapperFormData,
    setCreateTypeItemWrapperFormData,
    setReplenisItemWrapperFormData,
    setReplenisMissingItemWrapperFormData,
    setListingItemWrapperFormData,
    setListingMissingItemWrapperFormData,
    setPackTypeItemWrapperFormData,
    setExpirationDateItemWrapperFormData,
    getCurrentMSKUSettigs,
    setCurrentMSKUDefaultNotVaraible,
    setMskuValue,
    validateItemWrapperFormData,
    setListOnAmazon,
    bundleItems,
    bundleItemQuantity,
    bundleItemRemind,
    addBundleItems,
    removeBundleItems,
    clearBundleItems,
    normalizeBundleItems,
    calculatePrice,
    priceSettingListPriceType,
    loadCalculationPriceSettings,
  }
}
