<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div v-for="(item, index) in boxes" :key="index" class="form-row mt-1">
          <div v-show="index === 0" class="form-group col-6 mb-0 px-half">
            <dx-util-select-box
              id="printer-select-box"
              v-model="selectedPrinter"
              :data-source="printers"
              display-expr="Name"
            />
          </div>
          <div v-show="index === 0" class="form-group col-6 mb-0 px-half">
            <dx-util-button
              icon="print"
              type="default"
              text="Print All"
              width="100%"
              :element-attr="{class: 'text-left'}"
              @click="onPrintAllBarcodes($event, item)"
            />
          </div>
          <div class="form-group col-6 mb-0 px-half">
            <label v-show="index === 0" for="text">Boxes</label>
            <div class="form-control bg-success text-white rounded border-0" style="height: 2.571rem; white-space: nowrap">
              <b-icon icon="box" />
              Box {{ item.boxName }}
            </div>
          </div>
          <div class="col-2 mb-0 px-half">
            <label v-show="index === 0" for="text">Download</label>
            <dx-util-button
              icon="download"
              type="normal"
              text="Download"
              width="100%"
              :element-attr="{class: 'text-left btn-warning'}"
              @click="onDownloadBarcode(item)"
            />
          </div>
          <div class="col-4 mb-0 px-half">
            <label v-show="index === 0" for="text">Print 2D Content Barcode</label>
            <dx-util-button
              icon="print"
              type="default"
              text="Print"
              width="100%"
              :element-attr="{class: 'text-left'}"
              @click="onPrintBarcode($event, item)"
            />
          </div>
        </div>
      </div>
      <div class="col-6 mt-1">
        <div class="card card-psh border mb-1">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="border-0">
                <p-icon name="psh-item-tracking3" color="warning" size="48px" class="mr-2" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  PSH Shipping Label
                </h4>
                <p class="card-text mb-0">
                  If you prefer, you can also get shipping label from PSH.
                </p>
              </div>
              <div class="ml-auto">
                <dx-util-button
                  text="Buy Label"
                  type="default"
                  styling-mode="contained"
                  @click="onClickBuyShippingLabel"
                />
              </div>
            </div>
          </div>
          <div v-if="pshShippingLabels.length > 0" class="card-body">
            <table class="table table-sm table-bordered rounded">
              <tr v-for="item in pshShippingLabels" :key="item.id">
                <td>{{ item.carrier }}</td>
                <td class="w-100">
                  {{ item.trackingNumber }}</td>
                <td>
                  <a :href="item.labelUrl" target="_blank" class="btn btn-sm btn-primary" rel="noopener noreferrer">
                    <p-icon name="feather-download-cloud" size="16px" class="align-text-center" />
                </a></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="card card-psh border mb-1">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="border-0">
                <p-icon name="psh-drop-shipping" color="primary" size="48px" class="mr-2" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  Amazon Shipping Label
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <app-timeline>
            <app-timeline-item v-show="partnerType" icon="BoxIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Estimate Shipping Charges</h6>
                <small class="text-muted">{{ transportStatusEnums.WORKING.text }}</small>
              </div>
              <div class="d-flex flex-column flex-wrap justify-content-start">
                <p>Click on Estimate Button to calculate estimated shipping cost.</p>
              </div>
              <b-button
                size="sm"
                variant="success"
                :disabled="transportStatus !== transportStatusEnums.ERROR_ON_ESTIMATING.key"
                @click="onClickEstimateTransport()"
              >
                Estimate
              </b-button>
            </app-timeline-item>
            <app-timeline-item v-show="partnerType" variant="secondary" icon="ClockIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Estimating</h6>
                <small class="text-muted">{{ transportStatusEnums.ESTIMATING.text }}</small>
              </div>
              <b-button
                size="sm"
                variant="outline-secondary"
                :disabled="transportStatus !== transportStatusEnums.ESTIMATING.key"
                @click="getTransportDetails()"
              >
                Check Estimate Status
              </b-button>
            </app-timeline-item>
            <app-timeline-item v-show="partnerType" variant="success" icon="CheckCircleIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Shipping Charges</h6>
                <small class="text-muted">{{ transportStatusEnums.ESTIMATED.text }}</small>
              </div>
              <div class="d-flex flex-column flex-wrap justify-content-start">
                <p>Shipping carrier service UNITED PARCEL SERVICE INC</p>
                <p>Estimated shipping cost <span class="font-weight-bolder text-warning">{{ estimateShipmentCharges.value }}$</span></p>
              </div>
              <b-button
                :disabled="transportStatus !== transportStatusEnums.ESTIMATED.key"
                size="sm"
                variant="primary"
                @click="onClickConfirmTransport()"
              >
                Accept Charges
              </b-button>
            </app-timeline-item>
            <app-timeline-item v-show="partnerType" variant="warning" icon="ClockIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Confirming</h6>
                <small class="text-muted">{{ transportStatusEnums.CONFIRMING.text }}</small>
              </div>
              <b-button
                size="sm"
                variant="outline-secondary"
                :disabled="transportStatus !== transportStatusEnums.CONFIRMING.key"
                @click="getTransportDetails()"
              >
                Check Confirm Status
              </b-button>
            </app-timeline-item>
            <app-timeline-item v-show="partnerType" variant="danger" icon="MinusCircleIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Void Labels</h6>
                <small class="text-muted">{{ transportStatusEnums.CONFIRMED.text }}</small>
              </div>
              <b-button
                size="sm"
                variant="danger"
                :disabled="voidDate.disabled"
                @click="voidFbaShipmentLabel(shipmentBatchId)"
              >
                Void Labels {{ voidDate.counter }}
            </b-button>
            </app-timeline-item>
            <app-timeline-item variant="warning" icon="DownloadIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Shipping Labels</h6>
                <small class="text-muted">{{ transportStatusEnums.CONFIRMED.text }}</small>
              </div>
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-start mb-1 mb-sm-0">
                <button
                  class="btn btn-sm btn-warning mr-1"
                  :disabled="transportStatus !== transportStatusEnums.CONFIRMED.key"
                  @click="getFbaShipmentLabels(shipmentBatchId)"
                >
                  Download Shipping Labels
                </button>
                <dx-util-select-box
                  v-model="shippingLabelPage"
                  :items="shippingLabelPages"
                  :disabled="transportStatus !== transportStatusEnums.CONFIRMED.key"
                  display-expr="text"
                  value-expr="value"
                  :width="300"
                  placeholder="Select Shipping Lable Page"
                  @value-changed="onValueChangedShippingLabelPage"
                />
                <div class="align-self-center">
                  <span
                    id="shippingLabelDesctiption"
                    class="badge badge-danger cursor-pointer"
                    @mouseenter="toggleShippingLabelPageDescriptionPopoverOptions"
                    @mouseleave="toggleShippingLabelPageDescriptionPopoverOptions"
                  >
                    Description
                  </span>
                  <dx-util-popover
                    :width="400"
                    :visible="shippingLabelPageDescriptionPopoverVisible"
                    :animation="shippingLabelPageDescriptionPopoverAnimationConfig"
                    target="#shippingLabelDesctiption"
                    position="top"
                  >
                    {{ shippingLabelPageDescription }}
                  </dx-util-popover>
                </div>
              </div>
            </app-timeline-item>
            <app-timeline-item variant="success" icon="CheckCircleIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Mark as Shipped</h6>
                <small class="text-muted">{{ transportStatusEnums.CONFIRMED.text }}</small>
              </div>
              <b-button
                size="sm"
                class="mr-1"
                variant="success"
                :disabled="(transportStatus !== transportStatusEnums.CONFIRMED.key && partnerType) || isActionStarted"
                @click="markAsShipped(shipmentBatchId)"
              >
                <div v-if="isActionStarted" class="spinner-border spinner-border-sm" role="status" />
                Mark as Shipped
              </b-button>
            </app-timeline-item>
            <app-timeline-item v-if="previewPickupDate" variant="info" icon="CheckCircleIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Estimated Pickup Date</h6>
              </div>
                {{ previewPickupDate }}
            </app-timeline-item>
            </app-timeline>
          </div>
        </div>
      </div>
      <div>
        <label-form
          :component-id="labelFormComponentId"
          :reference-id="refShipmentBatchId"
          :reference-key="referenceKey"
          @on-label-form-closed="onEmitCloseLabelForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import moment from 'moment'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import { v4 as uuidv4 } from 'uuid'
import { Notify } from '@robustshell/utils'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import transportStatusEnum, { getTransportStatusEnum } from '@/enums/transportStatusEnum'
import usePrinter from '@/libs/printer/print'
import useDownloadLabel from '@/libs/printer/label-download'
import printerVarabiles, { labelTypes } from '@/libs/printer/printer-varaibles'
import shipmentLabelService from '@/http/requests/external/shipmentLabels'
import { LabelParam } from '@/http/models/printer/entity/labelparam'
import shippingLabelPageEnum, { getShippingLabelPageEnum, getShippingLabelPageEnumList } from '@/enums/shipping-label-page.enum'
import LabelForm from '@/views/apps/tools/labels/LabelForm.vue'
import shippingLabelReferanceEnum from '@/enums/shipping-label-referance.enum.js'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    LabelForm,
  },
  setup() {
    const {
      selectedPrinter,
      printers,
      pshBase64,
      printStaticLabel,
      printStaticLabelList,
      getLabelDocument,
    } = usePrinter()
    const { downloadContentLabel } = useDownloadLabel()
    return {
      selectedPrinter,
      printers,
      pshBase64,
      printStaticLabel,
      printStaticLabelList,
      getLabelDocument,
      downloadContentLabel,
    }
  },
  props: {
    shipmentBatchId: {
      type: Number,
      required: true,
    },
    shipmentBatchName: {
      type: String,
      required: true,
    },
    shipmentType: {
      type: String,
      default: 'SP',
    },
    transportDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      labelFormComponentId: '',
      partnerType: this.transportDetail.partnerType,
      transportStatus: this.transportDetail.transportStatus,
      estimateShipmentCharges: this.transportDetail.estimateShipmentCharges,
      voidDeadline: this.transportDetail.voidDeadline,
      previewPickupDate: this.transportDetail.previewPickupDate ? moment(this.transportDetail.previewPickupDate).format('YYYY-MM-DD HH:mm:ss') : '',
      boxes: [],
      transportStatusEnums: { ...transportStatusEnum },
      transportHeader: {},
      currentPrinter: {},
      printerList: [],
      voidDate: {
        counter: '',
        disabled: true,
      },
      isActionStarted: false,
      shippingLabelPage: shippingLabelPageEnum.PACKAGELABEL_LETTER_2.value,
      shippingLabelPageDescription: shippingLabelPageEnum.PACKAGELABEL_LETTER_2.description,
      shippingLabelPages: getShippingLabelPageEnumList(),
      shippingLabelPageDescriptionPopoverVisible: false,
      shippingLabelPageDescriptionPopoverAnimationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0,
          },
          to: {
            scale: 1,
          },
        },
        hide: {
          type: 'fade',
          from: 1,
          to: 0,
        },
      },
      pshShippingLabels: [],
      referenceKey: shippingLabelReferanceEnum.FBA.value,
    }
  },
  computed: {
    currentTransportStatus: {
      get() {
        return `${this.transportStatus}`
      },
      set(status) {
        this.transportStatus = status
      },
    },
    refShipmentBatchId() {
      return this.shipmentBatchId.toString()
    },
  },
  watch: {
    shipmentBatchId: {
      immediate: true,
      async handler(current, previous) {
        if (current && current > 0) {
          this.getBatchBoxes(current)
          await this.getShippingLabelByReference(current)
        }
      },
    },
    transportDetail: {
      immediate: true,
      async handler(current, previous) {
        if (current) {
          this.partnerType = current.partnerType
          this.transportStatus = current.transportStatus
          this.estimateShipmentCharges = current.estimateShipmentCharges
          this.voidDeadline = current.voidDeadline
          this.previewPickupDate = current.previewPickupDate
          this.voidLabelDateAge(current.voidDeadline)
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    toggleShippingLabelPageDescriptionPopoverOptions() {
      this.shippingLabelPageDescriptionPopoverVisible = !this.shippingLabelPageDescriptionPopoverVisible
    },
    onValueChangedShippingLabelPage(e) {
      const selectedShippingPage = getShippingLabelPageEnum(this.shippingLabelPage)
      this.shippingLabelPageDescription = selectedShippingPage.description
    },
    getBatchBoxes(id) {
      const parsed = parseInt(id, 10)
      fbaShipmentService.findAllBoxesByBatchId(parsed).then(result => {
        this.boxes.splice(0, this.boxes.length)
        const { data } = result
        data.forEach(item => {
          const boxDimension = {
            boxId: item.id,
            boxName: item.boxName,
            barcodeText: `${this.shipmentBatchName}-${item.boxName}`,
          }
          this.boxes.push(boxDimension)
        })
      })
    },
    async getShippingLabelByReference(id) {
      const parsed = parseInt(id, 10)
      const result = await shipmentLabelService.getShippingLabelByReference(shippingLabelReferanceEnum.FBA.value, parsed)
      const { data } = result
      this.pshShippingLabels.length = 0
      this.pshShippingLabels = [...data]
    },
    onClickBuyShippingLabel() {
      this.labelFormComponentId = uuidv4()
    },
    async onEmitCloseLabelForm() {
      const parsed = parseInt(this.shipmentBatchId, 10)
      const result = await shipmentLabelService.getShippingLabelByReference(shippingLabelReferanceEnum.FBA.value, parsed)
      const { data } = result
      this.pshShippingLabels.length = 0
      this.pshShippingLabels = [...data]
    },
    // Transport Detail
    getTransportDetails() {
      this.$emit('on-emit-get-transport-detail')
    },
    voidLabelDateAge(val) {
      if (val) {
        const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
        const result = moment.duration(moment(val).diff(now))
        const diff = Math.trunc(result.asSeconds())
        if (diff <= 0) {
          this.voidDate = {
            counter: '',
            disabled: true,
          }
        }
        this.voidDate = {
          counter: moment.utc(result.asMilliseconds()).format('hh:mm:ss'),
          disabled: false,
        }
      }
    },
    getFbaShipmentLabels(id) {
      fbaShipmentService.getFbaShipmentLabels(id, this.shippingLabelPage).then(result => {
        const { data } = result
        const link = document.createElement('a')
        link.href = data.payload.downloadURL
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    onClickEstimateTransport() {
      fbaShipmentService.estimateTransport(this.shipmentBatchId, this.shipmentType)
        .then(result => {
          const { data } = result
          this.currentTransportStatus = data.transportStatus
        })
        .then(() => {
          this.onEmitGetTransportDetail()
        })
    },
    onClickConfirmTransport() {
      fbaShipmentService.confirmTransport(this.shipmentBatchId).then(result => {
        const { data } = result
        this.currentTransportStatus = data.transportStatus
      }).then(() => {
        this.onEmitGetTransportDetail()
      })
    },
    voidFbaShipmentLabel(id) {
      this.$swal({
        title: 'Void Label',
        text: 'Are you sure you want o void this label?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Void',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await fbaShipmentService.voidFbaShipmentLabel(id)
          this.voidDate = {
            counter: '',
            disabled: true,
          }
          this.onEmitGetTransportDetail()
        }
      })
    },
    markAsShipped(id) {
      this.isActionStarted = true
      outboundShippingService.markAsShipped(id)
        .then(() => {
          this.isActionStarted = false
        })
        .then(() => {
          this.onEmitGetTransportDetail()
        })
    },
    onEmitGetTransportDetail() {
      this.$emit('on-emit-get-transport-detail')
    },
    // #region print
    createPrinterParams(data) {
      const params = []
      params.push(new LabelParam(printerVarabiles.SHIPPING_PLAN_NAME.value, data.shippingPlanName))
      params.push(new LabelParam(printerVarabiles.CREATED_AT.value, moment(data.createdAt).format('YYYY-MM-DD HH:mm')))
      params.push(new LabelParam(printerVarabiles.BATCH_AND_BOX_NAME.value, data.batchAndBoxName))
      params.push(new LabelParam(printerVarabiles.ITEM_QTY.value, data.itemQty))
      params.push(new LabelParam(printerVarabiles.SKU_QTY.value, data.skuQty))
      params.push(new LabelParam(printerVarabiles.BARCODE_CONTENT.value, data.barcode))
      params.push(new LabelParam(printerVarabiles.CURRENT_DATE.value, moment(new Date()).format('YYYY-MM-DD')))
      params.push(new LabelParam(printerVarabiles.PSH_BASE64_CONST.value, this.pshBase64))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE1.value, data.shipFrom[0]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE2.value, data.shipFrom[1]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE3.value, data.shipFrom[2]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE4.value, data.shipFrom[3]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE5.value, data.shipFrom[4]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE6.value, data.shipFrom[5]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE1.value, data.shipTo[0]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE2.value, data.shipTo[1]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE3.value, data.shipTo[2]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE4.value, data.shipTo[3]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE5.value, data.shipTo[4]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE6.value, data.shipTo[5]))
      return params
    },
    async onPrintAllBarcodes() {
      if (!this.selectedPrinter.VendorName) {
        Notify.warning('Select a printer')
        return
      }
      try {
        const data = await fbaShipmentService.getBoxContentFor2DBarcodeAllBatch(this.shipmentBatchId)
        const listParams = []
        data.forEach(item => {
          const params = this.createPrinterParams(item)
          listParams.push(params)
        })
        await this.printStaticLabelList(this.selectedPrinter.VendorName, this.selectedPrinter.Address, labelTypes.CONTENT_BARCODE_LABEL.value, listParams)
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    async onPrintBarcode(e, item) {
      if (!this.selectedPrinter.VendorName) {
        Notify.warning('Select a printer')
        return
      }
      const result = await fbaShipmentService.getBoxContentFor2DBarcode(item.boxId)
      try {
        const data = result.data
        const params = this.createPrinterParams(data)
        this.printStaticLabel(this.selectedPrinter.VendorName, this.selectedPrinter.Address, labelTypes.CONTENT_BARCODE_LABEL.value, params)
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    async onDownloadBarcode(item) {
      const result = await fbaShipmentService.getBoxContentFor2DBarcode(item.boxId)
      try {
        const data = result.data
        const params = {
          boxName: `BOX ${data.boxOrder}`,
          boxWeight: data.weight,
          shipFromLine1: data.shipFrom[0],
          shipFromLine2: data.shipFrom[1],
          shipFromLine3: data.shipFrom[2],
          shipFromLine4: data.shipFrom[3],
          shipFromLine5: data.shipFrom[4],
          shipFromLine6: data.shipFrom[5],
          shipToLine1: data.shipTo[0],
          shipToLine2: data.shipTo[1],
          shipToLine3: data.shipTo[2],
          shipToLine4: data.shipTo[3],
          shipToLine5: data.shipTo[4],
          shipToLine6: data.shipTo[5],
          shippingPlanName: data.shippingPlanName,
          createdAt: data.createdAt,
          batchAndBoxName: data.batchAndBoxName,
          itemQty: data.itemQty,
          skuQty: data.skuQty,
          barcode: data.barcode,
        }
        setTimeout(async () => {
          await this.downloadContentLabel(params)
        }, 250)
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    // #endregion
  },
}
</script>
