<template>
  <div class="p-1">
    <dx-util-form
      ref="batchItemFormRef"
      :form-data="fbaBatchParentItemWrapperFormData"
      :col-count="1"
      :show-colon-after-label="true"
      label-location="top"
      :scrolling-enabled="true"
      @field-data-changed="onFieldDataChanged"
      @content-ready="onFormContentReady"
    >
      <template #update-info-message-template>
        <div class="d-flex align-items-start">
          <div class="text-warning display-h-4">
            Please select and add additional items from inventory or inbound.
          </div>
        </div>
      </template>
      <template #missing-info-message-template>
        <div class="d-flex align-items-start">
          <div class="text-warning display-h-4">
            Create and add items into the batch. Please make sure that you first add all the items from inbound and inventory before creating new items. This action will create items in inbound > Shipment Plans!
          </div>
        </div>
      </template>
      <dx-util-simple-item :visible="isUpdateItem" template="update-info-message-template" />
      <dx-util-simple-item :visible="isMissingItem" template="missing-info-message-template" />
      <dx-util-simple-item
        ref="quantityField"
        data-field="quantity"
        :visible="isUpdateItem"
        editor-type="dxNumberBox"
        :editor-options="quantityOptions"
        :label="{text: isCasePacked ? 'Quantity in Each Case' : 'Quantity' }"
        :col-span="3"
      />
      <dx-util-item
        :visible="isCasePacked"
        data-field="quantityPerCase"
        :col-span="2"
        editor-type="dxNumberBox"
        :editor-options="quantityPerCaseOptions"
        :label="{text: 'Number of Cases' }"
      />
      <dx-util-simple-item
        data-field="missingItemQuantity"
        :visible="isMissingItem"
        editor-type="dxNumberBox"
        :editor-options="quantityMissingItemOptions"
      />
      <dx-util-simple-item
        data-field="trackingNumber"
        editor-type="dxTextBox"
        :label="{ text: 'Tracking Number' }"
      />
      <dx-util-simple-item
        data-field="orderNumber"
        editor-type="dxTextBox"
        :label="{ text: 'Order Number' }"
      />
    </dx-util-form>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/require-default-prop */
import { Notify } from '@robustshell/utils/index'
import CreateItemTypeEnum from '../createItemType'

export default {
  props: {
    onEmitHandleSubmitEvent: Function,
    fbaBatchParentItemWrapperFormData: {
      type: Object,
      default: () => ({
        id: null,
        companyId: null,
        warehouseId: null,
        storeId: null,
        batchParentId: null,
        quantity: 0,
        quantityPerCase: 0,
        missingItemQuantity: 0,
        countPerBundle: 0,
        productId: null,
        mskuFnskuPairId: null,
        cost: 0,
        salePrice: 0,
        supplier: '',
        title: '',
        upc: '',
        asin: '',
        msku: '',
        fnsku: '',
        itemCondition: '',
        planId: null,
        expirationDate: null,
        taxCode: '',
        listingItem: '',
        notes: '',
        packType: '',
        productType: '',
        createType: null,
        orderNumber: '',
        trackingNumber: '',
        palletId: '',
      }),
    },
    isCasePacked: {
      type: Boolean,
      required: true,
    },
    currentQuantities: {
      type: Object,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
    updateOrMissing: {
      type: Number || String || null,
      required: true,
    },
  },
  computed: {
    quantityOptions() {
      return {
        inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
      }
    },
    quantityMissingItemOptions() {
      return {
        inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
      }
    },
    quantityPerCaseOptions() {
      let min = 0
      if (this.isUpdateItem) min = this.currentQuantities.currentQuantityPerCase
      return {
        min: min,
        inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
      }
    },
    quantityDecrementOptions() {
      const max = this.currentQuantities.currentQuantity
      const isReadonly = this.isMissingItem
      return {
        readOnly: isReadonly,
        min: 1,
        max: max,
        step: 1,
        inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
      }
    },
    isUpdateItem() {
      return this.fbaBatchParentItemWrapperFormData.createType === CreateItemTypeEnum.update_quantity.value
    },
    isMissingItem() {
      return this.fbaBatchParentItemWrapperFormData.createType === CreateItemTypeEnum.create_and_add_missing_items.value
    },
  },
  watch: {
    fbaBatchParentItemWrapperFormData: {
      handler(newValue, oldValue) {
        if (newValue.quantity > oldValue.quantity) {
          this.fbaBatchParentItemWrapperFormData.quantity -= this.currentQuantities.currentQuantity
        }
      },
      deep: true, // Use deep watch to observe changes in nested properties
      immediate: true, // Use immediate to handle the prop value when the component is created
    },
  },
  mounted() {
    this.onEmitHandleSubmitEvent(this.handleSubmit)
    if (!this.isMissingItem) {
      this.fbaBatchParentItemWrapperFormData.quantity = 0
    }
  },
  beforeUpdate() {
    const form = this.$refs.batchItemFormRef.instance

    form.repaint()
  },
  methods: {
    onFieldDataChanged(e) {
      if (e.dataField === 'quantityPerCase' && this.isMissingItem) {
        if (e.value && e.value > this.currentQuantities.currentQuantityPerCase) {
          let newMissingItemQuantity = (parseInt(e.value, 10) - parseInt(this.currentQuantities.currentQuantityPerCase, 10)) * parseInt(this.currentQuantities.currentQuantity, 10)
          newMissingItemQuantity = newMissingItemQuantity && !Number.isNaN(newMissingItemQuantity) ? newMissingItemQuantity : 0
          e.component.updateData('missingItemQuantity', newMissingItemQuantity)
        } else {
          e.component.updateData('missingItemQuantity', 0)
          e.component.updateData('quantityPerCase', this.currentQuantities.currentQuantityPerCase)
          Notify.info('You can only change the combination of number of cases and quantity per case. However, number of total items in all packs cannot exceed the total existing quantity.')
        }
      }
    },
    onFormContentReady(e) {
      this.$nextTick(() => {
        const formInstance = this.$refs.batchItemFormRef.instance
        const quantityEditor = formInstance.getEditor('quantity')
        if (quantityEditor) {
          quantityEditor.focus()
        }
        const missingItemQuantityEditor = formInstance.getEditor('missingItemQuantity')
        if (missingItemQuantityEditor) {
          missingItemQuantityEditor.focus()
        }
      })
    },
    handleSubmit(payload) {
      this.fbaBatchParentItemWrapperFormData.items = payload.items
      this.fbaBatchParentItemWrapperFormData.charges = payload.charges
      this.fbaBatchParentItemWrapperFormData.listingItem = null
      this.fbaBatchParentItemWrapperFormData.quantity += this.currentQuantities.currentQuantity
      if (this.isMissingItem) {
        this.fbaBatchParentItemWrapperFormData.quantity = this.fbaBatchParentItemWrapperFormData.missingItemQuantity
      }
      this.$emit('on-emit-add-item-batch', this.fbaBatchParentItemWrapperFormData)
    },
  },
}
</script>

<style></style>
