/**
 * Enum representing Amazon Marketplaces.
 * @typedef {Object} AmazonMarketPlace
 * @property {string} id - The unique identifier of the Amazon Marketplace.
 * @property {string} countryCode - The country code of the Amazon Marketplace.
 * @property {string} currency - The currency used in the Amazon Marketplace.
 */

/**
 * Enum representing Amazon Marketplaces.
 * @enum {AmazonMarketPlace}
 */
const AmazonMarketPlaceEnum = Object.freeze({
  CA: { marketPlaceId: 'A2EUQ1WTGCTBG2', countryCode: 'CA', currencyCode: 'CAD' },
  US: { marketPlaceId: 'ATVPDKIKX0DER', countryCode: 'US', currencyCode: 'USD' },
  MX: { marketPlaceId: 'A1AM78C64UM0Y8', countryCode: 'MX', currencyCode: 'MXN' },
  BR: { marketPlaceId: 'A2Q3Y263D00KWC', countryCode: 'BR', currencyCode: 'BRL' },
  ES: { marketPlaceId: 'A1RKKUPIHCS9HS', countryCode: 'ES', currencyCode: 'EUR' },
  UK: { marketPlaceId: 'A1F83G8C2ARO7P', countryCode: 'UK', currencyCode: 'GBP' },
  FR: { marketPlaceId: 'A13V1IB3VIYZZH', countryCode: 'FR', currencyCode: 'EUR' },
  BE: { marketPlaceId: 'AMEN7PMS3EDWL', countryCode: 'BE', currencyCode: 'EUR' },
  NL: { marketPlaceId: 'A1805IZSGTT6HS', countryCode: 'NL', currencyCode: 'EUR' },
  DE: { marketPlaceId: 'A1PA6795UKMFR9', countryCode: 'DE', currencyCode: 'EUR' },
  IT: { marketPlaceId: 'APJ6JRA9NG5V4', countryCode: 'IT', currencyCode: 'EUR' },
  SE: { marketPlaceId: 'A2NODRKZP88ZB9', countryCode: 'SE', currencyCode: 'SEK' },
  ZA: { marketPlaceId: 'AE08WJ6YKNBMC', countryCode: 'ZA', currencyCode: 'ZAR' },
  PL: { marketPlaceId: 'A1C3SOZRARQ6R3', countryCode: 'PL', currencyCode: 'PLN' },
  EG: { marketPlaceId: 'ARBP9OOSHTCHU', countryCode: 'EG', currencyCode: 'EGP' },
  TR: { marketPlaceId: 'A33AVAJ2PDY3EV', countryCode: 'TR', currencyCode: 'TRY' },
  SA: { marketPlaceId: 'A17E79C6D8DWNP', countryCode: 'SA', currencyCode: 'SAR' },
  AE: { marketPlaceId: 'A2VIGQ35RCS4UG', countryCode: 'AE', currencyCode: 'AED' },
  IN: { marketPlaceId: 'A21TJRUUN4KGV', countryCode: 'IN', currencyCode: 'INR' },
  SG: { marketPlaceId: 'A19VAU5U5O7RUS', countryCode: 'SG', currencyCode: 'SGD' },
  AU: { marketPlaceId: 'A39IBJ37TRP1C6', countryCode: 'AU', currencyCode: 'AUD' },
  JP: { marketPlaceId: 'A1VC38T7YXB528', countryCode: 'JP', currencyCode: 'JPY' },

  /**
   * Find an Amazon Marketplace by country code.
   * @param {string} query - The country code to search for.
   * @returns {AmazonMarketPlace|undefined} - The Amazon Marketplace object if found, otherwise undefined.
   */
  find: query => Object.values(AmazonMarketPlaceEnum)
    .find(value => typeof value === 'object' && (value.countryCode === query)),
})

export default AmazonMarketPlaceEnum
