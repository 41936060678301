import find from 'lodash/find'
import moment from 'moment'

const expirationProductsEnum = Object.freeze({
  ALCOHOLIC_BEVERAGE: {
    key: 'ALCOHOLIC_BEVERAGE',
    text: 'Alcoholic Beverage',
  },
  ANIMAL_MEDICATION: {
    key: 'ANIMAL_MEDICATION',
    text: 'Animal Medication',
  },
  ANIMAL_NUTRITIONAL_SUPPLEMENT: {
    key: 'ANIMAL_NUTRITIONAL_SUPPLEMENT',
    text: 'Animal Nutritional Supplement',
  },
  ANIMAL_VITAMIN: {
    key: 'ANIMAL_VITAMIN',
    text: 'Animal Vitamin',
  },
  AQUARIUM_POND_WATER_TREATMENT: {
    key: 'AQUARIUM_POND_WATER_TREATMENT',
    text: 'Aquarium Pond Water Treatment',
  },
  AREA_DEODORIZER: {
    key: 'AREA_DEODORIZER',
    text: 'Area Deodorizer',
  },
  AROMA_DIFFUSER: {
    key: 'AROMA_DIFFUSER',
    text: 'Aroma Diffuser',
  },
  ASTRINGENT_SUBSTANCE: {
    key: 'ASTRINGENT_SUBSTANCE',
    text: 'Astringent Substance',
  },
  BABY_BOTTLE: {
    key: 'BABY_BOTTLE',
    text: 'Baby Bottle',
  },
  BABY_FORMULA: {
    key: 'BABY_FORMULA',
    text: 'Baby Formula',
  },
  BABY_PRODUCT: {
    key: 'BABY_PRODUCT',
    text: 'Baby Product',
  },
  BABY_RATTLE: {
    key: 'BABY_RATTLE',
    text: 'Baby Rattle',
  },
  BAKING_CHOCOLATE: {
    key: 'BAKING_CHOCOLATE',
    text: 'Baking Chocolate',
  },
  BAKING_MIX: {
    key: 'BAKING_MIX',
    text: 'Baking Mix',
  },
  BAKING_PAPER: {
    key: 'BAKING_PAPER',
    text: 'Baking Paper',
  },
  BALANCE_BOARD: {
    key: 'BALANCE_BOARD',
    text: 'Balance Board',
  },
  BEAUTY: {
    key: 'BEAUTY',
    text: 'Beauty',
  },
  BEVERAGE: {
    key: 'BEVERAGE',
    text: 'Beverage',
  },
  BEVERAGE_INSULATOR: {
    key: 'BEVERAGE_INSULATOR',
    text: 'Beverage Insulator',
  },
  BLOWTORCH: {
    key: 'BLOWTORCH',
    text: 'Blowtorch',
  },
  BODY_DEODORANT: {
    key: 'BODY_DEODORANT',
    text: 'Body Deodorant',
  },
  BODY_LUBRICANT: {
    key: 'BODY_LUBRICANT',
    text: 'Body Lubricant',
  },
  BODY_PAINT: {
    key: 'BODY_PAINT',
    text: 'Body Paint',
  },
  BODY_POSITIONER: {
    key: 'BODY_POSITIONER',
    text: 'Body Positioner',
  },
  BODY_STRAP: {
    key: 'BODY_STRAP',
    text: 'Body Strap',
  },
  BREAD: {
    key: 'BREAD',
    text: 'Bread',
  },
  BREAKFAST_CEREAL: {
    key: 'BREAKFAST_CEREAL',
    text: 'Breakfast Cereal',
  },
  CAKE: {
    key: 'CAKE',
    text: 'Cake',
  },
  CAKE_TOPPER: {
    key: 'CAKE_TOPPER',
    text: 'Cake Topper',
  },
  CANDY: {
    key: 'CANDY',
    text: 'Candy',
  },
  CEREAL: {
    key: 'CEREAL',
    text: 'Cereal',
  },
  CHEWING_GUM: {
    key: 'CHEWING_GUM',
    text: 'Chewing Gum',
  },
  CHOCOLATE_CANDY: {
    key: 'CHOCOLATE_CANDY',
    text: 'Chocolate Candy',
  },
  COFFEE: {
    key: 'COFFEE',
    text: 'Coffee',
  },
  CONCENTRATED_FLAVORING_SUBSTANCE: {
    key: 'CONCENTRATED_FLAVORING_SUBSTANCE',
    text: 'Concentrated Flavoring Substance',
  },
  CONDIMENT: {
    key: 'CONDIMENT',
    text: 'Condiment',
  },
  COOKIE: {
    key: 'COOKIE',
    text: 'Cookie',
  },
  COSMETIC_POWDER: {
    key: 'COSMETIC_POWDER',
    text: 'Cosmetic Powder',
  },
  COSTUME_EYEWEAR: {
    key: 'COSTUME_EYEWEAR',
    text: 'Costume Eyewear',
  },
  CULINARY_SALT: {
    key: 'CULINARY_SALT',
    text: 'Culinary Salt',
  },
  DAILY_LIVING_AIDS: {
    key: 'DAILY_LIVING_AIDS',
    text: 'Daily Living Aids',
  },
  DAIRY_BASED_BUTTER: {
    key: 'DAIRY_BASED_BUTTER',
    text: 'Dairy-Based Butter',
  },
  DAIRY_BASED_CHEESE: {
    key: 'DAIRY_BASED_CHEESE',
    text: 'Dairy-Based Cheese',
  },
  DAIRY_BASED_CREAM: {
    key: 'DAIRY_BASED_CREAM',
    text: 'Dairy-Based Cream',
  },
  DAIRY_BASED_DRINK: {
    key: 'DAIRY_BASED_DRINK',
    text: 'Dairy-Based Drink',
  },
  DAIRY_BASED_ICE_CREAM: {
    key: 'DAIRY_BASED_ICE_CREAM',
    text: 'Dairy-Based Ice Cream',
  },
  DAIRY_BASED_YOGURT: {
    key: 'DAIRY_BASED_YOGURT',
    text: 'Dairy-Based Yogurt',
  },
  DIETARY_SUPPLEMENTS: {
    key: 'DIETARY_SUPPLEMENTS',
    text: 'Dietary Supplements',
  },
  DRESSER: {
    key: 'DRESSER',
    text: 'Dresser',
  },
  DRIED_PLANT: {
    key: 'DRIED_PLANT',
    text: 'Dried Plant',
  },
  DRINK_COASTER: {
    key: 'DRINK_COASTER',
    text: 'Drink Coaster',
  },
  DRINK_FLAVORED: {
    key: 'DRINK_FLAVORED',
    text: 'Drink Flavored',
  },
  EDIBLE_OIL_ANIMAL: {
    key: 'EDIBLE_OIL_ANIMAL',
    text: 'Edible Oil (Animal)',
  },
  EDIBLE_OIL_VEGETABLE: {
    key: 'EDIBLE_OIL_VEGETABLE',
    text: 'Edible Oil (Vegetable)',
  },
  ESSENTIAL_OIL: {
    key: 'ESSENTIAL_OIL',
    text: 'Essential Oil',
  },
  EYEBROW_COLOR: {
    key: 'EYEBROW_COLOR',
    text: 'Eyebrow Color',
  },
  FACE_SHAPING_MAKEUP: {
    key: 'FACE_SHAPING_MAKEUP',
    text: 'Face Shaping Makeup',
  },
  FACE_SHIELD: {
    key: 'FACE_SHIELD',
    text: 'Face Shield',
  },
  FACIAL_STEAMER: {
    key: 'FACIAL_STEAMER',
    text: 'Facial Steamer',
  },
  FACIAL_TISSUE: {
    key: 'FACIAL_TISSUE',
    text: 'Facial Tissue',
  },
  FACIAL_TISSUE_HOLDER: {
    key: 'FACIAL_TISSUE_HOLDER',
    text: 'Facial Tissue Holder',
  },
  FALSE_EYELASH: {
    key: 'FALSE_EYELASH',
    text: 'False Eyelash',
  },
  FISH: {
    key: 'FISH',
    text: 'Fish',
  },
  FLOUR: {
    key: 'FLOUR',
    text: 'Flour',
  },
  FOOD: {
    key: 'FOOD',
    text: 'Food',
  },
  FOOD_AND_BEVERAGE: {
    key: 'FOOD_AND_BEVERAGE',
    text: 'Food and Beverage',
  },
  FOOD_BIB: {
    key: 'FOOD_BIB',
    text: 'Food Bib',
  },
  FOOD_COLORING: {
    key: 'FOOD_COLORING',
    text: 'Food Coloring',
  },
  FOOD_DEHYDRATOR: {
    key: 'FOOD_DEHYDRATOR',
    text: 'Food Dehydrator',
  },
  FOOD_GLITTER_SPRINKLE: {
    key: 'FOOD_GLITTER_SPRINKLE',
    text: 'Food Glitter Sprinkle',
  },
  FOOD_GRATER: {
    key: 'FOOD_GRATER',
    text: 'Food Grater',
  },
  FOOD_PREPARATION_MOLD: {
    key: 'FOOD_PREPARATION_MOLD',
    text: 'Food Preparation Mold',
  },
  FOOTREST: {
    key: 'FOOTREST',
    text: 'Footrest',
  },
  FRUIT: {
    key: 'FRUIT',
    text: 'Fruit',
  },
  FRUIT_SNACK: {
    key: 'FRUIT_SNACK',
    text: 'Fruit Snack',
  },
  GOURMET_FOOD: {
    key: 'GOURMET_FOOD',
    text: 'Gourmet Food',
  },
  GROCERY: {
    key: 'GROCERY',
    text: 'Grocery',
  },
  HAIR_COLORING_AGENT: {
    key: 'HAIR_COLORING_AGENT',
    text: 'Hair Coloring Agent',
  },
  HAND_SANITIZER: {
    key: 'HAND_SANITIZER',
    text: 'Hand Sanitizer',
  },
  HONEY: {
    key: 'HONEY',
    text: 'Honey',
  },
  LUXURY_BEAUTY: {
    key: 'LUXURY_BEAUTY',
    text: 'Luxury Beauty',
  },
  MAKE_UP: {
    key: 'MAKE_UP',
    text: 'Makeup',
  },
  MAKEUP_PRIMER: {
    key: 'MAKEUP_PRIMER',
    text: 'Makeup Primer',
  },
  MASCARA: {
    key: 'MASCARA',
    text: 'Mascara',
  },
  MEAL_REPLACEMENT_BEVERAGE: {
    key: 'MEAL_REPLACEMENT_BEVERAGE',
    text: 'Meal Replacement Beverage',
  },
  MEAT: {
    key: 'MEAT',
    text: 'Meat',
  },
  MEDICATION: {
    key: 'MEDICATION',
    text: 'Medication',
  },
  MILK_FROTHER: {
    key: 'MILK_FROTHER',
    text: 'Milk Frother',
  },
  MILK_SUBSTITUTE: {
    key: 'MILK_SUBSTITUTE',
    text: 'Milk Substitute',
  },
  MINERAL_SUPPLEMENT: {
    key: 'MINERAL_SUPPLEMENT',
    text: 'Mineral Supplement',
  },
  NAIL_POLISH: {
    key: 'NAIL_POLISH',
    text: 'Nail Polish',
  },
  NAIL_POLISH_REMOVER: {
    key: 'NAIL_POLISH_REMOVER',
    text: 'Nail Polish Remover',
  },
  NAIL_POWDER: {
    key: 'NAIL_POWDER',
    text: 'Nail Powder',
  },
  NON_DAIRY_CREAM: {
    key: 'NON_DAIRY_CREAM',
    text: 'Non-Dairy Cream',
  },
  NOODLE: {
    key: 'NOODLE',
    text: 'Noodle',
  },
  NUT_AND_SEED: {
    key: 'NUT_AND_SEED',
    text: 'Nut and Seed',
  },
  NUT_BUTTER: {
    key: 'NUT_BUTTER',
    text: 'Nut Butter',
  },
  NUTRITIONAL_SUPPLEMENT: {
    key: 'NUTRITIONAL_SUPPLEMENT',
    text: 'Nutritional Supplement',
  },
  NUTRITION_BAR: {
    key: 'NUTRITION_BAR',
    text: 'Nutrition Bar',
  },
  NUTS: {
    key: 'NUTS',
    text: 'Nuts',
  },
  OLIVE: {
    key: 'OLIVE',
    text: 'Olive',
  },
  OTC_MEDICATION: {
    key: 'OTC_MEDICATION',
    text: 'Over-the-Counter Medication',
  },
  PACKAGED_SOUP_AND_STEW: {
    key: 'PACKAGED_SOUP_AND_STEW',
    text: 'Packaged Soup and Stew',
  },
  PASTRY: {
    key: 'PASTRY',
    text: 'Pastry',
  },
  PET_FOOD: {
    key: 'PET_FOOD',
    text: 'Pet Food',
  },
  PET_HEALTH_CARE: {
    key: 'PET_HEALTH_CARE',
    text: 'Pet Health Care',
  },
  PET_SUPPLIES: {
    key: 'PET_SUPPLIES',
    text: 'Pet Supplies',
  },
  POPCORN: {
    key: 'POPCORN',
    text: 'Popcorn',
  },
  POULTRY: {
    key: 'POULTRY',
    text: 'Poultry',
  },
  PRETZEL: {
    key: 'PRETZEL',
    text: 'Pretzel',
  },
  PROTEIN_DRINK: {
    key: 'PROTEIN_DRINK',
    text: 'Protein Drink',
  },
  PROTEIN_SUPPLEMENT_POWDER: {
    key: 'PROTEIN_SUPPLEMENT_POWDER',
    text: 'Protein Supplement Powder',
  },
  RICE_MIX: {
    key: 'RICE_MIX',
    text: 'Rice Mix',
  },
  RICE_WINE: {
    key: 'RICE_WINE',
    text: 'Rice Wine',
  },
  SALAD_DRESSING: {
    key: 'SALAD_DRESSING',
    text: 'Salad Dressing',
  },
  SEAFOOD: {
    key: 'SEAFOOD',
    text: 'Seafood',
  },
  SEASONING: {
    key: 'SEASONING',
    text: 'Seasoning',
  },
  SEASONING_MILL: {
    key: 'SEASONING_MILL',
    text: 'Seasoning Mill',
  },
  SEEDS_AND_PLANTS: {
    key: 'SEEDS_AND_PLANTS',
    text: 'Seeds and Plants',
  },
  SKIN_BLEMISH_REMOVAL_TOOL: {
    key: 'SKIN_BLEMISH_REMOVAL_TOOL',
    text: 'Skin Blemish Removal Tool',
  },
  SKIN_CARE_AGENT: {
    key: 'SKIN_CARE_AGENT',
    text: 'Skin Care Agent',
  },
  SKIN_CARE_PRODUCT: {
    key: 'SKIN_CARE_PRODUCT',
    text: 'Skin Care Product',
  },
  SKIN_CLEANING_AGENT: {
    key: 'SKIN_CLEANING_AGENT',
    text: 'Skin Cleaning Agent',
  },
  SKIN_CLEANING_WIPE: {
    key: 'SKIN_CLEANING_WIPE',
    text: 'Skin Cleaning Wipe',
  },
  SKIN_EXFOLIANT: {
    key: 'SKIN_EXFOLIANT',
    text: 'Skin Exfoliant',
  },
  SKIN_FOUNDATION_CONCEALER: {
    key: 'SKIN_FOUNDATION_CONCEALER',
    text: 'Skin Foundation/Concealer',
  },
  SKIN_MOISTURIZER: {
    key: 'SKIN_MOISTURIZER',
    text: 'Skin Moisturizer',
  },
  SKIN_PROTECTANT: {
    key: 'SKIN_PROTECTANT',
    text: 'Skin Protectant',
  },
  SKIN_SERUM: {
    key: 'SKIN_SERUM',
    text: 'Skin Serum',
  },
  SKIN_TREATMENT_MASK: {
    key: 'SKIN_TREATMENT_MASK',
    text: 'Skin Treatment Mask',
  },
  SNACK_CHIP_AND_CRISP: {
    key: 'SNACK_CHIP_AND_CRISP',
    text: 'Snack Chip and Crisp',
  },
  SNACK_FOOD_BAR: {
    key: 'SNACK_FOOD_BAR',
    text: 'Snack Food Bar',
  },
  SNACK_MIX: {
    key: 'SNACK_MIX',
    text: 'Snack Mix',
  },
  SUGAR: {
    key: 'SUGAR',
    text: 'Sugar',
  },
  SUGAR_CANDY: {
    key: 'SUGAR_CANDY',
    text: 'Sugar Candy',
  },
  SUGAR_SUBSTITUTE: {
    key: 'SUGAR_SUBSTITUTE',
    text: 'Sugar Substitute',
  },
  SYRUP: {
    key: 'SYRUP',
    text: 'Syrup',
  },
  TEA: {
    key: 'TEA',
    text: 'Tea',
  },
  TOOTHBRUSH: {
    key: 'TOOTHBRUSH',
    text: 'Toothbrush',
  },
  VEGETABLE: {
    key: 'VEGETABLE',
    text: 'Vegetable',
  },
  VINE: {
    key: 'VINE',
    text: 'Vine',
  },
  VINEGAR: {
    key: 'VINEGAR',
    text: 'Vinegar',
  },
  VITAMIN: {
    key: 'VITAMIN',
    text: 'Vitamin',
  },
})

function getExpirationDate(predicate, predicateKey = 'key') {
  const result = find(expirationProductsEnum, { [predicateKey]: predicate })
  if (result) {
    const currentDate = moment()
    const newDate = currentDate.add(18, 'months')
    const date = moment(newDate).format('MM/DD/YYYY')
    return date
  }
  return null
}

export default expirationProductsEnum
export {
  getExpirationDate,
}
