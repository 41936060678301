import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const guidanceReasonEnum = Object.freeze({
  SLOW_MOVING_ASIN: { key: 'SLOWMOVINGASIN', value: 'Slow Moving ASIN', text: 'The ASIN is well stocked and/or not selling quickly.' },
  NO_APPLICAB_LEGUIDANCE: { key: 'NOAPPLICABLEGUIDANCE', value: 'No Applicable Guidance', text: 'No applicable guidance.' },
})

function getGuidanceReasonEnum(predicate, predicateKey = 'key') {
  const result = find(guidanceReasonEnum, { [predicateKey]: predicate })
  return result || { value: 'None', text: 'None' }
}

export default guidanceReasonEnum

export {
  getGuidanceReasonEnum,
}
