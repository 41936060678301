import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const prepInstructionEnum = Object.freeze({
  POLYBAGGING: {
    key: 'POLYBAGGING',
    value: 'Polybagging',
    text: 'Indicates that polybagging is required.',
  },
  BUBBLE_WRAPPING: {
    key: 'BUBBLEWRAPPING',
    value: 'Bubble Wrapping',
    text: 'Indicates that bubble wrapping is required.',
  },
  TAPING: {
    key: 'TAPING',
    value: 'Taping',
    text: 'Indicates that taping is required.',
  },
  BLACK_SHRINK_WRAPPING: {
    key: 'BLACKSHRINKWRAPPING',
    value: 'Black Shrink Wrapping',
    text: 'Indicates that black shrink wrapping is required.',
  },
  LABELING: {
    key: 'LABELING',
    value: 'Labeling',
    text: 'Indicates that the FNSKU label should be applied to the item.',
  },
  HANG_GARMENT: {
    key: 'HANGGARMENT',
    value: 'Hang Garment',
    text: 'Indicates that the item should be placed on a hanger.',
  },
})

function getPrepInstructionEnum(predicate, predicateKey = 'key') {
  const result = find(prepInstructionEnum, { [predicateKey]: predicate })
  return result || { value: 'None', text: 'None' }
}

export default prepInstructionEnum

export {
  getPrepInstructionEnum,
}
