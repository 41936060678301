import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getInboundGuidance(data) {
    const result = await axios.post(endpoints.externalAmazon.controller.getInboundGuidance(), data)
    return result.data.body
  },

  async getInventorySummaries(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.externalAmazon.controller.getInventorySummaries(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async getCatalogItemByAsin(asin) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.externalAmazon.controller.getCatalogItemByAsin(asin))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async searchCatalogItems(storeId, data) {
    const result = await axios.post(endpoints.externalAmazon.controller.searchCatalogItems(storeId), data)
    return result.data.body
  },

  async getItemOffersByAsin(storeId, condition, asin, countryCode) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.externalAmazon.controller.getItemOffersByAsin(storeId, condition, asin, countryCode))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getCatalogItemVariationsByAsin(asin) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.externalAmazon.controller.getCatalogItemVariationsByAsin(asin))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getPrepInstructions(storeId, countryCode, data) {
    const result = await axios.post(endpoints.externalAmazon.controller.getPrepInstructions(storeId, countryCode), data)
    return result.data.body
  },
  // @to-be-deleted
  async getListingRestrictions(storeId, asin) {
    const result = await axios.get(endpoints.externalAmazon.controller.getListingRestrictions(storeId, asin))
    return result.data.body
  },
  async getProductDefinition(storeId, asin) {
    const result = await axios.get(endpoints.externalAmazon.controller.getProductDefinition(storeId, asin))
    return result.data.body
  },
  async getShippingRates(storeId, data) {
    const result = await axios.post(endpoints.externalAmazon.controller.getShippingRates(storeId), data)
    return result.data.body
  },
}
