import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const shippingLabelPageEnum = Object.freeze({
  PACKAGELABEL_LETTER_2: {
    key: 'PackageLabel_Letter_2',
    value: 'PackageLabel_Letter_2',
    text: 'FBA and Shipping Labels Single Page',
    visible: true,
    description: 'Two labels per US Letter label sheet. This is the only valid value for Amazon-partnered shipments in the US that use United Parcel Service (UPS) as the carrier. Supported in Canada and the US.',
  },
  PACKAGELABEL_LETTER_4: {
    key: 'PackageLabel_Letter_4',
    value: 'PackageLabel_Letter_4',
    text: 'PackageLabel Letter 4',
    visible: false,
    description: 'Four labels per US Letter label sheet. This is the only valid value for non-Amazon-partnered shipments in the US. Supported in Canada and the US.',
  },
  PACKAGELABEL_LETTER_6: {
    key: 'PackageLabel_Letter_6',
    value: 'PackageLabel_Letter_6',
    text: 'Package Label Letter 6',
    visible: false,
    description: 'Six labels per US Letter label sheet. This is the only valid value for non-Amazon-partnered shipments in the US. Supported in Canada and the US.',
  },
  PACKAGELABEL_LETTER_6_CARRIERLEFT: {
    key: 'PackageLabel_Letter_6_CarrierLeft',
    value: 'PackageLabel_Letter_6_CarrierLeft',
    text: 'Package Label Letter 6 Carrier Left',
    visible: false,
    description: 'PackageLabel_Letter_6_CarrierLeft',
  },
  PACKAGELABEL_A4_2: {
    key: 'PackageLabel_A4_2',
    value: 'PackageLabel_A4_2',
    text: 'PackageLabel A4 2',
    visible: false,
    description: 'Two labels per A4 label sheet.',
  },
  PACKAGELABEL_A4_4: {
    key: 'PackageLabel_A4_4',
    value: 'PackageLabel_A4_4',
    text: 'PackageLabel A4 4',
    visible: false,
    description: 'Four labels per A4 label sheet.',
  },
  PACKAGELABEL_PLAIN_PAPER: {
    key: 'PackageLabel_Plain_Paper',
    value: 'PackageLabel_Plain_Paper',
    text: 'PackageLabel Plain Paper',
    visible: false,
    description: 'One label per sheet of US Letter paper. Only for non-Amazon-partnered shipments.',
  },
  PACKAGELABEL_PLAIN_PAPER_CARRIERBOTTOM: {
    key: 'PackageLabel_Plain_Paper_CarrierBottom',
    value: 'PackageLabel_Plain_Paper_CarrierBottom',
    text: 'PackageLabel Plain Paper Carrier Bottom',
    visible: false,
    description: 'PackageLabel_Plain_Paper_CarrierBottom',
  },
  PACKAGELABEL_THERMAL: {
    key: 'PackageLabel_Thermal',
    value: 'PackageLabel_Thermal',
    text: 'FBA and Shipping Labels Separate Pages',
    visible: true,
    description: 'For use of a thermal printer. Supports Amazon-partnered shipments with UPS.',
  },
  PACKAGELABEL_THERMAL_UNIFIED: {
    key: 'PackageLabel_Thermal_Unified',
    value: 'PackageLabel_Thermal_Unified',
    text: 'PackageLabel Thermal Unified',
    visible: false,
    description: 'For use of a thermal printer. Supports shipments with ATS.',
  },
  PACKAGELABEL_THERMAL_NONPCP: {
    key: 'PackageLabel_Thermal_NonPCP',
    value: 'PackageLabel_Thermal_NonPCP',
    text: 'PackageLabel Thermal NonPCP',
    visible: false,
    description: 'For use of a thermal printer. Supports non-Amazon-partnered shipments.',
  },
  PACKAGELABEL_THERMAL_NO_CARRIER_ROTATION: {
    key: 'PackageLabel_Thermal_No_Carrier_Rotation',
    value: 'PackageLabel_Thermal_No_Carrier_Rotation',
    text: 'Packagelabel Thermal No Carrier Rotation',
    visible: false,
    description: 'For use of a thermal printer. Supports Amazon-partnered shipments with DHL.',
  },
})

function getShippingLabelPageEnum(predicate, predicateKey = 'key') {
  const result = find(shippingLabelPageEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getShippingLabelPageEnumList() {
  const options = Object.keys(shippingLabelPageEnum).map(key => (
    {
      value: shippingLabelPageEnum[key].value,
      text: shippingLabelPageEnum[key].text,
      description: shippingLabelPageEnum[key].description,
      visible: shippingLabelPageEnum[key].visible,
    }))
  return options
}

export default shippingLabelPageEnum

export {
  getShippingLabelPageEnum,
  getShippingLabelPageEnumList,
}
