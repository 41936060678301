<template>
  <dx-util-scroll-view show-scrollbar="onHover">
    <div class="row p-1">
      <div class="col-12">
        <div v-if="!hasCurrentMSKU" class="pb-1">
          <i>You do not have a MSKU formula. You can create a new MSKU formula using this page.</i>
        </div>
      </div>
      <div class="col-6 pb-2">
        <table style="width: 100%;">
          <tr v-if="!hasCurrentMSKU">
            <td class="w-25 text-bold">
              <small class="d-block">
                Default MSKU Formula
              </small>
              <strong>
                COUNT
              </strong>
            </td>
          </tr>
          <tr>
            <td class="w-25 text-bold">
              <small class="d-block">
                Current MSKU Formula
              </small>
              <strong>
                {{ currentMSKU }}
                <i v-if="hasCurrentMSKU" class="edit-formula dx-icon dx-icon-edit"
                  @click="onSetUpdateMSKU"
                ></i>
              </strong>
            </td>
          </tr>
          <tr v-if="isPreviewMSKU">
            <td class="w-25 text-bold">
              <small class="d-block">
                MSKU Formula Preview
              </small>
              <strong class="text-warning">
                {{ previewMSKU }}
              </strong>
            </td>
          </tr>
          <tr>
            <td class="w-25 text-bold">
              <small class="d-block">
                Sample MSKU
              </small>
              <strong>
                {{ sampleMSKU }}
              </strong>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-6">
        <dx-sortable id="list" drop-feedback-mode="push"
          item-orientation="vertical" drag-direction="vertical"
          :scroll-speed="30" :scroll-sensitivity="60"
          handle=".handle" :drag-template="dragTemplate"
          :cursor-offset="cursorOffset" @drag-start="onDragStart"
          @reorder="onReorder"
        >
          <template #content>
            <div>
              <div v-for="(item, index) in mskuPatterns" :key="item.id"
                class="item item-with-handle dx-card dx-theme-text-color dx-theme-background-color"
              >
                <div class="d-flex flex-row justify-content-start">
                  <i class="handle dx-icon dx-icon-dragvertical align-self-center" />
                  <div class="w-100 mr-1">
                    <dx-util-select-box :id="item.id" :ref="item.id"
                      v-model="item.msku" :data-source="mskuDataSource"
                      :read-only="item.msku.required" display-expr="text"
                      @custom-item-creating="mskuPatternItemCreating($event)"
                      @value-changed="onMSKUValueChanged($event, item.id)"
                  />
                  </div>
                  <div class="mr-1">
                    <dx-util-select-box :id="item.id" v-model="item.seperatorValue"
                      :data-source="seperatorDataSource"
                      value-expr="value" display-expr="text"
                      @value-changed="onSeperatorValueChanged"
                    />
                  </div>
                  <div>
                    <dx-util-button icon="trash" type="danger"
                      :disabled="isRemovable || item.msku.required"
                      @click="onRemoveMSKUOption($event, index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </dx-sortable>
        <div class="d-flex flex-row justify-content-start">
          <div class="w-100 mr-1">
            <dx-util-button text="Add MSKU Varaible Option" type="default"
              width="100%" :disabled="isAddNew"
              @click="onAddMSKUOption"
            />
          </div>
          <div class="w-100">
            <dx-util-button text="Save MSKU Formula" type="success"
              width="100%" :visible="!hasCurrentMSKU"
              @click="onCreateMSKUFormula"
            />
            <dx-util-button text="Save MSKU Formula" type="success"
              width="100%" :visible="hasCurrentMSKU"
              @click="onUpdateMSKUFormula"
            />
          </div>
        </div>
      </div>
    </div>
  </dx-util-scroll-view>
</template>

<script>
import DxSortable from 'devextreme-vue/sortable'
import { v4 as uuidv4 } from 'uuid'
import settingsService from '@/http/requests/common/settings'
import { isArray } from 'lodash/lang'
import DataSource from 'devextreme/data/data_source'
import formulaChangeTypeEnum from '@/enums/msku-formula-change-type.enum'
import { ReferenceTypeEnum, SettingsTypeEnum } from '~/enums'
import useStoreSettings from './useStoreSettings'

const mskuDataSource = new DataSource({
  store: {
    data: [
      {
        text: 'COUNT', value: 'COUNT', sample: '25', required: true, disabled: true,
      },
      {
        text: 'BUY COST', value: 'BUYCOST', sample: '6.85', required: false, disabled: false,
      },
      {
        text: 'ASIN', value: 'ASIN', sample: 'B00002JV62', required: false, disabled: false,
      },
      {
        text: 'CONDITION', value: 'CONDITION', sample: 'NEW', required: false, disabled: false,
      },
      {
        text: 'LIST PRICE', value: 'LISTPRICE', sample: '7.57', required: false, disabled: false,
      },
      {
        text: 'PURCHASE DATE', value: 'PURCHASEDATE', sample: '1/1/1999', required: false, disabled: false,
      },
      {
        text: 'SALES RANK', value: 'SALESRANK', sample: '18,141,788', required: false, disabled: false,
      },
      {
        text: 'SUPPLIER', value: 'SUPPLIER', sample: 'Microsoft', required: false, disabled: false,
      },
    ],
    type: 'array',
    key: 'value',
  },
})

const seperatorDataSource = new DataSource({
  store: {
    data: [
      { text: '_', value: '_' },
      { text: '-', value: '-' },
      { text: '.', value: '.' },
      { text: ',', value: ',' },
      { text: '?', value: '?' },
      { text: '|', value: '|' },
    ],
    type: 'array',
    key: 'value',
  },
})

export default {
  components: {
    'dx-sortable': DxSortable,
  },
  data() {
    return {
      handle: '.handle',
      dragTemplate: false,
      mskuDataSource,
      seperatorDataSource,
      mskuPatterns: [
        {
          id: uuidv4(),
          msku: {
            value: 'COUNT',
            sample: '25',
            required: true,
          },
          seperatorValue: '_',
        },
      ],
      currentMSKU: '',
      previewMSKU: 'COUNT',
      sampleMSKU: '25',
      mskuFormulaSetting: {},
      storeDataSource: [],
      selectedCompanyId: 0,
      selectedStore: 0,
      selectedStoreName: '',
      initialMskuPatternLen: 0,
    }
  },
  setup() {
    const { currentStore } = useStoreSettings()
    return {
      currentStore,
    }
  },
  computed: {
    cursorOffset() {
      return this.dragTemplate ? { x: 10, y: 20 } : null
    },
    isAddNew() {
      return !(isArray(this.mskuPatterns) && this.mskuPatterns.length < 5)
    },
    isRemovable() {
      return !(isArray(this.mskuPatterns) && this.mskuPatterns.length > 1)
    },
    hasCurrentMSKU() {
      return this.currentMSKU !== ''
    },
    isPreviewMSKU() {
      return this.previewMSKU !== ''
    },
  },
  watch: {
    currentStore() {
      if (this.currentStore.id) {
        this.setDefaults()
        this.getCurrentMSKU()
      }
    },
  },
  mounted() {
    if (this.currentStore.id) {
      this.setDefaults()
      this.getCurrentMSKU()
    }
  },
  methods: {
    setDefaults() {
      this.selectedCompanyId = this.currentStore?.parentCompany
      this.selectedStore = this.currentStore?.id
      this.selectedStoreName = this.currentStore?.name
    },
    getCurrentMSKU() {
      settingsService
        .getByReference(
          ReferenceTypeEnum.STORE.value,
          this.selectedStore,
          SettingsTypeEnum.MSKUFORMULA.value,
        )
        .then(result => {
          const data = { ...result.data }
          if (data.id) {
            this.mskuFormulaSetting = {
              id: data.id,
              companyId: data.companyId,
              referenceId: data.referenceId,
              referenceType: data.referenceType,
              settingType: data.settingType,
              settingKey: data.settingKey,
              settingName: data.settingName,
              description: data.description,
              value: JSON.parse(data.value),
            }
            this.currentMSKUFormulaText(data.value)
            this.setInitialMskuPatternLen(data.value)
            this.onSetUpdateMSKU()
          }
        })
    },
    onAddMSKUOption() {
      this.mskuPatterns.push({
        id: uuidv4(),
        msku: {
          value: 'BUYCOST',
          sample: '6.85',
          required: false,
        },
        seperatorValue: '_',
      })
      this.mskuFormulaPreview()
    },
    onRemoveMSKUOption(e, index) {
      this.mskuPatterns.splice(index, 1)
      this.mskuFormulaPreview()
    },
    mskuPatternItemCreating(data) {
      const newItem = {
        text: data.text,
        value: data.text,
        sample: data.text,
      }
      // eslint-disable-next-line no-param-reassign
      data.customItem = mskuDataSource
        .store()
        .insert(newItem)
        .then(() => mskuDataSource.load())
        .then(() => newItem)
        .catch(error => {
          throw error
        })
    },
    onMSKUValueChanged(e) {
      this.mskuFormulaPreview()
    },
    onSeperatorValueChanged(e) {
      this.mskuFormulaPreview()
    },
    onDragStart(e) {
      e.itemData = this.mskuPatterns[e.fromIndex]
    },
    onReorder(e) {
      this.mskuPatterns.splice(e.fromIndex, 1)
      this.mskuPatterns.splice(e.toIndex, 0, e.itemData)
      this.mskuFormulaPreview()
    },
    mskuFormulaPreview() {
      this.previewMSKU = ''
      this.sampleMSKU = ''
      this.mskuPatterns.forEach(item => {
        this.previewMSKU += item.msku.value + item.seperatorValue
        this.sampleMSKU += item.msku.sample + item.seperatorValue
      })
      this.previewMSKU = this.previewMSKU.slice(0, -1)
      this.sampleMSKU = this.sampleMSKU.slice(0, -1)
    },
    onCreateMSKUFormula() {
      const data = this.normalizeMSKUPatternValue()
      const mskuFormula = {
        companyId: this.selectedCompanyId,
        referenceId: this.selectedStore,
        referenceType: ReferenceTypeEnum.STORE.value,
        settingType: SettingsTypeEnum.MSKUFORMULA.value,
        settingKey: SettingsTypeEnum.MSKUFORMULA.value,
        description: `${this.selectedStoreName} MSKU Formula`,
        settingName: `${this.selectedStoreName} MSKU Formula`,
        value: data,
      }

      settingsService
        .create(mskuFormula)
        .then(() => {
          this.currentMSKUFormulaText(mskuFormula.value)
        })
    },
    onSetUpdateMSKU() {
      this.mskuPatterns.splice(0, this.mskuPatterns.length)
      if (this.mskuFormulaSetting && this.mskuFormulaSetting.value) {
        this.mskuFormulaSetting.value.forEach(pattern => {
          this.mskuPatterns.push({
            id: uuidv4(),
            msku: {
              required: pattern.msku.value === 'COUNT',
              value: pattern.msku.value,
              sample: pattern.msku.sample,
            },
            seperatorValue: pattern.seperatorValue,
          })
        })
      }
    },
    onUpdateMSKUFormula() {
      const updateMskuPatternLen = this.mskuPatterns.length
      let changeType = ''
      if (updateMskuPatternLen === this.initialMskuPatternLen || (this.initialMskuPatternLen > 1 && updateMskuPatternLen > 1)) {
        changeType = formulaChangeTypeEnum.NO_CHANGE.value
      }
      if (this.initialMskuPatternLen > 1 && updateMskuPatternLen === 1) {
        changeType = formulaChangeTypeEnum.FORMULA_TO_COUNTER.value
      }
      if (updateMskuPatternLen > 1 && this.initialMskuPatternLen === 1) {
        changeType = formulaChangeTypeEnum.COUNTER_TO_FORMULA.value
      }
      const data = this.normalizeMSKUPatternValue()
      this.mskuFormulaSetting.value = data
      const mskuFormula = { ...this.mskuFormulaSetting, formulaChangeType: changeType }
      settingsService
        .update(mskuFormula)
        .then(() => {
          this.getCurrentMSKU()
        })
    },
    normalizeMSKUPatternValue() {
      const data = []
      this.mskuPatterns.forEach((item, key) => {
        const pattern = {
          order: key,
          msku: {
            value: item.msku.value,
            required: item.msku.required,
            sample: item.msku.sample,
          },
          seperatorValue: item.seperatorValue,
        }
        data.push(pattern)
      })
      return JSON.stringify(data)
    },
    setInitialMskuPatternLen(pattern) {
      const patternArr = JSON.parse(pattern)
      if (patternArr) {
        this.initialMskuPatternLen = patternArr.length
      }
    },
    currentMSKUFormulaText(pattern) {
      this.currentMSKU = ''
      this.sampleMSKU = ''
      const patternArr = JSON.parse(pattern)
      if (patternArr) {
        patternArr.forEach(item => {
          this.currentMSKU += item.msku.value + item.seperatorValue
          this.sampleMSKU += item.msku.sample + item.seperatorValue
        })
      }
      this.currentMSKU = this.currentMSKU.slice(0, -1)
      this.sampleMSKU = this.sampleMSKU.slice(0, -1)
      this.previewMSKU = ''
    },
  },
}
</script>

<style scoped>
.handle {
  font-size: 18px;
  line-height: 19px;
  margin-right: 0.375rem;
  cursor: move;
}

.edit-formula {
  font-size: 18px;
  line-height: 19px;
  margin-left: 0.375rem;
  cursor: pointer;
}

.item {
  padding: 10px 10px;
  margin-bottom: 10px;
  background: white;
  cursor: pointer;
}

.item-with-handle {
  cursor: default;
}

td {
  padding: 4px;
}
</style>
