<template>
  <div class="p-1">
    <div v-if="!singleStore">
      <dx-util-tabs :items="dataSource"
        selection-mode="single"
        :selected-index="0"
        @item-click="selectTab"
      />
        <div class="d-flex justify-content-end align-items-center pt-2">
          <div class="mr-3 text-warning font-weight-bold">
            Store:
          </div>
          <dx-util-select-box
            v-model="selectedStore"
            class="col-4"
            placeholder="Select a store"
            :data-source="storeList"
            display-expr="text"
            value-expr="value"
          />
        </div>
      <div v-for="preference in preferences" :key="preference.groupKey">
        <PreferenceItem :preference="preference" :store-id="selectedStore" />
      </div>
    </div>
    <div v-else>
      <div v-for="preference in preferences" :key="preference.groupKey">
        <PreferenceItem :preference="preference" :store-id="selectedStore" />
      </div>
    </div>
  </div>
</template>

<script>
import useCurrentUser from '@/libs/app/current-user'
import NotificationTypesEnum from '@/enums/notification-types.enum'
import companyService from '@/http/requests/company/companyService'
import managedKeysData from './managedKeysData'
import PreferenceItem from './PreferenceItem.vue'

export default {
  components: {
    PreferenceItem,
  },
  props: {
    storeId: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const {
      userAccountNo,
    } = useCurrentUser()
    return {
      userAccountNo,
    }
  },
  data() {
    return {
      tabIndex: 0,
      dataSource: Object.values(NotificationTypesEnum),
      managedKeysData,
      preferences: [],
      storeList: [],
      selectedStore: 0,
    }
  },
  computed: {
    singleStore() {
      return this.storeId !== undefined && this.storeId > 0
    },
  },
  watch: {
    storeId: {
      immediate: true,
      deep: true,
      handler() {
        if (this.storeId !== undefined && this.storeId > 0) {
          this.selectedStore = this.storeId
        }
      },
    },
  },
  async mounted() {
    this.preferences = this.managedKeysData
      .filter(item => item.groupTitle.key === this.dataSource[0].key)
    this.getStoreList()
  },
  methods: {
    selectTab(e) {
      const key = e.itemData.key
      this.tabIndex = e.itemIndex
      this.preferences = this.managedKeysData.filter(item => item.groupTitle.key === key)
    },
    getStoreList() {
      companyService.fetchCompanyAndStoresByAccountNo(this.userAccountNo).then(response => {
        this.storeList = response.data.stores.map(store => ({
          key: store.id,
          text: store.name,
          value: store.id,
        }))
        if (this.storeList?.length === 1) {
          this.selectedStore = this.storeList[0].value
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
