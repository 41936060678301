<template>
  <div class="new-item-content">
    <div v-if="!hasInvalidAsin" class="container-fluid">
      <div class="row">
        <div class="col-7 px-0 border-right" :style="rightSide">
          <dx-util-scroll-view show-scrollbar="onHover" :height="rightSide.scroolHeight">
            <!-- Begin:: Replenish -->
            <div class="row">
              <div v-if="isReplenish" class="col-md-12">
                <div class="d-flex flex-row justify-content-start align-items-center shipping-content">
                  <div class="align-self-center px-half">
                    <dx-util-button
                      type="normal"
                      text="Ignore and Create New"
                      hint="List the item under a new SKU"
                      class="btn-warning"
                      @click="onClickIgnoreReplenishCreateNew($event, ignoreMatchingMsku)"
                    />
                  </div>
                  <div class="info-text align-self-center">
                    You currently have MSKU(s) matching this ASIN in your inventory
                  </div>
                </div>
              </div>
              <div v-if="isReplenish" class="col-md-12">
                <dx-data-grid
                  id="searchedReplenismentGrid"
                  ref="searchedReplenismentGridRef"
                  key-expr="id"
                  :data-source="replenishItems"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :show-borders="true"
                  :row-alternation-enabled="false"
                  :hover-state-enabled="true"
                  :customize-columns="customizeColumns"
                  @content-ready="onContentReady"
                >
                  <dx-column type="buttons" caption="Actions" width="auto" alignment="left">
                    <dx-button>
                      <template #default="{ data }">
                        <dx-util-button type="success" text="Replenish" @click="onClickReplenish($event, data)" />
                      </template>
                    </dx-button>
                    <dx-button>
                      <template #default="{ data }">
                        <dx-util-button :visible="false" type="danger" text="delete" @click="onClickIgnoreReplenishCreateNew($event, data)" />
                      </template>
                    </dx-button>
                  </dx-column>
                  <dx-column data-field="imageUrl" caption="Image" cell-template="imageCellTemplate" width="auto" alignment="center" :allow-sorting="false" />
                  <template #imageCellTemplate="{ data }">
                    <img :src="data.value" style="width:4rem;height:4rem" class="img-thumbnail">
                  </template>
                  <dx-column data-field="upc" caption="UPC / ASIN / Title" cell-template="upcCellTemplate" />
                  <template #upcCellTemplate="{data}">
                    <table style="border-collapse: collapse; border: none;">
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>UPC:</small>
                        </td>
                        <td style="border: none;">
                          <span>
                            <copy-clipboard :text="data.value ? data.value : '-'" />
                            <a target="_blank" :href="`https://www.barcodelookup.com/${data.value}`">
                              {{ data.value ? data.value : '-' }}
                            </a>
                          </span>
                        </td>
                      </tr>
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>ASIN:</small>
                        </td>
                        <td style="border: none;">
                          <span>
                            <copy-clipboard :text="data.data.asin ? data.data.asin : '-'" />
                            <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.data.asin}`">
                              {{ data.data.asin }}
                            </a>
                          </span>
                        </td>
                      </tr>
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>Title:</small>
                        </td>
                        <td style="border: none;">
                          <div v-b-tooltip.hover="{ variant: 'info' }" :title="data.data.title" class="align-self-center flex-grow-1">
                            <copy-clipboard :text="data.data.title ? data.data.title : '-'" />
                            <span class="dx-product-title text-info" role="button" @click="openProductDetails(data.data.productId)">
                              {{ getTitle(data.data.title) }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </template>
                  <dx-column data-field="msku" caption="MSKU / FNSKU / Cond" cell-template="mskuFnskuCellTemplate" />
                  <template #mskuFnskuCellTemplate="{data}">
                    <table style="border-collapse: collapse; border: none;">
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>MSKU:</small>
                        </td>
                        <td style="border: none;">
                          <span>
                            <copy-clipboard :text="data.value ? data.value : '-'" />
                            {{ data.value }}
                          </span>
                        </td>
                      </tr>
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>FNSKU:</small>
                        </td>
                        <td style="border: none;">
                          <span>
                            <copy-clipboard :text="data.data.fnsku ? data.data.fnsku : '-'" />
                            {{ data.data.fnsku }}
                          </span>
                        </td>
                      </tr>
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>Cond. :</small>
                        </td>
                        <td style="border: none;">
                          <span>
                            {{ data.data.condition }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </template>
                  <dx-column data-field="inStock" caption="Stock" alignment="left" width="180" cell-template="numbersCellTemplate" />
                  <template #numbersCellTemplate="{data}">
                    <table style="border-collapse: collapse; border: none;">
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>InStock: </small>
                        </td>
                        <td style="border: none;">
                          <span :class="resolveNumberColor(data.value)">
                            {{ data.value }}
                          </span>
                        </td>
                      </tr>
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>Inbound: </small>
                        </td>
                        <td style="border: none;">
                          <span :class="resolveInboundNumberColor(data.data.inbound)">
                            {{ data.data.inbound }}
                          </span>
                        </td>
                      </tr>
                      <tr style="border: none;">
                        <td style="border: none;">
                          <small>List Price: </small>
                        </td>
                        <td style="border: none;">
                          <span :class="resolveNumberColor(data.data.listPrice)">
                            ${{ data.data.listPrice }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </template>
                </dx-data-grid>
              </div>
            </div>
            <!-- End:: Replenish -->
            <!-- Begin:: Listing -->
            <div class="row">
              <div v-if="isListing && !isIgnoreMacthingMsku" class="col-md-12">
                <div class="d-flex flex-row justify-content-start shipping-content">
                  <div class="info-text align-self-center">
                    Select Item to List
                  </div>
                </div>
              </div>
              <div v-for="item in newListingItems" :key="item.rowId" class="col-md-12 mb-1 px-2 py-1 border-bottom">
                <div class="row">
                  <div class="col-9">
                    <item-info
                      :id="item.rowId"
                      :large-image-url="item.imageUrl"
                      :image-url="item.imageUrl"
                      :title="item.title"
                      :price-list="item.priceList"
                      :asin="item.asin"
                      :upc="item.identifier.identifier"
                      :notes="item.notes"
                      :fnsku="item.fnsku"
                      :msku="item.msku"
                      :rank="item.rank"
                      :product-type="item.productType"
                      :item-info-visible="false"
                      :is-printable="false"
                      :is-truncated="false"
                    />
                  </div>
                  <div v-if="item.hasVariations" class="col-3  ml-auto pr-1 d-flex flex-row justify-content-end align-self-center">
                    <dx-util-button text="Variations" :element-attr="{ class: 'btn-danger' }" @click="onAddNewListingChildItem($event, item)" />
                  </div>
                  <div v-else class="col-3 ml-auto pr-1 d-flex flex-row justify-content-end align-self-center">
                    <div v-if="listingRestrictionsMap[item.asin] && listingRestrictionsMap[item.asin].length > 0" class="mr-half">
                      <div :id="`restrictions-button-${item.asin}`" class="badge-item rounded text-white px-half" :class="containsNewCondition(item.asin) ? 'badge-danger' : 'badge-warning'">
                        Restrictions
                      </div>
                    </div>
                    <dx-util-popover :target="`#restrictions-button-${item.asin}`" position="bottom" show-event="dxhoverstart" hide-event="dxclick" :shading="true" style="z-index: 9999;">
                      <table class="custom-table">
                        <thead>
                          <tr>
                            <th>Condition Type</th>
                            <th>Reason Code</th>
                            <th>Reason Message</th>
                            <th>Links</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="condition in listingRestrictionsMap[item.asin]" :key="condition.conditionType" :class="{ 'new-new-row': condition.conditionType === 'NEW_NEW' }">
                            <td>{{ condition.conditionType }}</td>
                            <td>{{ condition.reasons[0].reasonCode }}</td>
                            <td>{{ condition.reasons[0].message }}</td>
                            <td style="width: 250px;">
                              <span v-for="(links, index1) in condition.reasons[0].links" :key="index1">
                                <a :href="links.resource" target="_blank">
                                  {{ links.title }}
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </dx-util-popover>
                    <div class="align-self-center bg-secondary rounded-left px-half text-white badge-item">
                      Offers
                    </div>
                    <div class="align-self-center bg-primary rounded-right px-half badge-item mr-half" style="width: 48px;">
                      {{ item.offersCount }}
                    </div>
                    <dx-util-button text="Select" :element-attr="{ class: 'btn-warning' }" @click="onAddNewListingItem($event, item)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div v-if="isListing && isIgnoreMacthingMsku" class="col-12 px-2 my-1">
                <item-info
                  :id="ignoreMatchingMsku.id"
                  :catalog-item="ignoreMatchingMsku.catalogItem"
                  :price-list="ignoreMatchingMsku.priceList"
                  :upc="ignoreMatchingMsku.upc"
                  :notes="ignoreMatchingMsku.notes"
                  :fnsku="ignoreMatchingMsku.fnsku"
                  :msku="ignoreMatchingMsku.msku"
                  :is-printable="false"
                  :is-truncated="false"
                />
              </div>
            </div>
            <!-- End:: Listing -->
            <!-- Begin:: Existing -->
            <div class="row">
              <div v-if="isExisting" class="col-md-12">
                <div class="d-flex flex-row justify-content-start shipping-content">
                  <div class="info-text align-self-center">
                    Item already exists in the batch
                  </div>
                </div>
              </div>
              <div v-if="isExisting" class="col-md-12 px-2 py-1">
                <div class="d-flex flex-row justify-content-start">
                  <div class="flex-grow-1">
                    <div class="d-flex flex-column w-100">
                      <div><span class="text-yellow font-weight-bolder">Title:</span>{{ existingParentItem.title }}</div>
                      <div class="d-flex flex-row justify-content-between">
                        <div class="w-50">
                          <span class="text-yellow font-weight-bolder">
                            FNSKU:
                          </span>
                          {{ existingParentItem.fnsku }}
                          <span class="text-yellow font-weight-bolder">ASIN: </span>
                          <a :href="`https://www.amazon.com/gp/product/${existingParentItem.asin}`" target="_blank">{{ existingParentItem.asin }} <i class="dx-icon-link" /></a>
                        </div>
                      </div>
                      <div class="d-flex flex-row justify-content-start">
                        <div class="mr-1">
                          <span class="text-yellow font-weight-bolder">
                            MSKU:
                          </span>
                          {{ existingParentItem.msku }}
                        </div>
                        <div>
                          <span class="text-yellow font-weight-bolder">
                            Quantity:
                          </span>
                          {{ existingParentItem.quantity }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="align-self-center">
                    <dx-util-button
                      type="danger"
                      text="Delete From Batch"
                      class="mr-1"
                      @click="onClickExistingItemDelete($event, existingParentItem)"
                    >
                  </dx-util-button>
                  </div>
                  <div class="align-self-center">
                  <dx-util-button
                      type="default"
                      text="Add As New Condition"
                      @click="onClickExistingAddAsNewCondition($event, existingParentItem)"
                    >
                  </dx-util-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End:: Existing -->
            <!--Begin:: Search Result On Inventory -->
            <div class="row mt-1">
              <div class="col-12 px-2">
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                      <div class="mr-1 flex-grow-1 ">
                        <dx-util-text-box v-model="filter.searchText" mode="search" @key-down="getSearchByFilter" /></div>
                      <div>
                        <dx-util-check-box v-model="showReservedItems" text="Show Reserved Items" @value-changed="getAvailableSearchItems" />
                      </div>
                    </div>
                  </div>
                  <!--Begin:: Header -->
                  <div class="col-12 border-bottom new-item-header">
                    <div class="row">
                      <div class="col-6 py-1 text-yellow">
                        Item
                      </div>
                      <div class="col-2 py-1 text-yellow">
                        Quantities
                      </div>
                      <div class="col-1 py-1 text-yellow">
                        Receive
                      </div>
                      <div class="col-1 py-1 text-yellow">
                        Condition
                      </div>
                      <div class="col-2 py-1 text-yellow">
                        Source
                      </div>
                    </div>
                  </div>
                  <!--End:: Header -->
                  <div v-for="item in searchItems" :key="`${item.id}`" class="col-12 border-bottom new-item-detail">
                    <div class="row">
                      <div class="col-6">
                        <item-info
                          :id="item.id"
                          :catalog-item="item.catalogItem"
                          :title="item.title"
                          :pallet-id="item.palletId"
                          :asin="item.asin"
                          :rank="item.amzRank"
                          :upc="item.upc"
                          :notes="item.notes"
                          :fnsku="item.fnsku"
                          :msku="item.msku"
                          :order-number="item.orderNumber"
                          :tracking-number="item.tracking"
                          :cost-price="item.itemCost"
                          :supplier="item.supplier"
                          :expiration-date="item.expirationDate"
                          :image-url="item.smallImage"
                          :large-image-url="item.largeImage"
                          :product-type="item.productType"
                          :is-printable="false"
                          :is-truncated="true"
                          :purchase-date="item.purchaseDate"
                          :batch-uploaded-at="item.batchUploadedAt"
                          :batch-name="item.batchName"
                        />
                      </div>
                      <div class="col-2 d-flex flex-column" style="font-size: 18px">
                        <div class="d-flex flex-row justify-content-start align-content-center">
                          <div v-b-tooltip.hover="{ variant: 'info' }" title="Receive all avaible quantity" class="flex-fill px-0">
                            Available
                            <span class="count-item count-success cursor-pointer d-inline-flex" @click="setAvailableQuantity(item)">
                              <span class="ml-half">
                              {{ item.quantity }}
                              </span>
                              <i class="bi bi-plus-square-fill ml-half"></i>
                            </span>
                          </div>
                          <div v-if="item.shippedCount > 0" class="flex-fill px-0">
                            Shipped
                            <span class="count-item" :class="item.shippedCount > 0 ? 'count-warning' : ''">
                              {{ item.shippedCount }}
                            </span>
                          </div>
                        </div>
                        <div class="d-flex flex-row justify-content-start">
                          <div v-if="item.reservedCount > 0" class="flex-fill px-0">
                            Reserved
                            <span class="count-item" :class="item.reservedCount > 0 ? 'count-warning' : ''">{{ item.reservedCount }}</span>
                          </div>
                          <div v-if="item.countInBundle > 0" class="flex-fill px-0">
                            In Bundle
                            <span class="count-item" :class="item.countInBundle > 0 ? 'count-warning' : ''">{{ item.countInBundle }}</span>
                          </div>
                        </div>
                        <div class="d-flex flex-row justify-content-start">
                          <div v-if="item.sortedCount > 0" class="flex-fill px-0">
                            Sorted
                            <span class="count-item" :class="item.sortedCount > 0 ? 'count-warning' : ''">{{ item.sortedCount }}</span>
                          </div>
                          <div v-if="item.countPerBundle > 0" class="flex-fill px-0">
                            Per Bundle
                            <span class="count-item">
                              <span v-if="item.countPerBundle > 0" class="badge badge-danger rounded">
                                {{ item.countPerBundle }}
                              </span>
                              <span v-else> {{ item.countPerBundle }}</span>
                            </span>
                          </div>
                        </div>
                        <div class="d-flex flex-row justify-content-start">
                          <div v-if="item.damagedCount > 0" class="flex-fill px-0">
                            Damaged
                            <span class="count-item" :class="item.damagedCount > 0 ? 'count-danger' : ''">{{ item.damagedCount }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-1 align-self-center">
                        <dx-util-number-box
                          v-model="item.inUsedCount"
                          width="100%"
                          :read-only="isReadOnlyQuantity"
                          :min="0"
                          :max="item.quantity"
                          :show-spin-buttons="false"
                          :input-attr="{ style: 'padding-top:0px; padding-bottom:0px ' }"
                          @value-changed="onAddQuatity($event, item)"
                        />
                      </div>
                      <div class="col-1 align-self-center text-center">
                        <span v-if="item.itemCondition" class="badge badge-pill badge-info">
                          {{ item.itemCondition }}
                        </span>
                        <span v-else class="badge badge-pill badge-warning">
                          -
                        </span>
                      </div>
                      <div class="col-2 d-flex flex-column align-self-center">
                        <span class="badge mb-half" :class="`badge-${getEnumToValue($enums.ItemSourceEnum, item.itemSource).variant}`">
                          {{ getEnumToValue($enums.ItemSourceEnum, item.itemSource).text }}
                        </span>
                        <dx-util-button
                          type="default"
                          :text="bundleButtonText"
                          :visible="showBundleButton"
                          class="mb-half"
                          @click="onClickAddBundleItem($event, item)"
                        />
                        <dx-util-drop-down-button
                          v-if="!isExisting"
                          text="Copy All Info"
                          icon="square"
                          key-expr="id"
                          display-expr="text"
                          styling-mode="contained"
                          :drop-down-options="usePropertyDropDownOptions"
                          :items="usePropertyActions"
                          :split-button="true"
                          @item-click="onClickUseBatchItemDropDown($event, item)"
                          @button-click="onClickUseBatchItem($event, item)"
                        />
                        <dx-util-button
                          text="Update"
                          icon="bi bi-pencil-square"
                          :visible="item.itemSource === 'inbound'"
                          :element-attr="{ class: 'btn-primary mt-half' }"
                          @click="onClickInboundItemUpdate($event, item)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dx-util-scroll-view>
          <!--End:: Search Result On Inventory -->
          <div class="row w-100 action-content">
            <div class="col px-half">
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div>
                  <dx-util-button :visible="!isExisting && showCreateMissingItemButton" text="Create Missing Item" icon="plus" type="normal" class="btn-warning" @click="onClickCreateMissingItem" />
                </div>
                <div>
                  <dx-util-button type="danger" text="Cancel" width="120px" class="mr-1" @click="onCancelNewItem()" />
                  <dx-util-button :disabled="isDisableAddItemButton" type="default" text="Add" width="120px" @click="onAddNewItem($event)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5 px-0" :style="rightSide">
          <dx-util-scroll-view show-scrollbar="onHover" :height="rightSide.scroolHeight">
            <div class="row">
              <div v-if="!isExisting" class="col-12 px-1 mb-1">
                <div class="card mb-0">
                  <div class="card-body">
                    <dx-util-text-box v-if="isListing" v-model="currentMSKUPatternText" name="txtBoxCurrentMSKUPattern" :element-attr="{ class: 'mb-1 dx-button-padding' }">
                      <dx-text-box-button :options="currentMSKUPatternButtonOptions" name="currentMSKUPatternButton" location="after" />
                    </dx-util-text-box>
                    <div class="d-flex flex-row justify-content-start">
                      <div class="flex-grow-1">
                        <dx-util-text-box v-model="currentMSKU" :read-only="isReplenish" name="txtBoxCurrentMSKU" :element-attr="{ class: isReplenish ? '' : 'dx-button-padding' }">
                          <dx-text-box-button :options="currentMSKUButtonOptions" name="currentMSKUButton" location="after" />
                          <dx-text-box-button :options="currentMSKUCoutButtonOptions" name="currentMSKUCountButton" location="before" />
                        </dx-util-text-box>
                      </div>
                      <div class="align-self-center">
                        <dx-switch
                          id="id-use-manual-msku"
                          v-model="useManualCreatedMskuValue"
                          :visible="isListing"
                          :height="36"
                          :element-attr="{ class: 'custom-switch' }"
                          switched-off-text="No"
                          switched-on-text="Yes"
                          hint="Use Custom MSKU"
                          @value-changed="onChangeUseManualCreatedMskuValue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isReplenish && showIsReplenish" class="col-12 px-1 mb-1">
                <div class="card mb-0">
                  <div class="card-body">
                    <batch-new-item-form
                      v-if="isReplenish"
                      :company-id="headerInfo.companyId"
                      :channel="channel"
                      :case-pack-quantity="casePackQuantity"
                      :fba-batch-parent-item-wrapper-form-data="fbaBatchParentItemWrapperFormData"
                      :on-emit-handle-submit-event="handleSubmitEventSource"
                      @on-emit-add-item-batch="onEmitAddItemBatch"
                      @on-emit-changed-multiple-item="onEmitChangedMultipleItem"
                      @on-emit-changed-pack-type="onEmitChangedPackType"
                    />
                  </div>
                </div>
              </div>
              <div v-if="isExisting" class="col-12 px-1 mb-1">
                <div class="card mb-0">
                  <batch-new-item-form-exist
                    v-if="isExistingWorkFlow"
                    :is-case-packed="isCasePacked"
                    :company-id="headerInfo.companyId"
                    :update-or-missing="updateOrMissing"
                    :current-quantities="fbaBatchParentItemExistingCurrents"
                    :fba-batch-parent-item-wrapper-form-data="fbaBatchParentItemWrapperFormData"
                    :on-emit-handle-submit-event="handleSubmitEventSource"
                    @on-emit-add-item-batch="onEmitAddItemBatch"
                  />
                </div>
              </div>
              <div v-if="isCreateNewListingItem" class="col-12 px-1 mb-1">
                <div class="card mb-0">
                  <div class="card-body">
                    <batch-new-item-form-listing
                      :fba-batch-parent-item-wrapper-form-data="fbaBatchParentItemWrapperFormData"
                      :on-emit-handle-submit-event="handleSubmitEventSource"
                      :case-pack-quantity="casePackQuantity"
                      :product-definition="productDefinition"
                      :channel="channel"
                      :shipment-plan-id="shipmentPlanId"
                      @on-emit-update-form-data="onEmitUpdateFormData"
                      @on-emit-add-item-batch="onEmitAddItemBatch"
                      @on-emit-changed-multiple-item="onEmitChangedMultipleItem"
                      @on-emit-changed-pack-type="onEmitChangedPackType"
                    />
                  </div>
                </div>
              </div>
              <!-- #region Bundle Item -->
              <div v-if="bundleItems.length > 0" class="col-12 px-1 mb-1">
                <div class="card mb-0 p-0">
                  <div class="card-body p-0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" class="px-half">
                            MSKU
                          </th>
                          <th v-b-tooltip.hover="{ variant: 'info' }" scope="col" class="px-half" title="Count Per Bundle">
                            Count Per Bundle
                          </th>
                          <th v-b-tooltip.hover="{ variant: 'info' }" scope="col" class="px-half" title="Item Quantity">
                            QTY
                          </th>
                          <th scope="col" class="px-half" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index2) in bundleItems" :key="index2">
                          <td scope="row" class="px-half w-50">
                            {{ item.msku }}
                          </td>
                          <td class="px-half">
                            <dx-util-number-box
                              v-model="item.quantity"
                              :min="0"
                              :show-spin-buttons="false"
                              :input-attr="{ style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' }"
                              styling-mode="filled"
                              width="100%"
                              @value-changed="onBundleItemQuantityChanged($event)"
                            />
                          </td>
                          <td class="px-half">
                            <dx-util-number-box
                              v-model="item.itemQuantity"
                              :min="0"
                              :show-spin-buttons="false"
                              :read-only="true"
                              :input-attr="bundleItemQunatityValidFormat(item.isValid)"
                              styling-mode="filled"
                              width="100%"
                            />
                          </td>
                          <td class="px-half">
                            <dx-util-button type="danger" icon="trash" hint="Remove from bundle items" @click="removeBundleItem($event, index2)" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- #endregion -->
              <!-- #region Amazon Item Guidance -->
              <div class="col-12">
                <amazon-item-guidance
                  :component-id="amazonItemGuidanceCompId"
                  :company-id="amazonItemGuidanceCompProps.companyId"
                  :store-id="amazonItemGuidanceCompProps.storeId"
                  :asin="amazonItemGuidanceCompProps.asin"
                  :ship-to-country-code="amazonItemGuidanceCompProps.shipToCountryCode"
                  :sku="amazonItemGuidanceCompProps.msku"
                />
              </div>
              <!-- #endregion -->
            </div>
          </dx-util-scroll-view>
          <div v-show="!isExisting && isShowSalesPriceInfo" class="col-12 mb-1 px-1 sticky-info">
            <sales-price-info
              v-if="!isExisting"
              :asin="salePriceAsin"
              @on-emit-set-selected-price="onEmitSetSelectedPrice"
              @on-emit-calculate-price="onEmitCalculatePrice"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container-fluid">
      <div class="d-flex justify-content-center align-items-center" :style="rightSide">
        <div class="row">
          <div class="col-12">
            <div class="card text-white bg-danger text-center w-100">
              <div class="card-header border-bottom">
                <h3 class="card-title">
                  Amazon Inbound ASIN Guidance
                </h3>
              </div>
              <div v-for="(item, index3) in inboundGuidanceResult.invalidASINList" :key="index3" class="card-body py-2">
                <h4 class="card-title">
                  {{ getErrorReason(item.errorReason).value }}
                </h4>
                <p class="card-text">
                  {{ item.asin }} {{ getErrorReason(item.errorReason).text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Begin Select Variations-->
    <dx-util-popup
      id="popupVariations"
      ref="popupVariationsRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="true"
      content-template="popup-content"
      title="Product Variations"
      @showing="onShowingVariationsPopup"
      @hiding="onHidingVariationsPopup"
    >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <product-variations v-if="reCreateVariationsComp" :parent-item="selectedVariationParent" @on-emit-select-variation="onEmitSelectVariation" />
      </template>
    </dx-util-popup>
    <!--End Shipping-->
    <!--Begin Current MSKU Settings -->
    <dx-util-popup
      ref="refmskuOptionPopup"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      title="MSKU Settings"
      :width="getPopupWidth"
      :height="getPopupHeight"
      content-template="popup-content"
      @hiding="onHidingMSKUOptionPopup"
    >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <div class="container-fluid">
            <customer-msku-tab :company-id="currentMSKUStoreInfo.companyId" :store-id="currentMSKUStoreInfo.storeId" :store-name="currentMSKUStoreInfo.storeName" />
          </div>
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
    <!--End Current MSKU Settings -->
    <dx-util-popup
      ref="inboundItemUpdatePopupRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      title="Update Inbound Item"
      :width="getPopupWidth"
      :height="getPopupHeight"
      content-template="popup-content"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelInboundItemUpdateButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveInboundItemUpdateButtonOptions" />

      <template #popup-content>
        <dx-util-scroll-view width="100%" height="100%">
          <form onsubmit="return false;">
            <dx-util-form ref="inboundItemUpdateFromRef" :form-data="selectedInboundItem" :col-count="2" :show-colon-after-label="true" label-location="top">
              <dx-util-simple-item data-field="title" editor-type="dxTextBox" :col-span="2" :editor-options="{ disabled: true }" />
              <dx-util-simple-item data-field="asin" editor-type="dxTextBox" :editor-options="{ disabled: true }" />
              <dx-util-simple-item data-field="upc" editor-type="dxTextBox" :editor-options="{ disabled: true }" />
              <dx-util-simple-item data-field="msku" editor-type="dxTextBox" :editor-options="{ disabled: true }" />
              <dx-util-simple-item data-field="fnsku" editor-type="dxTextBox" :editor-options="{ disabled: true }" />
              <dx-util-simple-item data-field="tracking" editor-type="dxTextBox" />
              <dx-util-simple-item data-field="orderNumber" editor-type="dxTextBox" />
              <dx-util-simple-item data-field="palletId" editor-type="dxTextBox" />
            </dx-util-form>
          </form>
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
    <product-details :product-id="selectedProductId"
      :show-product="isProductDetailsVisible"
      @close="isProductDetailsVisible=false,selectedProductId=null"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable vue/no-unused-components */
import { ref } from '@vue/composition-api'
import find from 'lodash/find'
import remove from 'lodash/remove'
import store from '@/store'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import { ValueToEnum, Notify } from '@robustshell/utils/index'
import shipService from '@/http/requests/ship/shipService'
import productsService from '@/http/requests/product/productsService'
import amazonApi from '@/http/requests/external/amazon.fba'
import SearchFilter from '@/http/models/search-filters/ReceivePageSearchFilter'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import CoolLightBox from 'vue-cool-lightbox'
import { DxSwitch } from 'devextreme-vue/switch'
import packingTypeEnum from '@/enums/packingTypeEnum'
import packTypeEnum from '@/enums/packTypeEnum'
import { formatedDateStr, formatedIsoDateStr } from '@/@core/utils/dateHelper.js'
import { getExpirationDate } from '@/enums/expirationProductsEnum'
import { isValidAsin, isValidUpc } from '@/views/apps/ship/validationItem'
import CatalogItem, {
  itemIdentifier, itemProductType, itemImageLink, itemSummary, itemRank, itemRank1,
} from '@/libs/app/catalog-item'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import { newEventSource } from 'vue-parent-emit'
import CustomerMskuTab from '@/views/apps/company/tabs/CustomerMskuTab.vue'
import ShipmentChannelEnum from '@/enums/shipment-channel.enum'
import listPriceEnum from '@/enums/listPrice.enum'
import truncate from 'lodash/truncate'
import ProductDetails from '@/views/apps/product/products/components/ProductDetails.vue'
import isNumber from 'lodash/isNumber'
import useConfirmation from '@/libs/app/confirmation'
import useFBAShipment from '../fbaShipment'
import ItemInfo from './ItemInfo.vue'
import fbaShipment from '../fbaShipment.mixin'
import BatchNewItemFormListing from './BatchNewItemFormListing.vue'
import BatchNewItemForm from './BatchNewItemForm.vue'
import BatchNewItemFormExist from './BatchNewItemFormExist.vue'
import SalesPriceInfo from './SalesPriceInfo.vue'
import ProductVariations from './ProductVariations.vue'
import AmazonItemGuidance from './AmazonItemGuidance.vue'
import CreateItemTypeEnum from '../createItemType'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { getErrorReasonEnum } from '../consts/error-reason.enum'

export default {
  components: {
    DxSwitch,
    'item-info': ItemInfo,
    'light-box': CoolLightBox,
    'batch-new-item-form-listing': BatchNewItemFormListing,
    'batch-new-item-form-exist': BatchNewItemFormExist,
    'sales-price-info': SalesPriceInfo,
    'batch-new-item-form': BatchNewItemForm,
    'dx-text-box-button': DxTextBoxButton,
    'product-variations': ProductVariations,
    CustomerMskuTab,
    AmazonItemGuidance,
    ProductDetails,
  },
  mixins: [GridBase, fbaShipment],
  emits: ['cancel-add-item-batch', 'add-item-batch'],
  props: {
    searchParams: {
      type: Object,
      required: true,
      default: null,
    },
  },
  setup() {
    const {
      warehouse,
      batchInfo,
      fbaBatchParentItemWrapperFormData,
      fbaBatchParentItemExistingCurrents,
      isCasePacked,
      currentMSKUDefaultNotVaraible,
      currentMSKU,
      tempCurrentMSKU,
      currentMSKUPatternText,
      useManualCreatedMskuValue,
      setInitialItemWrapperFormData,
      setExistingParentItem,
      setExistingParentItemCurrents,
      setExistingParentItemCreateType,
      setListOnAmazon,
      setQuantityItemWrapperFormData,
      setCreateTypeItemWrapperFormData,
      setReplenisItemWrapperFormData,
      setReplenisMissingItemWrapperFormData,
      setListingItemWrapperFormData,
      setListingMissingItemWrapperFormData,
      setExpirationDateItemWrapperFormData,
      setUpdateDefaultChargesItemWrapperFormData,
      setPackTypeItemWrapperFormData,
      getCurrentMSKUSettigs,
      setCurrentMSKUDefaultNotVaraible,
      setMskuValue,
      validateItemWrapperFormData,
      bundleItems,
      bundleItemQuantity,
      addBundleItems,
      removeBundleItems,
      clearBundleItems,
      normalizeBundleItems,
      calculatePrice,
      priceSettingListPriceType,
      loadCalculationPriceSettings,
    } = useFBAShipment()

    const warehouseId = ref(warehouse.value.id)
    const warehouseName = ref(warehouse.value.name)

    const { pshConfirm } = useConfirmation()
    return {
      pshConfirm,
      warehouseId,
      warehouseName,
      batchInfo,
      fbaBatchParentItemWrapperFormData,
      fbaBatchParentItemExistingCurrents,
      isCasePacked,
      currentMSKU,
      currentMSKUDefaultNotVaraible,
      tempCurrentMSKU,
      currentMSKUPatternText,
      useManualCreatedMskuValue,
      setInitialItemWrapperFormData,
      setExistingParentItem,
      setExistingParentItemCurrents,
      setExistingParentItemCreateType,
      setListOnAmazon,
      setQuantityItemWrapperFormData,
      setCreateTypeItemWrapperFormData,
      setReplenisItemWrapperFormData,
      setReplenisMissingItemWrapperFormData,
      setListingItemWrapperFormData,
      setListingMissingItemWrapperFormData,
      setExpirationDateItemWrapperFormData,
      setUpdateDefaultChargesItemWrapperFormData,
      setPackTypeItemWrapperFormData,
      getCurrentMSKUSettigs,
      setCurrentMSKUDefaultNotVaraible,
      setMskuValue,
      validateItemWrapperFormData,
      bundleItems,
      bundleItemQuantity,
      addBundleItems,
      removeBundleItems,
      clearBundleItems,
      normalizeBundleItems,
      calculatePrice,
      priceSettingListPriceType,
      loadCalculationPriceSettings,
    }
  },
  data() {
    return {
      filter: {
        batchId: 0,
        searchText: '',
        isUpc: false,
        isAsin: false,
        isKeywords: false,
        showReservedItems: false,
      },
      showReservedItems: false,
      salePriceAsin: '',
      searchItems: [],
      replenishItems: [],
      newListingItems: [],
      existingParentItem: {},
      isCreateNewListingItem: false,
      fbaBatchParentItems: [],
      fbaBatchParentItem: {
        id: 0,
        productId: 0,
        itemSource: '',
        quantity: 0,
      },
      index: 0,
      selectItemToList: false,
      createType: null,
      reCreateVariationsComp: false,
      selectedVariationParent: {},
      handleSubmitEventSource: newEventSource(),
      isExistingWorkFlow: false,
      updateOrMissing: null,
      isReadOnlyQuantity: false,
      isShowSalesPriceInfo: false,
      currentMSKUStoreInfo: {
        companyId: 0,
        storeId: 0,
        storeName: '',
      },
      createTypeCounts: {
        inventorySummaryCount: null,
        itemVariationCount: null,
        existingItem: null,
        batchItemCount: null,
      },
      isIgnoreMacthingMsku: false,
      ignoreMatchingMsku: {},
      usePropertyActions: [
        { id: 1, text: 'Copy All Info', icon: 'square' },
        { id: 2, text: 'Copy (Supplier, Cost, Sale, Plan)', icon: 'copy' },
      ],
      usePropertyDropDownOptions: {
        width: 300,
      },
      bundleButtonText: '',
      showBundleButton: false,
      casePackQuantity: 0,
      replenishProductId: null,
      amazonItemGuidanceCompId: '',
      amazonItemGuidanceCompProps: {
        companyId: 0,
        storeId: 0,
        asin: '',
        msku: '',
        shipToCountryCode: '',
      },
      inboundGuidanceResult: {
        errors: [],
        invalidSKUList: [],
        skuInboundGuidanceList: [],
        invalidASINList: [],
        asinInboundGuidanceList: [],
      },
      selectedInboundItem: {
        id: 0,
        title: '',
        asin: '',
        msku: '',
        upc: '',
        fnsku: '',
        tracking: '',
        orderNumber: '',
        palletId: '',
      },
      listingRestrictionsMap: {},
      productDefinition: {},
      isDisableAddItemButton: true,
      channel: '',
      shipmenyPlanId: null,
      selectedProductId: null,
      isProductDetailsVisible: false,
      showIsReplenish: false,
    }
  },
  computed: {
    isMfnChannel() {
      return this.channel === ShipmentChannelEnum.MFN.value
    },
    hasInvalidAsin() {
      return this.inboundGuidanceResult.invalidASINList.length > 0
    },
    isReplenish() {
      return this.createType === CreateItemTypeEnum.replenish.value || this.createType === CreateItemTypeEnum.replenish_create_items.value
    },
    isExisting() {
      return this.createType === CreateItemTypeEnum.update_quantity.value || this.createType === CreateItemTypeEnum.create_and_add_missing_items.value
    },
    isListing() {
      return this.createType === CreateItemTypeEnum.list.value || this.createType === CreateItemTypeEnum.list_create_items.value
    },
    accountInfo() {
      return store.getters['fbaShipment/getBatchAccountInfo']
    },
    headerInfo() {
      return store.getters['fbaShipment/getSelectedBactInfo']
    },
    showCreateMissingItemButton() {
      return this.searchItems.length > 0
    },
    hasInventory() {
      return this.searchItems.length > 0
    },
    shipToCountryCode() {
      const { shipToCountryCode } = store.getters['fbaShipment/getSelectedBactInfo']
      return shipToCountryCode
    },
    currentMSKUPatternButtonOptions() {
      return {
        text: 'Edit',
        type: 'default',
        onClick: () => {
          this.onClickMSKUOptionPopup()
        },
      }
    },
    currentMSKUButtonOptions() {
      return {
        visible: this.isListing,
        disabled: this.useManualCreatedMskuValue,
        text: 'Generate MSKU',
        type: 'default',
        onClick: () => {
          this.onCreateVariableMsku()
        },
      }
    },
    currentMSKUCount() {
      return this.currentMSKU ? this.currentMSKU.length : 0
    },
    currentMSKUCoutButtonOptions() {
      const type = this.currentMSKUCount > 40 ? 'danger' : 'success'
      return {
        visible: this.isListing,
        text: this.currentMSKUCount,
        type: type,
      }
    },
    searchButtonOptions() {
      return {
        text: 'SEARCH',
        type: 'default',
        icon: 'search',
        onClick: () => {
          this.onClickGetSearchByFilter(this.filter.searchText)
        },
      }
    },
    getPopupWidth() {
      return window.innerWidth / 1.5
    },
    getPopupHeight() {
      return window.innerHeight / 1.5
    },
    saveInboundItemUpdateButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        onClick: () => {
          this.onSaveInboundItemUpdate()
        },
      }
    },
    cancelInboundItemUpdateButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.onCancelInboundItemUpdate()
        },
      }
    },
  },
  watch: {
    searchParams: {
      immediate: true,
      async handler(current, previous) {
        this.channel = this.$route.params.channel
        this.shipmentPlanId = parseInt(this.$route.params.batchId, 10)
        this.fbaBatchParentItemWrapperFormData.channel = this.channel
        this.filter = {
          batchId: current.batchId,
          searchText: current.searchText,
          isUpc: current.isUpc,
          isAsin: current.isAsin,
          isKeywords: current.isKeywords,
        }
        this.createType = null
        this.showIsReplenish = false
        this.isCreateNewListingItem = false
        this.isDisableAddItemButton = true
        this.fbaBatchParentItems = []
        await this.getItemSearchResult(current)
        this.loadCalculationPriceSettings()
      },
    },
    isExisting() {
      this.isExistingWorkFlow = true
      this.isDisableAddItemButton = false
      this.updateOrMissing = this.existingParentItem.id
    },
    hasInventory(value) {
      this.isExistingWorkFlow = false
      this.createType = null
      if (value) {
        this.$nextTick(() => {
          this.createType = CreateItemTypeEnum.create_and_add_missing_items.value
          this.isExistingWorkFlow = true
          this.setCreateType()
        })
      } else {
        this.$nextTick(() => {
          this.createType = CreateItemTypeEnum.update_quantity.value
          this.isExistingWorkFlow = true
          this.setCreateType()
        })
      }
    },
  },
  mounted() {
    const {
      companyId, storeId, storeName, batchId,
    } = this.headerInfo
    this.getCurrentMSKUSettigs(storeId)
    this.currentMSKUStoreInfo.storeId = storeId
    this.currentMSKUStoreInfo.storeName = storeName
    this.currentMSKUStoreInfo.companyId = companyId
  },
  methods: {
    // #region MSKU Settings
    onClickMSKUOptionPopup() {
      const popup = this.$refs.refmskuOptionPopup.instance
      popup.show()
    },
    onCreateVariableMsku() {
      this.currentMSKUDefaultNotVaraible = false
      this.setMskuValue(this.fbaBatchParentItemWrapperFormData)
    },
    onEmitUpdateFormData(payload) {
      this.setMskuValue(payload)
    },
    onHidingMSKUOptionPopup() {
      this.getCurrentMSKUSettigs(this.currentMSKUStoreInfo.storeId)
      this.setMskuValue(this.fbaBatchParentItemWrapperFormData)
    },
    onChangeUseManualCreatedMskuValue(e) {
      if (!e.value) {
        this.onCreateVariableMsku()
      }
    },
    getTitle(title) {
      return truncate(title, { length: 150 })
    },
    openProductDetails(productId) {
      this.selectedProductId = productId
      this.isProductDetailsVisible = true
    },
    resolveNumberColor(number) {
      if (number > 0) return 'badge badge-light-success'
      return ''
    },
    resolveInboundNumberColor(number) {
      if (number > 0) return 'badge badge-light-warning'
      return ''
    },
    // #endregion
    getEnumToValue(enumType, value) {
      return ValueToEnum(enumType, value, 'value')
    },
    async getItemSearchResult(params) {
      const loader = this.$loading.show()
      try {
        this.salePriceAsin = ''
        this.isShowSalesPriceInfo = false
        this.setInitialItemWrapperFormData()
        this.clearBundleItems()
        this.replenishItems.splice(0, this.replenishItems.length)
        this.newListingItems.splice(0, this.newListingItems.length)
        const summaryItems = [
          {
            storeId: this.accountInfo.storeId,
            batchId: params.batchId,
            companyId: this.accountInfo.companyId,
            upc: params.isUpc ? params.searchText : null,
            asin: params.isAsin ? params.searchText : null,
            keywords: !params.isUpc && !params.isAsin ? params.searchText : null,
            channel: this.channel,
          },
        ]
        const { inventorySummaryCount, itemVariationCount, existingParentItem } = await this.getInventorySummaries(summaryItems)
        const existingItem = !!existingParentItem
        if (existingItem) {
          this.setExistingParentItem(existingParentItem)
          this.setExistingParentItemCurrents(existingParentItem)
        }
        this.createTypeCounts = {
          inventorySummaryCount: inventorySummaryCount,
          itemVariationCount: itemVariationCount,
          existingItem: existingItem,
        }
        await this.getInventoryData(params)
        loader.hide()
      } catch (error) {
        loader.hide()
      }
    },
    async getInventorySummaries(summaryItems) {
      const result = await amazonApi.getInventorySummaries(summaryItems)
      const { inventorySummaries, itemListOrVariations, existingParentItem } = result.data
      const resultSummary = {
        inventorySummaryCount: inventorySummaries ? inventorySummaries.length : 0,
        itemVariationCount: itemListOrVariations ? itemListOrVariations.length : 0,
        existingParentItem: existingParentItem || null,
      }

      if (existingParentItem) {
        this.existingParentItem = { ...existingParentItem }
      }

      if (inventorySummaries && inventorySummaries.length > 0) {
        const ignoreSelected = head(inventorySummaries)
        this.setIgnoreMatchingMskuItem(ignoreSelected)
        inventorySummaries.forEach(item => {
          const catalogItemIns = new CatalogItem()
          catalogItemIns.catalogItem = item.catalogItem
          const { itemName, manufacturer } = catalogItemIns.getSummary()
          const replenishItem = {
            id: uuidv4(),
            batchId: item.batchId,
            warehouseId: this.warehouseId,
            companyId: item.companyId,
            storeId: item.storeId,
            asin: item.asin,
            condition: item.condition,
            fnsku: item.fnsku,
            inStock: item.inStock,
            inbound: item.inbound,
            msku: item.msku,
            productId: item.productId,
            upc: item.upc,
            supplier: manufacturer,
            imageUrl: catalogItemIns.getImageLink(),
            productType: catalogItemIns.getProductType(),
            rank: catalogItemIns.getSalesRank(),
            title: itemName,
            listPrice: item.listPrice,
          }
          this.replenishItems.push(replenishItem)
        })
      }

      if (itemListOrVariations && itemListOrVariations.length > 0) {
        itemListOrVariations.forEach(itemList => {
          const {
            hasVariations, items, offers, restrictions,
          } = itemList
          const offersCount = offers && offers.offers ? offers.offers.length : 0
          const item = head(items)
          const {
            identifiers, images, productTypes, summaries, salesRanks,
          } = item
          const {
            brandName, colorName, itemName, manufacturer, modelNumber, sizeName, styleName,
          } = itemSummary(summaries)
          const newListingItem = {
            rowId: uuidv4(),
            hasVariations: hasVariations,
            asin: item.asin,
            imageUrl: itemImageLink(images),
            largeImageUrl: itemImageLink(images),
            productType: itemProductType(productTypes),
            identifier: itemIdentifier(identifiers),
            brand: brandName,
            color: colorName,
            title: itemName,
            rank: itemRank1(salesRanks),
            manufacturer: manufacturer,
            modelNumber: modelNumber,
            size: sizeName,
            style: styleName,
            offersCount: offersCount,
            variations: [],
          }
          this.newListingItems.push(newListingItem)

          this.listingRestrictionsMap[item.asin] = restrictions?.restrictions
        })
      }
      return resultSummary
    },
    setIgnoreMatchingMskuItem(item) {
      const catalogItemIns = new CatalogItem()
      catalogItemIns.catalogItem = item.catalogItem
      const { itemName, manufacturer } = catalogItemIns.getSummary()
      this.ignoreMatchingMsku = {
        id: uuidv4(),
        catalogItem: item.catalogItem,
        priceList: item.priceList,
        batchId: item.batchId,
        warehouseId: this.warehouseId,
        companyId: item.companyId,
        storeId: item.storeId,
        asin: item.asin,
        condition: item.condition,
        fnsku: item.fnsku,
        inStock: item.inStock,
        inbound: item.inbound,
        msku: item.msku,
        productId: item.productId,
        upc: item.upc,
        supplier: manufacturer,
        imageUrl: catalogItemIns.getImageLink(),
        productType: catalogItemIns.getProductType(),
        rank: catalogItemIns.getSalesRank(),
        title: itemName,
        listPrice: item.listPrice,
      }
    },
    async getInventoryData(params, setCreateType = true) {
      this.searchItems.splice(0, this.searchItems.length)
      const filter = new SearchFilter()
      filter.companyId = this.accountInfo.companyId
      filter.storeId = this.accountInfo.storeId
      filter.q = params.searchText
      filter.upc = params.isUpc
      filter.asin = params.isAsin
      filter.showReservedItems = this.showReservedItems
      filter.itemAction = this.channel === ShipmentChannelEnum.MFN.value ? 'FBM' : 'FBA'
      if (filter.upc && filter.q.startsWith('0')) {
        filter.q = filter.q.substring(1)
      }
      const result = await shipService.fetchFbaShipmentBatchParentItemByQuery(filter, 'page=0&size=500')
      this.createTypeCounts.batchItemCount = result.data.content.length
      if (setCreateType) {
        this.setCreateType()
      }
      if (!isEmpty(result.data.content)) {
        const fbaShipmentBatchParentItems = result.data.content
        fbaShipmentBatchParentItems.forEach(item => {
          const purchaseDate = formatedIsoDateStr(item.purchaseDate)
          const shipmentDate = formatedIsoDateStr(item.shipmentDate)
          const exprDate = formatedDateStr(item.expirationDate)
          const catalogItem = item.catalogItem ? item.catalogItem : null
          const batchItem = {
            id: item.id,
            amzRank: item.amzRank,
            batchParentId: item.batchParentId,
            itemSource: item.itemSource,
            companyId: item.companyId,
            storeId: item.storeId,
            storeName: item.storeName,
            warehouseId: item.warehouseId,
            productId: item.productId,
            mskuFnskuPairId: item.mskuFnskuPairId,
            title: item.title,
            upc: item.upc,
            asin: item.asin,
            msku: item.msku,
            fnsku: item.fnsku,
            packType: item.packType,
            planId: item.planId,
            palletId: item.palletId,
            priceList: item.priceList,
            quantity: item.quantity,
            quantityPerCase: item.quantityPerCase,
            reservedCount: item.reservedCount,
            receivedCount: item.receivedCount,
            sortedCount: item.sortedCount,
            damagedCount: item.damagedCount,
            shippedCount: item.shippedCount,
            countInBundle: item.countInBundle,
            countPerBundle: item.countPerBundle,
            itemAction: item.itemAction,
            itemCost: item.itemCost,
            salePrice: item.salePrice,
            itemCondition: item.itemCondition,
            supplier: item.supplier,
            batchId: item.batchId,
            batchName: item.batchName,
            tracking: item.tracking,
            orderNumber: item.orderNumber,
            notes: item.notes,
            catalogItem: catalogItem,
            productType: item.productType,
            taxCode: item.taxCode,
            expirationDate: exprDate,
            listingItem: '',
            inUsedCount: 0,
            purchaseDate: purchaseDate,
            shipmentDate: shipmentDate,
            smallImage: item.smallImage,
            largeImage: item.largeImage,
            batchUploadedAt: item.batchUploadedAt,
          }
          if (!this.showReservedItems) {
            if (item.quantity > 0) {
              this.searchItems.push(batchItem)
            }
          } else {
            this.searchItems.push(batchItem)
          }
        })
      }
    },
    getAvailableSearchItems() {
      this.filter.showReservedItems = this.showReservedItems
      this.getInventoryData(this.filter, false)
    },
    onClickGetSearchByFilter(e) {
      if (e.event.keyCode !== 13) return
      let val = e.event.target.value
      if (e.event.target.value) {
        this.filter.isUpc = isValidUpc(val)
        if (this.filter.isUpc && val.startsWith('0')) {
          val = val.substring(1)
        }
        this.filter.isAsin = isValidAsin(val)
        this.filter.searchText = val
      }
      this.getInventoryData(this.filter)
    },
    getSearchByFilter(e) {
      if (e.event.keyCode !== 13) return
      let val = e.event.target.value
      if (val) {
        this.filter.isUpc = isValidUpc(val)
        if (this.filter.isUpc && val.startsWith('0')) {
          val = val.substring(1)
        }
        this.filter.isAsin = isValidAsin(val)
        this.filter.searchText = val
      }

      this.getInventoryData(this.filter, false)
    },
    setCreateType() {
      if (this.createTypeCounts.existingItem && this.hasInventory) {
        this.createType = CreateItemTypeEnum.update_quantity.value
        this.setCreateTypeItemWrapperFormData(CreateItemTypeEnum.update_quantity.value)
        this.setExistingParentItemCreateType(CreateItemTypeEnum.update_quantity.value)
        this.isReadOnlyQuantity = false
      }
      if (this.createTypeCounts.existingItem && !this.hasInventory) {
        this.createType = CreateItemTypeEnum.create_and_add_missing_items.value
        this.setCreateTypeItemWrapperFormData(CreateItemTypeEnum.create_and_add_missing_items.value)
        this.setExistingParentItemCreateType(CreateItemTypeEnum.create_and_add_missing_items.value)
        this.isReadOnlyQuantity = true
      }
      if (this.createTypeCounts.batchItemCount > 0 && this.createTypeCounts.inventorySummaryCount > 0) {
        this.createType = CreateItemTypeEnum.replenish.value
        this.setCreateTypeItemWrapperFormData(CreateItemTypeEnum.replenish.value)
        return
      }
      if (this.createTypeCounts.batchItemCount > 0 && this.createTypeCounts.inventorySummaryCount === 0 && this.createTypeCounts.itemVariationCount > 0) {
        this.createType = CreateItemTypeEnum.list.value
        this.setCreateTypeItemWrapperFormData(CreateItemTypeEnum.list.value)
        return
      }
      if (this.createTypeCounts.batchItemCount === 0 && this.createTypeCounts.inventorySummaryCount > 0) {
        this.createType = CreateItemTypeEnum.replenish_create_items.value
        this.setCreateTypeItemWrapperFormData(CreateItemTypeEnum.replenish_create_items.value)
        return
      }
      if (this.createTypeCounts.batchItemCount === 0 && this.createTypeCounts.inventorySummaryCount === 0 && this.createTypeCounts.itemVariationCount > 0) {
        this.createType = CreateItemTypeEnum.list_create_items.value
        this.setCreateTypeItemWrapperFormData(CreateItemTypeEnum.list_create_items.value)
      }
    },
    onClickUseBatchItem(e, item) {
      const quantity = this.fbaBatchParentItemWrapperFormData.quantity !== 0 ? this.fbaBatchParentItemWrapperFormData.quantity : 0
      let fnsku = item.fnsku
      if (this.createType === CreateItemTypeEnum.replenish.value || this.createType === CreateItemTypeEnum.replenish_create_items.value) {
        fnsku = this.fbaBatchParentItemWrapperFormData.fnsku ? this.fbaBatchParentItemWrapperFormData.fnsku : fnsku
        this.fbaBatchParentItemWrapperFormData = {
          ...this.fbaBatchParentItemWrapperFormData,
          productId: this.replenishProductId,
          title: item.title,
          fnsku: fnsku,
        }
      }
      if (this.createType === CreateItemTypeEnum.list.value || this.createType === CreateItemTypeEnum.list_create_items.value) {
        this.setCurrentMSKUDefaultNotVaraible(item.msku)
        if (item.msku) {
          this.useManualCreatedMskuValue = true
          this.fbaBatchParentItemWrapperFormData = {
            ...this.fbaBatchParentItemWrapperFormData,
            supplierDeclaredDgHzRegulation: 'not_applicable',
            batteriesRequired: false,
            msku: item.msku,
          }
        } else {
          this.fbaBatchParentItemWrapperFormData = {
            ...this.fbaBatchParentItemWrapperFormData,
            supplierDeclaredDgHzRegulation: 'not_applicable',
            batteriesRequired: false,
          }
        }
        this.fbaBatchParentItemWrapperFormData = {
          ...this.fbaBatchParentItemWrapperFormData,
          productId: item.productId,
        }
      }
      let upc = this.fbaBatchParentItemWrapperFormData.upc
      if (this.fbaBatchParentItemWrapperFormData.asin === item.asin) {
        upc = item.upc
      }

      const exprDate = formatedDateStr(item.expirationDate, 'MM/DD/YYYY')

      this.fbaBatchParentItemWrapperFormData = {
        ...this.fbaBatchParentItemWrapperFormData,
        quantity: quantity,
        quantityPerCase: 0,
        missingItemQuantity: 0,
        countPerBundle: item.countPerBundle,
        mskuFnskuPairId: item.mskuFnskuPairId,
        cost: item.itemCost,
        supplier: item.supplier,
        upc: upc,
        itemCondition: item.itemCondition,
        planId: item.planId,
        expirationDate: exprDate,
        taxCode: item.taxCode,
        listingItem: '',
        notes: item.note,
        packType: item.packType,
        updateDefaultCharges: true,
        shipmentDate: item.shipmentDate,
      }
      this.setSalePrice(item.salePrice)
    },
    onClickUseBatchItemDropDown(e, item) {
      if (e.itemData.id === 1) {
        this.onClickUseBatchItem(e, item)
      }
      if (e.itemData.id === 2) {
        this.fbaBatchParentItemWrapperFormData = {
          ...this.fbaBatchParentItemWrapperFormData,
          cost: item.itemCost,
          supplier: item.supplier,
          itemCondition: item.itemCondition,
          planId: item.planId,
        }
        this.setSalePrice(item.salePrice)
      }
    },
    onClickAddBundleItem(e, item) {
      this.addBundleItem(item)
    },
    setSalePrice(salePrice) {
      if (this.priceSettingListPriceType === listPriceEnum.FROM_UPLOADED_FILE.value || this.priceSettingListPriceType === null) {
        this.fbaBatchParentItemWrapperFormData.salePrice = salePrice
      }
    },
    setAvailableQuantity(item) {
      item.inUsedCount = item.quantity
    },
    onAddQuatity(e, item) {
      if (this.fbaBatchParentItemWrapperFormData.isMultipleItem) {
        if (this.bundleItems.length === 0) {
          Notify.warning(this.$t('FBAForceMessages.BundleMultiPack.NoBundleItems'))
          e.component.option('value', 0)
          return
        }
        const bundleItem = this.bundleItems.find(p => p.msku === item.msku)
        if (this.fbaBatchParentItemWrapperFormData.packType === packTypeEnum.multi_pack.value && bundleItem.quantity < 2) {
          Notify.warning(this.$t('FBAForceMessages.BundleMultiPack.MultiPackCountPerBundle'))
          e.component.option('value', 0)
          return
        }
        if (this.fbaBatchParentItemWrapperFormData.packType === packTypeEnum.bundle.value && bundleItem.quantity < 1) {
          Notify.warning('Count Per Bundle value should be at least 1.')
          e.component.option('value', 0)
          return
        }
        bundleItem.itemQuantity -= e.previousValue

        bundleItem.itemQuantity += e.value
        this.normalizeBundleItems()
        this.setQuantityItemWrapperFormData(this.bundleItemQuantity)
      }
      if (!e.value || e.value < 1) {
        Notify.warning('Quantity should be at least 1.')
        e.component.option('value', 0)
        return
      }
      item.inUsedCount = e.value
      const fbaBatchParentItem = {
        id: item.id,
        productId: item.productId,
        itemSource: item.itemSource,
        quantity: e.value,
      }
      this.addBatchParentItem(fbaBatchParentItem)
    },
    addBatchParentItem(item) {
      const existItem = find(this.fbaBatchParentItems, q => q.id === item.id)
      if (existItem) {
        remove(this.fbaBatchParentItems, q => q.id === existItem.id)
        this.fbaBatchParentItems.push(item)
      } else {
        this.fbaBatchParentItems.push(item)
      }
      if (this.fbaBatchParentItemWrapperFormData.isMultipleItem) {
        return
      }
      this.calculateAddedBatchParentItemQuantity()
    },
    calculateAddedBatchParentItemQuantity() {
      const { currentQuantity, currentQuantityPerCase } = this.fbaBatchParentItemExistingCurrents
      const totalQuantity = this.fbaBatchParentItems.map(p => p.quantity)
      const reducer = (accumulator, curr) => accumulator + curr
      const subQuantity = totalQuantity.reduce(reducer)

      if (this.batchInfo.packingType === packingTypeEnum.individual.value) {
        if (this.createType === CreateItemTypeEnum.update_quantity.value) {
          this.setQuantityItemWrapperFormData(subQuantity + currentQuantity)
          return
        }
        this.setQuantityItemWrapperFormData(subQuantity)
      } else {
        const { quantity, quantityPerCase } = this.fbaBatchParentItemWrapperFormData
        let totalCaseQuantity = 0
        if (this.createType === CreateItemTypeEnum.update_quantity.value) {
          const newAddedQuantityPerCase = parseInt(quantityPerCase, 10) - parseInt(currentQuantityPerCase, 10)
          if (newAddedQuantityPerCase === 0) {
            Notify.danger('Items quantity cannot be greater than Case Pack qauntity')
            return
          }
          if (quantity !== currentQuantity) {
            Notify.danger('Items quantity cannot be greater than Case Pack qauntity')
            return
          }
          totalCaseQuantity = parseInt(quantity, 10) * parseInt(newAddedQuantityPerCase, 10)
          if (subQuantity > totalCaseQuantity) {
            Notify.danger('Items quantity cannot be greater than Case Pack qauntity')
          }
          return
        }
        totalCaseQuantity = parseInt(quantity, 10) * parseInt(quantityPerCase, 10)
        if (subQuantity > totalCaseQuantity) {
          Notify.danger('Items quantity cannot be greater than Case Pack qauntity')
          return
        }
        this.casePackQuantity = subQuantity
      }
    },

    // #region Product Bundle Info
    async bundleProductInfo(asin) {
      const bundle = {
        packType: packTypeEnum.individual.value,
        countPerBundle: 0,
        isMultipleItem: false,
        bundleItems: [],
      }
      try {
        const result = await productsService.getProductBundleInfo(this.accountInfo.companyId, asin)
        if (result && 'bundleItems' in result && result.bundleItems && 'itemInfo' in result.bundleItems && result.bundleItems.itemInfo) {
          if (result.bundleItems.itemInfo.length > 0) {
            let countPerBundle = 0
            result.bundleItems.itemInfo.forEach(p => {
              const itemInfo = {
                ...p,
                itemQuantity: 0,
                isValid: false,
              }
              bundle.bundleItems.push(itemInfo)
              countPerBundle += itemInfo.quantity
            })
            bundle.countPerBundle = countPerBundle
            bundle.isMultipleItem = true
          }
          if (result.bundleItems.itemInfo.length === 1) {
            bundle.packType = packTypeEnum.multi_pack.value
          }
          if (result.bundleItems.itemInfo.length > 1) {
            bundle.packType = packTypeEnum.bundle.value
          }
        }
        return bundle
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
        return bundle
      }
    },
    // #endregion
    // #region New Listing Item

    async getProductDefinition(asin) {
      const { storeId } = this.accountInfo
      const result = await amazonApi.getProductDefinition(storeId, asin)
      this.productDefinition = result
    },
    async onAddNewListingItem(e, item) {
      this.isDisableAddItemButton = false
      const upc = item.identifier.identifierType === 'UPC' ? item.identifier.identifier : ''
      const bundle = await this.bundleProductInfo(item.asin)
      this.setListingItemWrapperFormData()
      this.setListingMissingItemWrapperFormData(item.asin, item.manufacturer, item.productType, upc, item.title)
      this.setPackTypeItemWrapperFormData(bundle.packType, bundle.countPerBundle, bundle.isMultipleItem)
      this.salePriceAsin = item.asin
      this.isShowSalesPriceInfo = true
      this.isCreateNewListingItem = true
      if (this.newListingItems.length > 1) {
        this.newListingItems.length = 0
        this.newListingItems.push(item)
      }
      this.setAmazonItemGuidance(item.asin)
      this.getProductDefinition(item.asin)
      this.$nextTick(() => {
        this.intializeBundleItems(bundle.bundleItems)
      })
    },
    onAddNewListingChildItem(e, item) {
      const popup = this.$refs.popupVariationsRef.instance
      this.selectedVariationParent = { ...item }
      popup.show()
    },
    onShowingVariationsPopup() {
      this.reCreateVariationsComp = true
    },
    onHidingVariationsPopup() {
      this.reCreateVariationsComp = false
    },
    onEmitSelectVariation(payload) {
      const popup = this.$refs.popupVariationsRef.instance
      remove(this.newListingItems, p => p.rowId === payload.rowId)
      this.newListingItems.length = 0
      this.newListingItems.push(payload)
      popup.hide()
      this.reCreateVariationsComp = false
    },
    // #endregion
    // #region Replenish
    async onClickReplenish(e, data) {
      this.showIsReplenish = true
      this.isDisableAddItemButton = false
      const dataGrid = this.$refs.searchedReplenismentGridRef.instance
      dataGrid.deselectAll()
      dataGrid.selectRows(data.key)
      const {
        msku, asin, fnsku, upc, title, productId, supplier, itemCondition, productType,
      } = data.data
      this.replenishProductId = productId
      this.salePriceAsin = asin
      this.isShowSalesPriceInfo = true
      this.currentMSKU = msku
      const bundle = await this.bundleProductInfo(asin)
      this.setReplenisItemWrapperFormData(msku, fnsku)
      this.setReplenisMissingItemWrapperFormData(productId, supplier, title, upc, asin, msku, fnsku, itemCondition, productType)
      this.setPackTypeItemWrapperFormData(bundle.packType, bundle.countPerBundle, bundle.isMultipleItem)
      if (this.replenishItems.length > 1) {
        this.replenishItems.length = 0
        this.replenishItems.push(data.data)
      }
      this.setAmazonItemGuidance(asin, msku)
      this.$nextTick(() => {
        this.intializeBundleItems(bundle.bundleItems)
      })
    },
    async onClickIgnoreReplenishCreateNew(e, data) {
      this.isDisableAddItemButton = false
      this.isIgnoreMacthingMsku = true
      if (this.searchItems.length > 0) {
        this.createType = CreateItemTypeEnum.list.value
      } else {
        this.createType = CreateItemTypeEnum.list_create_items.value
      }

      this.isShowSalesPriceInfo = true
      this.isCreateNewListingItem = true
      const {
        msku, asin, fnsku, upc, title, productId, supplier, itemCondition, productType,
      } = data
      const bundle = await this.bundleProductInfo(asin)
      this.setListingItemWrapperFormData()
      this.setCreateTypeItemWrapperFormData(this.createType)
      this.setReplenisMissingItemWrapperFormData(productId, supplier, title, upc, asin, msku, fnsku, itemCondition, productType)
      this.setPackTypeItemWrapperFormData(bundle.packType, bundle.countPerBundle, bundle.isMultipleItem)
      this.salePriceAsin = asin
      this.setAmazonItemGuidance(asin, msku)
      this.getProductDefinition(asin)
      this.$nextTick(() => {
        this.intializeBundleItems(bundle.bundleItems)
      })
    },
    intializeBundleItems(bundleItems) {
      bundleItems.forEach(item => {
        this.addBundleItems(item)
      })
    },
    // #endregion
    // #region Existing Item Actions
    async onClickExistingItemDelete(e, item) {
      this.isExistingWorkFlow = false
      this.updateOrMissing = null
      this.isReadOnlyQuantity = false
      const confirm = await this.pshConfirm(
        'Delete Item Confirmation',
        `<p>Are you sure you want to delete this item?</p>
         <h4 class="text-danger mt-half">This action cannot be undone.</h4>`,
      )
      if (confirm) {
        try {
          this.$emit('on-emit-delete-item-batch', this.existingParentItem.id, false)
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    onClickExistingAddAsNewCondition(e, item) {
      this.isExistingWorkFlow = false
      this.updateOrMissing = null
      this.isReadOnlyQuantity = false
      this.$nextTick(() => {
        this.isExistingWorkFlow = false
        this.updateOrMissing = null
        this.setInitialItemWrapperFormData()
        this.createAsNewItemSelect(item)
        this.createType = CreateItemTypeEnum.list_create_items.value
        this.setExistingParentItemCreateType(CreateItemTypeEnum.list_create_items.value)
        this.setListingItemWrapperFormData()
        this.setListingMissingItemWrapperFormData(item.asin, item.supplier, itemProductType(item.catalogItem.productTypes))
        this.isDisableAddItemButton = false
      })
    },
    createAsNewItemSelect(item) {
      const newListingItem = {
        rowId: uuidv4(),
        hasVariations: false,
        asin: item.asin,
        imageUrl: item.largeImage,
        productType: item.productType,
        identifier: {
          identifierType: 'UPC',
          identifier: item.upc,
        },
        brand: '',
        color: '',
        title: item.title,
        rank: item.amzRank,
        manufacturer: item.supplier,
        modelNumber: '',
        size: '',
        style: '',
        offersCount: '',
        variations: [],
      }
      this.newListingItems.push(newListingItem)
    },
    // #endregion
    // #region Create Missing Item
    onClickCreateMissingItem(e) {
      this.$swal({
        title: 'Confirmation',
        html: `
          <div class="d-flex  justify-content-start px-5">
            <div class="w-100 d-flex flex-row py-half">
              Please confirm that you want to create missing item. This action will create items on behalf of your client and save them into the Needs Action batch in the Inbound > Shipping Plans.
            </div>
          </div>
        `,
        width: '25vw',
        showCancelButton: true,
        confirmButtonText: 'Create',
        customClass: {
          confirmButton: 'btn btn-primary w-25',
          cancelButton: 'btn btn-outline-danger ml-1 w-25',
          content: 'px-0',
          htmlContainer: 'mx-0',
        },
        buttonsStyling: false,
        icon: 'warning',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: 'List on Amazon',
      }).then(result => {
        if (result.isConfirmed) {
          const listOnAmazon = result.value === 1
          this.setListOnAmazon(listOnAmazon)
          if (this.createType === CreateItemTypeEnum.replenish.value) {
            this.searchItems.splice(0, this.searchItems.length)
            this.setExistingParentItemCreateType(CreateItemTypeEnum.replenish_create_items.value)
            return
          }
          if (this.createType === CreateItemTypeEnum.list.value) {
            this.searchItems.splice(0, this.searchItems.length)
            this.setExistingParentItemCreateType(CreateItemTypeEnum.list_create_items.value)
          }
        }
      })
    },
    // #endregion
    // #region Bundle Item
    addBundleItem(item) {
      if (!this.fbaBatchParentItemWrapperFormData.isMultipleItem) {
        Notify.warning('Set multiple items to true to form a custom bundle or multi-pack listing.')
        return
      }
      if (this.fbaBatchParentItemWrapperFormData.packType === packTypeEnum.individual.value) {
        Notify.warning('Pack Type should be bundle or multi-pack.')
        return
      }
      if (item.itemSource === 'inbound') {
        Notify.warning('This item cannot be added bundle or multi-pack items. Item source should be inventory.')
        return
      }
      if (!item.quantity || item.quantity === 0) {
        Notify.warning('This item cannot be added bundle items. Available quantity should be at least 1.')
        return
      }
      if (this.fbaBatchParentItemWrapperFormData.packType === packTypeEnum.multi_pack.value && this.bundleItems.length >= 1) {
        Notify.warning('Multi-Pack can only have single product.')
        return
      }

      const productId = item.productId
      const data = {
        productId: productId,
        msku: item.msku,
        mskuFnskuPairId: item.mskuFnskuPairId,
        quantity: 1,
        itemQuantity: 0,
        isValid: false,
      }
      this.addBundleItems(data)
    },
    removeBundleItem(e, index) {
      this.removeBundleItems(index)
    },
    onBundleItemQuantityChanged(e) {
      this.fbaBatchParentItemWrapperFormData.countPerBundle = 0
      const bundleItem = this.bundleItems.map(item => item.quantity)
      const reducer = (accumulator, curr) => accumulator + curr
      const subQuantityTotal = bundleItem.reduce(reducer)
      this.fbaBatchParentItemWrapperFormData.countPerBundle = subQuantityTotal
    },
    bundleItemQunatityValidFormat(isValid) {
      if (isValid) {
        return { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#caffbf' }
      }
      return { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffadad' }
    },
    onEmitChangedPackType(payload) {
      this.fbaBatchParentItemWrapperFormData = {
        ...this.fbaBatchParentItemWrapperFormData,
        packType: payload,
      }
    },
    onEmitChangedMultipleItem(payload) {
      if (!payload) {
        this.clearBundleItems()
        this.showBundleButton = false
      }
      if (this.fbaBatchParentItemWrapperFormData.packType === packTypeEnum.bundle.value) {
        this.bundleButtonText = 'Set As Bundle Item'
        this.showBundleButton = true
      }
      if (this.fbaBatchParentItemWrapperFormData.packType === packTypeEnum.multi_pack.value) {
        this.bundleButtonText = 'Set As Multipack Item'
        this.showBundleButton = true
      }
      this.fbaBatchParentItemWrapperFormData = {
        ...this.fbaBatchParentItemWrapperFormData,
        isMultipleItem: payload,
      }
    },
    // #endregion
    onEmitAddItemBatch(payload) {
      const validate = this.validateItemWrapperFormData(payload)
      if (!validate) return
      if (payload.bundleItems && payload.bundleItems.itemInfo.length > 0) {
        if (this.isReplenish) {
          payload.createType = CreateItemTypeEnum.replenish.value
        }
        if (this.isListing) {
          payload.createType = CreateItemTypeEnum.list.value
        }
      }
      payload.channel = this.channel
      this.$emit('add-item-batch', payload, false)
    },
    onCancelNewItem() {
      this.$emit('cancel-add-item-batch', false)
    },
    onAddNewItem(e) {
      // protect only one click
      e.element.removeEventListener('click', this.onAddNewItem)

      // pre validation
      const qauntity = this.fbaBatchParentItemWrapperFormData.quantity
      if (qauntity === 0) {
        Notify.warning('Quantity should be at least 1.')
        return
      }
      const salePrice = this.fbaBatchParentItemWrapperFormData.salePrice

      if (!this.isExisting && (!isNumber(salePrice) || salePrice === 0)) {
        Notify.warning('Sale Price cannot be 0. Please enter the sale price.')
        return
      }

      const cost = this.fbaBatchParentItemWrapperFormData.cost
      if (!this.isExisting && (!isNumber(cost) || cost === 0)) {
        Notify.warning('Cost cannot be 0. Please enter the cost.')
        return
      }

      let exprDate = formatedDateStr(this.fbaBatchParentItemWrapperFormData.expirationDate, 'MM/DD/YYYY')
      let exprDateStr = exprDate

      exprDate = exprDate ?? ''
      if (!exprDate && this.fbaBatchParentItemWrapperFormData.productType) {
        const exp = getExpirationDate(this.fbaBatchParentItemWrapperFormData.productType)
        if (exp) {
          exprDateStr = `${exp} Auto Generated!`
          exprDate = exp
        }
      }

      if (exprDate) {
        this.setExpirationDateItemWrapperFormData(exprDate)
      }

      let mskuText = this.tempCurrentMSKU ? this.tempCurrentMSKU : this.currentMSKU
      if (this.useManualCreatedMskuValue) {
        mskuText = this.currentMSKU
      }
      if (!mskuText && mskuText === '') {
        mskuText = this.fbaBatchParentItemWrapperFormData.msku
      }

      mskuText = mskuText.replace(/#/g, '')

      if (!this.isExisting && (mskuText && mskuText.length > 40)) {
        Notify.warning('MSKU should be less than 40 characters.')
        return
      }

      const missingItemQuantity = this.fbaBatchParentItemWrapperFormData.missingItemQuantity
      let missingItemQuantityMessage = ''
      if (missingItemQuantity > 0) {
        missingItemQuantityMessage = `
        <div class="w-100 d-flex flex-row py-half">
            <div class="col-4 font-weight-bold text-left">Missing Quantity</div>
            <div>:${missingItemQuantity}</div>
          </div>
        `
      }

      this.$swal({
        title: 'Confirmation',
        html: `
        <div class="d-flex flex-column justify-content-start px-3">
          <div class="w-100 d-flex flex-row py-half">
            <div class="col-4 font-weight-bold text-left">MSKU</div>
            <div>:${mskuText}</div>
          </div>
          <div class="w-100 d-flex flex-row py-half">
            <div class="col-4 font-weight-bold text-left">ASIN</div>
            <div>:${this.fbaBatchParentItemWrapperFormData.asin}</div>
          </div>
          <div class="w-100 d-flex flex-row py-half">
            <div class="col-4 font-weight-bold text-left">Total Quantity</div>
            <div>:${this.fbaBatchParentItemWrapperFormData.quantity}</div>
          </div>
          ${missingItemQuantityMessage}
          <div class="w-100 d-flex flex-row py-half">
            <div class="col-4 font-weight-bold text-left">Expiration Date</div>
            <div>:${exprDateStr}</div>
          </div>
        </div>
        `,
        icon: 'info',
        width: '30vw',
        showCancelButton: true,
        confirmButtonText: 'Add To Batch',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          content: 'px-0',
          htmlContainer: 'mx-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const items = [...this.fbaBatchParentItems]
          // Replenish set msku
          this.tempCurrentMSKU = this.tempCurrentMSKU ? this.tempCurrentMSKU : this.currentMSKU
          if (this.useManualCreatedMskuValue) {
            this.tempCurrentMSKU = this.currentMSKU
          }
          let bundleItems = null
          if (this.bundleItems.length > 0) {
            bundleItems = {
              productId: this.fbaBatchParentItemWrapperFormData.productId,
              itemInfo: [...this.bundleItems],
            }
          }
          const payload = {
            msku: this.tempCurrentMSKU,
            items: items,
            charges: [],
            bundleItems: bundleItems,
          }
          this.handleSubmitEventSource.emit(payload)
        }
      })
    },
    // #region Amazon Item Guidance
    getErrorReason(predicate) {
      return getErrorReasonEnum(predicate)
    },
    async getInboundGuidance(asin) {
      const { companyId, storeId } = this.headerInfo
      this.inboundGuidanceResult = {
        errors: [],
        invalidSKUList: [],
        skuInboundGuidanceList: [],
        invalidASINList: [],
        asinInboundGuidanceList: [],
      }
      const data = {
        storeId: storeId,
        asinList: [asin],
        sellerSKUList: null,
        countryCode: this.shipToCountryCode,
      }

      const result = await amazonApi.getInboundGuidance(data)

      const { errors, payload } = result
      if (errors && errors.length > 0) {
        this.inboundGuidanceResult.errors = payload.errors
      }
      if (payload && payload.invalidASINList && payload.invalidASINList.length > 0) {
        this.inboundGuidanceResult.invalidASINList = payload.invalidASINList
      }
      if (payload && payload.asINInboundGuidanceList && payload.asINInboundGuidanceList.length > 0) {
        this.inboundGuidanceResult.asinInboundGuidanceList = payload.asINInboundGuidanceList
      }
      if (payload && payload.invalidSKUList && payload.invalidSKUList.length > 0) {
        this.inboundGuidanceResult.invalidSKUList = payload.invalidSKUList
      }
      if (payload && payload.skUInboundGuidanceList && payload.skUInboundGuidanceList.length > 0) {
        this.inboundGuidanceResult.skuInboundGuidanceList = payload.skUInboundGuidanceList
      }
    },
    setAmazonItemGuidance(asin, msku = '') {
      const { companyId, storeId } = this.headerInfo
      this.amazonItemGuidanceCompId = uuidv4()
      this.amazonItemGuidanceCompProps.storeId = storeId
      this.amazonItemGuidanceCompProps.companyId = companyId
      this.amazonItemGuidanceCompProps.asin = asin
      this.amazonItemGuidanceCompProps.msku = msku
      this.amazonItemGuidanceCompProps.shipToCountryCode = this.shipToCountryCode
    },
    // #endregion
    onEmitSetSelectedPrice(payload) {
      if (this.priceSettingListPriceType === listPriceEnum.MANUAL_PRICING.value || this.priceSettingListPriceType === null) {
        this.fbaBatchParentItemWrapperFormData.salePrice = payload
      }
    },
    onEmitCalculatePrice(payload) {
      this.fbaBatchParentItemWrapperFormData.salePrice = this.calculatePrice(payload)
    },

    onClickInboundItemUpdate(e, item) {
      const popup = this.$refs.inboundItemUpdatePopupRef.instance
      this.selectedInboundItem.id = item.id
      this.selectedInboundItem.title = item.title
      this.selectedInboundItem.asin = item.asin
      this.selectedInboundItem.msku = item.msku
      this.selectedInboundItem.upc = item.upc
      this.selectedInboundItem.fnsku = item.fnsku
      this.selectedInboundItem.tracking = item.tracking
      this.selectedInboundItem.orderNumber = item.orderNumber
      this.selectedInboundItem.palletId = item.palletId
      popup.show()
    },
    onCancelInboundItemUpdate() {
      const popup = this.$refs.inboundItemUpdatePopupRef.instance
      popup.hide()
    },
    onSaveInboundItemUpdate() {
      const popup = this.$refs.inboundItemUpdatePopupRef.instance
      const data = {
        id: this.selectedInboundItem.id,
        tracking: this.selectedInboundItem.tracking,
        orderNumber: this.selectedInboundItem.orderNumber,
        palletId: this.selectedInboundItem.palletId,
      }
      shipService
        .inboundItemUpdate(data)
        .then(result => {
          Notify.success('Successfully updated.')
          this.getInventoryData(this.filter)
        })
        .catch(err => {
          const messeage = err.message || err
          Notify.error(messeage)
        })
        .finally(() => {
          popup.hide()
        })
    },
    containsNewCondition(asin) {
      return this.listingRestrictionsMap[asin].some(condition => condition.conditionType === 'NEW_NEW')
    },
  },
}
</script>

<style lang="scss">
.batch-item-card {
  border-radius: 0 !important;
}

.new-item-content {
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
  }

  .new-item-header {
    font-display: block;
    font-weight: 500;
    font-size: 1rem;
  }

  .new-item-detail {
    .count-item {
      font-display: block;
      font-weight: 500;

      &.count-success {
        color: rgba(0, 184, 148, 1);
      }

      &.count-warning {
        color: rgba(253, 203, 110, 1);
      }

      &.count-danger {
        color: rgba(214, 48, 49, 1);
      }

      &.count-info {
        color: rgba(0, 206, 201, 1);
      }

      &::before {
        content: ': ';
        color: rgba(223, 230, 233, 1);
      }
    }
  }

  .shipping-content {
    //background-color: rgba(223, 230, 233, 1);
    background-color: #a0c4ff;
    color: rgba(45, 52, 54, 1);
    height: 2.571rem;

    .info-text {
      padding-left: 8px;
      display: block;
      font-size: 1.125rem;
      font-weight: 500;
      text-align: justify;
      text-justify: inter-word;
    }
  }

  .action-content {
    background-color: rgba(223, 230, 233, 1);
    color: rgba(45, 52, 54, 1);
    position: absolute;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding-top: 0.286rem;
    padding-bottom: 0.286rem;
    bottom: 0;
    margin: 0 0 !important;
  }

  .dx-button-padding {
    padding: 0.286rem 0.286rem;
  }
}

.swal2-label {
  font-size: 18px !important;
  color: #ff2700;
  margin-left: 5px;
}

#swal2-checkbox {
  width: 24px !important;
  height: 24px !important;
}

.custom-table {
  max-width: 1200px;
  border-collapse: collapse;
  margin: 6px;
  border-radius: 10px;
}

th,
td {
  border: 1px solid rgba(221, 221, 221, 0.1);
  padding: 6px;
  text-align: left;
}

th {
  background-color: rgba(17, 26, 10, 0.3);
}

.new-new-row {
  background-color: rgba(227, 50, 50, 0.5);
}

.sticky-info {
  position: absolute;
  bottom: -16px;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}
</style>
