<template>
  <div>
    <div class="m-1 mb-3 d-flex justify-content-between">
      <div>
        <h5 class="text-warning">
          FBM Order Auto Close Delay (Hours)
        </h5>
        <div class="text-primary mb-1 pr-3">
          Specify the time, in hours, for an order marked as 'Shipped' to automatically transition to the 'Shipped' category without
          any charge or invoice. This duration should be between 0 and 96 hours.
          <p class="text-warning">
            If you prefer to manually close the FBM orders, please enter 0."
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <dx-util-number-box v-model="fbmAutoCloseDelay.value" :min="0" :max="96" :width="100" />
        <dx-util-button text="Save" class="ml-1" type="success" width="60" @click="saveFBMAutoCloseDelay" />
      </div>
    </div>
  </div>
</template>

<script>
import managedKeysEnum from '@/enums/managedKeysEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import tenantService from '@/http/requests/tenant/tenan.settings'

export default {
  data() {
    return {
      fbmAutoCloseDelay: {
        id: null,
        key: managedKeysEnum.FBM_STORE_ORDER_AUTO_CLOSE_DELAY_HOUR.key,
        value: null,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.INTEGER.key,
        version: null,
        referenceId: this.storeId,
      },
    }
  },
  async mounted() {
    await this.getFbmAutoCloseDelay()
  },
  methods: {
    getFbmAutoCloseDelay() {
      tenantService.fetchByKey(managedKeysEnum.FBM_STORE_ORDER_AUTO_CLOSE_DELAY_HOUR.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.fbmAutoCloseDelay = {
            id: data.id,
            key: data.key,
            value: parseInt(data.value, 10),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    saveFBMAutoCloseDelay() {
      const fbmAutoCloseDelay = { ...this.fbmAutoCloseDelay }
      if (fbmAutoCloseDelay.id) {
        tenantService.update(fbmAutoCloseDelay).then(result => {
          const { id, version, tenantId } = result.data.body
          this.fbmAutoCloseDelay = {
            ...this.fbmAutoCloseDelay,
            id,
            version,
            tenantId,
          }
        })
      } else {
        tenantService
          .create(fbmAutoCloseDelay)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.fbmAutoCloseDelay = {
              ...this.fbmAutoCloseDelay,
              id,
              version,
              tenantId,
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
