<template>
<div class="container-fluid">
  <div v-if="notSelectPlan" class="row page-header-info">
    <div class="px-0" :class="isOpen(headerInfo.batchStatus) ? 'col-8': 'col-12'">
      <div class="wizard nomargin">
        <div class="work-flow flex-grow-1">
            {{ headerInfo.accountNo }} | {{ headerInfo.companyName }} | {{ headerInfo.storeName }} | {{ headerInfo.batchName }}
        </div>
          <a v-for="(item, index) in headerInfo.batchStatus"
            :key="item.value" class="work-flow"
            :class="getClass(item.currentStatus,index)"
            @click="onSubmitBatch($event, item)"
          >
            {{ item.text }}
          </a>
      </div>
    </div>
    <div v-if="isOpen(headerInfo.batchStatus)" class="col-4 pr-0 ">
      <div class="d-flex flex-row justify-content-start">
        <div class="flex-grow-1">
          <dx-util-text-box
            id="searchTextBox"
            ref="searchTextBoxRef"
            v-model="searchText"
            :value="searchText"
            name="txtBoxSearch"
            styling-mode="filled"
            placeholder="Search items to add to batch by UPC, ASIN, FNSKU and Title"
            :element-attr="{style: 'border-radius: 0rem; border: none; color: rgba(223, 230, 233, 1)'}"
            :input-attr="{style: 'color: rgba(45, 52, 54, 1)'}"
            :show-clear-button="true"
            :read-only="isReadOnlySearch"
            @value-changed="onClearSearchItem"
            @enter-key="onEnterSearchItem"
            @initialized="onInitializedSearchItem"
          >
            <dx-text-box-button
              id="searchTextBoxButton"
              :options="searchButtonOptions"
              name="searchTextBoxButton"
              location="before"
              css-class="btn-default"
            />
          </dx-util-text-box>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row page-header-info">
    <div class="col-12 px-0">
      <div class="wizard nomargin">
        <div class="work-flow flex-grow-1">
            Select Batch
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import store from '@/store'
import { emitter } from '@/libs/bus'
import { FBAEvents } from '@/libs/constants/events.const'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import {
  isValidAsin,
  isValidUpc,
} from '@/views/apps/ship/validationItem'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  emits: ['search-item'],
  data() {
    return {
      isIndividualTenant: false,
      isReadOnlySearch: false,
      isUPC: false,
      isASIN: false,
      isKeywords: false,
      searchText: '',
      amazonSearchFilter: 'none',
      amazonSearchFilters: [
        { value: 'none', text: 'Select Search Filter' },
        { value: 'asin', text: 'ASIN' },
        { value: 'msku', text: 'MSKU' },
        { value: 'title', text: 'Title' },
      ],
    }
  },
  computed: {
    headerInfo() {
      const headerInfo = store.getters['fbaShipment/getSelectedBactInfo']
      if (this.isIndividualTenant) {
        headerInfo.accountNo = ''
      }
      return headerInfo
    },
    notSelectPlan() {
      const headerInfo = store.getters['fbaShipment/getSelectedBactInfo']
      return !isEmpty(headerInfo)
    },
    searchButtonOptions() {
      const self = this
      return {
        text: 'SEARCH',
        type: 'default',
        icon: 'search',
        height: '36px',
        elementAttr: { style: 'padding: 1px 0 1px' },
        onClick: e => {
          self.$nextTick(() => {
            const seachTextBox = this.$refs.searchTextBoxRef.instance
            let searchText = seachTextBox.option('value')
            searchText = searchText.trim()
            if (searchText) {
              self.onSearchItem(searchText)
            }
          })
        },
      }
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.tenantType === 'INDIVIDUAL') {
      this.isIndividualTenant = true
    }
    emitter.on(FBAEvents.CLEAR_SEARCH_TEXT.event, () => {
      this.clearSeachText()
    })
  },
  methods: {
    onInitializedSearchItem(e) {
      setTimeout(() => {
        e.component.focus()
      }, 0)
    },
    getClass(current, index) {
      const currentIndex = findIndex(this.headerInfo.batchStatus, ['currentStatus', true])
      if (current) { return 'progress-current' }
      if (!current && index > currentIndex) { return '' }
      return 'progress-success'
    },
    isOpen(batchStatus) {
      this.searchText = ''
      const openStatus = find(batchStatus, { key: 'open', currentStatus: true })
      return !isEmpty(openStatus)
    },
    onEnterSearchItem(e) {
      if (e.event.keyCode === 13) {
        if (e.event.target.value) {
          this.isReadOnlySearch = true
          const searchText = e.event.target.value.trim()
          e.component.option('value', searchText)
          this.onSearchItem(searchText)
        }
      }
    },
    clearSeachText() {
      this.$nextTick(() => {
        if (this.isOpen(this.headerInfo.batchStatus)) {
          this.searchText = ''
          if (this.$refs.searchTextBoxRef && this.$refs.searchTextBoxRef.instance) {
            const seachTextBox = this.$refs.searchTextBoxRef.instance
            seachTextBox.option('value', '')
            seachTextBox.focus()
          }
        }
      })
    },
    onClearSearchItem(e) {
      if (e.value === '' || e.value === null || typeof e.value === 'undefined') {
        const payload = {
          searchText: e.value,
          status: false,
        }
        emitter.emit(FBAEvents.BATCH_SEARCH_ITEM.event, payload)
      } else {
        this.searchText = e.value
      }
    },
    onSearchItem(searchText) {
      if (searchText) {
        this.isUPC = isValidUpc(searchText)
        this.isASIN = isValidAsin(searchText)
        if (!this.isUPC && !this.isASIN) {
          this.isKeywords = true
        }
        const payload = {
          searchText: searchText,
          isUpc: this.isUPC,
          isAsin: this.isASIN,
          isKeywords: this.isKeyword,
          amazonSearchFilter: this.amazonSearchFilter,
          status: true,
        }
        emitter.emit(FBAEvents.BATCH_SEARCH_ITEM.event, payload)
        this.isReadOnlySearch = false
      } else {
        const payload = {
          searchText: '',
          status: false,
        }
        emitter.emit(FBAEvents.BATCH_SEARCH_ITEM.event, payload)
        this.isReadOnlySearch = false
      }
    },
    onSubmitBatch(e, item) {
      if (!item.currentStatus && item.key === 'boxing') {
        this.$emit('submit-batch', 'boxing', this.headerInfo.batchId)
      }
    },
  },
}
</script>

<style lang="scss">
.page-header-info {
  height: 36px;
  background-color: rgba(223, 230, 233, 1);
  color: rgba(45, 52, 54, 1);
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
    Helvetica Neue, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: justify;
  text-justify: inter-word;
  .text-success {
    color: rgba(0, 184, 148, 1);
  }
}
#searchTextBox {
  .dx-texteditor-container {
    height: 36px;
  }
}

.nomargin {
  padding: 0 !important;
  margin: 0 !important;
}

.wizard {
  background-color: rgba(223, 230, 233, 1);
  width: 100% !important;
  display: flex;
  .work-flow {
    padding: 8px 12px 8px 24px;
    position: relative;
    display: inline-flexbox;
    text-decoration: none;
    margin-left: 1px;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    color: rgba(45, 52, 54,1.0);
    background: rgba(223, 230, 233, 1);
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &:first-child {
      margin-left: 0;
      text-align: left;
      padding: 8px 12px 8px 8px;
    }
    &:nth-child(2) {
      margin-left: 0;
    }
    &:before {
        width: 0;
        height: 0;
        border-top: 18px inset transparent;
        border-bottom: 18px inset transparent;
        border-left: 18px solid rgba(99, 110, 114,1.0);
        position: absolute;
        content: "";
        top: 0;
        left: 0;
    }
    &:after {
      width: 0;
      height: 0;
      border-top: 18px inset transparent;
      border-bottom: 18px inset transparent;
      border-left: 18px solid rgba(223, 230, 233, 1);
      position: absolute;
      content: '';
      top: 0;
      right: -18px;
      z-index: 2;
    }
  }
  .work-flow {
    &:first-child:before,
    &:first-child::after,
    &:last-child::after {
      border: none;
    }
    &:nth-child(2):before {
        border: none;
    }
  }
  .current {
    background: rgba(99, 110, 114,1.0);
    color: rgba(223, 230, 233,1.0);
  }

  .work-flow {
    &.progress-current {
      background: rgba(99, 110, 114,1.0);
      color: rgba(223, 230, 233,1.0);
    }
    &.current:after,
    &.progress-current:after {
      border-left-color: rgba(99, 110, 114,1.0) !important;
    }

    &.progress-success {
      background: #27ae60 ;
      color: rgba(223, 230, 233,1.0);
    }
    &.progress-success:after {
      border-left-color: #27ae60 !important;
    }
  }

  .work-flow {
    &.current .badge,
    &.progress-current .badge {
      background-color: rgba(223, 230, 233,1.0);
      color: rgba(99, 110, 114,1.0);
    }
  }
}

_:-ms-fullscreen,
:root .wizard.small.left-arrow a:before {
  left: -14px;
}

_:-ms-fullscreen,
:root .wizard.small:not(.left-arrow) a:after {
  right: -14px;
}

_:-ms-fullscreen,
:root .wizard.left-arrow a:before {
  left: -29px;
}

_:-ms-fullscreen,
:root .wizard:not(.left-arrow) a:after {
  right: -29px;
}
</style>
