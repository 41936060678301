<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="border-info mt-2 p-2">
      <div class="title-box bg-info text-light">
        {{ preference.text }}
      </div>
      <div class="mt-1">
        <span v-if="preference.description !== ''"
          v-html="preference.description"
        />
      </div>
      <div v-for="item in preference.items" :key="item.key">
        <div class="d-flex flex-row mt-2">
          <div class="col-5 font-weight-bold">
            {{ item.text }}:
          </div>
          <div v-if="item.value === notificationPreferenceEnum.ENABLED.value">
            <dx-util-switch v-model="isEnabled" @value-changed="setEnabled($event, item)" />
          </div>
            <div v-if="item.value === notificationPreferenceEnum.SUB_TOPICS.value" class="d-flex flex-row">
              <div v-for="(topic, index) in item.topics" :key="topic.key">
                <div class="mr-3">
                  <span>
                    {{ topic.text }}
                  </span>
                  <dx-util-switch
                    :disabled="!isEnabled"
                    v-model="topics[index].enabled"
                    @value-changed="setTopicDetail($event, {item: item.value, topic: topic.value} )"
                  />
                </div>
              </div>
          </div>
          <div v-if="item.value === notificationPreferenceEnum.FREQUENCY.value">
            <dx-util-select-box
              class="box-width"
              :data-source="prepareValues(item.valueType)"
              :disabled="!isEnabled"
              display-expr="text"
              v-model="selectedFrequency"
              value-expr="value"
              @valueChanged="setFrequency($event, item)"
            />
          </div>
          <div v-if="item.value === notificationPreferenceEnum.RECEIVERS.value">
            <div class="box-width">
              <dx-util-tag-box
                :disabled="!isEnabled"
                :data-source="usersList"
                :search-enabled="true"
                display-expr="text"
                value-expr="value"
                :hide-selected-items="true"
                placeholder="Choose Receivers..."
                v-model="receivers"
                @selectionChanged="setReceivers(item)"
              />
            </div>
          </div>

        </div>
      </div>
      <div class="d-flex justify-content-end pt-2 pr-2">
        <dx-util-button
          text="Save"
          :width="'100px'"
          type="success"
          styling-mode="contained"
          @click="save($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useCurrentUser from '@/libs/app/current-user'
import { NotificationFrequencyEnum, ManagedKeysEnum, ReferenceTypeEnum } from '@/enums'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import notificationPreferenceEnum from '@/enums/notification-preference.enum'
import companyService from '@/http/requests/company/companyService'
import settingsService from '@/http/requests/common/settings'
import { Notify } from '@/@robustshell/utils'
import managedKeysEnum from '@/enums/managedKeysEnum'

export default {
  setup() {
    const {
      userCompanyId,
    } = useCurrentUser()
    return {
      userCompanyId,
    }
  },
  props: {
    preference: {
      type: Object,
      default: () => ({}),
    },
    storeId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      NotificationFrequencyEnum,
      notificationPreferenceEnum,
      ManagedKeysEnum,
      isEnabled: false,
      selectedItemKeys: [],
      usersList: [],
      isItemDisabled: true, // enableDetails will be updated by SHIPMENTBATCHEMAILNOTIFICATIONENABLED
      receivers: [],
      selectedFrequency: '',
      notificationPreferencesJSON: {},
      topics: [],
    }
  },
  watch: {
    storeId: {
      immediate: true,
      deep: true,
      handler() {
        this.getCurrentNotificationPreferences()
      },
    },
  },
  mounted() {
    this.getUsersList()
    this.initPreferenceItems(this.preference)
  },
  methods: {
    initPreferenceItems(preference) {
      preference.items.forEach(item => {
        switch (item.value) {
          case notificationPreferenceEnum.ENABLED.value:
            this.notificationPreferencesJSON[item.value] = false
            break
          case notificationPreferenceEnum.FREQUENCY.value:
            this.notificationPreferencesJSON[item.value] = ''
            break
          case notificationPreferenceEnum.SUB_TOPICS.value:
            this.notificationPreferencesJSON[item.value] = []
            item.topics.forEach(el => {
              this.notificationPreferencesJSON[item.value].push({
                subTopicName: el.value,
                enabled: false,
              })
              this.topics.push({
                subTopicName: el.value,
                enabled: false,
              })
            })
            break
          case notificationPreferenceEnum.RECEIVERS.value:
            this.notificationPreferencesJSON[item.value] = []
            break
          default:
            break
        }
      })
    },
    setEnabled(e, item) {
      this.notificationPreferencesJSON[item.value] = e.value
    },
    prepareValues(e) {
      return Object.values(e)
    },
    setFrequency(e, item) {
      this.notificationPreferencesJSON[item.value] = e.value
    },
    setTopicDetail(e, payload) {
      this.notificationPreferencesJSON[payload.item]
        .filter(el => el.subTopicName === payload.topic)[0].enabled = e.value
    },
    setReceivers(item) {
      this.notificationPreferencesJSON[item.value] = this.receivers
    },
    async save() {
      if (this.store === '') {
        Notify.warning('Please select a store')
        return
      }
      if (this.selectedFrequency === '') {
        Notify.warning('Please select a notification frequency')
        return
      }
      if (this.receivers.length === 0) {
        Notify.warning('Please select at least one receiver for notifications')
        return
      }
      const preferenceData = {
        companyId: this.userCompanyId,
        referenceId: this.storeId,
        referenceType: referenceTypeEnum.STORE.value,
        settingType: SettingsTypeEnum.NOTIFICATION_PREFERENCES.value,
        settingKey: ManagedKeysEnum.SHIPMENTBATCHEMAILNOTIFICATION.value,
        description: `Notification Preferences for the store #${this.storeId}`,
        settingName: ManagedKeysEnum.SHIPMENTBATCHEMAILNOTIFICATION.value,
        value: JSON.stringify(this.notificationPreferencesJSON),
      }
      await settingsService.createOrUpdate(preferenceData)
    },
    getUsersList() {
      companyService.fetchUsersByCompanyId(this.userCompanyId, '').then(response => {
        this.usersList = response.data.content.map(user => ({
          key: user.id,
          text: `${user.fullName} - ${user.email}`,
          value: user.id,
        }))
      })
    },
    getCurrentNotificationPreferences() {
      settingsService
        .getByReference(
          ReferenceTypeEnum.STORE.value,
          this.storeId,
          managedKeysEnum.SHIPMENTBATCHEMAILNOTIFICATION.value,
        )
        .then(result => {
          const data = { ...result.data }
          if (data.value !== null) {
            const fetchedPreference = JSON.parse(data.value)
            this.notificationPreferencesJSON = fetchedPreference
            this.isEnabled = fetchedPreference[notificationPreferenceEnum.ENABLED.value]
            this.selectedFrequency = fetchedPreference[notificationPreferenceEnum.FREQUENCY.value]
            this.receivers = fetchedPreference[notificationPreferenceEnum.RECEIVERS.value]
            this.topics = fetchedPreference[notificationPreferenceEnum.SUB_TOPICS.value]
          }
        })
    },
  },
}
</script>

<style>
.title-box {
  position: absolute;
  margin-top: -2.9rem;
  padding: 6px 24px 6px 24px;
  font-size: 16px;
  border-radius: 0.1rem;
}
.box-width {
  min-width: 360px;
}
.dx-tab-selected {
    background-color: #2980b9;
}
.dx-tab {
  font-size: 18px;
}
</style>
