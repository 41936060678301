import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const carrierEnum = Object.freeze({
  UNITED_PARCEL_SERVICE_INC: { key: 'UNITED_PARCEL_SERVICE_INC', value: 'UNITED_PARCEL_SERVICE_INC', text: 'UPS' },
  FEDERAL_EXPRESS_CORP: { key: 'FEDERAL_EXPRESS_CORP', value: 'FEDERAL_EXPRESS_CORP', text: 'FedEx' },
  UNITED_STATES_POSTAL_SERVICE: { key: 'UNITED_STATES_POSTAL_SERVICE', value: 'UNITED_STATES_POSTAL_SERVICE', text: 'USPS' },
  DHL_EXPRESS_USA_INC: { key: 'DHL_EXPRESS_USA_INC', value: 'DHL_EXPRESS_USA_INC', text: 'DHL' },
  OTHER: { key: 'OTHER', value: 'OTHER', text: 'Other' },
})

function getCarrierEnum(predicate, predicateKey = 'key') {
  const result = find(carrierEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getCarrierEnumList() {
  const options = []
  Object
    .keys(carrierEnum)
    .map(key => options.push(
      {
        value: carrierEnum[key].value,
        text: carrierEnum[key].text,
      },
    ))
  return options
}

export default carrierEnum

export {
  getCarrierEnum,
  getCarrierEnumList,
}
