<template>
  <div>
    <div class="d-flex p-1">
      <div class="flex-grow-1">
        <label for="selectedCountries">Working Countries</label>
        <dx-util-tag-box id="selectedCountries" v-model="selectedCountryCodes"
          placeholder="Type Country Names or Codes" :data-source="countriesWithRegions"
          display-expr="name"
          value-expr="code" class="mx-half"
          style="min-width: 300px;" :show-selection-controls="true"
          :show-multi-tag-only="false" :search-enabled="true"
          :multiline="false" :max-displayed-tags="10"
          :drop-down-options="dropDownOptions"
        />
      </div>
      <div class="text-right mt-2">
        <dx-util-button text="Save" type="success" style="width:150px" @click="handleSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import useStoreSettings from './useStoreSettings'

export default {
  components: {
  },
  data() {
    return {
      dropDownOptions: {
        height: 300,
      },
    }
  },
  setup() {
    const { currentStore, countriesWithRegions, selectedCountryCodes } = useStoreSettings()
    return {
      currentStore, countriesWithRegions, selectedCountryCodes,
    }
  },
  watch: {
    currentStore(value) {
      if (value.id) {
        this.loadFBMCountries()
      }
    },
  },
  mounted() {
    if (this.currentStore.id) {
      this.loadFBMCountries()
    }
  },
  methods: {
    async handleSubmit() {
      await this.saveFBMCountries()
      this.closePopup()
    },
    async loadFBMCountries() {
      const result = await settingsService.getByReference(referenceTypeEnum.STORE.value, this.currentStore.id, SettingsTypeEnum.STORE_FBM_COUNTRY.key)
      const codesPayload = JSON.parse(result.data.value)
      this.selectedCountryCodes = codesPayload?.countryCodeList
    },
    async saveFBMCountries() {
      const codesPayload = {
        countryCodeList: this.selectedCountryCodes,
      }

      const preferenceData = {
        companyId: this.currentStore.parentCompany,
        referenceId: this.currentStore.id,
        referenceType: referenceTypeEnum.STORE.value,
        settingType: SettingsTypeEnum.STORE_FBM_COUNTRY.value,
        settingKey: SettingsTypeEnum.STORE_FBM_COUNTRY.key,
        description: `FBM Preferences for storeID: ${this.currentStore.id}`,
        settingName: SettingsTypeEnum.STORE_FBM_COUNTRY.value,
        value: JSON.stringify(codesPayload),
      }
      await settingsService.createOrUpdate(preferenceData)
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  padding-left: 8px;
}
</style>
