<template>
  <dx-util-popup
    ref="storeSettingsPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="true"
    :position="'center'"
    @shown="storeSettingsPopupShown"
    @hidden="storeSettingsPopupHidden"
  >
    <div>
      <dx-util-tab-panel
        :selected-index="activeTab"
        :show-nav-buttons="true"
        :animation-enabled="true"
        :scroll-by-content="true"
        >
        <dx-util-panel-item title="Auto Pricing" icon="bi bi-cash-coin">
          <template #default>
            <auto-pricing-settings />
          </template>
        </dx-util-panel-item>
        <dx-util-panel-item title="MSKU Formula" icon="bi bi-columns-gap">
          <template #default>
            <msku-formula />
          </template>
        </dx-util-panel-item>
        <dx-util-panel-item :visible="isWalmartStore" title="Walmart Auth" icon="bi bi-shield-check">
          <template #default>
           <walmart-auth />
          </template>
        </dx-util-panel-item>
        <dx-util-panel-item :visible="!isIndividualTenant" title="FBM Working Countries" icon="bi bi-box-fill">
          <template #default>
            <fbm-countries />
          </template>
        </dx-util-panel-item>
        <dx-util-panel-item title="Return & Removal" icon="bi bi-stack">
          <template #default>
           <returns-and-removals />
          </template>
        </dx-util-panel-item>
        <dx-util-panel-item title="Auto Close Orders" icon="bi bi-list-ol">
          <template #default>
            <auto-close-orders :store-id="storeId" />
          </template>
        </dx-util-panel-item>
        <dx-util-panel-item title="Printer Settings" icon="bi bi-printer">
          <template #default>
            <printer-settings :show-store-select="false" :store-id="currentStore.id" />
          </template>
        </dx-util-panel-item>
        <dx-util-panel-item title="Email Preferences" icon="bi bi-at">
          <template #default>
            <notification-preferences :store-id="currentStore.id" />
          </template>
        </dx-util-panel-item>
      </dx-util-tab-panel>
    </div>
  </dx-util-popup>
</template>

<script>
import MerchantPlatformsEnum from '@/enums/merchant-platforms.enum'
import PrinterSettings from '@/views/apps/settings/PrinterSettings.vue'
import NotificationPreferences from '@/views/apps/outbound/shipment-batches/components/NotificationPreferences.vue'
import commonEntityService from '@/http/requests/common/commonEntityService'
import useCurrentUser from '@/libs/app/current-user'
import FBMCountries from './FBMCountries.vue'
import MskuFormula from './MskuFormula.vue'
import WalmartAuth from './WalmartAuth.vue'
import ReturnsAndRemovals from './ReturnsAndRemovals.vue'
import AutoPricingSettings from './AutoPricingSettings.vue'
import AutoCloseOrders from './AutoCloseOrders.vue'
import useStoreSettings from './useStoreSettings'

export default {
  components: {
    'fbm-countries': FBMCountries,
    'msku-formula': MskuFormula,
    'walmart-auth': WalmartAuth,
    'auto-pricing-settings': AutoPricingSettings,
    'returns-and-removals': ReturnsAndRemovals,
    'printer-settings': PrinterSettings,
    'notification-preferences': NotificationPreferences,
    'auto-close-orders': AutoCloseOrders,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    // Bu undefined olmadığında ve 0 dan büyük geldiğinde store data api den çekilecek.
    // Parent componentde store data varsa kullanılmayacak.
    storeId: {
      type: Number,
      default: 0,
    },
    store: {
      type: Object,
      default: () => { },
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const {
      currentStore, loadStore, selectedCountryCodes, resetFormData,
    } = useStoreSettings()
    const { isIndividualTenant } = useCurrentUser()

    return {
      currentStore, loadStore, selectedCountryCodes, resetFormData, isIndividualTenant,
    }
  },
  data() {
    return {
      popupTitle: '',
      MerchantPlatformsEnum,
    }
  },
  computed: {
    storeSettingsPopup() {
      return this.$refs.storeSettingsPopupRef.instance
    },
    isWalmartStore() {
      return MerchantPlatformsEnum.WALMART.value === this.store?.commercePlatform
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.storeSettingsPopup.show()
        if (this.storeId > 0 && !this.store) {
          this.getStoreData()
        } else {
          this.loadStore(this.store)
        }
      },
    },
  },
  methods: {
    async getStoreData() {
      const result = await commonEntityService.fetchECommerceStoreById(this.storeId)
      const store = { ...result.data.body }
      this.loadStore(store)
    },
    storeSettingsPopupShown() {
      this.popupTitle = `Store Settings for ${this.currentStore.name}`
    },
    storeSettingsPopupHidden() {
      this.clearStoreData()
      this.$emit('emit-form-closed')
    },
    closePopup() {
      this.storeSettingsPopup.hide()
    },
    clearStoreData() {
      this.loadStore()
      this.resetFormData()
      this.selectedCountryCodes = []
    },
  },
}
</script>

<style>
.dx-tab {
  font-size: 14px;
}
</style>
