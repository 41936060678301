<template>
  <div class="card mb-0 border-top">
    <div class="card-body px-0 py-0">
      <!-- Inbound ASIN Guidance  -->
      <table v-if="prepInstructionsResult.asinPrepInstructionsList.length > 0" class="table table-bordered">
        <thead>
          <tr>
            <th>
              Amazon Prep Instructions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in prepInstructionsResult.asinPrepInstructionsList" :key="index">
            <td scope="row">
              <div class="d-flex flex-row">
                <div class="mr-half align-self-center">
                  {{ item.asin }}
                </div>
                <div class="mr-half">
                  <span v-b-tooltip.hover="{ variant: 'primary' }" :title="enumParcodeInstruction(item.barcodeInstruction).text" class="badge badge-primary">
                    {{ enumParcodeInstruction(item.barcodeInstruction).value }}
                  </span>
                </div>
                <div class="mr-half">
                  <span v-b-tooltip.hover="{ variant: 'primary' }" :title="enumPrepGuidance(item.prepGuidance).text" class="badge badge-primary">
                    {{ enumPrepGuidance(item.prepGuidance).value }}
                  </span>
                </div>
                <div v-for="(pitem, pindex) in item.prepInstructionList" :key="pindex" class="mr-half">
                  <span v-b-tooltip.hover="{ variant: 'warning' }" :title="enumPrepInstruction(pitem).text" class="badge badge-warning">
                    {{ enumPrepInstruction(pitem).value }}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Invalid ASIN  -->
      <table v-if="prepInstructionsResult.invalidASINList.length > 0" class="table table-bordered">
        <thead>
          <tr>
            <th colspan="3">
              Amazon Prep Instructions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in prepInstructionsResult.invalidASINList" :key="index">
            <td scope="row">
              {{ item.asin }}
            </td>
            <td>
              {{ getErrorReason(item.errorReason).value }}
            </td>
            <td>
              {{ getErrorReason(item.errorReason).text }}
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Inbound SKU Guidance  -->
      <table v-if="prepInstructionsResult.skuPrepInstructionsList.length > 0" class="table table-bordered">
        <thead>
          <tr>
            <th colspan="3">
              Amazon Prep Instructions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in prepInstructionsResult.skuPrepInstructionsList" :key="index">
            <td scope="row">
              {{ item.asin }}
            </td>
            <td>
              {{ getInboundGuidanceText(item.inboundGuidance).value }}
            </td>
            <td>
              <div class="d-flex flex-column">
                <div v-for="(reason, rindex) in item.guidanceReasonList" :key="rindex">
                    {{ getGuidanceReason(reason).value }} / {{ getGuidanceReason(reason).text }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Invalid SKU  -->
      <table v-if="prepInstructionsResult.invalidSKUList.length > 0" class="table table-bordered">
        <thead>
          <tr>
            <th colspan="3">
              Amazon Prep Instructions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in prepInstructionsResult.invalidSKUList" :key="index">
            <td scope="row">
              {{ item.asin }}
            </td>
            <td>
              {{ getErrorReason(item.errorReason).value }}
            </td>
            <td>
              {{ getErrorReason(item.errorReason).text }}
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Errors  -->
      <table v-if="prepInstructionsResult.errors.length > 0" class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">
              Amazon Amazon Prep Instructions Erros
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in prepInstructionsResult.errors" :key="index">
            <td scope="row">
              {{ item.code }}
            </td>
            <td>
              {{ item.message }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import amazonApi from '@/http/requests/external/amazon.fba'
import { getGuidanceReasonEnum } from '../consts/guidance-reason.enum'
import { getInboundGuidanceEnum } from '../consts/inbound-guidance.enum'
import { getErrorReasonEnum } from '../consts/error-reason.enum'
import { getBarcodeInstructionEnum } from '../consts/barcode-instruction.enum'
import { getPrepGuidanceEnum } from '../consts/prep-guidance.enum'
import { getPrepInstructionEnum } from '../consts/prep-instruction.enum'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    asin: {
      type: String,
      default: '',
    },
    sku: {
      type: String,
      default: '',
    },
    companyId: {
      type: Number,
      default: 0,
    },
    storeId: {
      type: Number,
      default: 0,
    },
    shipToCountryCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      error: {
        code: '',
        message: '',
        details: '',
      },
      inboundGuidanceResult: {
        errors: [],
        invalidSKUList: [],
        skuInboundGuidanceList: [],
        invalidASINList: [],
        asinInboundGuidanceList: [],
      },
      prepInstructionsResult: {
        errors: [],
        skuPrepInstructionsList: [],
        invalidSKUList: [],
        asinPrepInstructionsList: [],
        invalidASINList: [],
      },
    }
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.getPrepInstructions()
        }
      },
    },
  },
  methods: {
    getGuidanceReason(predicate) {
      return getGuidanceReasonEnum(predicate)
    },
    getInboundGuidanceText(predicate) {
      return getInboundGuidanceEnum(predicate)
    },
    getErrorReason(predicate) {
      return getErrorReasonEnum(predicate)
    },
    async getInboundGuidance() {
      this.inboundGuidanceResult = {
        errors: [],
        invalidSKUList: [],
        skuInboundGuidanceList: [],
        invalidASINList: [],
        asinInboundGuidanceList: [],
      }
      const data = {
        storeId: this.storeId,
        countryCode: this.shipToCountryCode,
        asinList: [this.asin],
        sellerSKUList: null,
      }
      const result = await amazonApi.getInboundGuidance(data)
      const { errors, payload } = result
      if (errors && errors.length > 0) {
        this.inboundGuidanceResult.errors = payload.errors
      }
      if (payload && payload.invalidASINList && payload.invalidASINList.length > 0) {
        this.inboundGuidanceResult.invalidASINList = payload.invalidASINList
      }
      if (payload && payload.asINInboundGuidanceList && payload.asINInboundGuidanceList.length > 0) {
        this.inboundGuidanceResult.asinInboundGuidanceList = payload.asINInboundGuidanceList
      }
      if (payload && payload.invalidSKUList && payload.invalidSKUList.length > 0) {
        this.inboundGuidanceResult.invalidSKUList = payload.invalidSKUList
      }
      if (payload && payload.skUInboundGuidanceList && payload.skUInboundGuidanceList.length > 0) {
        this.inboundGuidanceResult.skuInboundGuidanceList = payload.skUInboundGuidanceList
      }
    },
    enumParcodeInstruction(predicate) {
      return getBarcodeInstructionEnum(predicate)
    },
    enumPrepGuidance(predicate) {
      return getPrepGuidanceEnum(predicate)
    },
    enumPrepInstruction(predicate) {
      return getPrepInstructionEnum(predicate)
    },
    async getPrepInstructions() {
      this.prepInstructionsResult = {
        errors: [],
        skuPrepInstructionsList: [],
        invalidSKUList: [],
        asinPrepInstructionsList: [],
        invalidASINList: [],
      }
      const asinList = [this.asin]
      const result = await amazonApi.getPrepInstructions(this.storeId, this.shipToCountryCode, asinList)
      const { errors, payload } = result
      if (errors && errors.length > 0) {
        this.prepInstructionsResult.errors = payload.errors
      }
      if (payload && payload.invalidASINList && payload.invalidASINList.length > 0) {
        this.prepInstructionsResult.invalidASINList = payload.invalidASINList
      }
      if (payload && payload.asINPrepInstructionsList && payload.asINPrepInstructionsList.length > 0) {
        this.prepInstructionsResult.asinPrepInstructionsList = payload.asINPrepInstructionsList
      }
      if (payload && payload.invalidSKUList && payload.invalidSKUList.length > 0) {
        this.prepInstructionsResult.invalidSKUList = payload.invalidSKUList
      }
      if (payload && payload.skUPrepInstructionsList && payload.skUPrepInstructionsList.length > 0) {
        this.prepInstructionsResult.skuPrepInstructionsList = payload.skUPrepInstructionsList
      }
    },
  },
}
</script>

<style>

</style>
