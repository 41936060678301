import { v4 as uuidv4 } from 'uuid'
import notificationPreferenceEnum from '@/enums/notification-preference.enum'
import { ManagedKeysEnum, ValueTypeEnum, NotificationFrequencyEnum } from '~/enums'

const managedKeys = [
  {
    groupKey: uuidv4(),
    groupTitle: ManagedKeysEnum.EMAIL,
    title: 'Shipment Batch Email Notifications',
    description: 'PrepShipHub will send email notifications for FBA Shipment Batch updates '
    + 'for the selected store and according to the following settings.',
    key: ManagedKeysEnum.SHIPMENTBATCHEMAILNOTIFICATION.key,
    value: ManagedKeysEnum.SHIPMENTBATCHEMAILNOTIFICATION.value,
    valueType: ValueTypeEnum.JSON.value,
    text: ManagedKeysEnum.SHIPMENTBATCHEMAILNOTIFICATION.text,
    items: [
      {
        key: notificationPreferenceEnum.ENABLED.key,
        value: notificationPreferenceEnum.ENABLED.value,
        text: notificationPreferenceEnum.ENABLED.text,
        valueType: ValueTypeEnum.BOOLEAN.value,
      },
      {
        key: notificationPreferenceEnum.SUB_TOPICS.key,
        value: notificationPreferenceEnum.SUB_TOPICS.value,
        text: notificationPreferenceEnum.SUB_TOPICS.text,
        valueType: ValueTypeEnum.ARRAY.value,
        description: '',
        topics: [
          ManagedKeysEnum.SHIPMENTBATCHSHIPPED,
          ManagedKeysEnum.SHIPMENTBATCHCHECKEDIN,
          ManagedKeysEnum.SHIPMENTBATCHCLOSED,
        ],
      },
      {
        key: notificationPreferenceEnum.FREQUENCY.key,
        value: notificationPreferenceEnum.FREQUENCY.value,
        valueType: NotificationFrequencyEnum,
        text: notificationPreferenceEnum.FREQUENCY.text,
        description: '',
      },
      {
        key: notificationPreferenceEnum.RECEIVERS.key,
        value: notificationPreferenceEnum.RECEIVERS.value,
        valueType: ValueTypeEnum.ARRAY.value,
        text: notificationPreferenceEnum.RECEIVERS.text,
        description: '',
      },
    ],
  },
]

export default managedKeys
