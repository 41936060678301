<template>
  <div class="card card-batch batch-light mb-0 px-0" :style="leftSide">
    <div class="card-body p-0">
      <div class="d-flex flex-column justify-content-start">
        <div class="card-batch-title">
          <div v-if="isDrawerOpen" class="d-flex flex-row justify-content-start">
            <h6 class="mr-auto m-0 align-self-center pl-1">
              Shipment Plans
            </h6>
            <dx-switch
              v-model="showOnlyOpenShipmentPlans"
              :height="36"
              :width="60"
              switched-off-text="ALL"
              switched-on-text="OPEN"
              hint="Show only OPEN shipment plans"
              @value-changed="onShowOnlyOpenShipmentPlans"
            />
            <dx-util-button
              type="normal"
              icon="plus"
              hint="Create new FBA Shipment Plan"
              :element-attr="{class: 'btn-primary'}"
              @click="onCreateShipmentPlan"
            />
            <dx-util-button
              type="normal"
              icon="refresh"
              hint="Refresh"
              :element-attr="{class: 'btn-primary'}"
              @click="onClickReloadShipmentPlans"
            />
            <dx-util-button
              type="normal"
              icon="preferences"
              hint="Store Settings"
              :element-attr="{class: 'btn-primary'}"
              @click="onShowSettings"
            />
          </div>
        </div>
        <div class="w-100" style="height: 36px;">
          <dx-util-text-box
            v-model="searchText"
            mode="search"
            :visible="isDrawerOpen"
            styling-mode="filled"
            placeholder="Search"
            :input-attr="{style: 'color: #293046 !important;'}"
            @key-down="onFilterBatchShipmentPlans"
          />
        </div>
        <dx-util-scroll-view
          ref="scrollView"
          show-scrollbar="onHover"
          direction="vertical"
          :height="leftSide.scroolHeight"
        >
          <!-- TODO: convert this part with d-dropdown together -->
          <div>
            <div v-for="(item, index) in shipmentPlans" :key="`${item.id}`"
              class="d-flex flex-row justify-content-start batch-list"
              :class="{ 'selected-batch': selectedIndex === index }"
              @click="onSelectShipmentPlan(item,index)"
            >
              <div v-if="item.batchStatus==='open'" class="align-self-center text-center mr-half" style="font-size: 2rem; padding-left: 0.625rem">
                <b-icon :icon="item.packingType === $enums.PackingTypeEnum.individual.key ? 'layers-fill' : 'archive-fill'" class="border icon-border-warning rounded p-half" />
              </div>
              <div v-else-if="item.batchStatus==='boxing'" class="align-self-center text-center mr-half" style="font-size: 2rem; padding-left: 0.625rem">
                <b-icon-box class="border icon-border-success rounded p-half" />
              </div>
              <div v-else class="align-self-center text-center mr-half" style="font-size: 2rem; padding-left: 0.625rem">
                <b-icon icon="truck" variant="secondary" sclass="mr-half" />
              </div>
              <div v-if="isDrawerOpen" class="d-flex flex-column justify-content-start flex-grow-1 mr-half">
                  <p class="mb-0 list-item-title">
                    {{ item.batchName }}
                  </p>
                  <p class="mb-0 list-item-info">
                    {{ getEnumToValue($enums.WorkflowEnum, item.workflow).text }} {{ item.channel }} - <strong>{{ item.shipToCountryCode }}</strong>
                  </p>
              </div>
              <div v-if="isDrawerOpen" class="align-self-center mr-half">
                <span v-b-tooltip.hover="{ variant: 'success' }" title="Item Count" class="badge badge-success">{{ item.itemCount }}</span>
              </div>
              <div v-if="isDrawerOpen" class="align-self-center mr-half">
                <span v-b-tooltip.hover="{ variant: 'info' }" title="MSKU Count" class="badge badge-primary">{{ item.skuCount }}</span>
              </div>
              <div v-if="isDrawerOpen" class="align-self-center mr-half">
                <span v-b-tooltip.hover="{ variant: 'warning' }" title="Batch Count" class="badge badge-warning">{{ item.batchCount }}</span>
              </div>
              <div v-if="isDrawerOpen" class="align-self-center">
                <b-dropdown size="md" variant="link" boundary="window" toggle-class="text-decoration-left p-0" no-caret right>
                  <template #button-content>
                    <b-icon icon="three-dots-vertical" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item
                    :disabled="item.batchStatus !== 'open' && item.batchStatus !== 'boxing'"
                    @click="onEditShipmentPlan(item, index)"
                  >
                    <b-icon icon="pencil-square" variant="warning" scale="1" class="mr-half" />
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="item.batchStatus !== 'open'"
                    @click="onDeleteShipmentPlan(item.id)"
                  >
                    <b-icon icon="trash" variant="danger" scale="1" class="mr-half" />
                    Delete
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isVisibleChannelAction(item)"
                    :disabled="item.batchStatus !== 'open'"
                    @click="onSubmitShipmentPlan(item.id)"
                  >
                    <b-icon icon="cloud-upload-fill" variant="primary" scale="1" class="mr-half" />
                    Submit
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isVisibleChannelAction(item)"
                    :disabled="item.batchStatus !== 'boxing'"
                    @click="onRevertShipmentPlanFromBoxingToWorking(item.id)"
                  >
                    <b-icon icon="arrow-left-square-fill" variant="warning" scale="1" class="mr-half" />
                    Revert
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isVisibleChannelAction(item)"
                    :disabled="item.batchStatus !== 'open'"
                    @click="onMergeShipmentPlan(item.id)"
                  >
                    <b-icon icon="intersect" variant="warning" scale="1" class="mr-half" />
                    Merge
                  </b-dropdown-item>
                  <b-dropdown-item :disabled="item.batchStatus !== 'boxing' && item.channel.toUpperCase() === 'FBA'" @click="onCloseShipmentPlan(item.id)">
                    <b-icon icon="check-square-fill" variant="success" scale="1" class="mr-half" />
                    Close
                  </b-dropdown-item>
                  <b-dropdown-item :disabled="item.channel.toUpperCase() === 'MFN'" @click="onClickDownloadFnskuLables( item.batchName, item.id)">
                    <b-icon icon="cloud-arrow-down-fill" variant="primary" scale="1" class="mr-half" />
                    FNSKUs
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </dx-util-scroll-view>
        <div class="d-flex w-100 align-items-center justify-content-center">
          <dx-util-button
            :visible="isDrawerOpen"
            type="default"
            width="100%"
            height="42"
            text="Load More"
            @click="onClickLoadMore"
          />
        </div>
      </div>
    </div>
    <dx-util-popup
        ref="createNewShipmentPlanPopupRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :width="getPopupWidth()"
        :height="getPopupHeight()"
        content-template="popup-content"
        :title="shipmentPlanTitle"
        @shown="onShownCreateNewShipmentPlan"
        @hidden="onHiddenCreateNewShipmentPlan"
      >
        <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
        <template #popup-content>
          <dx-util-scroll-view width="100%" height="100%">
        <form @submit="handleSubmit">
          <dx-util-form
            ref="batchFormRef"
            :form-data="shipmentPlanFormData"
            :col-count="2"
            :show-colon-after-label="false"
            :scrolling-enabled="true"
            label-location="top"
            @field-data-changed="onShipmentPlanFormFieldDataChanged"
            >
            <!-- We will enable this feature later
            <dx-util-simple-item data-field="shipmentPlanType" editor-type="dxRadioGroup" :label="{ visible: false}" :editor-options="shipmentPlanTypeOptions" />
            -->
            <template #selected-address-template="{}">
              <div class="d-flex justify-content-start">
                <div v-html="selectedShippingAddress" />
              </div>
            </template>
            <dx-util-simple-item :col-span="2" data-field="batchName" editor-type="dxTextBox" :editor-options="shipmentPlanNameOptions" :visible="showOnlyNewShipmentPlan">
              <dx-util-required-rule
                message="Batch name is required."
              />
            </dx-util-simple-item>
            <dx-util-simple-item :col-span="2" :visible="!showOnlyNewShipmentPlan">
              <template #default>
                <div>
                  <div class="d-flex flex-row align-items-center">
                  <dx-util-text-box
                    v-model="amazonShipmentId"
                    placeholder="Shipment ID"
                    class="flex-grow-1"
                    @enter-key="onKeyDownAddAmazonShipmentId"
                    >
                  </dx-util-text-box>
                  <dx-util-button
                    type="normal"
                    icon="plus"
                    hint="Add Shipment ID"
                    :element-attr="{class: 'btn-primary'}"
                    @click="onClickAddShipmentBatchId"
                  />
                </div>
                <div :class="amazonShipmentIds.length > 0 ? 'mt-1' : ''">
                  <span v-for="(item, index) in amazonShipmentIds" :key="index" class="b-avatar badge-primary rounded-sm mr-1" style="width: 120px;">
                    {{ item }}
                    <span class="b-avatar-badge badge-danger badge-pointer" @click="onClickRemoveAmazonShipmentId(index)">
                      x
                    </span>
                  </span>
                </div>
                </div>
              </template>
            </dx-util-simple-item>
            <dx-util-simple-item :col-span="2" template="selected-address-template" />
            <dx-util-simple-item :col-span="2">
              <template #default>
                <dx-util-select-box
                  v-model="shipmentPlanFormData.fromAddress"
                  :data-source="shippingAddress"
                  display-expr="text"
                  value-expr="id"
                  item-template="item"
                  @value-changed="onAddressValueChanged"
                >
                  <template #item="{ data }">
                    <div v-html="data.textHtml" />
                  </template>
                </dx-util-select-box>
              </template>
            </dx-util-simple-item>
            <dx-util-simple-item data-field="shipToCountryCode" :label="{ text: 'Ship to Country'}" editor-type="dxSelectBox" :editor-options="shipToCountryCodeOptions" :visible="showOnlyNewShipmentPlan" />
            <dx-util-simple-item :visible="isServingTenant" data-field="serviceChargeType" editor-type="dxSelectBox" :editor-options="serviceChargeTypeOptions" />
            <dx-util-simple-item data-field="packingType" editor-type="dxSelectBox" :editor-options="packingTypeOptions" :visible="showOnlyNewShipmentPlan" />
            <dx-util-simple-item data-field="channel" editor-type="dxSelectBox" :editor-options="channelOptions" />
            <dx-util-simple-item data-field="labelingPreference" editor-type="dxSelectBox" :editor-options="labelingPreferenceOptions" :visible="showOnlyNewShipmentPlan" />
            <dx-util-simple-item data-field="shipmentType" editor-type="dxSelectBox" :editor-options="shipmentTypeOptions" :visible="showOnlyNewShipmentPlan" />
          </dx-util-form>
        </form>
      </dx-util-scroll-view>
      </template>
    </dx-util-popup>
    <!--BEGIN::Merge Shipment Plam-->
    <dx-util-popup
      ref="popupMergeShipmentPlanRef"
      title="Merge Shipment Plan into Selected Shipment Plan"
      :width="1000"
      :height="600"
      :show-close-button="true"
      :show-title="true"
      :drag-enabled="false"
      :close-on-click-outside="true"
      :close-on-escape="true"
      :scrolling-enabled="true"
      content-template="popup-content"
      @hiding="onHidingPopupMergeShipmentPlan"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
      <template #cancelPopupButton>
        <dx-util-button id="popupCancelButtonCreateConsolidate" text="Cancel" type="danger" @click="onClickCancelButtonMergeShipmentPlan" />
      </template>
      <dx-util-toolbar-item toolbar="bottom" location="before" template="submitPopupButton" />
      <template #submitPopupButton>
        <dx-util-button id="popupSubmitButtonCreateConsolidate" text="Merge" type="success" @click="onClickSubmitButtonMergeShipmentPlan" />
      </template>
      <template #popup-content>
        <div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 mb-1">
              <div class="card border mb-0">
                <div class="card-body py-2">
                  <div class="d-flex flex-row align-items-center">
                    <div class="mr-half">
                      <p-icon name="bi-exclamation-circle-fill text-warning" size="32px" />
                    </div>
                    <div class="text-warning font-weight-bold">
                      Are you sure you want to merge this shipment plan items into another shipment plan? This action cannot be reversed!
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12">
              <div class="card border">
                <div class="card-header border-bottom py-1">
                  <span class="fs-h-5">
                    Select Existing Shipment Plan
                  </span>
                </div>
                <div class="card-body py-2">
                  <dx-util-radio-group
                    v-if="shipmentPlanList.length > 0"
                    v-model="toShipmentPlanId"
                    :value="0"
                    :data-source="shipmentPlanList"
                    display-expr="shipmentPlanName"
                    value-expr="id"
                    layout="vertical"
                    />
                  <div v-else class="text-warning font-weight-bold">
                    No shipment plans available.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dx-util-popup>
    <!--END::Merge Shipment Plam-->
    <!--Begin:: Create Or Update Form-->
    <dx-util-popup
        ref="downloadFnskuLabelPopupRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :width="480"
        :height="360"
        title="Download FNSKU Labels"
        @hidden="onHiddenDownloadFnskuLabelPopup"
      >
        <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveDownloadFnskuLabelButtonOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelDownloadFnskuLabelButtonOptions" />
        <form method="post" @submit="handleSubmitDownloadFnskuLabel">
          <dx-util-form ref="downloadFnskuLabelFromRef" :form-data="downloadFnskuLabelFrom" :col-count="1" :show-colon-after-label="true" label-location="top">
            <dx-util-item
              data-field="labelType"
              editor-type="dxRadioGroup"
              :label="{text: 'Label Type'}"
              :editor-options="labelTypeOptions"
            />
          </dx-util-form>
        </form>
      </dx-util-popup>
      <!--End:: Create Or Update Form-->
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/require-default-prop */
import store from '@/store'
import { emitter } from '@/libs/bus'
import { FBAEvents } from '@/libs/constants/events.const'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import head from 'lodash/head'
import useDownloadLabel from '@/libs/printer/label-download'
import { getLocalText } from '@/enums/conditionTypeEnum'
import { DxSwitch } from 'devextreme-vue/switch'
import service from '@/http/requests/outbound/fbaShipmentService'
import commonService from '@/http/requests/common/commonEntityService'
import SearchFilter from '@/http/models/search-filters/FbaShipmentSearchFilter'
import { ValueToEnum, Notify } from '@robustshell/utils/index'
import moment from 'moment'
import ShipmentChannelEnum from '@/enums/shipment-channel.enum'
import serviceChargeTypeEnum, { getListServiceChargeTypeEnum } from '@/enums/service-charge-type.enum'
import shipToCountryCodeEnum, { getShipToCountryCodeEnumList } from '../consts/shipto-country-code.enum'
import fbaShipment from '../fbaShipment.mixin'

export default {
  components: {
    DxSwitch,
  },
  mixins: [fbaShipment],
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'selected-batch',
  ],
  setup() {
    const { downloadFnskuLabelList, downloadFnskuLabelMultiple } = useDownloadLabel()

    return {
      downloadFnskuLabelList,
      downloadFnskuLabelMultiple,
    }
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    return {
      tenantId: '',
      searchText: '',
      isServingTenant: true,
      shipmentPlans: [],
      selectedIndex: -1,
      selectedShipmentPlanId: null,
      inputAttr: {
        class: 'w-100',
      },
      shipmentPlanFormDataDefault: {},
      shipmentPlanTypes: [
        { value: 1, text: 'Create New Shipment Plan' },
        { value: 2, text: 'Import Shipments From Amazon' },
      ],
      shipmentPlanTitle: '',
      shipmentPlanFormData: {
        shipmentPlanType: 1,
        selectedShippingAddress: {},
        id: null,
        batchName: '',
        fromAddress: null,
        packingType: 'individual',
        channel: 'fba',
        workflow: 'private_flow',
        labelingPreference: 'SELLER_LABEL',
        shipmentType: 'SP',
        companyId: 0,
        storeId: 0,
        invoiceId: null,
        itemCount: 0,
        warehouseId: userData.warehouse.id,
        batchStatus: 'open',
        notes: '',
        serviceChargeType: serviceChargeTypeEnum.PER_ITEM.value,
        shipToCountryCode: shipToCountryCodeEnum.US.value,
      },
      packingTypes: [],
      channels: [],
      workflows: [],
      labelingPreferences: [],
      shipmentTypes: [],
      shippingAddress: [],
      selectedShippingAddress: '',
      isReadonlyMode: false,
      showOnlyOpenShipmentPlans: true,
      showOnlyNewShipmentPlan: true,
      amazonShipmentIds: [],
      amazonShipmentId: '',
      shipmentPlanList: [],
      fromShipmentPlanId: 0,
      toShipmentPlanId: 0,
      downloadFnskuLabelFrom: {
        id: null,
        batchName: '',
        labelType: '',
      },
      page: 0,
      totalPages: 0,
    }
  },
  computed: {
    accountInfo() {
      return store.getters['fbaShipment/getBatchAccountInfo']
    },
    createNewBatchPopup() {
      const popupIns = this.$refs.createNewShipmentPlanPopupRef.instance
      return popupIns
    },
    shipmentPlanTypeOptions() {
      return {
        items: this.shipmentPlanTypes,
        valueExpr: 'value',
        displayExpr: 'text',
        value: this.shipmentPlanFormData.shipmentPlanType,
        layout: 'horizontal',
        readOnly: this.isReadonlyMode,
      }
    },
    shipmentPlanNameOptions() {
      return {
        readOnly: this.isReadonlyMode,
      }
    },
    packingTypeOptions() {
      return {
        dataSource: this.packingTypes,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.isReadonlyMode,
      }
    },
    channelOptions() {
      return {
        dataSource: this.channels,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.isReadonlyMode,
      }
    },
    labelingPreferenceOptions() {
      return {
        dataSource: this.labelingPreferences,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.isReadonlyMode,
      }
    },
    shipmentTypeOptions() {
      return {
        dataSource: this.shipmentTypes,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.isReadonlyMode,
      }
    },
    serviceChargeTypeOptions() {
      return {
        dataSource: getListServiceChargeTypeEnum(),
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.isReadonlyMode,
      }
    },
    shipToCountryCodeOptions() {
      return {
        dataSource: getShipToCountryCodeEnumList(),
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.isReadonlyMode,
      }
    },
    saveButtonOptions() {
      let text = this.shipmentPlanFormData.shipmentPlanType === 2 ? 'Import' : 'Create'
      text = this.isReadonlyMode ? 'Update' : text
      return {
        text: text,
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.createNewBatchPopup.hide()
        },
      }
    },
    popupInsMergeShipmentPlan() {
      return this.$refs.popupMergeShipmentPlanRef.instance
    },
    downloadFnskuLabelPopupIns() {
      return this.$refs.downloadFnskuLabelPopupRef.instance
    },
    labelTypeOptions() {
      return {
        dataSource: [
          { value: 'single', text: 'Single Label Per Page' },
          { value: 'multiple', text: 'Labels 3x10 Per Page 8.5"-11"' },
        ],
        valueExpr: 'value',
        displayExpr: 'text',
        layout: 'vertical',
      }
    },
    saveDownloadFnskuLabelButtonOptions() {
      return {
        text: 'Download',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmitDownloadFnskuLabel(e)
        },
      }
    },
    cancelDownloadFnskuLabelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.downloadFnskuLabelPopupIns.hide()
        },
      }
    },
  },
  watch: {
    searchText(current, previous) {
      if (!current) {
        this.page = 0
        this.getShipmentPlans()
      }
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.tenantId = userData.tenantId
    if (userData.tenantType !== 'SERVING') {
      this.isServingTenant = false
    }
    const batchId = this.$route.params.batchId

    if (batchId) {
      this.selectedShipmentPlanId = parseInt(batchId, 10)
    }

    emitter.on(FBAEvents.UPDATE_SELECTED_BATCH.event, payload => {
      this.onUpdateSelectedBatch(payload)
    })
    this.$parent.$on('reload-shipment-plans', this.onReloadComponent)
  },
  beforeMount() {
    this.getShipmentPlans()
  },
  mounted() {
    this.getShippingAddress()
    this.channels = ShipmentChannelEnum.list('target', 'amazon')
    Object.assign(this.shipmentPlanFormDataDefault, this.shipmentPlanFormData)
    Object.keys(this.$enums.PackingTypeEnum).map(key => this.packingTypes.push({ value: this.$enums.PackingTypeEnum[key].value, text: this.$enums.PackingTypeEnum[key].text }))
    Object.keys(this.$enums.LabelingPreferenceEnum).map(key => this.labelingPreferences.push({ value: this.$enums.LabelingPreferenceEnum[key].value, text: this.$enums.LabelingPreferenceEnum[key].text }))
    Object.keys(this.$enums.ShipmentTypeEnum).map(key => this.shipmentTypes.push({ value: this.$enums.ShipmentTypeEnum[key].value, text: this.$enums.ShipmentTypeEnum[key].text }))
  },
  methods: {
    isVisibleChannelAction(item) {
      const res = item.channelValue === ShipmentChannelEnum.FBA.value
      return res
    },
    getPopupWidth() {
      return window.innerWidth / 1.5
    },
    getPopupHeight() {
      return window.innerHeight / 1.5
    },
    onReloadComponent(eventData) {
      this.selectedShipmentPlanId = eventData
      this.getShipmentPlans()
    },
    getShippingAddress() {
      const { companyId, accountNo } = this.accountInfo
      this.shippingAddress.splice(0, this.shippingAddress.length)
      commonService.fetchAddressesByTenantAndCompany(companyId).then(result => {
        const { data } = result
        data.forEach((item, index) => {
          let line = item.line1
          line += item.line2 ? ` ${item.line2}` : ''
          line += item.line3 ? ` ${item.line3}` : ''
          let addressStringHtml = `${item.name}<br />${line}`
          let addressString = `${item.name} ${line}`

          if (this.isServingTenant) {
            addressStringHtml += `<br />${item.city}, ${item.state} ${item.zipcode}`
            addressString += `${item.city}, ${item.state} ${item.zipcode}`
          } else {
            addressStringHtml += `<br />${item.city}, ${item.state} ${item.zipcode}`
            addressString += ` ${item.city}, ${item.state} ${item.zipcode}`
          }
          this.shippingAddress.push({
            id: item.id,
            textHtml: addressStringHtml,
            text: addressString,
          })
        })
      })
    },
    onAddressValueChanged(e) {
      const selected = find(this.shippingAddress, p => p.id === e.value)
      this.selectedShippingAddress = selected.textHtml
    },
    onShowOnlyOpenShipmentPlans() {
      this.page = 0
      this.getShipmentPlans()
    },
    onKeyDownAddAmazonShipmentId(e) {
      if (e.event.keyCode === 13) {
        if (e.event.target.value) {
          const item = e.event.target.value.trim()
          e.component.option('value', '')
          this.amazonShipmentIds.push(item)
        }
      }
    },
    onClickAddShipmentBatchId() {
      this.amazonShipmentIds.push(this.amazonShipmentId)
      this.amazonShipmentId = ''
    },
    onClickRemoveAmazonShipmentId(index) {
      this.amazonShipmentIds.splice(index, 1)
    },
    onClickLoadMore() {
      if (this.page < this.totalPages - 1) {
        this.page += 1
        this.getShipmentPlans(false)
      } else {
        Notify.warning('No more data to load')
      }
    },
    onClickReloadShipmentPlans() {
      this.page = 0
      this.getShipmentPlans()
    },
    async getShipmentPlans(clean = true) {
      try {
        const pager = `page=${this.page}&size=100`
        const filter = new SearchFilter()
        filter.accountNo = this.accountInfo.accountNo
        filter.companyId = this.accountInfo.companyId
        filter.storeId = this.accountInfo.storeId
        filter.batchStatus = this.showOnlyOpenShipmentPlans ? 'all_but_closed' : 'all'
        const result = await service.getBatchParentsByQuery(filter, pager)
        const data = result.data.body.content
        this.totalPages = result.data.body.totalPages
        this.setShipmentPlanData(data, clean)
        const index = this.selectedShipmentPlanId ? findIndex(this.shipmentPlans, p => p.id === this.selectedShipmentPlanId) : -1

        if (index !== -1) {
          const item = this.selectedShipmentPlanId ? find(this.shipmentPlans, p => p.id === this.selectedShipmentPlanId) : this.shipmentPlans[0]
          this.onSelectShipmentPlan(item, index)
          return
        }
        if (this.shipmentPlans.length > 0) {
          const item = head(this.shipmentPlans)
          this.onSelectShipmentPlan(item, 0)
        } else {
          this.$router
            .push({
              name: 'fba-shipments-workflow',
              params: {
                accountNo: this.accountInfo.accountNo,
                workflow: 'batching',
              },
            })
        }
      } catch (error) {
        Notify.error('Failed to load shipment plans')
      }
    },
    onFilterBatchShipmentPlans(e) {
      if (e.event.keyCode !== 13) return
      this.searchText = e.event.target.value
      const filter = new SearchFilter()
      filter.accountNo = this.accountInfo.accountNo
      filter.companyId = this.accountInfo.companyId
      filter.storeId = this.accountInfo.storeId
      filter.batchStatus = this.showOnlyOpenShipmentPlans ? 'all_but_closed' : 'all'
      filter.q = this.searchText
      this.page = 0
      const pager = `page=${this.page}&size=100`
      service.getBatchParentsByQuery(filter, pager)
        .then(result => {
          const data = result.data.body.content
          this.setShipmentPlanData(data, true)
        }).then(() => {
          if (this.shipmentPlans.length > 0) {
            const index = 0
            const item = this.shipmentPlans[0]
            this.onSelectShipmentPlan(item, index)
          }
        })
    },
    onSelectShipmentPlan(item, index) {
      if (this.selectedIndex !== index) {
        this.selectedIndex = index
        const payload = { ...item }
        emitter.emit(FBAEvents.CLEAR_SEARCH_TEXT.event)
        if (item.batchStatus === 'boxing' && item.channelValue === ShipmentChannelEnum.FBA.value) {
          this.$emit('selected-batch', 'boxing', payload)
          return
        }
        this.$emit('selected-batch', 'batching', payload)
      }
    },
    setShipmentPlanData(data, clean = false) {
      if (clean) {
        this.shipmentPlans.splice(0, this.shipmentPlans.length)
        this.shipmentPlanList.splice(0, this.shipmentPlanList.length)
      }
      data.forEach(batch => {
        const channel = ShipmentChannelEnum.find(batch.channel.toLowerCase())
        this.shipmentPlans.push({
          id: batch.id,
          amzBatchStatus: batch.amzBatchStatus,
          avgAmzRank: batch.avgAmzRank,
          batchName: batch.batchName,
          batchStatus: ValueToEnum(this.$enums.BatchStatusEnum, batch.batchStatus).value,
          carrier: batch.carrier,
          channel: channel.text,
          channelValue: batch.channel,
          companyId: batch.companyId,
          fromAddress: batch.fromAddress,
          batchCount: batch.batchCount,
          itemCount: batch.itemCount,
          itemQty: batch.itemQty,
          serviceChargeType: batch.serviceChargeType,
          labelingPreference: ValueToEnum(this.$enums.LabelingPreferenceEnum, batch.labelingPreference).value,
          shipToCountryCode: batch.shipToCountryCode,
          notes: batch.notes,
          packingType: ValueToEnum(this.$enums.PackingTypeEnum, batch.packingType).value,
          shipmentType: ValueToEnum(this.$enums.ShipmentTypeEnum, batch.shipmentType).value,
          storeId: batch.storeId,
          warehouseId: batch.warehouseId,
          workflow: ValueToEnum(this.$enums.WorkflowEnum, batch.workflow).value,
          skuCount: batch.skuCount,
          totalCost: batch.totalCost,
          totalSales: batch.totalSales,
        })
        if (batch.batchStatus === 'open') {
          this.shipmentPlanList.push({
            id: batch.id,
            shipmentPlanName: batch.batchName,
          })
        }
      })
    },
    onHidingPopupMergeShipmentPlan() {
      this.fromShipmentPlanId = 0
      this.toShipmentPlanId = 0
    },
    onClickSubmitButtonMergeShipmentPlan() {
      if (this.toShipmentPlanId === 0) {
        Notify.warning('Please select a shipment plan to proceed.')
        return
      }
      this.getShipmentPlans()
      service.mergeShipmentPlan(this.fromShipmentPlanId, this.toShipmentPlanId).finally(() => {
        this.getShipmentPlans()
        this.popupInsMergeShipmentPlan.hide()
      })
    },
    onClickCancelButtonMergeShipmentPlan() {
      this.fromShipmentPlanId = 0
      this.popupInsMergeShipmentPlan.hide()
    },
    getEnumToValue(enumType, value) {
      return ValueToEnum(enumType, value, 'value')
    },
    createBatchName(casePack = '') {
      const now = new Date()
      const formatedDate = moment(now).format('YYYY-MM-DD HH:mm:ss')
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userName = userData.fullname.split(' ')[0].charAt(0) + userData.fullname.split(' ')[1].charAt(0)
      let batchName = `${this.tenantId}-${userName}${casePack} ${formatedDate}`
      if (!this.isServingTenant) {
        batchName = `${userName}${casePack} ${formatedDate}`
      }
      return batchName
    },
    onShipmentPlanFormFieldDataChanged(e) {
      if (e.dataField === 'packingType') {
        if (e.value) {
          const packType = e.value
          if (packType === this.$enums.PackingTypeEnum.case_packed.key) {
            this.shipmentPlanFormData.batchName = this.createBatchName(' CP')
            e.component.repaint()
          } else {
            this.shipmentPlanFormData.batchName = this.createBatchName()
            e.component.repaint()
          }
        }
        return
      }
      if (e.dataField === 'shipmentPlanType') {
        if (e.value) {
          const shipmentPlanType = e.value
          if (shipmentPlanType === 1) {
            this.showOnlyNewShipmentPlan = true
            e.component.repaint()
          } else {
            this.showOnlyNewShipmentPlan = false
            e.component.repaint()
          }
        }
      }
    },
    onShownCreateNewShipmentPlan() {
      if (this.isReadonlyMode) {
        this.shipmentPlanTitle = 'Update Shipment Plan'
      } else {
        this.shipmentPlanTitle = 'Create Shipment Plan'
      }
    },
    onHiddenCreateNewShipmentPlan() {
      this.shipmentPlanTitle = ''
    },
    onCreateShipmentPlan(e) {
      this.isReadonlyMode = false
      Object.assign(this.shipmentPlanFormData, this.shipmentPlanFormDataDefault)
      const address = this.shippingAddress[0]
      this.shipmentPlanFormData.fromAddress = address.id
      this.selectedShippingAddress = address.textHtml
      this.shipmentPlanFormData.batchName = this.createBatchName()
      this.createNewBatchPopup.show()
    },
    onEditShipmentPlan(item, index) {
      this.isReadonlyMode = true
      this.selectedIndex = index
      this.shipmentPlanFormData = { ...item }
      const channel = ShipmentChannelEnum.find(item.channelValue)
      this.shipmentPlanFormData.channel = channel.value
      const packingType = ValueToEnum(this.$enums.PackingTypeEnum, item.packingType).value
      this.shipmentPlanFormData.packingType = packingType
      const selectedAddress = find(this.shippingAddress, p => p.id === this.shipmentPlanFormData.fromAddress)
      if (selectedAddress) {
        this.selectedShippingAddress = selectedAddress.textHtml
      }
      this.createNewBatchPopup.show()
    },
    handleSubmit(e) {
      const form = this.$refs.batchFormRef.instance
      if (this.shipmentPlanFormData.shipmentPlanType === 2) {
        const data = {
          batches: this.amazonShipmentIds,
          storeId: this.accountInfo.storeId,
          companyId: this.accountInfo.companyId,
          warehouseId: this.shipmentPlanFormData.warehouseId,
          fromAddress: this.shipmentPlanFormData.fromAddress,
          serviceChargeType: this.shipmentPlanFormData.serviceChargeType,
        }
        service.importAmazonShipmentBatch(data).then(result => {
          const payload = { ...result.data }
          this.selectedShipmentPlanId = payload.id
          this.$emit('selected-batch', 'boxing', payload)
          this.page = 0
          this.getShipmentPlans()
        }).finally(() => {
          this.createNewBatchPopup.hide()
          this.isReadonlyMode = false
        })
        return
      }

      const formValidate = form.validate()
      if (formValidate.isValid) {
        const data = {
          selectedShippingAddress: this.selectedShippingAddress,
          id: this.shipmentPlanFormData.id,
          batchName: this.shipmentPlanFormData.batchName,
          fromAddress: this.shipmentPlanFormData.fromAddress,
          packingType: this.shipmentPlanFormData.packingType,
          channel: this.shipmentPlanFormData.channel,
          workflow: this.shipmentPlanFormData.workflow,
          labelingPreference: this.shipmentPlanFormData.labelingPreference,
          shipmentType: this.shipmentPlanFormData.shipmentType,
          companyId: this.shipmentPlanFormData.companyId,
          storeId: this.shipmentPlanFormData.storeId,
          invoiceId: this.shipmentPlanFormData.invoiceId,
          itemCount: this.shipmentPlanFormData.itemCount,
          warehouseId: this.shipmentPlanFormData.warehouseId,
          batchStatus: this.shipmentPlanFormData.batchStatus,
          notes: this.shipmentPlanFormData.notes,
          serviceChargeType: this.shipmentPlanFormData.serviceChargeType,
          shipToCountryCode: this.shipmentPlanFormData.shipToCountryCode,
        }
        if (data.serviceChargeType === serviceChargeTypeEnum.PER_BOX.value) {
          data.packingType = this.$enums.PackingTypeEnum.case_packed.value
        }
        data.storeId = this.accountInfo.storeId
        data.companyId = this.accountInfo.companyId
        if (data.id === null) {
          service.createBatchParent(data).then(result => {
            const payload = { ...result.data }
            payload.channelValue = this.shipmentPlanFormData.channel
            this.selectedShipmentPlanId = payload.id
            this.getShipmentPlans()
            this.$emit('selected-batch', 'batching', payload)
          }).finally(() => {
            this.createNewBatchPopup.hide()
            this.isReadonlyMode = false
          })
        } else {
          service.updateBatchParent(data).then(result => {
            const payload = { ...result.data }
            payload.channelValue = this.shipmentPlanFormData.channel
            this.getShipmentPlans()
            this.createNewBatchPopup.hide()
            this.$emit('selected-batch', 'batching', payload)
          }).finally(() => {
            this.isReadonlyMode = false
          })
        }
      }
    },
    onDeleteShipmentPlan(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this Shipment Plan? Once this Shipment Plan is deleted, all the items added to the Shipment Plan will be deleted and send back to the inventory.', {
          title: 'Delete Selected Shipment Plan',
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'warning',
          headerClass: 'px-1 py-half border-bottom-0',
          bodyClass: 'px-1 py-half',
          footerClass: 'd-flex flex-row px-1 py-half border-top-1',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            service.deleteBatchParent(id)
              .then(() => {
                this.selectedShipmentPlanId = null
                this.selectedIndex = -1
                this.getShipmentPlans()
              })
          }
        })
    },
    onUpdateSelectedBatch(payload) {
      this.selectedShipmentPlanId = parseInt(payload, 10)
      service.findBatchParentById(this.selectedShipmentPlanId).then(result => {
        const data = result.data
        const batch = find(this.shipmentPlans, p => p.id === this.selectedShipmentPlanId)
        batch.itemCount = data.itemCount
        batch.itemQty = data.itemQty
        batch.skuCount = data.skuCount
        batch.totalCost = data.totalCost
        batch.totalSales = data.totalSales
        this.shipmentPlans.splice(this.selectedIndex, 1, batch)
      })
      this.$emit('on-emit-update-selected-shipment-status', this.selectedShipmentPlanId)
    },
    onSubmitShipmentPlan(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to submit this Shipment Plan. Once submitted, you will be able to start to box the items according to Amazon propsed batches and destinations.', {
          title: 'Submit Shipment Plan',
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'Submit',
          cancelTitle: 'Cancel',
          cancelVariant: 'danger',
          headerClass: 'px-1 py-half border-bottom-0',
          bodyClass: 'px-1 py-half',
          footerClass: 'd-flex flex-row px-1 py-half border-top-1',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            service.submitAmazonInboundShipmentPlan(id).then(result => {
              this.selectedShipmentPlanId = id
              this.selectedIndex = -1
              this.getShipmentPlans()
            })
          }
        })
    },
    onRevertShipmentPlanFromBoxingToWorking(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to revert this shipment plan. Once reverted, you will be able to continue to add more items in this Shipment Plan. '
        + 'IMPORTANT: If there are any created shipment batch, they will not be reverted.', {
          title: 'Revert Shipment Plan from BOXING to WORKING stage',
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'Revert',
          cancelTitle: 'Cancel',
          cancelVariant: 'danger',
          headerClass: 'px-1 py-half border-bottom-0',
          bodyClass: 'px-1 py-half',
          footerClass: 'd-flex flex-row px-1 py-half border-top-1',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            service.revertShipmentPlanFromBoxingToWorking(id).then(result => {
              this.selectedShipmentPlanId = null
              this.selectedIndex = -1
              this.getShipmentPlans()
            })
          }
        })
    },
    onMergeShipmentPlan(id) {
      this.fromShipmentPlanId = id
      this.popupInsMergeShipmentPlan.show()
    },
    onCloseShipmentPlan(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to reconcile and close this Shipment Plan. You will have to create and transmit all the Shipment Batches before closing a Shipment Plan. '
        + 'Closing a Shipment Plan will reconcile Shipment Batches and subtract the reserved inventory.', {
          title: 'Reconcile and Close Shipment Plan',
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'Reconcile and Close',
          cancelTitle: 'Cancel',
          cancelVariant: 'danger',
          headerClass: 'px-1 py-half border-bottom-0',
          bodyClass: 'px-1 py-half',
          footerClass: 'd-flex flex-row px-1 py-half border-top-1',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            service.closeBatchParent(id).then(result => {
              this.selectedShipmentPlanId = null
              this.selectedIndex = -1
              this.getShipmentPlans()
            })
          }
        })
    },
    onHiddenDownloadFnskuLabelPopup() {
      this.downloadFnskuLabelFrom.id = null
      this.downloadFnskuLabelFrom.batchName = ''
      this.downloadFnskuLabelFrom.labelType = ''
    },
    onClickDownloadFnskuLables(batchName, id) {
      const parsed = parseInt(id, 10)
      this.downloadFnskuLabelFrom.id = parsed
      this.downloadFnskuLabelFrom.batchName = batchName
      this.downloadFnskuLabelPopupIns.show()
    },
    handleSubmitDownloadFnskuLabel(e) {
      const form = this.$refs.downloadFnskuLabelFromRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const data = {
          id: this.downloadFnskuLabelFrom.id,
          batchName: this.downloadFnskuLabelFrom.batchName,
          labelType: this.downloadFnskuLabelFrom.labelType,
        }
        const filters = {
          companyId: 0,
          accountNo: '',
          q: '',
          storeId: 0,
          warehouseId: 0,
          beginDate: null,
          endDate: null,
          tenantType: 'SERVING',
          batchId: data.id,
          status: 'ALL',
          batchStatus: 'all',
        }
        service.getBatchParentItemsByQuery(filters)
          .then(result => {
            const items = result.data.map(item => {
              const conditionText = getLocalText(item.itemCondition)
              const title = conditionText ? `${conditionText} - ${item.title}` : item.title
              return {
                fnsku: item.fnsku,
                title: title,
                expireDate: item.expirationDate,
                count: item.quantity,
              }
            })
            if (data.labelType === 'single') {
              this.downloadFnskuLabelList(data.batchName, items)
            } else {
              this.downloadFnskuLabelMultiple(data.batchName, items)
            }
          })
          .then(() => {
            this.downloadFnskuLabelPopupIns.hide()
          })
      }
    },
    onShowSettings() {
      this.$emit('on-emit-show-settings', this.accountInfo.storeId)
    },
  },
}
</script>

<style lang="scss" scoped>
    $base-light: #dfe6e9;
    $base-light-boder: #b2bec3;
    $base-light-reverse: #293046;

    $base-gray: #9a9fad;
    $base-gray-boder: #b2bec3;
    $base-gray-reverse: #293046;

    $base-purple: #3c4256;
    $base-purple-boder: #b2bec3;
    $base-purple-reverse: #dfe6e9;
    .badge-pointer {
      top: -10px;
      right: -10px;
      width: 20px;
      height: 20px;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
    }
    .card-batch {
      border-radius: 0px !important;
      font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
      text-align: justify;
      text-justify: inter-word;
      .card-batch-title{
        height: 36px;
        h6 {
          font-size: 1.375rem;
          font-weight: 400;
        }
        .dx-button-normal{
          font-weight: 500;
          border: 0 transparent;
          border-radius: 0px;
          background: inherit;
          height: 36px;
        }
      }
      .dx-textbox {
        border: 0px transparent;
      }
      .batch-list {
        cursor: pointer;
        display: block;
        .list-item-title {
          font-size: 1rem;
        }
        .list-item-info {
          font-size: 0.879rem;
        }
      }
      &.batch-light {
        background-color: $base-light;
        .card-batch-title{
          color: $base-light-reverse;
          border-bottom: 1px solid $base-light-boder;
          border-right: 1px solid $base-light-boder;
          h6 {
            color: $base-light-reverse;
          }
          .dx-button-normal{
            background-color: $base-light-boder;
            color: $base-light-reverse;
            &:hover {
              background-color: rgba($base-light-reverse,0.8);
              color: rgba($base-light, 0.8);
            }
          }
        }
        .batch-list {
          border-bottom: 1px solid $base-light-boder;
          &:nth-of-type(1) {
            border-top: 1px solid $base-light-boder;
          }
          .icon-border-warning {
            border-color: #ffd6a5 !important;
            background-color: #ffd6a5 !important;
            color: #1d3557 !important;
          }
          .icon-border-success {
            border-color: #caffbf !important;
            background-color: #caffbf !important;
            color: #1d3557 !important;
          }
          .list-item-title {
            color: $base-light-reverse
          }
          .list-item-info {
            color: $base-light-reverse
          }
          &.selected-batch {
            background-color:$base-light-reverse;
            .list-item-title {
              color: $base-light;
            }
            .list-item-info {
              color: $base-light;
            }
          }
        }
      }
      &.batch-light-semi {
        background-color: $base-gray;
        .card-batch-title{
          color: $base-gray-reverse;
          border-bottom: 1px solid $base-gray-boder;
          border-right: 1px solid $base-gray-boder;
          h6 {
            color: $base-gray-reverse;
          }
          .dx-button-normal{
            background-color: $base-gray-boder;
            color: $base-gray-reverse;
            &:hover {
              background-color: rgba($base-gray-reverse,0.8);
              color: rgba($base-gray, 0.8);
            }
          }
        }
        .batch-list {
          border-bottom: 1px solid $base-gray-boder;
          &:nth-of-type(1) {
            border-top: 1px solid $base-gray-boder;
          }
          .icon-border-warning {
            border-color: #f39c12 !important;
          }
          .list-item-title {
            color: $base-gray-reverse
          }
          .list-item-info {
            color: $base-gray-reverse
          }
          &.selected-batch {
            background-color:$base-gray-reverse;
            .list-item-title {
              color: $base-gray;
            }
            .list-item-info {
              color: $base-gray;
            }
          }
        }
      }
      &.batch-purple {
        background-color: $base-purple;
        .card-batch-title{
          color: $base-purple-reverse;
          border-bottom: 1px solid $base-purple-boder;
          border-right: 1px solid $base-purple-boder;
          h6 {
            color: $base-purple-reverse;
          }
          .dx-button-normal{
            background-color: $base-purple-boder;
            color: $base-purple-reverse;
            &:hover {
              background-color: rgba($base-purple-reverse,0.8);
              color: rgba($base-purple, 0.8);
            }
          }
        }
        .batch-list {
          border-bottom: 1px solid $base-purple-boder;
          &:nth-of-type(1) {
            border-top: 1px solid $base-purple-boder;
          }
          .icon-border-warning {
            border-color: #f39c12 !important;
          }
          .list-item-title {
            color: $base-purple-reverse
          }
          .list-item-info {
            color: $base-purple-reverse
          }
          &.selected-batch {
            background-color: $base-light-reverse;
            .list-item-title {
              color: $base-light;
            }
            .list-item-info {
              color: $base-light;
            }
          }
        }
      }
    }
</style>
