/**
 * Enum for value type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const CreateItemTypeEnum = Object.freeze({
  replenish: { key: 'replenish', value: 0 },
  list: { key: 'list', value: 1 },
  replenish_create_items: { key: 'replenish_create_items', value: 2 },
  list_create_items: { key: 'list_create_items', value: 3 },
  update_quantity: { key: 'update_quantity', value: 4 },
  create_and_add_missing_items: { key: 'create_and_add_missing_items', value: 5 },
  update_quantity_decrement: { key: 'update_quantity_decrement', value: 6 }, // Don't send backend
  delete: { key: 'delete', value: 6 }, // Don't send backend
})

export default CreateItemTypeEnum
