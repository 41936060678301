<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 px-2">
        <div v-show="!shipmentPallet.partnered" class="row px-0 mt-1 pb-1 border-bottom">
          <div class="form-group col-4 px-half">
            <label for="text">Carrier</label>
            <dx-util-text-box
              v-model="shipmentPallet.carrier"
              placeholder="Carrier"
              :input-attr="{style: 'text-transform: capitalize;'}"
            />
          </div>
          <div class="form-group col-8 px-half">
            <label for="id-proNumber">Pro Number</label>
            <div class="align-self-center">
              <dx-util-text-box
                id="id-proNumber"
                v-model.trim="shipmentPallet.proNumber"
              />
            </div>
          </div>
        </div>
        <div v-show="shipmentPallet.partnered" class="row px-0 mt-1 pb-1 border-bottom">
          <div class="col-12 px-half display-h-4 mb-1">
            Contact Info
          </div>
          <div class="form-group col-6 px-half">
            <label for="id-contact-name">Name</label>
            <div class="align-self-center">
              <dx-util-text-box
                id="id-contact-name"
                v-model="shipmentPallet.contact.name"
              />
            </div>
          </div>
          <div class="form-group col-6 px-half">
            <label for="id-contact-email">E-Mail</label>
            <div class="align-self-center">
              <dx-util-text-box
                id="id-contact-email"
                v-model="shipmentPallet.contact.email"
              />
            </div>
          </div>
          <div class="form-group col-6 px-half">
            <label for="id-contact-phone">Phone Number</label>
            <div class="align-self-center">
              <dx-util-text-box
                id="id-contact-phone"
                v-model="shipmentPallet.contact.phone"
                mask="+1 (000) 000-0000"
                mask-char="‒"
                mask-invalid-message="The input value does not match the phone number"
              />
            </div>
          </div>
          <div class="form-group col-6 px-half">
            <label for="id-contact-fax">Fax Number</label>
            <div class="align-self-center">
              <dx-util-text-box
                id="id-contact-fax"
                v-model="shipmentPallet.contact.fax"
                mask="+1 (000) 000-0000"
                mask-char="‒"
                mask-invalid-message="The input value does not match the fax number"
              />
            </div>
          </div>
        </div>
        <div v-show="shipmentPallet.partnered" class="row px-0 mt-1 pb-1 border-bottom">
          <div class="col-12 px-half display-h-4  mb-1">
            Pallet Info
          </div>
          <div class="form-group col-6 px-half">
            <label for="id-sellerFreightClass">Seller Freight Class</label>
            <div class="align-self-center">
              <dx-util-select-box
                id="id-sellerFreightClass"
                v-model="shipmentPallet.sellerFreightClass"
                :data-source="getSellerFreightClassList"
                value-expr="value"
                search-expr="text"
                display-expr="text"
              />
            </div>
          </div>
          <div class="form-group col-6 px-half">
            <label for="id-freightReadyDate">Freight Ready Date</label>
            <div class="align-self-center">
              <dx-util-date-box
                id="id-freightReadyDate"
                v-model="shipmentPallet.freightReadyDate"
                type="date"
                display-format="yyyy-MM-dd"
              />
            </div>
          </div>
          <div class="form-group col-6 px-half">
            <label for="id-declaredValue">Declared Value</label>
            <div class="align-self-center">
              <dx-util-number-box
                id="id-declaredValue"
                v-model="shipmentPallet.declaredValue"
                format="$ #,##0.##"
              />
            </div>
          </div>
        </div>
        <div class="row py-1 px-0 mt-1">
          <div class="col-2 px-half ml-auto">
          <dx-util-button
            icon="save"
            text="Submit"
            type="success"
            width="100%"
            @click="onSubmitBoxDimensionsPallet"
          />
          </div>
        </div>
      </div>
      <div class="col-6 px-0">
        <div class="card">
          <table class="table table-bordered w-100">
            <thead>
              <tr>
                <th scope="col">
                  Pallets
                </th>
                <th scope="col">
                  Width
                </th>
                <th scope="col">
                  Length
                </th>
                <th scope="col">
                  Height
                </th>
                <th scope="col">
                  Weight(lb.)
                </th>
                <th scope="col">
                  Count
                </th>
                <th scope="col">
                  Stacked
                </th>
                <th scope="col">
                  Total Weight(lb.)
                </th>
                <th>
                  <dx-util-button
                    icon="plus"
                    hint="Add another row"
                    type="default"
                    @click="onAddNewPallet()"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="shipmentPallet.palletList.length === 0">
                <td colspan="9" class="text-center">
                    You haven't added any pallet yet
                </td>
              </tr>
              <tr v-if="shipmentPallet.palletList.length > 0">
                <td colspan="8" class="text-center">
                  <dx-util-select-box
                      id="printer-select-box"
                      v-model="selectedPrinter"
                      :data-source="printers"
                      display-expr="Name"
                    />
                </td>
                <td>
                  <dx-util-button
                    icon="print"
                    type="default"
                    hint="Print Pallet Lalet"
                    @click="onPrintPalletBarcode($event)"
                  />
                </td>
              </tr>
              <tr v-for="(item, index) in shipmentPallet.palletList" :key="item.rowId">
                <td>
                  <div class="form-control bg-success text-white rounded border-0" style="height: 2.429rem; white-space: nowrap">
                    <b-icon icon="box" />
                    Pallet {{ index + 1 }}
                  </div>
                </td>
                <td>
                  <dx-util-number-box v-model="item.dimensions.width" :read-only="true" :min="0" :max="48" />
                </td>
                <td>
                  <dx-util-number-box v-model="item.dimensions.length" :read-only="true" :min="0" :max="48" />
                </td>
                <td>
                  <dx-util-number-box v-model="item.dimensions.height" format="###0" :min="0" :max="72" />
                </td>
                <td>
                  <dx-util-number-box v-model="item.weight.value" format="###0" :min="0" />
                </td>
                <td>
                  <dx-util-number-box v-model="item.count" format="###0" :min="0" />
                </td>
                <td>
                  <dx-util-check-box v-model="item.isStacked" />
                </td>
                <td>
                  <div class="badge badge-primary display-h-5 d-flex justify-content-center align-items-center" style="height: 36px;">
                    {{ item.count * item.weight.value }}
                  </div>
                </td>
                <td>
                  <dx-util-button
                    icon="trash"
                    hint="Remove Pallet"
                    type="danger"
                    @click="onRemovePallet($event, index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-show="shipmentPallet.partnered" class="col-6 mt-1">
        <div class="row">
          <div class="col-12 mb-half">
            To optimize trailer space, make sure pallets are stackable. Stacked, uniform pallets are essential for safety in transportation and handling.
          </div>
          <div class="col-12 mb-half">
            ✔ Pallets must have uniform, flat tops. For stacked pallets, each pallet must be 48-50 in. tall.
          </div>
          <div class="col-12 mb-half">
            ❌ Do not build pallets too tall to be stackable, or with uneven tops. <a href="https://sellercentral.amazon.com/gp/help/external/200978400" target="_blank">Learn more</a>
          </div>
          <div class="col-12">
            <img :src="palletImgSrc" height="280" class="img-fluid rounded">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable global-require */

import store from '@/store'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import tenantService from '@/http/requests/tenant/tenan.settings'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import { Notify } from '@robustshell/utils'
import {getCurrencyCodeEnumList} from '@/enums/currencyCodeEnum'
import {getSellerFreightClassEnumList} from '@/enums/sellerFreightClassEnum'
import useCurrentUser from '@/libs/app/current-user'
import managedKeysEnum from '@/enums/managedKeysEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'
import usePrinter from '@/libs/printer/print'
import { labelTypes, palletBarcodeVarabiles } from '@/libs/printer/printer-varaibles'
import { LabelParam } from '@/http/models/printer/entity/labelparam'

export default {
  props: {
    batchId: {
      type: Number,
      required: true,
    },
    shipmentBatchId: {
      type: Number,
      required: true,
    },
    partnerType: {
      type: Boolean,
      required: true,
    },
    shipmentType: {
      type: String,
      required: true,
    },
    transportStatus: {
      type: String,
      required: true,
    },
    carrier: {
      type: String,
      required: true,
    },
    selectedCarrier: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      selectedPrinter,
      printers,
      pshBase64,
      printStaticLabel,
    } = usePrinter()
    const {
      userTenantId,
    } = useCurrentUser()
    return {
      selectedPrinter,
      printers,
      pshBase64,
      printStaticLabel,
      userTenantId,
    }
  },
  data() {
    return {
      palletImgSrc: require('@/assets/images/pages/stacked-pallets-inbound-fba.png'),
      getSellerFreightClassList: getSellerFreightClassEnumList(),
      currencyCodeList: getCurrencyCodeEnumList(),
      shipmentPallet: {
        id: null,
        batchId: this.shipmentBatchId,
        palletQuantity: 0,
        sellerFreightClass: '',
        freightReadyDate: new Date(),
        weight: 0,
        unitOfWeight: 'POUNDS',
        currencyCode: 'USD',
        declaredValue: 0,
        carrier: '',
        proNumber: '',
        partnered: true,
        palletList: [],
        contact: {
          name: '',
          phone: '',
          email: '',
          fax: '',
        },
      },
      dimensions: {
        length: 0,
        width: 0,
        height: 0,
        unit: 'INCHES',
      },
      weight: {
        value: 0,
        unit: 'POUNDS',
      },
      currentPalletContact: {},
    }
  },
  computed: {
    headerInfo() {
      const headerInfo = store.getters['fbaShipment/getSelectedBactInfo']
      if (this.isIndividualTenant) {
        headerInfo.accountNo = ''
      }
      return headerInfo
    },
  },
  watch: {
    partnerType: {
      immediate: true,
      async handler(current, previous) {
        this.shipmentPallet.partnered = current
      },
    },
    selectedCarrier: {
      immediate: true,
      handler(current, previous) {
        this.shipmentPallet.carrier = current
      },
    },
  },
  mounted() {
    this.getCurrentPalletContact()
    this.getShipmentPalletDTOByBatchId()
    this.shipmentPallet.contact.name = this.headerInfo.storeName.toUpperCase()
  },
  methods: {
    getShipmentPalletDTOByBatchId() {
      outboundShippingService.getShipmentPalletByBatchId(this.shipmentBatchId)
        .then(result => {
          const { data } = result
          this.shipmentPallet.proNumber = data.proNumber
          if (data) {
            data.palletList.palletList.forEach(pallet => {
              const item = {
                ...pallet,
                rowId: uuidv4(),
                count: 1,
              }
              this.shipmentPallet.palletList.push(item)
            })
          }
        })
    },
    getCurrentPalletContact() {
      tenantService.findByKeyAndCurrentTenantId(managedKeysEnum.PALLETCONTACT.key).then(result => {
        const data = { ...result.data[0] }
        if (data.id) {
          this.currentPalletContact = {
            id: data.id,
            key: data.key,
            value: JSON.parse(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
          this.shipmentPallet.contact = {
            name: this.headerInfo.storeName.toUpperCase(),
            phone: this.currentPalletContact.value.phone,
            email: this.currentPalletContact.value.email,
            fax: this.currentPalletContact.value.fax,
          }
        }
      })
    },
    createOrUpdatePalletContactSetting() {
      let data = {
        name: this.shipmentPallet.contact.name,
        phone: this.shipmentPallet.contact.phone,
        email: this.shipmentPallet.contact.email,
        fax: this.shipmentPallet.contact.fax,
      }
      data = JSON.stringify(data)
      let palletContact = {
        id: null,
        key: managedKeysEnum.PALLETCONTACT.key,
        value: data,
        tenantId: this.userTenantId,
        scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
        valueType: valueTypeEnum.JSON.key,
        version: null,
        referenceId: this.headerInfo.storeId,
      }
      if (this.currentPalletContact.id) {
        this.currentPalletContact.value = data
        palletContact = { ...this.currentPalletContact }
        tenantService
          .update(palletContact)
          .then(result => {})
      } else {
        tenantService
          .create(palletContact)
          .then(result => {})
      }
    },
    onAddNewPallet(e) {
      const pallet = {
        rowId: uuidv4(),
        count: 1,
        isStacked: false,
        dimensions: {
          width: 48,
          length: 40,
          height: 0,
          unit: 'INCHES',
        },
        weight: {
          value: 0,
          unit: 'POUNDS',
        },
      }
      this.shipmentPallet.palletList.push(pallet)
    },
    onRemovePallet(e, index) {
      this.shipmentPallet.palletList.splice(index, 1)
    },
    async onSubmitBoxDimensionsPallet(e) {
      let shipmentType = 'Small parcel delivery (SPD)'
      if (this.shipmentType === 'LTL') {
        shipmentType = 'Less than truckload (LTL)'
      }
      const partnerType = this.partnerType ? 'Partnered' : 'Non Partner'
      this.$swal({
        title: 'Confirm Pallet Submission',
        html: `<table class="table table-bordered table-sm text-left bg-white">
          <tbody>
            <tr>
              <th scope="row">Is Partnered</th>
              <td>${partnerType}</td>
            </tr>
            <tr>
              <th scope="row">Carrier Name</th>
              <td>${this.selectedCarrier}</td>
            </tr>
            <tr>
              <th scope="row">Shipment Type</th>
              <td>${shipmentType}</td>
            </tr>
          </tbody>
        </table>`,
        icon: 'info',
        width: '30vw',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
          content: 'px-0',
          htmlContainer: 'mx-0',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.createOrUpdatePalletContactSetting()
          const tempPalletList = this.shipmentPallet.palletList
          const palletList = this.getPalletList()
          this.shipmentPallet.palletList.length = 0
          const pallets = { palletList: palletList }
          this.shipmentPallet.palletList = pallets
          this.shipmentPallet.palletQuantity = this.shipmentPallet.palletList.length
          try {
            await outboundShippingService.setShipmentPalletDetailsAndTransmitToAmazon(this.shipmentPallet)
            this.shipmentPallet.palletList = tempPalletList
            this.onEmitGetTransportDetail()
          } catch (error) {
            this.shipmentPallet.palletList = tempPalletList
          }
        }
      })
    },
    getPalletList() {
      const palletList = []
      this.shipmentPallet.palletList.forEach(item => {
        if (item.count > 1) {
          for (let index = 0; index < item.count; index++) {
            palletList.push({
              dimensions: item.dimensions,
              weight: item.weight,
              isStacked: item.isStacked,
            })
          }
        } else {
          palletList.push({
            dimensions: item.dimensions,
            weight: item.weight,
            isStacked: item.isStacked,
          })
        }
      })
      return palletList
    },
    onEmitGetTransportDetail() {
      this.$emit('on-emit-get-transport-detail')
    },
    createPrinterParams(data) {
      const params = []
      params.push(new LabelParam(palletBarcodeVarabiles.SHIPPING_PLAN_NAME.value, data.shippingPlanName))
      params.push(new LabelParam(palletBarcodeVarabiles.CREATED_AT.value, moment(data.createdAt).format('YYYY-MM-DD HH:mm')))
      params.push(new LabelParam(palletBarcodeVarabiles.BATCH_AND_BOX_NAME.value, data.batchName))
      params.push(new LabelParam(palletBarcodeVarabiles.CURRENT_DATE.value, moment(new Date()).format('YYYY-MM-DD')))
      params.push(new LabelParam(palletBarcodeVarabiles.PSH_BASE64_CONST.value, this.pshBase64))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_FROM_LINE1.value, data.shipFrom[0]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_FROM_LINE2.value, data.shipFrom[1]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_FROM_LINE3.value, data.shipFrom[2]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_FROM_LINE4.value, data.shipFrom[3]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_FROM_LINE5.value, data.shipFrom[4]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_FROM_LINE6.value, data.shipFrom[5]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_TO_LINE1.value, data.shipTo[0]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_TO_LINE2.value, data.shipTo[1]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_TO_LINE3.value, data.shipTo[2]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_TO_LINE4.value, data.shipTo[3]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_TO_LINE5.value, data.shipTo[4]))
      params.push(new LabelParam(palletBarcodeVarabiles.SHIP_TO_LINE6.value, data.shipTo[5]))
      return params
    },
    async onPrintPalletBarcode(e) {
      if (!this.selectedPrinter) {
        Notify.warning('Select a printer')
        return
      }
      if (this.shipmentPallet.palletList.length === 0) {
        Notify.warning('You must add a pallet to print a pallet barcode.')
        return
      }
      try {
        const data = await fbaShipmentService.getPalletBarcode(this.shipmentBatchId)
        const count = 4 * this.shipmentPallet.palletList.length
        data.forEach(item => {
          const params = this.createPrinterParams(item)
          this.printStaticLabel(this.selectedPrinter.VendorName, this.selectedPrinter.Address, labelTypes.PALLET_BARCODE_LABEL.value, params, count)
        })
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
  },
}
</script>

<style>

</style>
