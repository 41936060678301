<template>
  <div class="container-fluid">
    <div class="row my-1">
      <div class="col-4">
        <label for="channel">Channels</label>
        <dx-util-tag-box
          id="channel"
          v-model="formData.channels"
          placeholder="Select Channels"
          :data-source="channels"
          display-expr="text"
          value-expr="key"
          :show-selection-controls="true"
          :show-multi-tag-only="false"
          :multiline="false"
          :max-displayed-tags="10"
        />
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4">
        <label for="listPrice">List Price</label>
        <dx-util-select-box
          id="listPrice"
          v-model="formData.listPrice.type"
          placeholder="Select Channels"
          :data-source="listPrices"
          display-expr="text"
          value-expr="value"
        />
      </div>
      <div v-if="isPricingFormula" class="col-2">
        <label for="margin">Margin</label>
        <dx-util-number-box id="margin" v-model="formData.listPrice.margin" :step="0.1" />
      </div>
      <div v-if="isPricingFormula" class="col-2">
        <label for="unit">Unit</label>
        <dx-util-select-box
          id="unit"
          v-model="formData.listPrice.unit"
          :data-source="formulaUnit"
          display-expr="text"
          value-expr="value"
        />
      </div>
      <div v-if="isPricingFormula" class="col-4">
        <label for="base">Base</label>
        <dx-util-select-box
          id="base"
          v-model="formData.listPrice.base"
          :data-source="pricingBases"
          placeholder="Select pricing base"
          display-expr="text"
          value-expr="value"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <!--
          <label for="minPrice">Minimum Price</label>
          <dx-util-select-box
            id="minPrice"
            v-model="formData.minimumPrice.type"
            placeholder="Select Min Price"
            :data-source="minimumPrices"
            display-expr="text"
            value-expr="value"
          />
        -->
      </div>
      <div class="col-8 text-right mt-2">
        <dx-util-button text="Save" type="success" width="150px" @click="handleSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import MerchantPlatformsEnum from '@/enums/merchant-platforms.enum'
import { getPricingBaseList } from '@/enums/pricingBases.enum'
import listPriceEnum, { getListPriceList } from '@/enums/listPrice.enum'
import { getMinimumPriceList } from '@/enums/minimumPrice.enum'
import SettingsAutoPriceUnitEnum from '@/enums/settings-auto-price-unit.enum'
import useStoreSettings from './useStoreSettings'

export default {
  components: {
  },
  data() {
    return {
      pricingBases: getPricingBaseList(),
      listPrices: getListPriceList(),
      minimumPrices: getMinimumPriceList(),
      formulaUnit: SettingsAutoPriceUnitEnum.list(),
    }
  },
  setup() {
    const { currentStore, formData, resetFormData } = useStoreSettings()
    return {
      currentStore, formData, resetFormData,
    }
  },
  computed: {
    channels() {
      if (this.currentStore && this.currentStore.commercePlatform) {
        return MerchantPlatformsEnum.getChannels(this.currentStore.commercePlatform)
      }
      if (this.currentStore && this.currentStore.commercePlatformName) {
        return MerchantPlatformsEnum.getChannels(this.currentStore.commercePlatformName)
      }
      return []
    },
    isPricingFormula() {
      return this.formData?.listPrice?.type === listPriceEnum.PRICE_FORMULA.key
    },
  },
  watch: {
    currentStore(value) {
      if (value.id) {
        this.resetFormData()
        this.loadAutoPricingSettings()
      }
    },
  },
  mounted() {
    if (this.currentStore.id) {
      this.resetFormData()
      this.loadAutoPricingSettings()
    }
  },
  methods: {
    async handleSubmit() {
      await this.saveAutoPricingSettings()
      this.closePopup()
    },
    async loadAutoPricingSettings() {
      const result = await settingsService.getByReference(referenceTypeEnum.STORE.value, this.currentStore.id, SettingsTypeEnum.AUTO_PRICING.value)
      const resultObj = JSON.parse(result.data.value)
      if (typeof resultObj === 'object') {
        this.formData.channels = resultObj?.channels
        this.formData.listPrice = { ...resultObj?.listPrice }
        this.formData.minimumPrice = { ...resultObj?.minimumPrice }
      }
    },
    async saveAutoPricingSettings() {
      const preferenceData = {
        companyId: this.currentStore.parentCompany,
        referenceId: this.currentStore.id,
        referenceType: referenceTypeEnum.STORE.value,
        settingType: SettingsTypeEnum.AUTO_PRICING.value,
        settingKey: SettingsTypeEnum.AUTO_PRICING.value,
        description: `Auto Pricing Preferences for storeID: ${this.currentStore.id}`,
        settingName: SettingsTypeEnum.AUTO_PRICING.value,
        value: JSON.stringify(this.formData),
      }
      await settingsService.createOrUpdate(preferenceData)
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  padding-left: 8px;
}
</style>
