/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import nth from 'lodash/nth'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'

const catalogItemProperties = require('./catalog-item-properties.json')

// #region Merchant Suggested Asin - merchant_suggested_asin
const merchantSuggestedAsin = catalogItemProperties.properties.merchant_suggested_asin
const merchantSuggestedAsinField = {
  title: merchantSuggestedAsin.title,
  description: merchantSuggestedAsin.description,
  helpText: merchantSuggestedAsin.$comment,
}
// #endregion

// #region Fulfillment Availability - fulfillment_availability
const fulfillmentAvailability = catalogItemProperties.properties.fulfillment_availability
const fulfillmentChannelCode = fulfillmentAvailability.items.properties.fulfillment_channel_code
const fulfillmentChannelCodeField = {
  title: fulfillmentChannelCode.title,
  description: fulfillmentChannelCode.description,
  helpText: fulfillmentChannelCode.$comment,
}
const quantity = fulfillmentAvailability.items.properties.quantity
const quantityField = {
  title: quantity.title,
  description: quantity.description,
  helpText: quantity.$comment,
}
// #endregion

// #region Purchasable Offer - purchasable_offer
const purchasableOffer = catalogItemProperties.properties.purchasable_offer
const startAt = purchasableOffer.items.properties.start_at
const startAtField = {
  title: startAt.properties.value.title,
  description: startAt.properties.value.description,
  helpText: startAt.properties.value.$comment,
}
// #endregion

// #region List Price - list_price
const listPrice = catalogItemProperties.properties.list_price
const listPriceField = {
  title: listPrice.title,
  description: listPrice.description,
  helpText: listPrice.$comment,
}
// #endregion

// #region Product Tax Code - product_tax_code
const productTaxCode = catalogItemProperties.properties.product_tax_code
const productTaxCodeValues = nth(productTaxCode.items.properties.value.anyOf, 1) // enum[], enumNames[]
const productTaxCodeField = {
  title: productTaxCode.items.properties.value.title,
  description: productTaxCode.items.properties.value.description,
  helpText: productTaxCode.items.properties.value.$comment,
}
let productTaxCodeEnumData = productTaxCodeValues.enumNames.reduce((result, field, index) => {
  let order = 1
  switch (productTaxCodeValues.enum[index]) {
    case 'A_GEN_TAX':
      order = 1
      break
    case 'A_GEN_NOTAX':
      order = 2
      break
    case 'A_GEN_RENTAL':
      order = 2
      break
    default:
      order = 3
      break
  }
  result[index] = {
    value: productTaxCodeValues.enum[index], text: field, order: order,
  }
  return result
}, {})
const productTaxCodeEnum = sortBy(productTaxCodeEnumData, p => p.order)
// #endregion

// #region Condition Type - condition_type
const conditionType = catalogItemProperties.properties.condition_type
const conditionTypeField = {
  title: conditionType.items.properties.value.title,
  description: conditionType.items.properties.value.description,
  helpText: conditionType.items.properties.value.$comment,
}
let conditionTypeEnumData = conditionType.items.properties.value.enumNames.reduce((result, field, index) => {
  result[index] = {
    value: conditionType.items.properties.value.enum[index], text: field,
  }
  return result
}, {})

const conditionTypeEnum = values(conditionTypeEnumData)
// #endregion

// #region Condition Note - condition_note
const conditionNote = catalogItemProperties.properties.condition_note
const conditionNoteField = {
  title: conditionNote.items.properties.value.title,
  description: conditionNote.items.properties.value.description,
  helpText: conditionNote.items.properties.value.$comment,
}
// #endregion

// #region Batteries Required - batteries_required
const batteriesRequired = catalogItemProperties.properties.batteries_required
const batteriesRequiredField = {
  title: batteriesRequired.title,
  description: batteriesRequired.description,
  helpText: batteriesRequired.$comment,
}
let batteriesRequiredEnumData = batteriesRequired.items.properties.value.enumNames.reduce((result, field, index) => {
  result[index] = {
    value: batteriesRequired.items.properties.value.enum[index], text: field,
  }
  return result
}, {})

const batteriesRequiredEnum = values(batteriesRequiredEnumData)
// #endregion

// #region Supplier Declared Dg Hz Regulation - supplier_declared_dg_hz_regulation
const supplierDeclaredDgHzRegulation = catalogItemProperties.properties.supplier_declared_dg_hz_regulation
const supplierDeclaredDgHzRegulationField = {
  title: supplierDeclaredDgHzRegulation.title,
  description: supplierDeclaredDgHzRegulation.description,
  helpText: supplierDeclaredDgHzRegulation.$comment,
}
let supplierDeclaredDgHzRegulationEnumData = supplierDeclaredDgHzRegulation.items.properties.value.enumNames.reduce((result, field, index) => {
  result[index] = {
    value: supplierDeclaredDgHzRegulation.items.properties.value.enum[index], text: field,
  }
  return result
}, {})

const supplierDeclaredDgHzRegulationEnum = values(supplierDeclaredDgHzRegulationEnumData)
// #endregion

export {
  merchantSuggestedAsinField,
  fulfillmentChannelCodeField,
  quantityField,
  listPriceField,
  startAtField,
  productTaxCodeField,
  productTaxCodeEnum,
  conditionTypeField,
  conditionTypeEnum,
  conditionNoteField,
  batteriesRequiredField,
  batteriesRequiredEnum,
  supplierDeclaredDgHzRegulationField,
  supplierDeclaredDgHzRegulationEnum,
}
