<template>
  <div class="row">
    <div class="col-12 px-0">
      <dx-data-grid
        id="variationItemsGrid"
        ref="variationItemsGridRef"
        :data-source="dataSource"
        :height="gridHeight"
        :allow-column-resizing="true"
        key-expr="id"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :hover-state-enabled="true"
        :row-alternation-enabled="false"
        :customize-columns="customizeColumns"
        @initialized="onInitialized"
        @toolbar-preparing="onToolbarPreparing($event)"
        @exporting="onExporting"
      >
        <dx-sorting mode="single" />
        <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
        <dx-column type="buttons" caption="Actions">
          <dx-button>
            <template #default="{data}">
              <dx-util-button
                type="success"
                text="Select"
                @click="onSelectVariation($event, data)"
              />
            </template>
          </dx-button>
        </dx-column>
        <dx-column data-field="imageUrl" cell-template="imageCellTemplate" width="100" caption="Image" alignment="center" :allow-sorting="false" />
        <template #imageCellTemplate="{data}">
          <img :src="data.value" style="width:6rem;height:6rem" class="img-thumbnail">
        </template>
        <dx-column data-field="asin" caption="ASIN" cell-template="asinCellTemplate" />
        <template #asinCellTemplate="{data}">
          <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
            {{ data.value }}
          </a>
        </template>
        <dx-column data-field="identifier" caption="UPC/EAN" />
        <dx-column data-field="title" />
        <dx-column data-field="rank" alignment="left" />
        <dx-column data-field="color" />
        <dx-column data-field="size" />
        <dx-column data-field="style" />
        <dx-column data-field="manufacturer" />
        <dx-column data-field="modelNumber" />
        <template #filterToolbar>
          <div class="d-flex flex-row align-items-center">
            <h5 class="text-yellow my-0 mr-5">
              Title: {{ parentItem.title }}
            </h5>
            <h5 class="text-yellow my-0 mr-5">
              ASIN: {{ parentItem.asin }}
            </h5>
            <h5 class="text-yellow my-0 mr-5">
              Product Type: {{ parentItem.productType ? parentItem.productType : '-' }}
            </h5>
            <h5 class="text-yellow my-0 mr-5">
              Brand: {{ parentItem.brandName ? parentItem.brandName : '-' }}
            </h5>
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import GridBase from '@core/dev-extreme/mixins/grid/base'
import amazonService from '@/http/requests/external/amazon.fba'
import {
  itemIdentifier,
  itemProductType,
  itemImageLink,
  itemSummary,
  itemRank1,
} from '@/libs/app/catalog-item'

export default {
  mixins: [GridBase],
  props: {
    parentItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataSource: [],
    }
  },
  mounted() {
    this.getCatalogItemVariations()
  },
  methods: {
    gridHeight() {
      return window.innerHeight / 1.1
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
    },
    getCatalogItemVariations() {
      this.dataSource.splice(0, this.dataSource.length)
      amazonService.getCatalogItemVariationsByAsin(this.parentItem.asin).then(result => {
        const { data } = result
        data.forEach(item => {
          const {
            identifiers, images, productTypes, salesRanks, summaries,
          } = item.catalogItem
          const {
            brandName, colorName, itemName, manufacturer, modelNumber, sizeName, styleName,
          } = itemSummary(summaries)
          const listingItem = {
            id: item.id,
            asin: item.asin,
            imageUrl: itemImageLink(images),
            productType: itemProductType(productTypes),
            identifier: itemIdentifier(identifiers).identifier,
            brand: brandName,
            color: colorName,
            title: itemName,
            manufacturer: manufacturer,
            modelNumber: modelNumber,
            size: sizeName,
            style: styleName,
            rank: itemRank1(salesRanks),
          }
          this.dataSource.push(listingItem)
        })
      })
    },
    onSelectVariation(e, item) {
      const { data } = item
      data.rowId = this.parentItem.rowId
      this.$emit('on-emit-select-variation', data)
    },
  },
}
</script>

<style>

</style>
