<template>
  <div class="card card-psh border">
    <div class="m-2">
      <div class="m-1 mb-1 d-flex justify-content-between">
        <div>
          <h5 class="text-warning">
            Pull Amazon FBM Return Orders
          </h5>
          <div class="text-primary mb-1">
            Amazon customer returns for this store will be fetched as inbound items when this option is selected.
          </div>
        </div>
        <div>
          <dx-util-button :text="pullAmzReturnOrder ? 'Disable' : 'Enable'"
            :type="pullAmzReturnOrder ? 'danger' : 'success'" @click="toggleStorePullAmzReturnOrder"
          />
        </div>
      </div>
      <div class="divider mb-1">
        <div class="divider-text">
        </div>
      </div>
      <div class="m-1 mb-1 d-flex justify-content-between">
        <div>
          <h5 class="text-warning">
            Pull Amazon Removal Orders
          </h5>
          <div class="text-primary mb-1">
            Amazon customer returns for this store will be fetched as inbound items when this option is selected.
          </div>
        </div>
        <div>
          <dx-util-button :text="pullAmzRemovalOrder ? 'Disable' : 'Enable'"
            :type="pullAmzRemovalOrder ? 'danger' : 'success'" @click="toggleStorePullAmzRemovalOrder"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import useStoreSettings from './useStoreSettings'

export default {
  data() {
    return {
      pullAmzReturnOrder: false,
      pullAmzRemovalOrder: false,
    }
  },
  setup() {
    const { currentStore, formData, resetFormData } = useStoreSettings()

    return {
      currentStore, formData, resetFormData,
    }
  },
  watch: {
    currentStore(value) {
      if (value.id) {
        this.loadStorePullAmzReturnOrder()
        this.loadStorePullAmzRemovalOrder()
      }
    },
  },
  async mounted() {
    if (this.currentStore.id) {
      this.loadStorePullAmzReturnOrder()
      this.loadStorePullAmzRemovalOrder()
    }
  },
  methods: {
    async loadStorePullAmzReturnOrder() {
      const result = await settingsService.getByReference(referenceTypeEnum.STORE.value, this.currentStore.id, SettingsTypeEnum.STORE_PULL_AMZ_RETURN_ORDER.value)
      const resultObj = JSON.parse(result.data.value)
      this.pullAmzReturnOrder = resultObj
    },
    async loadStorePullAmzRemovalOrder() {
      const result = await settingsService.getByReference(referenceTypeEnum.STORE.value, this.currentStore.id, SettingsTypeEnum.STORE_PULL_AMZ_REMOVAL_ORDER.value)
      const resultObj = JSON.parse(result.data.value)
      this.pullAmzRemovalOrder = resultObj
    },
    async updateStorePullAmzReturnOrder() {
      const preferenceData = {
        companyId: this.currentStore.parentCompany,
        referenceId: this.currentStore.id,
        referenceType: referenceTypeEnum.STORE.value,
        settingType: SettingsTypeEnum.STORE_PULL_AMZ_RETURN_ORDER.value,
        settingKey: SettingsTypeEnum.STORE_PULL_AMZ_RETURN_ORDER.value,
        description: `Store Pull Amazon FBM Return Order for storeID: ${this.currentStore.id}`,
        settingName: SettingsTypeEnum.STORE_PULL_AMZ_RETURN_ORDER.value,
        value: JSON.stringify(this.pullAmzReturnOrder),
      }
      await settingsService.createOrUpdate(preferenceData)
    },
    async updateStorePullAmzRemovalOrder() {
      const preferenceData = {
        companyId: this.currentStore.parentCompany,
        referenceId: this.currentStore.id,
        referenceType: referenceTypeEnum.STORE.value,
        settingType: SettingsTypeEnum.STORE_PULL_AMZ_REMOVAL_ORDER.value,
        settingKey: SettingsTypeEnum.STORE_PULL_AMZ_REMOVAL_ORDER.value,
        description: `Store Pull Amazon Removal Order for storeID: ${this.currentStore.id}`,
        settingName: SettingsTypeEnum.STORE_PULL_AMZ_REMOVAL_ORDER.value,
        value: JSON.stringify(this.pullAmzRemovalOrder),
      }
      await settingsService.createOrUpdate(preferenceData)
    },
    toggleStorePullAmzRemovalOrder() {
      this.pullAmzRemovalOrder = !this.pullAmzRemovalOrder
      this.updateStorePullAmzRemovalOrder()
    },
    toggleStorePullAmzReturnOrder() {
      this.pullAmzReturnOrder = !this.pullAmzReturnOrder
      this.updateStorePullAmzReturnOrder()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
