import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const inboundGuidanceEnum = Object.freeze({
  INBOUND_NOT_RECOMMENDED: { key: 'INBOUNDNOTRECOMMENDED', value: 'Inbound Not Recomended', text: 'Shipping this item to Amazons fulfillment network is not recommended.' },
  INBOUND_OK: { key: 'INBOUNDOK', value: 'Inbound Ok', text: 'Shipping this item to Amazons fulfillment network should not cause any problems.' },
})

function getInboundGuidanceEnum(predicate, predicateKey = 'key') {
  const result = find(inboundGuidanceEnum, { [predicateKey]: predicate })
  return result || { value: 'None', text: 'None' }
}

export default inboundGuidanceEnum

export {
  getInboundGuidanceEnum,
}
