<template>
<div class="container-fluid">
    <div class="row batch-summary">
        <div class="col-2 px-0">
            <div class="summary-text">
                Total Buy Cost
            </div>
            <div class="summary-text">
                $ {{ formatedNumber(shipmentPlanSummary.totalCost) }}
            </div>
        </div>
        <div class="col-2 px-0">
            <div class="summary-text">
                Total Sales Value
            </div>
            <div class="summary-text">
                $ {{ formatedNumber(shipmentPlanSummary.totalSales) }}
            </div>
        </div>
        <div class="col-2 px-0">
            <div class="summary-text">
                Gross Profit
            </div>
            <div class="summary-text">
                $ {{ subtractFromated(shipmentPlanSummary.totalSales,shipmentPlanSummary.totalCost) }}
            </div>
        </div>
        <div class="col-2 px-0">
            <div class="summary-text">
                Avg Sales Rank
            </div>
            <div class="summary-text">
                {{ shipmentPlanSummary.avgAmzRank ? shipmentPlanSummary.avgAmzRank : 0 }}
            </div>
        </div>
        <div class="col-2 px-0">
            <div class="summary-text">
                SKU Count
            </div>
            <div class="summary-text">
                {{ shipmentPlanSummary.skuCount ? shipmentPlanSummary.skuCount : 0 }}
            </div>
        </div>
        <div class="col-2 px-0">
            <div class="summary-text">
                Item Count
            </div>
            <div class="summary-text">
                {{ shipmentPlanSummary.itemCount ? shipmentPlanSummary.itemCount : 0 }}
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as R from 'ramda'

export default {
  props: {
    shipmentPlanSummary: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        itemCount: null,
        itemQty: null,
        skuCount: null,
        totalCost: null,
        totalSales: null,
        avgAmzRank: null,
      }),
    },
  },
  watch: {
    shipmentPlanSummary: {
      handler() {
        this.$forceUpdate()
      },
      deep: true,
    },
  },
  methods: {
    subtractFromated(val1 = null, val2 = null) {
      const num1 = R.is(Number, val1) ? val1 : 0
      const num2 = R.is(Number, val2) ? val2 : 0
      return (num1 - num2).toFixed(2)
    },
    formatedNumber(val) {
      const num = R.is(Number, val) ? val : 0
      return num.toFixed(2)
    },
  },
}
</script>

<style lang="scss" scoped>
.batch-summary {
    background-color: rgba(223, 230, 233,1.0);
    color: #2b2d42;
    .summary-text {
        font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,Helvetica Neue, Arial, sans-serif;
        font-size: 1rem;
        text-align: center;
        text-justify: inter-word;
    }
}
</style>
