<template>
  <div class="container-fluid" :class="!isDrawerOpen ?'px-0':''">
    <div :class="isDrawerOpen ?'row':'d-flex flex-row'">
      <!--begin ::New Batch-->
      <div :style="leftSide" :class="isDrawerOpen ? 'col-2 px-0': ''">
        <span class="drawer-button" :class="isDrawerOpen ? 'is-open':'is-close'" @click="isDrawerOpen = !isDrawerOpen">
          <i class="bi" :class="isDrawerOpen ? 'bi-chevron-left':'bi-chevron-right'"></i>
        </span>
        <batch
          :is-drawer-open="isDrawerOpen"
          @selected-batch="onSelectedBatch"
          @on-emit-show-settings="onShowStoreSettings"
          @on-emit-update-selected-shipment-status="onUpdateSelectedShipmentStatus"
        />
      </div>
      <!--begin ::End Batch-->
      <div :class="isDrawerOpen ? 'col-10 px-0': 'w-100'">
        <header-info v-if="renderComponent" @submit-batch="onSubmitBatch" />
        <component
          :is="currentComponent"
          v-bind="currentComponentProps"
          @emit-batch-transmite="onEmitBatchTransmit"
          @emit-create-consolidated="onEmitCreateConsolidated"
        />
        <batch-summary v-if="currentComponent !== 'dimensions'" :shipment-plan-summary="shipmentPlanSummary" />
      </div>
    </div>

    <store-settings :component-id="storeSettingsCompId" :store-id="storeId" @emit-form-closed="onCloseStoreSettings" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import DxDrawer from 'devextreme-vue/drawer'
import usePrinter from '@/libs/printer/print'
import isNaN from 'lodash/isNaN'
import store from '@/store'
import service from '@/http/requests/outbound/fbaShipmentService'
import StoreSettings from '@/views/apps/company/components/StoreSettings.vue'
import { v4 as uuidv4 } from 'uuid'
import Batch from './components/Batch.vue'
import BatchSummary from './components/BatchSummary.vue'
import HeaderInfo from './components/HeaderInfo.vue'
import Batching from './Batching.vue'
import Boxing from './Boxing.vue'
import Dimensions from './Dimensions.vue'
import fbaShipment from './fbaShipment.mixin'

export default {
  components: {
    StoreSettings,
    Batch,
    HeaderInfo,
    Batching,
    Boxing,
    Dimensions,
    BatchSummary,
    DxDrawer,
  },
  mixins: [fbaShipment],
  data() {
    return {
      isDrawerOpen: true,
      currentComponent: '',
      currentBatch: null,
      accountNo: '',
      batchId: 0,
      storeId: 0,
      // Amazon Shipment Batch
      shipmentBatchInfo: {
        batchId: null,
        shipmentBatchId: null,
        shipmentBatchName: '',
        shipmentBatchDestCode: '',
        shipmentBatchTransportStatus: '',
      },
      shipmentPlanSummary: {},
      renderComponent: true,
      storeSettingsCompId: '',
    }
  },
  setup() {
    usePrinter()
  },
  computed: {
    currentComponentProps() {
      if (this.currentComponent === 'batching' || this.currentComponent === 'boxing') {
        const batchId = parseInt(this.batchId, 10)
        const data = isNaN(batchId) ? 0 : batchId
        return { batchId: data }
      } if (this.currentComponent === 'dimensions') {
        const selectedBatch = store.getters['fbaShipment/getSelectedBactInfo']
        const batchId = parseInt(this.batchId, 10)
        const data = isNaN(batchId) ? 0 : batchId
        return {
          batchId: data,
          shipmentBatchId: selectedBatch.shipmentItem.shipmentBatchId,
          shipmentBatchName: selectedBatch.shipmentItem.shipmentBatchName,
          shipmentBatchDestCode: selectedBatch.shipmentItem.shipmentBatchDestCode,
          shipmentBatchTransportStatus: selectedBatch.shipmentItem.shipmentBatchTransportStatus,
        }
      }
      return {}
    },
  },
  created() {
    this.accountNo = this.$route.params.accountNo
  },
  methods: {
    /**
     * @param {string} component 'batching' or 'boxing' dynamic render vue component name
     * @param {Object} payload selected batch's object
     */
    async onSelectedBatch(component, payload) {
      const result = await service.getShipmentPlanStats(payload.id)

      this.shipmentPlanSummary = {
        id: result ? result.id : payload.id,
        itemCount: result ? result.itemQty : 0,
        itemQty: result ? result.itemQty : 0,
        skuCount: result ? result.skuCount : 0,
        totalCost: result ? result.totalCost : 0,
        totalSales: result ? result.totalSales : 0,
        avgAmzRank: result ? result.avgAmzRank : 0,
      }
      this.currentComponent = component
      this.batchId = payload.id

      store.dispatch('fbaShipment/setSelectedBactInfo', payload)
      this.currentBatch = { ...payload }
      this.$router.push({
        name: 'fba-shipments-workflow',
        params: {
          accountNo: this.accountNo,
          workflow: component,
          batchId: payload.id,
          channel: payload.channelValue,
        },
      }).catch(() => {})
    },
    async onUpdateSelectedShipmentStatus(payload) {
      const result = await service.getShipmentPlanStats(payload)
      this.shipmentPlanSummary = {
        id: result ? result.id : payload,
        itemCount: result ? result.itemQty : 0,
        itemQty: result ? result.itemQty : 0,
        skuCount: result ? result.skuCount : 0,
        totalCost: result ? result.totalCost : 0,
        totalSales: result ? result.totalSales : 0,
        avgAmzRank: result ? result.avgAmzRank : 0,
      }
    },
    onEmitBatchTransmit(component, payload) {
      this.currentComponent = component
      Object.assign(this.shipmentBatchInfo, payload)
      this.currentBatch.batchStatus = 'box_dims'
      this.currentBatch.shipmentItem = payload
      store.dispatch('fbaShipment/setSelectedBactInfo', this.currentBatch)
      this.$router.push({
        name: 'fba-shipments-workflow',
        params: {
          accountNo: this.accountNo,
          workflow: component,
          batchId: payload.batchId,
          channel: payload.channelValue,
        },
      }).catch(() => {})
    },
    onEmitCreateConsolidated(payload) {
      this.$emit('reload-shipment-plans', payload)
    },
    onSubmitBatch(component, batchId) {
      this.currentComponent = component
      this.$router.push({
        name: 'fba-shipments-workflow',
        params: {
          accountNo: this.accountNo,
          workflow: component,
          batchId: batchId,
        },
      }).catch(() => {})

      this.renderComponent = false
      this.$nextTick(() => {
        this.currentBatch.batchStatus = 'boxing'
        store.dispatch('fbaShipment/setSelectedBactInfo', this.currentBatch)
        this.renderComponent = true
      })
    },
    getPopupWidth() {
      return window.innerWidth / 1.5
    },
    getPopupHeight() {
      return window.innerHeight / 1.5
    },
    onShowStoreSettings(payload) {
      this.storeId = parseInt(payload, 10)
      this.storeSettingsCompId = uuidv4()
    },
    onCloseStoreSettings() {
    },
  },
}
</script>

<style lang="scss">
  .drawer-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 36px;
    width: 36px;
    z-index: 999;
    background: #2f9aff;
    border-radius: 50% 50%;;
    cursor: pointer;
    text-align: center;
    padding-top: 4px;
    &.is-open {
      left: calc(calc(calc(100vw/12)*2) - 18px);
    }
    &.is-close {
      left: 30px;
    }
    & > i {
      font-size: 20px;
      font-weight: 700;
      color: #fff;
    }
  }
</style>
