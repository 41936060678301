import find from 'lodash/find'

/**
 * Enum for Currency Code.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const currencyCodeEnum = Object.freeze({
  USD: { key: 'USD', value: 'USD', text: 'USD' },
  GBP: { key: 'GBP', value: 'GBP', text: 'GBP' },
})

function getCurrencyCodeEnum(predicate, predicateKey = 'key') {
  const result = find(currencyCodeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getCurrencyCodeEnumList() {
  const options = []
  options.push({ value: '', text: 'Select Seller Freight Class' })
  Object.keys(currencyCodeEnum).map(key => options.push({ value: currencyCodeEnum[key].value, text: currencyCodeEnum[key].text }))
  return options
}

export default currencyCodeEnum

export {
  getCurrencyCodeEnum,
  getCurrencyCodeEnumList,
}
