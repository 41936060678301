<template>
  <dx-util-form
    ref="batchItemFormRef"
    :form-data="fbaBatchParentItemWrapperFormData"
    :col-count="1"
    :show-colon-after-label="true"
    label-location="top"
    :scrolling-enabled="true"
  >
    <dx-util-item
      data-field="planId"
      :visible="isServingTenant"
      editor-type="dxSelectBox"
      :editor-options="planOptions"
      :label="{ text: 'User Plan' }"
    />
    <dx-util-item data-field="title" editor-type="dxTextBox" />
    <dx-util-group-item :col-count="3">
      <dx-util-item data-field="upc" editor-type="dxTextBox" />
      <dx-util-item
        data-field="asin"
        editor-type="dxTextBox"
        :editor-options="{ readOnly: true }"
      />
      <dx-util-item data-field="fnsku" editor-type="dxTextBox" />
    </dx-util-group-item>
    <dx-util-group-item :col-count="2">
      <dx-util-item :visible="isCreateAndAddMissingItems" data-field="orderNumber" editor-type="dxTextBox" />
      <dx-util-item :visible="isCreateAndAddMissingItems" data-field="trackingNumber" editor-type="dxTextBox" />
    </dx-util-group-item>
    <dx-util-group-item :col-count="3">
      <dx-util-item
        data-field="quantity"
        editor-type="dxNumberBox"
        :editor-options="quantityOptions"
        :label="{text: isPackTypeCasePack ? 'Quantity in Each Case' : 'Quantity' }"
      />
      <dx-util-item
        :visible="isPackTypeCasePack"
        data-field="quantityPerCase"
        :col-span="1"
        editor-type="dxNumberBox"
        :editor-options="quantityPerCaseOptions"
        :label="{text: 'Number of Cases' }"
      />
      <dx-util-simple-item
        :visible="isPackTypeCasePack"
        :label="{text: 'Added / Required'}"
      >
        <template #default>
          <div class="d-flex flex-row">
            <div class="align-self-center text-white rounded-left px-half badge-item w-50" :class="casePackInfoItemClass">
              {{ casePackQuantity }}
            </div>
            <div class="align-self-center bg-success text-white rounded-right px-half badge-item w-50">
              {{ (fbaBatchParentItemWrapperFormData.quantity * fbaBatchParentItemWrapperFormData.quantityPerCase) }}
            </div>
          </div>
        </template>
      </dx-util-simple-item>

    </dx-util-group-item>
    <dx-util-group-item>
      <dx-util-item
        data-field="packType"
        editor-type="dxRadioGroup"
        :editor-options="packTypeOptions"
      />
    </dx-util-group-item>
    <dx-util-group-item :col-count="3">
    <dx-util-item
      data-field="supplier"
      editor-type="dxTextBox"
    />
    <dx-util-item
      data-field="taxCode"
      editor-type="dxSelectBox"
      :editor-options="productTaxCodeOptions"
    />
    <dx-util-item data-field="expirationDate" editor-type="dxDateBox" :editor-options="expirationDateOptions" />
    </dx-util-group-item>
    <dx-util-group-item :col-count="3">
    <dx-util-item
      data-field="itemCondition"
      editor-type="dxSelectBox"
      :editor-options="conditionTypeOptions"
    />
    <dx-util-item
      data-field="cost"
      editor-type="dxNumberBox"
      :label="{ text: 'Cost' }"
    />
    <dx-util-item
      data-field="salePrice"
      editor-type="dxNumberBox"
      :label="{ text: 'Sale Price' }"
    />
    </dx-util-group-item>
  </dx-util-form>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/require-default-prop */
import store from '@/store'
import {
  productTaxCodeEnum,
  conditionTypeEnum,
} from '@/libs/amazon/catalog-item-properties'
import packingTypeEnum from '@/enums/packingTypeEnum'
import PackTypeEnum from '@/enums/packTypeEnum'
import PlanCategoryEnum from '@/enums/plan-category.enum'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import planManagementService from '@/http/requests/plan/planManagementService'
import { getAmazonKey, getLocalKey } from '@/enums/conditionTypeEnum'
import ShipmentChannelEnum from '@/enums/shipment-channel.enum'
import useCurrentUser from '@/libs/app/current-user'
import CreateItemTypeEnum from '../createItemType'

export default {
  props: {
    onEmitHandleSubmitEvent: Function,
    casePackQuantity: {
      type: Number,
      required: false,
      default: 0,
    },
    channel: {
      type: String,
      required: false,
      default: '',
    },
    fbaBatchParentItemWrapperFormData: {
      type: Object,
      default: () => ({
        id: null,
        companyId: null,
        warehouseId: null,
        storeId: null,
        batchParentId: null,
        quantity: 0,
        quantityPerCase: 0,
        missingItemQuantity: 0,
        countPerBundle: 0,
        productId: null,
        mskuFnskuPairId: null,
        cost: 0,
        salePrice: 0,
        supplier: '',
        title: '',
        upc: '',
        asin: '',
        msku: '',
        fnsku: '',
        itemCondition: '',
        planId: null,
        expirationDate: null,
        taxCode: 'A_GEN_TAX',
        listingItem: '',
        notes: '',
        packType: '',
        productType: '',
        createType: '',
        bundleItems: [],
        isMultipleItem: false,
        orderNumber: '',
        trackingNumber: '',
        palletId: '',
      }),
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      packTypes: [],
      plans: [],
      itemWrapperFormData: {},
      isMultipleItem: false,
    }
  },
  setup() {
    const { isServingTenant, userWarehouseId } = useCurrentUser()
    return {
      isServingTenant, userWarehouseId,
    }
  },
  computed: {
    isCreateAndAddMissingItems() {
      return this.fbaBatchParentItemWrapperFormData.createType === CreateItemTypeEnum.replenish_create_items.value
    },
    isPackTypeCasePack() {
      const selectedBacth = store.getters['fbaShipment/getSelectedBactInfo']
      const result = selectedBacth.packingType === packingTypeEnum.case_packed.key
      return result
    },
    productTaxCodeOptions() {
      return {
        dataSource: productTaxCodeEnum,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: true,
      }
    },
    conditionTypeOptions() {
      return {
        dataSource: conditionTypeEnum,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: true,
      }
    },
    planOptions() {
      return {
        dataSource: this.plans,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
      }
    },
    packTypeOptions() {
      return {
        dataSource: this.packTypes,
        valueExpr: 'value',
        displayExpr: 'text',
        layout: 'horizontal',
        onValueChanged: e => {
          this.onChangePackType(e)
        },
      }
    },
    quantityOptions() {
      const isReadonly = this.fbaBatchParentItemWrapperFormData.createType === CreateItemTypeEnum.replenish.value && !this.isPackTypeCasePack
      return {
        readOnly: isReadonly,
        inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
      }
    },
    quantityPerCaseOptions() {
      return {
        inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
      }
    },
    casePackInfoItemClass() {
      const totalCaseQuantity = this.fbaBatchParentItemWrapperFormData.quantity * this.fbaBatchParentItemWrapperFormData.quantityPerCase
      return this.casePackQuantity === totalCaseQuantity ? 'bg-success' : 'bg-warning'
    },
    expirationDateOptions() {
      return {
        type: 'date',
        displayFormat: 'MM/dd/yyyy',
        pickerType: 'calendar',
        min: new Date(),
        onValueChanged: e => {
          this.fbaBatchParentItemWrapperFormData.expirationDate = e.value
        },
      }
    },
  },
  watch: {
    fbaBatchParentItemWrapperFormData: {
      immediate: true,
      handler(current, previous) {
        this.mapProductCondition()
        this.isCreateAndAddMissingItems()
        this.fbaBatchParentItemWrapperFormData.quantityPerCase = typeof (previous) !== 'undefined' ? previous.quantityPerCase : current.quantityPerCase
      },
    },
    casePackQuantity: {
      immediate: true,
      handler(current, previous) {
      },
    },
  },
  mounted() {
    Object.keys(this.$enums.PackTypeEnum).map(key => this.packTypes.push({
      value: this.$enums.PackTypeEnum[key].value,
      text: this.$enums.PackTypeEnum[key].text,
    }))
    this.getPlans()
    this.onEmitHandleSubmitEvent(this.handleSubmit)
  },
  beforeUpdate() {
    this.repaintForm()
  },
  methods: {
    repaintForm() {
      const form = this.$refs.batchItemFormRef.instance
      form.repaint()
    },
    onChangePackType(e) {
      this.$emit('on-emit-changed-pack-type', e.value)
      if (e.value === 'individual') {
        this.fbaBatchParentItemWrapperFormData.countPerBundle = 0
        this.isMultipleItem = false
        this.fbaBatchParentItemWrapperFormData.isMultipleItem = false
        this.$emit('on-emit-changed-multiple-item', false)
      }
      if (e.value === PackTypeEnum.bundle.value || e.value === PackTypeEnum.multi_pack.value) {
        this.isMultipleItem = true
        this.fbaBatchParentItemWrapperFormData.isMultipleItem = true
        this.$emit('on-emit-changed-multiple-item', true)
      }
    },
    getPlans() {
      const planCategory = this.channel === ShipmentChannelEnum.FBA.key ? PlanCategoryEnum.FBA.key : PlanCategoryEnum.FBM.key
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.warehouseId = this.userWarehouseId
      PlanSearchFilter.companyId = this.companyId
      PlanSearchFilter.planCategory = planCategory
      const searchFilter = PlanSearchFilter.getFilters()
      const pageable = 'page=0&size=200'
      planManagementService
        .fetchAllUserPlansByQuery(searchFilter, pageable)
        .then(result => {
          const data = result.data.content
          return data
        })
        .then(data => {
          data.forEach(element => {
            if (element.companyId === this.companyId) {
              this.plans.push({
                value: element.id,
                text: `${element.planCategory.toUpperCase()}:${
                  element.planName
                }:${element.planType}`,
              })
            }
          })
          const selectedPlanId = data.length > 0 ? data[0].id : null
          return selectedPlanId
        })
        .then(selectedPlanId => {
          this.fbaBatchParentItemWrapperFormData.planId = selectedPlanId
        })
    },
    mapProductCondition() {
      let { itemCondition } = this.fbaBatchParentItemWrapperFormData
      itemCondition = itemCondition || 'NEW'
      this.fbaBatchParentItemWrapperFormData.itemCondition = getAmazonKey(itemCondition)
    },
    handleSubmit(payload) {
      this.fbaBatchParentItemWrapperFormData.itemCondition = getLocalKey(this.fbaBatchParentItemWrapperFormData.itemCondition)
      this.fbaBatchParentItemWrapperFormData.msku = payload.msku
      this.fbaBatchParentItemWrapperFormData.items = payload.items
      this.fbaBatchParentItemWrapperFormData.charges = payload.charges
      this.fbaBatchParentItemWrapperFormData.listingItem = null
      this.fbaBatchParentItemWrapperFormData.bundleItems = payload.bundleItems
      this.$emit('on-emit-add-item-batch', this.fbaBatchParentItemWrapperFormData)
    },
  },
}
</script>

<style></style>
