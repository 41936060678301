<template>
  <dx-util-form
    ref="batchNewListingItemFormRef"
    :form-data="fbaBatchParentItemWrapperFormData"
    :col-count="1"
    :show-colon-after-label="true"
    label-mode="outside"
    label-location="top"
    :scrolling-enabled="true"
    @field-data-changed="onFieldDataChanged"
  >
    <dx-util-group-item :col-count="2">
      <dx-util-simple-item
        data-field="taxCode"
        editor-type="dxSelectBox"
        :editor-options="productTaxCodeOptions"
        :label="productTaxCodeItemOption.label"
      />
      <dx-util-item
        :visible="isServingTenant"
        data-field="planId"
        editor-type="dxSelectBox"
        :editor-options="planOptions"
        :label="{ text: 'User Plan' }"
      />
    </dx-util-group-item>
    <dx-util-group-item :col-count="3">
      <dx-util-simple-item
        data-field="upc"
        editor-type="dxTextBox"
        :label="{ text: 'UPC' }"
      />
      <dx-util-simple-item
        :visible="isCreateAndAddMissingItems"
        data-field="trackingNumber"
        editor-type="dxTextBox"
        :label="{ text: 'Tracking Number' }"
      />
      <dx-util-simple-item
        :visible="isCreateAndAddMissingItems"
        data-field="orderNumber"
        editor-type="dxTextBox"
        :label="{ text: 'Order Number' }"
      />
    </dx-util-group-item>
    <dx-util-group-item :col-count="3">
      <dx-util-simple-item
        data-field="batteriesRequired"
        editor-type="dxRadioGroup"
        :editor-options="batteriesRequiredOptions"
        :label="batteriesRequiredItemOption.label"
      />
      <dx-util-simple-item
      :col-span="2"
      data-field="supplierDeclaredDgHzRegulation"
      editor-type="dxSelectBox"
      :editor-options="supplierDeclaredDgHzRegulationOptions"
      :label="supplierDeclaredDgHzRegulationItemOption.label"
    />
    </dx-util-group-item>
    <dx-util-group-item :col-count="4">
      <dx-util-simple-item
        data-field="quantity"
        editor-type="dxNumberBox"
        :editor-options="quantityOptions"
        :label="{text: isPackTypeCasePack ? 'Quantity in Each Case' : 'Quantity' }"
      />
      <dx-util-item
        :visible="isPackTypeCasePack"
        data-field="quantityPerCase"
        editor-type="dxNumberBox"
        :editor-options="quantityPerCaseOptions"
        :label="{text: 'Number of Cases' }"
      />
    <dx-util-simple-item
      :visible="isPackTypeCasePack"
      :label="{text: 'Added / Required'}"
    >
      <template #default>
        <div class="d-flex flex-row">
          <div class="align-self-center text-white rounded-left px-half badge-item w-50" :class="casePackInfoItemClass">
            {{ casePackQuantity }}
          </div>
          <div class="align-self-center bg-success text-white rounded-right px-half badge-item w-50">
            {{ (fbaBatchParentItemWrapperFormData.quantity * fbaBatchParentItemWrapperFormData.quantityPerCase) }}
          </div>
        </div>
      </template>
    </dx-util-simple-item>
    </dx-util-group-item>
    <dx-util-group-item :col-count="4">
      <dx-util-item
        :col-span="2"
        data-field="packType"
        editor-type="dxRadioGroup"
        :editor-options="packTypeOptions"
      />
      <dx-util-simple-item
        data-field="cost"
        editor-type="dxNumberBox"
        :editor-options="costOptions"
      />
      <dx-util-simple-item
        data-field="salePrice"
        editor-type="dxNumberBox"
        :editor-options="salePriceOptions"
      />
    </dx-util-group-item>
    <dx-util-group-item :col-count="4">
      <dx-util-simple-item
        data-field="itemCondition"
        editor-type="dxSelectBox"
        :col-span="1"
        :editor-options="conditionTypeOptions"
        :label="conditionTypeItemOption.label"
      />
      <dx-util-simple-item
        data-field="expirationDate"
        editor-type="dxDateBox"
        :col-span="1"
        :editor-options="expirationDateOptions"
        :label="expirationDateItemOption.label"
      />
      <dx-util-simple-item
        data-field="supplier"
        editor-type="dxTextBox"
        :col-span="1"
        :label="supplierItemOption.label"
      />
      <dx-util-simple-item
        data-field="productType"
        :col-span="1"
        :editor-options="productTypeOptions"
        editor-type="dxTextBox"
      />
    </dx-util-group-item>
    <dx-util-simple-item
      :visible="showOfferConditionNote"
      data-field="notes"
      editor-type="dxTextArea"
      :editor-options="conditionNoteOptions"
      :label="conditionNoteItemOption.label"
    />
  </dx-util-form>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/require-default-prop */

import store from '@/store'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import planManagementService from '@/http/requests/plan/planManagementService'
import RadioGroup from 'devextreme/ui/radio_group'
import { Notify } from '@robustshell/utils'
import { isEmpty, find } from 'lodash'
import {
  quantityField,
  productTaxCodeField,
  productTaxCodeEnum,
  conditionTypeField,
  conditionNoteField,
  batteriesRequiredField,
  batteriesRequiredEnum,
  supplierDeclaredDgHzRegulationField,
  supplierDeclaredDgHzRegulationEnum,
} from '@/libs/amazon/catalog-item-properties'
import PlanCategoryEnum from '@/enums/plan-category.enum'
import conditionTypeEnum, { getAmazonKey, getLocalKey, getConditionTypeEnumList } from '@/enums/conditionTypeEnum'
import packingTypeEnum from '@/enums/packingTypeEnum'
import PackTypeEnum from '@/enums/packTypeEnum'
import ShipmentChannelEnum from '@/enums/shipment-channel.enum'
import useCurrentUser from '@/libs/app/current-user'
import fbaShipment from '../fbaShipment.mixin'
import CreateItemTypeEnum from '../createItemType'
import AmazonMarketPlaceEnum from './amazon-market-place.enum.js'

export default {
  components: {
    RadioGroup,
  },
  mixins: [fbaShipment],
  props: {
    onEmitHandleSubmitEvent: Function,
    casePackQuantity: {
      type: Number,
      required: false,
      default: 0,
    },
    fbaBatchParentItemWrapperFormData: {
      type: Object,
      default: () => ({
        id: null,
        companyId: null,
        warehouseId: null,
        storeId: null,
        batchParentId: null,
        quantity: 0,
        quantityPerCase: 0,
        missingItemQuantity: 0,
        countPerBundle: 0,
        productId: null,
        mskuFnskuPairId: null,
        cost: 0,
        salePrice: 0,
        supplier: '',
        title: '',
        upc: '',
        asin: '',
        msku: '',
        fnsku: '',
        itemCondition: 'NEW',
        planId: null,
        expirationDate: null,
        taxCode: '',
        listingItem: '',
        notes: '',
        packType: '',
        productType: '',
        createType: '',
        supplierDeclaredDgHzRegulation: 'not_applicable',
        batteriesRequired: false,
        bundleItems: [],
        isMultipleItem: false,
        orderNumber: '',
        trackingNumber: '',
        palletId: '',
      }),
    },
    productDefinition: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    channel: {
      type: String,
      required: false,
      default: '',
    },
    shipmentPlanId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      rules: {
        notes: {
          isReadonly: true,
        },
      },
      plans: [],
      packTypes: [],
      isMultipleItem: false,
    }
  },
  setup() {
    const { isServingTenant } = useCurrentUser()
    return {
      isServingTenant,
    }
  },
  computed: {
    isCreateAndAddMissingItems() {
      return this.fbaBatchParentItemWrapperFormData.createType === CreateItemTypeEnum.create_and_add_missing_items.value || this.fbaBatchParentItemWrapperFormData.createType === CreateItemTypeEnum.list_create_items.value
    },
    showOfferConditionNote() {
      return this.fbaBatchParentItemWrapperFormData.itemCondition !== conditionTypeEnum.NEW.key
    },
    headerInfo() {
      return store.getters['fbaShipment/getSelectedBactInfo']
    },
    planOptions() {
      return {
        dataSource: this.plans,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
      }
    },
    isPackTypeCasePack() {
      const selectedBacth = store.getters['fbaShipment/getSelectedBactInfo']
      const result = selectedBacth.packingType === packingTypeEnum.case_packed.key
      return result
    },
    quantityOptions() {
      const isReadonly = this.fbaBatchParentItemWrapperFormData.createType === CreateItemTypeEnum.list.value && !this.isPackTypeCasePack
      return {
        min: 0,
        max: Number.MAX_VALUE,
        readOnly: isReadonly,
        inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
      }
    },
    quantityPerCaseOptions() {
      return {
        inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
      }
    },
    quantityItemOption() {
      return {
        label: {
          text: quantityField.title,
        },
        helpText: quantityField.description,
      }
    },
    costOptions() {
      return {
        format: '$ #,##0.##',
      }
    },
    productTypeOptions() {
      return {
        readOnly: true,
      }
    },
    salePriceOptions() {
      return {
        format: '$ #,##0.##',
      }
    },
    expirationDateOptions() {
      return {
        type: 'date',
        displayFormat: 'MM/dd/yyyy',
        pickerType: 'calendar',
        min: new Date(),
        onValueChanged: e => {
          this.fbaBatchParentItemWrapperFormData.expirationDate = e.value
        },
      }
    },
    expirationDateItemOption() {
      return {
        label: {
          text: 'Expiration',
        },
      }
    },
    supplierItemOption() {
      return {
        label: {
          text: 'Supplier',
        },
      }
    },
    productTaxCodeOptions() {
      return {
        dataSource: productTaxCodeEnum,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: true,
      }
    },
    productTaxCodeItemOption() {
      return {
        label: {
          text: productTaxCodeField.title,
        },
        helpText: productTaxCodeField.description,
      }
    },
    batteriesRequiredOptions() {
      return {
        dataSource: batteriesRequiredEnum,
        valueExpr: 'value',
        displayExpr: 'text',
        layout: 'horizontal',
      }
    },
    batteriesRequiredItemOption() {
      return {
        label: {
          text: batteriesRequiredField.title,
        },
        helpText: batteriesRequiredField.description,
      }
    },
    supplierDeclaredDgHzRegulationOptions() {
      return {
        dataSource: supplierDeclaredDgHzRegulationEnum,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
      }
    },
    supplierDeclaredDgHzRegulationItemOption() {
      return {
        label: {
          text: supplierDeclaredDgHzRegulationField.title,
        },
        helpText: supplierDeclaredDgHzRegulationField.description,
      }
    },
    conditionTypeOptions() {
      return {
        dataSource: getConditionTypeEnumList(),
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: true,
        onValueChanged: e => {
          this.onChangeConditionType(e)
        },
      }
    },
    conditionTypeItemOption() {
      return {
        label: {
          text: conditionTypeField.title,
        },
        helpText: conditionTypeField.description,
      }
    },
    conditionNoteOptions() {
      return {
        readOnly: this.rules.notes.isReadonly,
      }
    },
    conditionNoteItemOption() {
      return {
        label: {
          text: conditionNoteField.title,
        },
        helpText: conditionNoteField.description,
      }
    },
    packTypeOptions() {
      return {
        dataSource: this.packTypes,
        valueExpr: 'value',
        displayExpr: 'text',
        layout: 'horizontal',
        onValueChanged: e => {
          this.onChangePackType(e)
        },
      }
    },
    casePackInfoItemClass() {
      const totalCaseQuantity = this.fbaBatchParentItemWrapperFormData.quantity * this.fbaBatchParentItemWrapperFormData.quantityPerCase
      return this.casePackQuantity === totalCaseQuantity ? 'bg-success' : 'bg-warning'
    },
  },
  watch: {
    fbaBatchParentItemWrapperFormData: {
      immediate: true,
      handler(current, previous) {
        this.isCreateAndAddMissingItems()
        this.fbaBatchParentItemWrapperFormData.quantityPerCase = typeof (previous) !== 'undefined' ? previous.quantityPerCase : current.quantityPerCase
        this.fbaBatchParentItemWrapperFormData.salePrice = current.salePrice ? current.salePrice : 0
      },
    },
    casePackQuantity: {
      immediate: true,
      handler(current, previous) {
      },
    },
  },
  mounted() {
    Object.keys(this.$enums.PackTypeEnum).map(key => this.packTypes.push({
      value: this.$enums.PackTypeEnum[key].value,
      text: this.$enums.PackTypeEnum[key].text,
    }))
    this.onEmitHandleSubmitEvent(this.handleSubmit)
    this.getPlans()
  },
  beforeUpdate() {
    this.repaintForm()
  },
  methods: {
    getUserData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    getPlans() {
      const planCategory = this.channel === ShipmentChannelEnum.FBA.key ? PlanCategoryEnum.FBA.key : PlanCategoryEnum.FBM.key
      const userData = this.getUserData()
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.warehouseId = userData.warehouse.id
      PlanSearchFilter.companyId = this.headerInfo.companyId
      PlanSearchFilter.planCategory = planCategory
      const searchFilter = PlanSearchFilter.getFilters()
      const pageable = 'page=0&size=200'
      planManagementService
        .fetchAllUserPlansByQuery(searchFilter, pageable)
        .then(result => {
          const data = result.data.content
          return data
        })
        .then(data => {
          data.forEach(element => {
            if (element.companyId === this.headerInfo.companyId) {
              this.plans.push({
                value: element.id,
                text: `${element.planCategory.toUpperCase()}:${
                  element.planName
                }:${element.planType}`,
              })
            }
          })
          const selectedPlanId = data.length > 0 ? data[0].id : null
          return selectedPlanId
        })
        .then(selectedPlanId => {
          this.fbaBatchParentItemWrapperFormData.planId = selectedPlanId
        })
    },
    repaintForm() {
      try {
        const form = this.$refs.batchNewListingItemFormRef.instance
        form.repaint()
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    onChangeConditionType(e) {
      const itemCondition = getAmazonKey(this.fbaBatchParentItemWrapperFormData.itemCondition)

      this.rules.notes.isReadonly = e.value === conditionTypeEnum.NEW.key
      this.fbaBatchParentItemWrapperFormData.notes = ''
    },
    onChangePackType(e) {
      this.$emit('on-emit-changed-pack-type', e.value)
      if (e.value === 'individual') {
        this.fbaBatchParentItemWrapperFormData.countPerBundle = 0
        this.isMultipleItem = false
        this.fbaBatchParentItemWrapperFormData.isMultipleItem = false
        this.$emit('on-emit-changed-multiple-item', false)
      }
      if (e.value === PackTypeEnum.bundle.value || e.value === PackTypeEnum.multi_pack.value) {
        this.isMultipleItem = true
        this.fbaBatchParentItemWrapperFormData.isMultipleItem = true
        this.$emit('on-emit-changed-multiple-item', true)
      }
    },
    handleSubmit(payload) {
      const id = this.shipmentPlanId
      fbaShipmentService.findBatchParentById(id)
        .then(response => {
          const batchParent = response.data
          const countryCode = batchParent.shipToCountryCode
          const amazonMarketPlace = AmazonMarketPlaceEnum.find(countryCode)
          const { marketPlaceId, currencyCode } = amazonMarketPlace
          this.fbaBatchParentItemWrapperFormData.msku = payload.msku
          this.fbaBatchParentItemWrapperFormData.items = payload.items
          this.fbaBatchParentItemWrapperFormData.charges = payload.charges
          this.fbaBatchParentItemWrapperFormData.bundleItems = payload.bundleItems
          this.fbaBatchParentItemWrapperFormData.listingItem = this.createListingItem(marketPlaceId, currencyCode)
        })
        .then(() => {
          this.$emit('on-emit-add-item-batch', this.fbaBatchParentItemWrapperFormData)
        })
    },
    onFieldDataChanged(e) {
      this.$emit('on-emit-update-form-data', e.component.option('formData'))
    },
    createListingItem(marketPlaceId, currencyCode) {
    // force mapping psh item condition to amazon item condition
      const itemCondition = getAmazonKey(this.fbaBatchParentItemWrapperFormData.itemCondition)

      let productType = this.fbaBatchParentItemWrapperFormData.productType
      if (typeof productType === 'undefined' || productType === '' || productType === null || productType === 'null') {
        productType = 'PRODUCT'
      }

      const listingsItemPutRequest = {
        productType: productType,
        requirements: 'LISTING_OFFER_ONLY',
        attributes: null,
      }
      const listingAttributes = {
        merchant_suggested_asin: [
          { marketplace_id: marketPlaceId, value: this.fbaBatchParentItemWrapperFormData.asin },
        ],
        condition_type: [
          { marketplace_id: marketPlaceId, value: itemCondition },
        ],
        purchasable_offer: [
          {
            marketplace_id: marketPlaceId,
            currency: currencyCode,
            our_price: [
              {
                schedule: [{
                  value_with_tax: this.fbaBatchParentItemWrapperFormData.salePrice.toString(10),
                }],
              },
            ],
          },
        ],
      }
      // this is for the channel fba
      if (this.channel === 'fba') {
        listingAttributes.fulfillment_availability = [
          { fulfillment_channel_code: 'AMAZON_NA' },
        ]
      }
      // this is for the channel mfn
      if (this.channel === 'mfn') {
        listingAttributes.fulfillment_availability = [
          {
            fulfillment_channel_code: 'DEFAULT',
            quantity: this.fbaBatchParentItemWrapperFormData.quantity,
          },
        ]
      }

      let price = this.fbaBatchParentItemWrapperFormData.salePrice * 1.25
      price = price.toFixed(2)
      listingAttributes.list_price = [
        {
          currency: currencyCode,
          marketplace_id: marketPlaceId,
          value: price,
        },
      ]

      const isBattaeriesRequired = this.isBattaeriesRequired()
      if (isBattaeriesRequired) {
        listingAttributes.batteries_required = [
          { marketplace_id: marketPlaceId, value: this.fbaBatchParentItemWrapperFormData.batteriesRequired },
        ]
      }

      const isSupplierDeclaredDgHzRegulation = this.isSupplierDeclaredDgHzRegulation()
      if (isSupplierDeclaredDgHzRegulation) {
        listingAttributes.supplier_declared_dg_hz_regulation = [
          { marketplace_id: marketPlaceId, value: this.fbaBatchParentItemWrapperFormData.supplierDeclaredDgHzRegulation },
        ]
      }

      if (itemCondition !== conditionTypeEnum.NEW.amazonKey) {
        listingAttributes.condition_note = [{ marketplace_id: marketPlaceId, value: this.fbaBatchParentItemWrapperFormData.notes }]
      }
      listingsItemPutRequest.attributes = listingAttributes
      return listingsItemPutRequest
    },
    isBattaeriesRequired() {
      if (!isEmpty(this.productDefinition) && !isEmpty(this.productDefinition.propertyGroups) && !isEmpty(this.productDefinition.propertyGroups.safety_and_compliance)) {
        const batteriesRequired = find(this.productDefinition.propertyGroups.safety_and_compliance.propertyNames, item => item === 'batteries_required')
        if (batteriesRequired) {
          return true
        }
      }
      return false
    },
    isSupplierDeclaredDgHzRegulation() {
      if (!isEmpty(this.productDefinition) && !isEmpty(this.productDefinition.propertyGroups) && !isEmpty(this.productDefinition.propertyGroups.safety_and_compliance)) {
        const supplierDeclaredDgHzRegulation = find(this.productDefinition.propertyGroups.safety_and_compliance.propertyNames, item => item === 'supplier_declared_dg_hz_regulation')
        if (supplierDeclaredDgHzRegulation) {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style></style>
