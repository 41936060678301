import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const errorReasonEnum = Object.freeze({
  DOES_NOT_EXIST: { key: 'DOESNOTEXIST', value: 'Does Not Exist', text: 'Indicates that the ASIN is not included in the Amazon product catalog for any of the marketplaces that the seller participates in.' },
  INVALID_ASIN: { key: 'INVALIDASIN', value: 'Invalid Asin', text: 'The ASIN is invalid.' },
})

function getErrorReasonEnum(predicate, predicateKey = 'key') {
  const result = find(errorReasonEnum, { [predicateKey]: predicate })
  return result || { value: 'None', text: 'None' }
}

export default errorReasonEnum

export {
  getErrorReasonEnum,
}
