<template>
  <div class="w-100 p-2">
    <dx-util-validation-group class="d-flex align-items-center">
      <dx-util-text-box v-model="form.partnerId" :label="'Client ID'" class="mx-half" width="300">
        <dx-util-validator>
          <dx-util-required-rule message="Client ID is required" />
        </dx-util-validator>
      </dx-util-text-box>
      <dx-util-text-box v-model="form.refreshToken" :label="'Client Secret'" class="mx-half" width="300">
        <dx-util-validator>
          <dx-util-required-rule message="Client secret is required" />
        </dx-util-validator>
      </dx-util-text-box>
      <div class="text-right pt-half">
        <dx-util-button text="Save" type="success" style="width:130px" @click="handleSubmit" />
      </div>
    </dx-util-validation-group>
    <div class="mt-2">
      <span class="text-warning">How to get Walmart Client ID and Client Secret?</span>
      <div class="text-info">
        <ol>
          <li>
            <div class="py-1">
              <a target="_blank" href="https://seller.walmart.com/api-key">
                Go to Walmart Seller Central - API Key Management page under Settings
                <p-icon name="bi-box-arrow-up-right" color="info" />
              </a>
            </div>
          </li>
          <li>
            <div class="py-1">
              <a target="_blank" href="https://developer.walmart.com/account/generateKey">
                Click on "API Key Management" button
                <p-icon name="bi-box-arrow-up-right" color="info" />
              </a>
            </div>
          </li>
          <li>
            <div class="py-1">
              Click on "+ Add New Key For A Solution Provider" button, then Select PrepShipHub as Solution Provider
            </div>
          </li>
          <li>
            <div class="py-1">
              Select "Full Access" for all categories. Finally click on "Submit" to generate credentials
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import { v4 as uuidv4 } from 'uuid'
import wfsService from '@/http/requests/wfs/index'
import { Notify } from '@/@robustshell/utils'
import useStoreSettings from './useStoreSettings'

export default {
  data() {
    return {
      form: {
        storeId: '',
        partnerId: '',
        refreshToken: '',
        apiAuthCode: '',
      },
    }
  },
  setup() {
    const { currentStore } = useStoreSettings()
    return {
      currentStore,
    }
  },
  methods: {
    async handleSubmit(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        this.form.storeId = this.currentStore.id
        this.form.apiAuthCode = uuidv4()
        try {
          await wfsService.createAuthInStoresTab(this.form)
        } catch (error) {
          Notify.error(error)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
