export function useFormDataMissingDimensions() {
  const formItems = [
    {
      isRequired: true,
      editorType: 'dxNumberBox',
      dataField: 'weightPounds',
      name: 'weightPounds',
      label: { text: 'Weight (Pounds)' },
    },
    {
      isRequired: true,
      editorType: 'dxNumberBox',
      dataField: 'weightOunces',
      name: 'weightOunces',
      label: { text: 'Weight (Ounces)' },
    },
    {
      isRequired: true,
      editorType: 'dxNumberBox',
      dataField: 'length',
      name: 'length',
      label: { text: 'Length (In)' },
    }, {
      isRequired: true,
      editorType: 'dxNumberBox',
      dataField: 'width',
      name: 'width',
      label: { text: 'Width (In)' },
    }, {
      isRequired: true,
      editorType: 'dxNumberBox',
      dataField: 'height',
      name: 'height',
      label: { text: 'Height (In)' },
    },
  ]
  return formItems
}

export function useFormDataSetMissingDimensions(length, width, height, weight, weightUnit, storeId, itemId, productType, msku) {
  const listingItemRequest = {
    storeId: storeId,
    itemId: itemId,
    msku: msku,
    putRequest: null,
    patchRequest: {
      productType: productType,
      patches: [
        {
          op: 'ADD',
          path: '/attributes/item_package_weight',
          value: [
            {
              marketplace_id: 'ATVPDKIKX0DER',
              unit: weightUnit,
              value: weight,
            },
          ],
        },
        {
          op: 'ADD',
          path: '/attributes/item_package_dimensions',
          value: [
            {
              marketplace_id: 'ATVPDKIKX0DER',
              height: {
                unit: 'inches',
                value: height,
              },
              length: {
                unit: 'inches',
                value: length,
              },
              width: {
                unit: 'inches',
                value: width,
              },
            },
          ],
        },
        {
          op: 'ADD',
          path: '/attributes/item_weight',
          value: [
            {
              marketplace_id: 'ATVPDKIKX0DER',
              unit: weightUnit,
              value: weight,
            },
          ],
        },
      ],
    },
  }
  return listingItemRequest
}
