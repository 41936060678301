<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="contentStatus" ref="searchItemRef" class="col-12 px-0">
        <batch-new-item
          :search-params="searchParams"
          @add-item-batch="onAddItemBatch"
          @on-emit-delete-item-batch="onEmitDeleteItemBatch"
          @on-emit-submit-batch-item-quantity="onEmitSubmitBatchItemQuantity"
          @cancel-add-item-batch="onCancelAddItemBatch"
        />
      </div>
      <div v-else class="col-12 px-0">
        <dx-util-text-box
            id="searchTextBox"
            ref="searchTextBoxRef"
            v-model="searchText"
            :value="searchText"
            name="txtBoxSearch"
            placeholder="Search in shipment plan"
            :show-clear-button="true"
            @value-changed="getSearchByFilter"
            @key-down="getSearchByFilter"
          >
            <dx-text-box-button
              id="searchTextBoxButton"
              :options="searchButtonOptions"
              name="searchTextBoxButton"
              location="before"
              css-class="btn-default"
            />
          </dx-util-text-box>
        <dx-data-grid
          id="batchItemGrid"
          ref="batchItemGridRef"
          :height="getBatchItemGridHeight"
          :data-source="batchItems"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @content-ready="onContentReady"
          @row-prepared="rowPrepared"
        >
          <dx-paging :enabled="false" />
          <dx-state-storing
            :enabled="true"
            type="custom"
            :custom-load="loadDataGridState"
            :custom-save="saveDataGridState"
          />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="175" :allow-resizing="false" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="default"
                icon="bi bi-chevron-down"
                class="mr-half"
                hint="Decrement Quantity"
                :disabled="isClosedBatch"
                @click="onClickEditBatchItemQuantity(data)"
              />
              <dx-util-button
                type="warning"
                icon="bi bi-pencil-square"
                class="mr-half"
                hint="Edit Item Properties"
                :disabled="isClosedBatch"
                @click="onClickEditBatchItemInfo(data)"
              />
              <dx-util-button
                :visible="isVisibleChannelAction"
                type="success"
                icon="bi bi-coin"
                class="mr-half"
                hint="Charge Details"
                @click="onClickAddCharges(data)"
              />
              <dx-util-button
                type="danger"
                icon="bi bi-trash-fill"
                hint="Delete"
                :disabled="isClosedBatch"
                @click="onClickDeleteBatchItem(data)"
              />
            </div>
          </template>
          <dx-column data-field="catalogItem" caption="Item" cell-template="item-cell-template" />
          <template #item-cell-template="{ data }">
            <item-info
              :id="data.row.data.productId"
              :title="data.row.data.title"
              :asin="data.row.data.asin"
              :upc="data.row.data.upc"
              :msku="data.row.data.msku"
              :fnsku="data.row.data.fnsku"
              :expiration-date="data.row.data.expirationDate"
              :rank="data.row.data.amzRank"
              :product-type="data.row.data.productType"
              :image-url="data.row.data.smallImage"
              :cost-price="data.row.data.itemCost"
              :supplier="data.row.data.supplier"
              :large-image-url="data.row.data.largeImage"
              :is-printable="false"
              :is-truncated="true"
              :item-info-visible="false"
              @on-emit-catalog-item-info="onEmitCatalogItemInfo"
            />
          </template>
          <dx-column data-field="supplier" caption="Supplier" :width="110" />
          <dx-column data-field="itemCondition" caption="Condition" :width="110" />
          <dx-column data-field="packType" caption="Packing" :width="100" cell-template="cellPackType" />
          <template #cellPackType="{ data }">
            <span
              v-if="data.value === 'individual'"
              v-b-tooltip.d800.hover.left.v-secondary="'Individual pack'"
              class="d-block badge badge-light-secondary"
              >
              Single
            </span>
            <span
              v-else-if="data.value === 'bundle'"
              v-b-tooltip.d800.hover.left.v-secondary="'Bundle'"
              class="d-block badge badge-light-secondary"
              >
              Bundle
            </span>
            <span
              v-else-if="data.value === 'multi_pack'"
              v-b-tooltip.d800.hover.left.v-secondary="'Multi Pack'"
              class="d-block badge badge-light-secondary"
              >
              MP
            </span>
          </template>
          <dx-column data-field="errors" width="90" alignment="left" cell-template="errors-cell-template" />
          <template #errors-cell-template="{data}">
            <div class="d-flex flex-row">
              <div v-if="renderErrors(data.value).key === errorTypeEnum.DONE.key" class="align-self-center bg-success text-white rounded badge-item" style="width: 100%;">
                {{ renderErrors(data.value).text }}
              </div>
              <div v-else class="align-self-center">
              <dx-util-button
                :text="renderErrors(data.value).text"
                type="danger"
                icon="edit"
                styling-mode="contained"
                :element-attr="btnElementAttr"
                @click="onClickHandleBatchItemError(data.data, renderErrors(data.value).key)"
              />
              </div>
            </div>
          </template>
          <dx-column data-field="quantity" width="140" :caption="isCasePacked ? 'Qty/Case Qty/Total' : 'Quantity'" alignment="left" cell-template="quantity-cell-template" />
          <template #quantity-cell-template="{data}">
          <div v-if="isCasePacked" class="d-flex flex-row mb-half">
            <div v-b-tooltip.hover="{ variant: 'info' }" title="Quantity in Each Case" class="align-self-center bg-secondary text-white rounded-left px-half badge-item" style="width: 48px;">
              {{ data.row.data.quantityPerCase }}
            </div>
            <div v-b-tooltip.hover="{ variant: 'info' }" title="Number of Cases" class="align-self-center bg-primary text-white rounded-0 px-half badge-item" style="width: 48px;">
              {{ data.row.data.quantity / data.row.data.quantityPerCase }}
            </div>
            <div v-b-tooltip.hover="{ variant: 'info' }" title="Total Quantity" class="align-self-center bg-success text-white rounded-right px-half badge-item" style="width: 48px;">
              {{ data.value }}
            </div>
          </div>
          <div v-else>
            <div v-b-tooltip.hover="{ variant: 'info' }" title="Quantity" class="align-self-center bg-success text-white rounded px-half badge-item" style="width: 48px;">
              {{ data.value }}
            </div>
          </div>
          </template>
          <dx-column :visible="isVisibleChannelAction" data-field="destinations" alignment="left" :width="140" cell-template="destination-cell-template" />
          <template #destination-cell-template="{data}">
            <div class="d-flex flex-column">
              <div v-for="(dest,index) in renderDestinations(data.value)" :key="index" class="d-flex flex-row mb-half">
                <div v-b-tooltip.hover="{ variant: 'info' }" title="Destination" class="align-self-center bg-secondary text-white rounded-left px-half badge-item" style="width: 100%;">
                  {{ dest.dest }}
                </div>
                <div class="align-self-center bg-success rounded-right px-half text-white badge-item" style="width: 64px;">
                  {{ dest.quantity }}
                </div>
              </div>
            </div>
          </template>
          <!--region    ::DataGird actions -->
          <!-- TODO: conversion b-dropdown to dx-select ? -->
          <dx-column :visible="isVisibleChannelAction" data-field="fnsku" caption="FNSKU" :width="220" alignment="center" cell-template="title-cell-template" />
          <template #title-cell-template="{ data }">
            <div class="d-flex flex-row">
            <b-dropdown
              split
              size="sm"
              split-variant="primary"
              variant="warning"
              :text="data.value"
              boundary="window"
              class="mr-half"
              block
              @click="onClickPrint(data, 1)"
            >
              <b-dropdown-header id="dropdown-header-label">
                {{ data.value }}
              </b-dropdown-header>
              <b-dropdown-form style="width:172px !important;">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="printCount"
                    type="number"
                    autofocus
                    @keydown.native="onEnterPrint($event, data)"
                  />
                  <b-input-group-append>
                    <b-button size="sm" variant="warning" @click="onClickPrint(data)">
                      <b-icon-printer-fill scale="1" aria-label="Help" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-dropdown-form>
            </b-dropdown>
            <b-button size="sm" variant="primary" @click="onClickDownloadLable(data)">
                  <i class="bi bi-cloud-arrow-down-fill" style="font-size: 1.4rem;"></i>
                </b-button>
              </div>
          </template>
          <dx-column data-field="planName" caption="Plan" :width="160" cell-template="planSelectionTemplate" />
          <template #planSelectionTemplate="{ data }">
            <dx-util-button
              :text="data.value ? data.value : 'Select a plan'"
              :hint="data.value ? data.value : 'Select a plan'"
              type="default"
              styling-mode="filled"
              :element-attr="{ class: 'btn-block' }"
              :disabled="isClosedBatch"
              @click="openPlanSelector(data.data)"
            />
          </template>
          <dx-column data-field="creationTime" caption="Added At" data-type="date" :width="110" :allow-sorting="false" />
          <!--endregion ::DataGrid actions -->
        </dx-data-grid>
        <dx-util-popup
          ref="editBatchItemQuantityPopupRef"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-close-button="true"
          :show-title="true"
          :width="480"
          :height="300"
          title="Decrement Quantity"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item toolbar="bottom" location="after" template="closePopupButton" />
          <template #closePopupButton>
            <dx-util-button id="cancelSubmitBatchItemQuantityButton" text="Cancel" type="danger" @click="onCancelSubmitBatchItemQuantity()" />
          </template>
          <dx-util-toolbar-item toolbar="bottom" location="after" template="acceptPopupButton" />
          <template #acceptPopupButton>
            <dx-util-button id="submitBatchItemQuantityButton" text="Update" type="success" @click="onSubmitBatchItemQuantity()" />
          </template>
          <dx-util-form ref="batchItemQuantityFormDataRef" :form-data="batchItemQuantityFormData" :col-count="1" :show-colon-after-label="true" label-location="top">
            <dx-util-simple-item
              data-field="newQuantity"
              :editor-options="quantityOptions"
              editor-type="dxNumberBox"
              :label="{text: batchItemQuantityFormData.isCasePack ? 'New Quantity in Each Case' : 'New Quantity'}"
            >
                <dx-util-required-rule message="This field is required" />
            </dx-util-simple-item>
            <dx-util-simple-item
              data-field="newQuantityPerCase"
              :visible="batchItemQuantityFormData.isCasePack"
              :editor-options="quantityPerCaseOptions"
              editor-type="dxNumberBox"
              :label="{text: 'New Number of Cases'}"
            >
                <dx-util-required-rule message="This field is required" />
            </dx-util-simple-item>
          </dx-util-form>
        </dx-util-popup>
        <dx-util-popup
          ref="editBatchItemInfoPopupRef"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-close-button="true"
          :show-title="true"
          :width="480"
          :height="360"
          title="Edit Item Info"
          @hiding="onHidingBatchItemInfoPopup"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item toolbar="bottom" location="after" template="closePopupButton" />
          <template #closePopupButton>
            <dx-util-button id="cancelSubmitBatchItemInfoButton" text="Cancel" type="danger" @click="onCancelSubmitBatchItemInfo()" />
          </template>
          <dx-util-toolbar-item toolbar="bottom" location="after" template="acceptPopupButton" />
          <template #acceptPopupButton>
            <dx-util-button id="submitBatchItemInfoButton" text="Update" type="success" @click="onSubmitBatchItemInfo()" />
          </template>
          <dx-util-form ref="batchItemInfoFormDataRef" :form-data="batchItemInfoFormData" :col-count="1" :show-colon-after-label="true" label-location="top">
            <dx-util-simple-item data-field="supplier" editor-type="dxTextBox">
                <dx-util-required-rule message="This field is required" />
            </dx-util-simple-item>
            <dx-util-group-item :col-count="2">
              <dx-util-simple-item data-field="cost" editor-type="dxNumberBox" :editor-options="{format: {type:'currency', precision: 2}}">
                  <dx-util-required-rule message="This field is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="salePrice" editor-type="dxNumberBox" :editor-options="{format: {type:'currency', precision: 2}}">
                  <dx-util-required-rule message="This field is required" />
              </dx-util-simple-item>
            </dx-util-group-item>
            <dx-util-simple-item data-field="expirationDate" editor-type="dxDateBox" />
          </dx-util-form>
        </dx-util-popup>
      </div>
      <div>
        <batch-item-error-handler :store-id="storeId" />
      </div>
    </div>
    <div>
      <dx-util-popup
        id="popupCharges"
        ref="popupChargesRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :height="720"
        content-template="popup-content"
        title="Shipment Item Charges"
        @showing="onShowingChargesPopup"
        @hidden="onHidingChargesPopup"
      >
        <dx-util-position at="center" my="center" />
        <template #popup-content>
          <charges v-if="charges.isCreated" :item-id="charges.itemId" :products="selectedProduct" :load-with-item-id="true" item-type="product" @on-emit-get-charges="onEmitGetCharges" />
        </template>
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveChargesOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="closeChargesOptions" />
      </dx-util-popup>
    </div>
    <div>
      <!-- #region Delete Shipment Item Popup Title -->
      <dx-util-popup
        ref="deleteShipmentItemPopupRef"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-close-button="false"
        :show-title="false"
        title="Confirm Delete"
        :width="deleteConfirmationData.isMfn ? 640 : 480"
        :height="360"
        content-template="popup-content"
        @shown="onShownDeleteShipmentItemPopup"
        @hidden="onHiddenDeleteShipmentItemPopup"
      >
        <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveDeleteShipmentItemOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelDeleteShipmentItemOptions" />
        <template #popup-content>
           <div class="d-flex flex-column">
            <div class="text-center mb-1">
              <i class="bi bi-exclamation-circle text-danger display-4"></i>
            </div>
            <div class="text-center display-h-4 mb-1">
              Confirm Delete
            </div>
            <div class="display-h-5 text-center mb-1">
              {{ deleteConfirmationData.isMfn
                  ? 'Are you sure you want to delete this item from this Shipment Plan?'
                  : 'Are you sure you want to delete this item from this Shipment Plan? Once confirmed, this item will be removed from the Shipment Plan and the reserved items will be return to the inventory.'
              }}
            </div>
            <div class="mb-1">
              <dx-util-check-box
                id="deleteOnAmazon"
                v-model="deleteConfirmationData.deleteOnAmazon"
                text="Delete on Amazon"
                :visible="deleteConfirmationData.isMfn"
                @value-changed="onValueChangeDeleteOnAmazon"
              />
            </div>
            <div v-show="deleteConfirmationData.deleteOnAmazon" class="text-danger text-center mb-1">
              ATTENTION: This item listing will be deleted from Amazon completely!
            </div>
            <div>
              <dx-util-text-box
                id="confirmDelete"
                v-model="deleteConfirmationData.confirmDelete"
                :visible="deleteConfirmationData.deleteOnAmazon"
                placeholder="Type 'DELETE' to confirm"
              >
               <dx-util-validator>
                <dx-util-required-rule message="This field is required" />
                <dx-util-pattern-rule pattern="^DELETE$" message="Please type 'DELETE' to confirm" />
               </dx-util-validator>
              </dx-util-text-box>
            </div>
           </div>
        </template>
      </dx-util-popup>
      <!-- #endregion -->
    </div>
    <div>
      <plan-selector
        :show-plan-selector="isPlanSelectorVisible"
        :plan-id="parseInt(selectedPlan)"
        :product-id="parseInt(selectedProductId)"
        :company-id="parseInt(companyId)"
        :account-no="parseInt(accountNo)"
        :plan-category="fbaPlanCategory"
        :use-apply-all-plan="true"
        :use-product-plan="false"
        @close="onEmitPlanSelectorClosed"
        @planUpdated="onEmitPlanSelectorPlanUpdated"
      />
    </div>
    <div>
      <product-details
        :product-id="selectedProductIdDetail"
        :show-product="isVisibleProductDetail"
        @close="isVisibleProductDetail=false,selectedProductId=null"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import store from '@/store'
import { DataGridNamesEnum } from '@/enums'
import settingsService from '@/http/requests/common/settings'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import usePrinter from '@/libs/printer/print'
import { emitter } from '@/libs/bus'
import { FBAEvents } from '@/libs/constants/events.const'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import { Notify } from '@robustshell/utils/index'
import Charges from '@components/charge/Charges.vue'
import isNaN from 'lodash/isNaN'
import split from 'lodash/split'
import useDownloadLabel from '@/libs/printer/label-download'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import { getLocalText } from '@/enums/conditionTypeEnum'
import PlanCategoryEnum from '@/enums/plan-category.enum'
import { formatedIsoDateStr } from '@/@core/utils/dateHelper.js'
import ShipmentChannelEnum from '@/enums/shipment-channel.enum'
import BatchStatusEnum from '@/enums/batchStatusEnum'
import ProductDetails from '@/views/apps/product/products/components/ProductDetails.vue'
import PlanSelector from '@/views/apps/product/products/components/PlanSelector.vue'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import useCurrentUser from '@/libs/app/current-user'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import useFBAShipment from './fbaShipment'
import ErrorTypeEnum, { getErrorTypeEnum } from './error-type.enum'
import ItemInfo from './components/ItemInfo.vue'
import BatchItemErrorHandler from './components/BatchItemErrorHandler.vue'
import BatchNewItem from './components/BatchNewItem.vue'
import fbaShipment from './fbaShipment.mixin'

export default {
  beforeRouteUpdate(to, from, next) {
    this.channel = from.params.channel
    next()
  },
  components: {
    ProductDetails,
    BatchNewItem,
    ItemInfo,
    BatchItemErrorHandler,
    PlanSelector,
    DxTextBoxButton,
    Charges,
  },
  mixins: [GridBase, fbaShipment],
  props: {
    batchId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const {
      isCasePacked,
    } = useFBAShipment()
    const { downloadFnskuLabel } = useDownloadLabel()
    const { printFnskuLabel } = usePrinter()

    const {
      userCompanyId,
      userId,
    } = useCurrentUser()

    return {
      downloadFnskuLabel,
      printFnskuLabel,
      isCasePacked,
      userId,
      userCompanyId,
    }
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      errorTypeEnum: ErrorTypeEnum,
      selectedItem: {},
      searchText: '',
      batchItems: [],
      searchParams: {
        batchId: null,
        searchText: '',
        isUpc: false,
        isAsin: false,
        isKeywords: false,
      },
      batchItemQuantityFormData: {
        id: null,
        currentQuantity: 0,
        newQuantity: 0,
        currentQuantityPerCase: 0,
        newQuantityPerCase: 0,
        isCasePack: false,
      },
      batchItemInfo: {},
      batchItemInfoFormData: {
        supplier: '',
        cost: 0,
        salePrice: 0,
        expirationDate: null,
      },
      selectedBatchItem: {},
      contentStatus: false,
      printCount: 1,
      btnElementAttr: {
        class: 'btn-block',
      },
      catalogItem: {},
      charges: {
        itemId: null,
        isCreated: false,
      },
      selectedProduct: [],
      selectedAsin: null,

      selectedProductId: null,
      selectedItemId: null,
      selectedPlan: null,
      isPlanSelectorVisible: false,
      channel: '',
      fbaPlanCategory: PlanCategoryEnum.FBA.key,
      isVisibleProductDetail: false,
      selectedProductIdDetail: null,
      deleteConfirmationData: {
        id: null,
        isMfn: false,
        deleteOnAmazon: false,
        confirmDelete: '',
      },
      batchStatus: '',
    }
  },
  computed: {
    searchButtonOptions() {
      const self = this
      return {
        text: 'Search',
        type: 'default',
        icon: 'search',
        onClick: e => {
          self.$nextTick(() => {
            this.setSelectedBatchItem(this.batchId, this.searchText)
          })
        },
      }
    },
    isVisibleChannelAction() {
      return this.channel === ShipmentChannelEnum.FBA.value
    },
    dataGridInstance() {
      return this.$refs.batchItemGridRef.instance
    },
    storeId() {
      const { storeId } = store.getters['fbaShipment/getBatchAccountInfo']
      return storeId
    },
    accountNo() {
      const { accountNo } = store.getters['fbaShipment/getBatchAccountInfo']
      return accountNo
    },
    companyId() {
      const { companyId } = store.getters['fbaShipment/getBatchAccountInfo']
      return companyId
    },
    quantityOptions() {
      const max = this.batchItemQuantityFormData.isCasePack
        ? Number.MAX_SAFE_INTEGER
        : this.batchItemQuantityFormData.currentQuantity
      return {
        min: 1,
        max: max,
        step: 1,
        showSpinButtons: true,
      }
    },
    quantityPerCaseOptions() {
      return {
        min: 1,
        step: 1,
        showSpinButtons: true,
      }
    },
    getBatchItemGridHeight() {
      return this.batchItemGridHeight()
    },
    saveChargesOptions() {
      return {
        text: 'Save Charges',
        type: 'success',
        onClick: () => {
          emitter.emit('on-emit-set-charges')
        },
      }
    },
    closeChargesOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.popupChargesRef.instance
          popup.hide()
        },
      }
    },
    deleteShipmentItemPopupIns() {
      return this.$refs.deleteShipmentItemPopupRef.instance
    },
    saveDeleteShipmentItemOptions() {
      return {
        text: 'Delete',
        type: 'danger',
        onClick: () => {
          this.onHandleDeleteShipmentItem()
        },
      }
    },
    cancelDeleteShipmentItemOptions() {
      return {
        text: 'Cancel',
        type: 'default',
        onClick: () => {
          this.deleteShipmentItemPopupIns.hide()
        },
      }
    },
    isClosedBatch() {
      return this.batchStatus === BatchStatusEnum.closed.value
    },
  },
  watch: {
    batchId: {
      immediate: true,
      handler(current, previous) {
        this.contentStatus = false
        if (current && current > 0) {
          this.getCurrentShipmentPlan(current)
        }
      },
    },
  },
  created() {
    emitter.on(FBAEvents.BATCH_SEARCH_ITEM.event, payload => {
      this.setItemContentStatus(payload)
    })
    this.$watch(
      () => this.$route.params.channel,
      newVal => { this.channel = newVal },
      { immediate: true },
    )
  },
  methods: {
    onEmitGetCharges(payload) {
      const charges = payload
      itemChargeService.create(this.itemId, charges).then(() => {
        const popup = this.$refs.popupChargesRef.instance
        popup.hide()
      })
    },
    onClickAddCharges(e) {
      this.selectedProduct.length = 0
      const { data } = e.row
      this.itemId = data.id
      this.selectedProduct.push({
        id: data.id,
        text: `${data.asin}-${data.title}`,
      })
      const popup = this.$refs.popupChargesRef.instance
      this.charges.itemId = data.id
      popup.show()
    },
    onShowingChargesPopup() {
      this.charges.isCreated = true
    },
    onHidingChargesPopup() {
      this.charges = {
        itemId: null,
        isCreated: false,
      }
    },
    rowPrepared(e) {
      if (e.rowType === 'data') {
        const row = e.row
      }
    },
    renderErrors(error) {
      return getErrorTypeEnum(error)
    },
    renderDestinations(dest) {
      const destinations = split(dest, '#')
      const destinationsItems = []
      destinations.forEach(item => {
        const destination = split(item, ':')
        const destinationItem = {
          dest: destination[0],
          quantity: destination[1],
        }
        destinationsItems.push(destinationItem)
      })
      return destinationsItems
    },
    setItemContentStatus(payload) {
      this.contentStatus = payload.status
      const searchText = payload.searchText.trim()
      this.searchParams = {
        batchId: this.batchId,
        searchText: searchText,
        isUpc: payload.isUpc,
        isAsin: payload.isAsin,
        isKeywords: payload.isKeywords,
      }
    },

    getCurrentShipmentPlan(id) {
      fbaShipmentService.findBatchParentById(id).then(result => {
        const { data } = result
        this.batchStatus = data.batchStatus
      }).then(() => {
        this.setSelectedBatchItem(id)
      })
    },

    setSelectedBatchItem(batchId, q = '') {
      const parsed = parseInt(batchId, 10)
      if (isNaN(parsed)) { return }
      const filters = {
        companyId: 0,
        accountNo: '',
        q: q,
        storeId: 0,
        warehouseId: 0,
        beginDate: null,
        endDate: null,
        tenantType: 'SERVING',
        batchId: parsed,
        status: 'ALL',
        batchStatus: 'all',
      }
      fbaShipmentService.getBatchParentItemsByQuery(filters).then(result => {
        this.batchItems.length = 0
        this.batchItems = result.data.map(item => {
          const data = {
            ...item,
            channel: this.channel,
          }
          return {
            ...data,
          }
        })
      })
    },
    getSearchByFilter(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== null && e.event.target.value !== '') {
          this.setSelectedBatchItem(this.batchId, e.event.target.value)
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.searchText = ''
        this.setSelectedBatchItem(this.batchId, '')
      }
    },
    onCancelAddItemBatch(status) {
      this.contentStatus = status
      this.selectedBatchItem = {}
      emitter.emit(FBAEvents.CLEAR_SEARCH_TEXT.event)
    },
    getPrinterCount(quantity, items) {
      let sumItemsQuantity = items.map(p => p.quantity).reduce((prev, curr) => prev + curr, 0)
      sumItemsQuantity = sumItemsQuantity === 0 ? quantity : sumItemsQuantity
      return (quantity >= sumItemsQuantity) ? sumItemsQuantity : quantity
    },
    onAddItemBatch(payload, status) {
      const { quantity, items } = payload
      const printQuantity = this.getPrinterCount(quantity, items)
      const exprDate = payload.expirationDate ? payload.expirationDate : ''
      const conditionText = getLocalText(payload.itemCondition)
      const title = conditionText ? `${conditionText} - ${payload.title}` : payload.title

      // Set payload expiration date to ISO format
      payload.expirationDate = formatedIsoDateStr(exprDate, 'MM/DD/YYYY')

      fbaShipmentService.createBatchParentItem(payload)
        .then(result => {
          const item = result.data
          return item
        })
        .then(item => {
          const batchItem = {
            fnsku: item.fnsku,
            title: title,
            expirationDate: exprDate,
            quantity: printQuantity,
          }
          this.onClickPrintAuto(batchItem)
        })
        .then(() => {
          emitter.emit(FBAEvents.CLEAR_SEARCH_TEXT.event)
        })
        .then(() => {
          this.setSelectedBatchItem(this.batchId)
        })
        .then(() => {
          emitter.emit(FBAEvents.UPDATE_SELECTED_BATCH.event, this.batchId)
        })
        .finally(() => {
          this.contentStatus = status
        })
    },
    onClickEditBatchItemQuantity(e) {
      const popup = this.$refs.editBatchItemQuantityPopupRef.instance
      const selectedItem = e.row.data
      const isCasePack = !!selectedItem.quantityPerCase
      if (isCasePack) {
        this.batchItemQuantityFormData = {
          id: selectedItem.id,
          currentQuantity: selectedItem.quantityPerCase,
          newQuantity: selectedItem.quantityPerCase,
          currentQuantityPerCase: selectedItem.quantity / selectedItem.quantityPerCase,
          newQuantityPerCase: selectedItem.quantity / selectedItem.quantityPerCase,
          isCasePack: isCasePack,
        }
      } else {
        this.batchItemQuantityFormData = {
          id: selectedItem.id,
          currentQuantity: selectedItem.quantity,
          newQuantity: selectedItem.quantity,
          currentQuantityPerCase: 0,
          newQuantityPerCase: 0,
          isCasePack: isCasePack,
        }
      }
      popup.show()
    },
    onSubmitBatchItemQuantity() {
      const form = this.$refs.batchItemQuantityFormDataRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const {
          currentQuantity,
          newQuantity,
          currentQuantityPerCase,
          newQuantityPerCase,
          isCasePack,
        } = this.batchItemQuantityFormData
        if ((!isCasePack && currentQuantity !== newQuantity) || isCasePack) {
          if (isCasePack) {
            const currentTotalQty = currentQuantity * currentQuantityPerCase
            const newTotalQty = newQuantity * newQuantityPerCase
            if (newTotalQty > currentTotalQty) {
              Notify.info('You can only change the combination of number of cases and quantity per case. However, number of total items in all packs cannot exceed the total existing quantity.')
              return
            }
          }
          fbaShipmentService.updateBatchParentItem(this.batchItemQuantityFormData)
            .then(result => {
              this.onCancelSubmitBatchItemQuantity()
            })
            .then(() => {
              this.setSelectedBatchItem(this.batchId)
            })
            .then(() => {
              emitter.emit(FBAEvents.UPDATE_SELECTED_BATCH.event, this.batchId)
            })
            .catch(error => {
              this.onCancelSubmitBatchItemQuantity()
            })
        } else {
          this.onCancelSubmitBatchItemQuantity()
          Notify.info('Quantity has not changed.')
        }
      }
    },
    onEmitSubmitBatchItemQuantity(payload) {
      if (payload.currentQuantity !== payload.newQuantity) {
        fbaShipmentService.updateBatchParentItem(payload)
          .then(result => {
            this.setSelectedBatchItem(this.batchId)
            Notify.success('Quantity successfully changed.')
            this.contentStatus = false
          })
          .then(() => {
            emitter.emit(FBAEvents.UPDATE_SELECTED_BATCH.event, this.batchId)
          })
          .catch(error => {
            Notify.danger(error)
          })
      } else {
        Notify.info('Quantity has not changed.')
      }
    },
    onCancelSubmitBatchItemQuantity() {
      const popup = this.$refs.editBatchItemQuantityPopupRef.instance
      const form = this.$refs.batchItemQuantityFormDataRef.instance
      form.resetValues()
      popup.hide()
    },
    onShownDeleteShipmentItemPopup() {
      this.deleteConfirmationData.deleteOnAmazon = false
      this.deleteConfirmationData.confirmDelete = ''
    },
    onHiddenDeleteShipmentItemPopup() {
      this.deleteConfirmationData.id = null
      this.deleteConfirmationData.isMfn = false
      this.deleteConfirmationData.deleteOnAmazon = false
      this.deleteConfirmationData.confirmDelete = ''
    },
    onClickDeleteBatchItem(e) {
      this.deleteConfirmationData.isMfn = e.row.data.channel === ShipmentChannelEnum.MFN.value
      this.deleteConfirmationData.id = e.row.data.id
      this.deleteShipmentItemPopupIns.show()
    },
    onValueChangeDeleteOnAmazon(e) {
      if (!e.value) {
        this.deleteConfirmationData.confirmDelete = ''
      }
    },
    onHandleDeleteShipmentItem() {
      const {
        id, isMfn, deleteOnAmazon, confirmDelete,
      } = this.deleteConfirmationData
      if (isMfn && deleteOnAmazon && confirmDelete !== 'DELETE') {
        Notify.danger('Please type DELETE to confirm')
        return
      }
      let deleteFromAmazon = false
      if (isMfn && deleteOnAmazon) {
        deleteFromAmazon = true
      }
      fbaShipmentService
        .deleteBatchParentItem(id, deleteFromAmazon)
        .then(() => {
          this.setSelectedBatchItem(this.batchId)
          this.deleteShipmentItemPopupIns.hide()
        })
        .then(() => {
          emitter.emit(FBAEvents.UPDATE_SELECTED_BATCH.event, this.batchId)
        })
    },
    onEmitDeleteItemBatch(id, status) {
      fbaShipmentService.deleteBatchParentItem(id).then(result => {
        this.setSelectedBatchItem(this.batchId)
      }).then(() => {
        emitter.emit(FBAEvents.UPDATE_SELECTED_BATCH.event, this.batchId)
      }).finally(() => {
        this.contentStatus = status
      })
    },
    /**
     * Edit Info
     */
    onClickEditBatchItemInfo(e) {
      const popup = this.$refs.editBatchItemInfoPopupRef.instance
      const selectedItem = e.row.data
      fbaShipmentService.getBatchParentItems(selectedItem.id)
        .then(result => {
          const { data } = result
          this.batchItemInfo = { ...data }
          this.batchItemInfoFormData = {
            supplier: data.supplier,
            cost: data.cost,
            salePrice: data.salePrice,
            expirationDate: data.expirationDate,
          }
        })
        .then(() => {
          popup.show()
        })
    },
    onSubmitBatchItemInfo() {
      const form = this.$refs.batchItemInfoFormDataRef.instance
      const popup = this.$refs.editBatchItemInfoPopupRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const data = {
          ...this.batchItemInfo,
          supplier: this.batchItemInfoFormData.supplier,
          cost: this.batchItemInfoFormData.cost,
          salePrice: this.batchItemInfoFormData.salePrice,
          expirationDate: this.batchItemInfoFormData.expirationDate,
        }
        fbaShipmentService.updateBatchParentItemInfo(data).then(() => {
          popup.hide()
          Notify.success('Item informations have been updated successfully')
        })
      }
    },
    onCancelSubmitBatchItemInfo() {
      const popup = this.$refs.editBatchItemInfoPopupRef.instance
      popup.hide()
    },
    onHidingBatchItemInfoPopup() {
      this.batchItemInfo = {}
      this.batchItemInfoFormData = {
        supplier: '',
        cost: 0,
        salePrice: 0,
        expirationDate: null,
      }
    },
    // #region Handle Amazon Errors
    onClickHandleBatchItemError(data, errorType) {
      emitter.emit('on-emit-error-handler-form', {
        id: data.id,
        catalogItem: data.catalogItem,
        type: errorType,
      })
    },
    // #endregion
    // #region Printer

    async onClickPrint(item, count) {
      if (this.channel !== ShipmentChannelEnum.FBA.value) return

      const conditionText = getLocalText(item.data.itemCondition)
      const title = conditionText ? `${conditionText} - ${item.data.title}` : item.data.title
      const copies = count || parseInt(this.printCount, 10)
      const payload = {
        barcode: item.data.fnsku,
        title: title,
        expirationDate: item.data.expirationDate,
        copies: copies,
      }
      this.printCount = 1
      await this.printFnskuLabel(payload)
    },
    onEnterPrint(e, item) {
      if (e.keyCode === 13) {
        this.onClickPrint(item)
      }
    },
    onClickDownloadLable(item) {
      const conditionText = getLocalText(item.data.itemCondition)
      const title = conditionText ? `${conditionText} - ${item.data.title}` : item.data.title
      const fnsku = item.data.fnsku
      const expDate = item.data.expirationDate
      const count = item.data.quantity
      this.downloadFnskuLabel(fnsku, title, expDate, count)
    },
    async onClickPrintAuto(item) {
      if (this.channel !== ShipmentChannelEnum.FBA.value) return

      const settings = store.getters['fbaShipment/getPrinterSettings']
      if (settings && settings.manageLabelPrintering && !settings.manageLabelPrintering.isAddedFBABatch) return

      const payload = {
        barcode: item.fnsku,
        title: item.title,
        expirationDate: item.expirationDate,
        copies: item.quantity,
      }
      await this.printFnskuLabel(payload)
    },
    // #endregion Printer
    onEmitCatalogItemInfo(payload) {
      const { isAsin, param } = payload
      this.selectedProductIdDetail = param
      this.isVisibleProductDetail = true
    },
    // #region Handle Plan Selection
    openPlanSelector(row) {
      this.isPlanSelectorVisible = true
      this.selectedProductId = row.productId
      this.selectedItemId = row.id
      this.selectedPlan = row.planId
    },
    onEmitPlanSelectorPlanUpdated(payload) {
      const { planId, applyAll } = payload
      const id = this.selectedItemId
      fbaShipmentService.updateBatchItemPlan(id, planId, applyAll).then(() => {
        this.setSelectedBatchItem(this.batchId)
        Notify.success('Plan successfully changed.')
      }).then(() => {
        emitter.emit(FBAEvents.UPDATE_SELECTED_BATCH.event, this.batchId)
      })
    },
    onEmitPlanSelectorClosed() {
      this.isPlanSelectorVisible = false
      this.selectedProductId = null
      this.selectedItemId = null
      this.selectedPlan = null
    },
    async loadDataGridState(e) {
      const dataGridKey = DataGridNamesEnum.OUTBOUND_FBA_BATCHING_GRID.key
      const result = await settingsService.getByReference(referenceTypeEnum.USER.value, this.userId, dataGridKey)
      this.currentGridStateId = result.data.id
      this.dataGridState = {}
      if (result.data.value) {
        const state = JSON.parse(result.data.value)
        state.searchText = ''
        this.dataGridState = state
      }
      return this.dataGridState
    },
    async saveDataGridState(e) {
      if (e === undefined || e === null) {
        return
      }
      if (JSON.stringify(e) === JSON.stringify(this.dataGridState)) {
        return
      }
      const dataGridPreferences = { ...e }
      dataGridPreferences.selectedRowKeys = []
      dataGridPreferences.searchText = ''
      const dataGridKey = DataGridNamesEnum.OUTBOUND_FBA_BATCHING_GRID.key
      const preferenceData = {
        companyId: this.userCompanyId,
        referenceId: this.userId,
        referenceType: referenceTypeEnum.USER.value,
        settingType: SettingsTypeEnum.DATAGRID_STATES.value,
        settingKey: dataGridKey,
        description: `State of the ${dataGridKey} for UserId: ${this.userId}`,
        settingName: dataGridKey,
        value: JSON.stringify(dataGridPreferences),
      }
      const result = await settingsService.createOrUpdate(preferenceData)
      this.currentGridStateId = result.data.body.id
    },
    // #endregion Handle Plan Selection
  },
}
</script>

<style lang="scss">
  .dx-fa-button.dx-fa-button-main {
    .dx-overlay-content {
      min-width: 36px;
      max-height: 36px;
      background-color: #27ae60;
      color: #f4f4f4;
      overflow: visible;
      border-radius: 18px;
      &.dx-state-hover {
        background-color: #37ff8ac9;
      }
      .dx-fa-button-label-wrapper {
        .dx-fa-button-label {
            color: #f4f4f4;
          }
      }
    }
  }
</style>
