<template>
  <div class="d-flex flex-column justify-content-start">
    <div class="row cursor-pointer" @click="collapsible = !collapsible">
      <div class="sales-price-header col">
        <i class="bi " :class="!collapsible ? 'bi-chevron-down' : 'bi-chevron-up'"></i>
        Offers {{ pricing.totalOfferCount }}
      </div>
    </div>
    <transition name="fade">
      <div v-show="!collapsible">
        <div class="d-flex flex-row row">
          <div class="col px-2 fba-header">
            FBA
          </div>
          <div class="col px-2 merchant-header">
            FBM
          </div>
          <div class="col px-2 used-header">
            Used
          </div>
        </div>
        <div class="d-flex flex-row row">
          <div class="col px-2 fba-content">
            <span
              v-for="(price, index) in pricing.fba"
              :id="`feedback-${index}`"
              :key="index"
              class="d-flex justify-content-between align-items-center"
              @click="onClickSetSalePrice(price.amount)"
            >
              <span class="flex-grow-1">{{ getFormatedPrice(price.currencyCode, price.amount) }}</span>
              <i v-if="price.isBuyBox" v-b-tooltip.hover title="FBA current buybox listing" class="bi bi-box-seam-fill mr-half"></i>
              <span v-if="price.feedbackCount" :id="`feedback-fba-${index}`"> {{ price.sellerPositiveFeedbackRating }}% </span>
              <i v-if="price.feedbackCount" :id="`feedback-fba-${index}`" class="bi bi-star-fill text-warning cursor-pointer"></i>
              <dx-tooltip
                :target="`#feedback-fba-${index}`"
                position="top"
                :animation="animationConfig"
                :close-on-outside-click="false"
                show-event="mouseenter"
                 hide-event="mouseleave"
              >
               {{ price.sellerPositiveFeedbackRating }}% Positive Feedback ({{ price.feedbackCount }} reviews)
              </dx-tooltip>
            </span>
          </div>
          <div class="col px-2 merchant-content">
            <span
              v-for="(price, index) in pricing.merchant"
              :key="index"
              class="d-flex justify-content-start align-items-center"
              @click="onClickSetSalePrice(price.amount)"
            >
              <span class="flex-grow-1">{{ getFormatedPrice(price.currencyCode, price.amount) }}</span>
              <i v-if="price.isBuyBox" v-b-tooltip.hover title="FBM current buybox listing" class="bi bi-box-seam-fill mr-half"></i>
              <span v-if="price.feedbackCount" :id="`feedback-fbm-${index}`"> {{ price.sellerPositiveFeedbackRating }}% </span>
              <i v-if="price.feedbackCount" :id="`feedback-fbm-${index}`" class="bi bi-star-fill text-warning cursor-pointer"></i>
              <dx-tooltip
                :target="`#feedback-fbm-${index}`"
                position="top"
                :animation="animationConfig"
                :close-on-outside-click="false"
                show-event="mouseenter"
                hide-event="mouseleave"
              >
                {{ price.sellerPositiveFeedbackRating }}% Positive Feedback ({{ price.feedbackCount }} reviews)
              </dx-tooltip>
            </span>
          </div>
          <div class="col px-2 used-content">
            <span v-for="(price, index) in pricing.used" :key="index" class="d-flex justify-content-between align-items-center" @click="onClickSetSalePrice(price.amount)">
              <span>{{ getFormatedPrice(price.currencyCode, price.amount) }}</span>
              <i v-if="price.isBuyBox" class="bi bi-box-seam-fill text-warning"></i>
            </span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import store from '@/store'
import amazonService from '@/http/requests/external/amazon.fba'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import groupBy from 'lodash/groupBy'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import { DxTooltip } from 'devextreme-vue/tooltip'

export default {
  components: {
    DxTooltip,
  },
  props: {
    asin: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      collapsible: false,
      pricing: {
        condition: '',
        totalOfferCount: 0,
        used: [],
        merchant: [],
        fba: [],
      },
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0.1,
            opacity: 0,
          },
          to: {
            scale: 1,
            opacity: 1,
          },
        },
        hide: {
          type: 'pop',
          from: {
            scale: 1,
            opacity: 1,
          },
          to: {
            scale: 0.1,
            opacity: 0,
          },
        },
      },
    }
  },
  computed: {
    accountInfo() {
      return store.getters['fbaShipment/getBatchAccountInfo']
    },
    shipToCountryCode() {
      const { shipToCountryCode } = store.getters['fbaShipment/getSelectedBactInfo']
      return shipToCountryCode
    },
  },
  watch: {
    asin: {
      immediate: true,
      handler(current) {
        if (current) {
          this.getItemOffers(current)
        }
      },
    },
  },
  methods: {
    async getItemOffers(asin) {
      if (!asin) return
      let salesPrice = []
      const result = await amazonService.getItemOffersByAsin(this.accountInfo.storeId, 'New', asin, this.shipToCountryCode)
      const { offers, summary, itemCondition } = result.data
      this.pricing.condition = itemCondition
      if (!isEmpty(summary)) {
        const {
          buyBoxEligibleOffers, buyBoxPrices, listPrice, lowestPrices, numberOfOffers, salesRankings, totalOfferCount,
        } = summary
        this.pricing.totalOfferCount = totalOfferCount
        if (isArray(lowestPrices) && lowestPrices.length > 0) {
          const usedSalesPrice = filter(lowestPrices, p => p.condition === 'used')
          if (isArray(usedSalesPrice) && usedSalesPrice.length > 0) {
            usedSalesPrice.forEach(offer => {
              const { listingPrice, shipping } = offer
              const price = {
                amount: (listingPrice.amount + shipping.amount).toFixed(2),
                listingPrice: listingPrice.amount.toFixed(2),
                shippingPrice: shipping.amount.toFixed(2),
                currencyCode: listingPrice.currencyCode,
                isBuyBox: false,
                type: 'used',
                isFeaturedMerchant: false,
                isUsed: true,
              }
              salesPrice.push(price)
            })
          }
        }
      }
      if (isArray(offers) && offers.length > 0) {
        offers.forEach(offer => {
          const {
            listingPrice, shipping, isFulfilledByAmazon, isBuyBoxWinner, isFeaturedMerchant, sellerFeedbackRating,
          } = offer
          const price = {
            amount: (listingPrice.amount + shipping.amount).toFixed(2),
            listingPrice: listingPrice.amount.toFixed(2),
            shippingPrice: shipping.amount.toFixed(2),
            currencyCode: listingPrice.currencyCode,
            isBuyBox: isBuyBoxWinner,
            type: isFulfilledByAmazon ? 'fba' : 'merchant',
            isFeaturedMerchant: isFeaturedMerchant,
            isUsed: null,
            feedbackCount: sellerFeedbackRating && sellerFeedbackRating.feedbackCount ? sellerFeedbackRating.feedbackCount : 0,
            sellerPositiveFeedbackRating: sellerFeedbackRating && sellerFeedbackRating.sellerPositiveFeedbackRating ? sellerFeedbackRating.sellerPositiveFeedbackRating : 0,
          }
          if (price.isBuyBox && price.type === 'fba') {
            this.onEmitCalculatePrice(parseFloat(price.amount))
          }
          salesPrice.push(price)
        })
      }
      salesPrice = orderBy(salesPrice, ['type', 'amount'], ['asc', 'asc'])
      salesPrice = groupBy(uniqWith(salesPrice, isEqual), p => p.type)
      const { merchant, used, fba } = salesPrice
      this.pricing.used = used
      this.pricing.fba = fba
      this.pricing.merchant = merchant
    },
    onClickSetSalePrice(amount) {
      const salePrice = parseFloat(amount)
      this.$emit('on-emit-set-selected-price', salePrice)
    },
    onEmitCalculatePrice(amount) {
      this.$emit('on-emit-calculate-price', amount)
    },
    getFormatedPrice(currencyCode, amount) {
      const currencySymbol = currencyCode === 'USD' ? '$' : currencyCode
      return `${currencySymbol} ${amount}`
    },
  },
}
</script>

<style lang="scss">
.sales-price-header {
  background: #a0c4ff;
  font-size: 1.143rem;
  font-weight: 500;
  color: #2b2d42;
  height: 2.571rem;
  padding-top: 0.357rem;
  padding-bottom: 0.357rem;
}
.fba-header {
  background: #bdb2ff;
  font-size: 1.143rem;
  font-weight: 500;
  color: #2b2d42;
  padding-top: 0.357rem;
  padding-bottom: 0.357rem;
}
.fba-content {
  background: #cac1ff;
  color: #2b2d42;
  & > span {
    display: block;
    font-weight: 400;
    line-height: 1.429rem;
    &:hover {
      cursor: pointer;
      color: #4a4e72;
      font-weight: 500;
    }
  }
}
.merchant-header {
  background: #ffd6a5;
  font-size: 1.143rem;
  font-weight: 500;
  color: #2b2d42;
  padding-top: 0.357rem;
  padding-bottom: 0.357rem;
}
.merchant-content {
  background: #ffdaae;
  color: #2b2d42;
  & > span {
    display: block;
    font-weight: 400;
    line-height: 1.429rem;
    &:hover {
      cursor: pointer;
      color: #4a4e72;
      font-weight: 500;
    }
  }
}
.used-header {
  background: #ffadad;
  font-size: 1.143rem;
  font-weight: 500;
  color: #2b2d42;
  padding-top: 0.357rem;
  padding-bottom: 0.357rem;
}
.used-content {
  background: #ffb5b5;
  color: #2b2d42;
  & > span {
    display: block;
    font-weight: 400;
    line-height: 1.429rem;
    &:hover {
      cursor: pointer;
      color: #4a4e72;
      font-weight: 500;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
