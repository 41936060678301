import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const barcodeInstructionEnum = Object.freeze({
  REQUIRES_FNSKU_LABEL: {
    key: 'REQUIRESFNSKULABEL',
    value: 'Requires FNSKU Label',
    text: 'Indicates that a scannable FBA product label must be applied to the item. Cover any original bar codes on the item.',
  },
  CAN_USE_ORIGINAL_BARCODE: {
    key: 'CANUSEORIGINALBARCODE',
    value: 'Can Use Original Barcode',
    text: 'Indicates that the item does not require a scannable FBA product label. The original manufacturers bar code can be used.',
  },
  MUSTPROVIDESELLERSKU: {
    key: 'MUSTPROVIDESELLERSKU',
    value: 'Must Provide Seller SKU',
    text: 'Amazon is unable to return labeling requirements. To get labeling requirements for items, call the getPrepInstructions operation.',
  },
})

function getBarcodeInstructionEnum(predicate, predicateKey = 'key') {
  const result = find(barcodeInstructionEnum, { [predicateKey]: predicate })
  return result || { value: 'None', text: 'None' }
}

export default barcodeInstructionEnum

export {
  getBarcodeInstructionEnum,
}
