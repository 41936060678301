<template>
    <dx-util-popup
      ref="popupItemErrorHandlerRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="false"
      :title="popupTitle"
      width="50vw"
      height="36vh"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
      <div class="row">
        <div class="col-12">
          <h4>{{ errorTitle }}</h4>
        </div>
        <div class="col-12">
          <p>{{ errorDescription }}</p>
        </div>
      </div>
      <form method="post" @submit="handleSubmit($event)">
        <dx-util-form
          id="errorHandlerForm"
          ref="errorHandlerFormRef"
          :form-data="errorHandlerFormData"
          :scrolling-enabled="true"
          :col-count="2"
          :show-colon-after-label="true"
          label-location="top"
          width="auto"
        />
      </form>
    </dx-util-popup>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  ref, onMounted, computed, toRefs, reactive,
} from '@vue/composition-api'
import { emitter } from '@/libs/bus'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import { Notify } from '@robustshell/utils/index'
import CatalogItem from '@/libs/app/catalog-item'
import ErrorTypeEnum from '../error-type.enum'
import { useFormDataMissingDimensions, useFormDataSetMissingDimensions } from './batchItemErrorHandlerFormData'

export default {
  props: {
    storeId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const errorHandlerFormRef = ref(null)
    const popupItemErrorHandlerRef = ref(null)
    const itemData = reactive({
      id: null,
      msku: '',
      storeId: null,
      productType: '',
    })
    const errorType = ref(null)
    const errorHandlerFormData = ref({})
    const popupTitle = ref(null)
    const errorTitle = ref(null)
    const errorDescription = ref(null)
    const catalogItemData = ref({})

    const formIns = computed(() => errorHandlerFormRef.value.instance)
    const popupIns = computed(() => popupItemErrorHandlerRef.value.instance)

    function showPopup() {
      if (errorType.value === ErrorTypeEnum.MISSING_DIMENSIONS.key) {
        popupTitle.value = ErrorTypeEnum.MISSING_DIMENSIONS.text
        errorTitle.value = 'Amazon Missing Dimensions'
        errorDescription.value = 'Please enter dimension and weight info'
        const formData = useFormDataMissingDimensions()
        formIns.value.option('items', formData)
      }
      popupIns.value.show()
    }

    async function getItemInfo(id) {
      const result = await fbaShipmentService.getBatchParentItems(id)
      const { data } = result
      if (Object.keys(data).length > 0) {
        let productType = 'PRODUCT'
        if (data.listingItem && data.listingItem.productType) {
          productType = data.listingItem.productType
        } else {
          const catalogItemIns = new CatalogItem()
          catalogItemIns.catalogItem = catalogItemData.value
          productType = catalogItemIns.getProductType()
        }
        itemData.id = data.id
        itemData.msku = data.msku
        itemData.productType = productType
        itemData.storeId = props.storeId
        showPopup()
      }
    }

    emitter.on('on-emit-error-handler-form', payload => {
      const { id, type, catalogItem } = payload
      errorType.value = type
      catalogItemData.value = { ...catalogItem }
      getItemInfo(id)
    })

    function handleSubmit() {
      const formValidate = formIns.value.validate()
      if (formValidate.isValid) {
        const formData = formIns.value.option('formData')
        const {
          length, width, height, weightPounds, weightOunces,
        } = formData
        let weight = 0
        let weightUnit = ''
        if (weightOunces) {
          weight = weightPounds ? weightPounds * 16 : 0 + weightOunces
          weightUnit = 'ounces'
        } else if (weightPounds) {
          weight = weightPounds
          weightUnit = 'pounds'
        } else {
          Notify.error('Item weight cannot be 0.')
          return
        }

        const {
          id, productType, msku, storeId,
        } = { ...toRefs(itemData) }
        const dimensionAttribute = useFormDataSetMissingDimensions(length, width, height, weight, weightUnit, storeId.value, id.value, productType.value, msku.value)
        fbaShipmentService.updatePatchItem(dimensionAttribute)
          .then(() => {
            Notify.success('Item informations have been updated successfully.')
          })
          .then(() => {
            formIns.value.resetValues()
          })
          .then(() => {
            popupIns.value.hide()
          })
      } else {
        Notify.warning('Please enter valid value.')
      }
    }

    const saveButtonOptions = computed(() => ({
      text: 'Save',
      type: 'success',
      useSubmitBehavior: true,
      onClick: e => {
        handleSubmit(e)
      },
    }))

    const cancelButtonOptions = computed(() => ({
      text: 'Cancel',
      type: 'danger',
      useSubmitBehavior: true,
      onClick: e => {
        popupIns.value.hide()
      },
    }))

    return {
      errorHandlerFormRef,
      popupItemErrorHandlerRef,
      saveButtonOptions,
      cancelButtonOptions,
      errorHandlerFormData,
      popupTitle,
      errorTitle,
      errorDescription,
      itemData,
    }
  },
}
</script>
