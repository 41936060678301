import {
  ref, reactive,
  //  watch, computed,
} from '@vue/composition-api'
import { countriesWithRegions } from '@/http/requests/statics/locations'
import minimumPriceEnum from '@/enums/minimumPrice.enum'

const currentStore = ref()

export default function useStoreSettings() {
  const formData = reactive({
    channels: [],
    listPrice: {
      type: '',
      margin: 0,
      unit: '',
      base: '',
    },
    minimumPrice: {
      type: minimumPriceEnum.MINIMUM_LISTED_PRICE.value,
      margin: 0,
      unit: '',
      base: '',
    },
  })

  function resetFormData() {
    formData.channels = []
    formData.listPrice.type = ''
    formData.listPrice.margin = 0
    formData.listPrice.unit = ''
    formData.listPrice.base = ''
    formData.minimumPrice.type = minimumPriceEnum.MINIMUM_LISTED_PRICE.value
    formData.minimumPrice.margin = 0
    formData.minimumPrice.unit = ''
    formData.minimumPrice.base = ''
  }

  const selectedCountryCodes = ref([])
  function loadStore(data) {
    currentStore.value = data
  }
  return {
    currentStore,
    loadStore,
    selectedCountryCodes,
    countriesWithRegions,
    formData,
    resetFormData,
  }
}
