/* eslint-disable no-unused-vars */
import { getLocalKey } from '@/enums/conditionTypeEnum'

const mskuOptions = [
  'COUNT',
  'BUYCOST',
  'ASIN',
  'CONDITION',
  'CUSTOM',
  'LISTPRICE',
  'PURCHASEDATE',
  'SALESRANK',
  'SUPPLIER',
]

function createMsku(pattern, data) {
  let msku = ''
  pattern.forEach(item => {
    switch (item.msku.value) {
      case 'COUNT':
        msku += data.count + item.seperatorValue
        break
      case 'BUYCOST':
        msku += data.cost + item.seperatorValue
        break
      case 'ASIN':
        msku += data.asin + item.seperatorValue
        break
      case 'CONDITION':
        msku += getLocalKey(data.itemCondition) + item.seperatorValue
        break
      case 'LISTPRICE':
        msku += data.salePrice + item.seperatorValue
        break
      case 'PURCHASEDATE':
        msku += data.purchaseDate + item.seperatorValue
        break
      case 'SALESRANK':
        msku += data.rank + item.seperatorValue
        break
      case 'SUPPLIER':
        msku += data.supplier ? data.supplier.replace(/\s/g, '') + item.seperatorValue : '_'
        break
      default:
        msku += item.msku.value + item.seperatorValue
        break
    }
  })
  msku = msku.slice(0, -1)
  return msku
}

export {
  createMsku,
}
