import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const prepGuidanceEnum = Object.freeze({
  CONSULT_HELP_DOCUMENTS: {
    key: 'CONSULTHELPDOCUMENTS',
    value: 'Consult Help Documents',
    text: 'Indicates that Amazon is currently unable to determine the preparation instructions for this item. Amazon might be able to provide guidance at a future date, after evaluating the item.',
  },
  NO_ADDITIONAL_PREP_REQUIRED: {
    key: 'NOADDITIONALPREPREQUIRED',
    value: 'No Additional Prep Required',
    text: 'Indicates that the item does not require preparation in addition to any item labeling that might be required.',
  },
  SEE_PREP_INSTRUCTIONS_LIST: {
    key: 'SEEPREPINSTRUCTIONSLIST',
    value: 'See Prep Instructions List',
    text: 'Indicates that the item requires preparation in addition to any item labeling that might be required. See the PrepInstructionList in the response for item preparation instructions.',
  },
})

function getPrepGuidanceEnum(predicate, predicateKey = 'key') {
  const result = find(prepGuidanceEnum, { [predicateKey]: predicate })
  return result || { value: 'None', text: 'None' }
}

export default prepGuidanceEnum

export {
  getPrepGuidanceEnum,
}
