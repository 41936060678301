import find from 'lodash/find'
/**
 * Enum for amazon errors type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const ErrorTypeEnum = Object.freeze({
  DONE: { key: 'DONE', value: null, text: 'Done' },
  MISSING_DIMENSIONS: { key: 'MISSING_DIMENSIONS', value: 'MISSING_DIMENSIONS', text: 'Add Dimensions' },
})

function getErrorTypeEnum(predicate, predicateKey = 'key') {
  const result = find(ErrorTypeEnum, { [predicateKey]: predicate })
  return result || ErrorTypeEnum.DONE
}

export default ErrorTypeEnum

export {
  getErrorTypeEnum,
}
