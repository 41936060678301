<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <shipping-items
          v-if="renderShippingItem"
          :batch-id="batchId"
          @emit-batch-transmite="onEmitBatchTransmit"
          @emit-update-shipping-item-content="onEmitUpdateShippingItemContent"
          @emit-create-consolidation="onEmitCreateConsolidation"
        />
      </div>
      <div class="col-12 px-0">
        <div class="d-flex flex-row justify-content-start shipping-content">
          <div class="info-text align-self-center flex-grow-1">
            Selected Shipping Plan Content
          </div>
          <div class="align-self-center">
            <dx-util-button
              type="normal"
              :icon="buttonIcon"
              :hint="buttonHint"
              :element-attr="{class: 'rounded-0'}"
              @click="onShowBoxContent"
            />
          </div>
        </div>
      </div>
      <div class="px-0" :class="showBoxContent ? 'col-9' : 'col-12'">
        <shipping-item-content v-if="renderShippingItemContent" :batch-id="batchId" />
      </div>
      <div v-show="showBoxContent" class="col-3 px-0">
        <shipping-item-boxes v-if="renderShippingItemBox" :batch-id="batchId" @emit-update-shipping-item-content="onEmitUpdateShippingItemContent" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { awaiter } from '@/libs/utils'
import fbaShipment from './fbaShipment.mixin'

export default {
  components: {
    'shipping-items': () => import('./components/ShippingItems.vue'),
    'shipping-item-content': () => import('./components/ShippingItemContent.vue'),
    'shipping-item-boxes': () => import('./components/ShippingItemBoxes.vue'),
  },
  mixins: [GridBase, fbaShipment],
  props: {
    batchId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      batchItems: [],
      showBoxContent: true,
      selectedAsin: '',
      renderShippingItemBox: true,
      renderShippingItemContent: true,
      renderShippingItem: true,
    }
  },
  computed: {
    searchItemPopup() {
      const popupIns = this.$refs.searchItemPopupRef.instance
      return popupIns
    },
    buttonIcon() {
      return this.showBoxContent ? 'chevrondoubleright' : 'chevrondoubleleft'
    },
    buttonHint() {
      return this.showBoxContent ? 'Hide Boxes' : 'Show Boxes'
    },
  },
  watch: {
    batchId: {
      immediate: true,
      handler(current, previous) {
        this.renderShippingItemComponents()
        this.renderShippingItemBoxComponents()
        this.renderShippingItemContentComponents()
      },
    },
  },
  methods: {
    onShowBoxContent() {
      this.showBoxContent = !this.showBoxContent
    },
    onEmitSetSelectedAsin(payload) {
      this.selectedAsin = payload
    },
    onEmitBatchTransmit(payload) {
      if (payload) {
        this.$emit('emit-batch-transmite', 'dimensions', payload)
      }
    },
    renderShippingItemComponents() {
      this.renderShippingItem = false
      awaiter(500).then(() => {
        this.renderShippingItem = true
      })
    },
    renderShippingItemBoxComponents() {
      this.renderComponent = false
      awaiter(500).then(() => {
        this.renderShippingItemBox = true
      })
    },
    renderShippingItemContentComponents() {
      this.renderShippingItemContent = false
      awaiter(500).then(() => {
        this.renderShippingItemContent = true
      })
    },
    onEmitUpdateShippingItemContent() {
      this.renderShippingItemContentComponents()
      this.renderShippingItemComponents()
    },
    onEmitCreateConsolidation() {
      this.renderShippingItemContentComponents()
      this.renderShippingItemBoxComponents()
      this.$emit('emit-create-consolidated', this.batchId)
    },
  },
}
</script>

<style lang="scss" scoped>
.shipping-content {
    background-color: rgba(223, 230, 233,1.0);
    color: rgba(45, 52, 54,1.0);
    height: 36px;
    .info-text {
        padding-left: 8px;
        display: block;
        font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,Helvetica Neue, Arial, sans-serif;
        font-size: 1.125rem;
        font-weight: 500;
        text-align: justify;
        text-justify:inter-word;
    }
}
</style>
