<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 px-2">
        <div v-show="partnerType">
          <div class="row px-0 mt-1 border-bottom">
            <div class="form-group col-3 px-half">
              <label for="text">Boxes</label>
              <div class="form-control bg-success text-white rounded border-0" style="height: 2.429rem; white-space: nowrap">
                <b-icon icon="box" />
                All Boxes
              </div>
            </div>
            <div class="form-group col-2 px-half">
              <label for="text">Weight (lb)</label>
              <dx-util-number-box
                v-model="weight.value"
                :min="0"
                format="###0"
                @value-changed="onValueChangedWeightValue"
              />
            </div>
            <div class="form-group col-2 ml-auto px-half">
              <label for="text">Length (in)</label>
              <dx-util-number-box
                v-model="dimensions.length"
                :min="0"
                format="###0"
                @value-changed="onValueChangedDimensionsLength"
              />
            </div>
            <div class="form-group col-2 px-half">
              <label for="text">Width (in)</label>
              <dx-util-number-box
                v-model="dimensions.width"
                :min="0"
                format="###0"
                @value-changed="onValueChangedDimensionsWidth"
              />
            </div>
            <div class="form-group col-2 px-half">
              <label for="text">Height (in)</label>
              <dx-util-number-box
                v-model="dimensions.height"
                :min="0"
                format="###0"
                @value-changed="onValueChangedDimensionsHeight"
              />
            </div>
          </div>
        </div>
        <div v-for="(item, index) in boxDimensions.boxDimensions" :key="index" class="row px-0 mt-1">
            <div class="form-group col-3 mb-0 px-half">
              <label v-show="index === 0 && !partnerType" for="text">Boxes</label>
              <div class="form-control bg-success text-white rounded border-0" style="height: 2.429rem; white-space: nowrap">
                <b-icon icon="box" />
                Box {{ item.boxName }}
              </div>
            </div>
            <div v-show="!partnerType" class="form-group col-9 px-half mb-0">
              <label v-show="index === 0 && !partnerType" for="text">Tracking</label>
              <dx-util-text-box v-model="item.tracking" />
            </div>
            <div v-show="partnerType" class="form-group col-2 px-half mb-0">
              <dx-util-number-box
                v-model="item.weight.value"
                format="###0"
                :min="0"
              />
            </div>
            <div v-show="partnerType" class="form-group col-2 ml-auto px-half mb-0">
              <dx-util-number-box
                v-model="item.dimensions.length"
                format="###0"
                :min="0"
              />
            </div>
            <div v-show="partnerType" class="form-group col-2 px-half mb-0">
              <dx-util-number-box
                v-model="item.dimensions.width"
                format="###0"
                :min="0"
              />
            </div>
            <div v-show="partnerType" class="form-group col-2 px-half mb-0">
              <dx-util-number-box
                v-model="item.dimensions.height"
                format="###0"
                :min="0"
              />
            </div>
        </div>
        <div class="row py-1 px-0 mt-1 border-top">
          <div class="col-2 px-half ml-auto">
          <dx-util-button
            icon="save"
            text="Submit"
            :disabled="transportStatus === transportStatusEnums.CONFIRMED.key"
            type="success"
            width="100%"
            @click="onSubmitBoxDimensions"
          />
          </div>
        </div>
        <div v-if="isSavedSuccess" class="row pt-1 px-0 mt-1 border-top">
          <div class="col-12 px-half">
            <div class="alert alert-success" role="alert">
              <h4 class="alert-heading">
                Success
              </h4>
              <p class="px-1">
                Box Dimensions have been saved and transmitted to Amazon successfully.
              </p>
              <p v-show="partnerType" class="mb-0 px-1">
                Shipping Label cost is being estimated by Amazon and will be available in the Shipping Label tab momentarily.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import map from 'lodash/map'
import { Notify } from '@robustshell/utils/index'
import transportStatusEnum from '@/enums/transportStatusEnum'

export default {
  props: {
    batchId: {
      type: Number,
      required: true,
    },
    shipmentBatchId: {
      type: Number,
      required: true,
    },
    partnerType: {
      type: Boolean,
      required: true,
    },
    shipmentType: {
      type: String,
      required: true,
    },
    transportStatus: {
      type: String,
      required: true,
    },
    carrier: {
      type: String,
      required: true,
    },
    selectedCarrier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSavedSuccess: false,
      boxDimensions: {},
      dimensions: {
        length: 0,
        width: 0,
        height: 0,
        unit: 'INCHES',
      },
      weight: {
        value: 0,
        unit: 'POUNDS',
      },
      transportStatusEnums: transportStatusEnum,
    }
  },
  computed: {
    showLabel() {
      return this.boxDimensions.length < 2
    },
  },
  watch: {
    shipmentBatchId: {
      immediate: true,
      async handler(current, previous) {
        if (current && current > 0) {
          this.getBatchBoxes(current)
        }
      },
    },
  },
  methods: {
    getBatchBoxes(id) {
      const parsed = parseInt(id, 10)
      fbaShipmentService.findAllBoxesByBatchId(parsed).then(result => {
        Object.assign(this.boxDimensions, {})
        const { data } = result
        const boxDimensions = {
          batchId: parsed,
          carrier: this.carrier,
          partnered: true,
          boxDimensions: [],
        }
        data.forEach(item => {
          const boxDimension = {
            boxId: item.id,
            boxName: item.boxName,
            tracking: item.tracking,
            dimensions: {
              length: item.length,
              width: item.width,
              tracking: item.tracking,
              height: item.height,
              unit: 'INCHES',
            },
            weight: {
              value: item.weight,
              unit: 'POUNDS',
            },
          }
          boxDimensions.boxDimensions.push(boxDimension)
        })
        this.boxDimensions = { ...boxDimensions }
      })
    },
    /** Multiple Changes */
    onValueChangedWeightValue(e) {
      const val = e.value
      map(this.boxDimensions.boxDimensions, p => {
        p.weight = { ...p.weight, value: val }
      })
    },
    onValueChangedDimensionsLength(e) {
      const val = e.value
      map(this.boxDimensions.boxDimensions, p => {
        p.dimensions = { ...p.dimensions, length: val }
      })
    },
    onValueChangedDimensionsWidth(e) {
      const val = e.value
      map(this.boxDimensions.boxDimensions, p => {
        p.dimensions = { ...p.dimensions, width: val }
      })
    },
    onValueChangedDimensionsHeight(e) {
      const val = e.value
      map(this.boxDimensions.boxDimensions, p => {
        p.dimensions = { ...p.dimensions, height: val }
      })
    },
    onSubmitBoxDimensions(e) {
      let shipmentType = 'Small parcel delivery (SPD)'
      if (this.shipmentType === 'LTL') {
        shipmentType = 'Less than truckload (LTL)'
      }
      const partnerType = this.partnerType ? 'Partnered' : 'Non Partner'
      this.$swal({
        title: 'Confirm Carton Content Submission',
        html: `<table class="table table-sm text-left bg-white">
          <tbody>
            <tr>
              <td colspan="2" class="text-danger font-weight-bold py-10 text-center">
                Please confirm that you want to submit box count and dimensions. Once submitted, you will not be able to modify them.
              </td>
            <tr>
              <td scope="row">Is Partnered</td>
              <td>${partnerType}</td>
            </tr>
            <tr>
              <td scope="row">Carrier Name</td>
              <td>${this.selectedCarrier}</td>
            </tr>
            <tr>
              <td scope="row">Shipment Type</td>
              <td>${shipmentType}</td>
            </tr>
          </tbody>
        </table>`,
        icon: 'info',
        width: '30vw',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
          content: 'px-0',
          htmlContainer: 'mx-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = { ...this.boxDimensions }
          data.partnered = this.partnerType
          data.carrier = this.carrier
          data.shipmentType = this.shipmentType
          fbaShipmentService.setBoxBoxDimensions(data).then(res => {
            this.isSavedSuccess = true
            this.onEmitGetTransportDetail()
          }).catch(error => {
            Notify.danger(error.message)
          })
        }
      })
    },
    onEmitGetTransportDetail() {
      this.$emit('on-emit-get-transport-detail')
    },
  },
}
</script>
