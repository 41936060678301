<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div id="dimensions-content" class="card m-0 rounded-0 new-item-content border-bottom">
          <div class="card-body py-0 px-0" :style="dimensionContent">
            <div class="d-flex flex-row justif-content-start w-100">
              <div v-b-tooltip.hover="{ variant: 'info', title: 'Destination'}" class="align-self-center bg-secondary text-white px-half badge-item">
                {{ shipmentBatchDestCode }}
              </div>
              <div class="align-self-center bg-warning px-half text-black text-left badge-item mr-1">
                {{ shipmentBatchName }}
              </div>
              <div class="align-self-center mr-1">
                <dx-util-select-box
                  v-model="shipmentType"
                  :data-source="shipmentTypes"
                  display-expr="text"
                  value-expr="value"
                  placeholder="Shipment Type"
                  label="Shipping method"
                  label-mode="floating"
                  width="250px"
                />
              </div>
              <div class="align-self-center mr-1">
                <dx-util-select-box
                  v-model="partnerType"
                  :data-source="partnerTypes"
                  display-expr="text"
                  value-expr="value"
                  placeholder="Carrier Type"
                  label="Carrier Type"
                  label-mode="floating"
                  @value-changed="onValueChangedPartnerType"
                />
              </div>
              <div class="align-self-center mr-1">
                <dx-util-select-box
                  v-model="carrier"
                  :data-source="carriers"
                  :disabled="isPartnerSmallParcelDelivery"
                  display-expr="text"
                  value-expr="value"
                  placeholder="Select a Carrier"
                  label="Select a Carrier"
                  label-mode="floating"
                  @value-changed="onValueChangedCarrier"
                />
              </div>
              <div class="align-self-center">
                <dx-util-text-box
                  v-model="otherCarrierName"
                  :visible="isOtherCarrier"
                  label="Carrier Name"
                  label-mode="floating"
                />
              </div>
            </div>
            <b-tabs id="dimensions-tabs" fill pills nav-class="my-0 p-0">
              <b-tab v-if="shipmentType === shipmentTypeEnum.SP.value" key="shipment-dimensions-box" lazy active>
                <template #title>
                  <b-icon icon="box" />
                  <span>Box Dimensions</span>
                </template>
                <dx-util-scroll-view show-scrollbar="onHover" :height="dimensionContent.scroolHeight">
                  <box-dimensions
                    :batch-id="batchId"
                    :shipment-batch-id="shipmentBatchId"
                    :partner-type="partnerType"
                    :shipment-type="shipmentType"
                    :transport-status="transportStatus"
                    :carrier="carrier"
                    :selected-carrier="selectedCarrier"
                    @on-emit-get-transport-detail="onEmitGetTransportDetail"
                  />
                </dx-util-scroll-view>
              </b-tab>
              <b-tab v-if="shipmentType === shipmentTypeEnum.LTL.value" key="shipment-dimensions-pallet" lazy active>
                <template #title>
                  <b-icon icon="box" />
                  <span>Shipment Pallet</span>
                </template>
                <dx-util-scroll-view show-scrollbar="onHover" :height="dimensionContent.scroolHeight">
                  <box-dimensions-pallet
                    :batch-id="batchId"
                    :shipment-batch-id="shipmentBatchId"
                    :partner-type="partnerType"
                    :shipment-type="shipmentType"
                    :transport-status="transportStatus"
                    :carrier="carrier"
                    :selected-carrier="selectedCarrier"
                    @on-emit-get-transport-detail="onEmitGetTransportDetail"
                  />
                </dx-util-scroll-view>
              </b-tab>
              <b-tab v-if="shipmentType === shipmentTypeEnum.SP.value || shipmentType === shipmentTypeEnum.LTL.value" key="shipping-label">
                <template #title>
                  <b-icon icon="printer-fill" />
                  <span>Shipping Labels</span>
                </template>
                <dx-util-scroll-view show-scrollbar="onHover" :height="dimensionContent.scroolHeight">
                  <shipping-label
                    :batch-id="batchId"
                    :shipment-batch-id="shipmentBatchId"
                    :shipment-batch-name="shipmentBatchName"
                    :shipment-type="shipmentType"
                    :transport-detail="{
                      partnerType: partnerType,
                      estimateShipmentCharges: estimateShipmentCharges,
                      voidDeadline: voidDeadline,
                      transportStatus: transportStatus,
                      previewPickupDate: previewPickupDate
                    }"
                    @on-emit-get-transport-detail="onEmitGetTransportDetail"
                  />
                </dx-util-scroll-view>
              </b-tab>
              <b-tab key="extra-charges" title-item-class="mr-0">
                <template #title>
                  <feather-icon icon="DollarSignIcon" size="18" />
                  <span>Extra Charges</span>
                </template>
                <dx-util-scroll-view show-scrollbar="onHover" :height="dimensionContent.scroolHeight">
                  <div class="row">
                    <div class="col-12">
                      <charge-item v-if="reload" :item-id="shipmentBatchId" :product-id="shipmentBatchId" :is-item-id="true" :is-selected-type="true" />
                    </div>
                    <div class="col-2 offset-10 px-half">
                      <dx-util-button
                        icon="save"
                        text="Save Charges"
                        type="success"
                        class="mt-1"
                        width="100%"
                        @click="addCharges"
                      />
                    </div>
                  </div>
                </dx-util-scroll-view>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable vue/no-unused-components */

import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import transportStatusEnum from '@/enums/transportStatusEnum'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import store from '@/store'
import ChargeItem from '@components/charge/TheChargeItem.vue'
import { Notify } from '@robustshell/utils/index'
import shipmentTypeEnum, { getShipmentTypeEnumList } from './consts/shipment-type.enum'
import carrierEnum, { getCarrierEnum, getCarrierEnumList } from './consts/carrier.enum'
import fbaShipment from './fbaShipment.mixin'
import BoxDimensions from './components/BoxDimensions.vue'
import BoxDimensionsPallet from './components/BoxDimensionsPallet.vue'
import ShippingLabel from './components/ShippingLabel.vue'

export default {
  components: {
    BoxDimensions,
    BoxDimensionsPallet,
    ShippingLabel,
    ChargeItem,
  },
  mixins: [fbaShipment],
  props: {
    batchId: {
      type: Number,
      required: true,
    },
    shipmentBatchId: {
      type: Number,
      required: true,
    },
    shipmentBatchName: {
      type: String,
      required: true,
    },
    shipmentBatchDestCode: {
      type: String,
      required: true,
    },
    shipmentBatchTransportStatus: {
      type: String,
      required: true,
    },
    shipmentPartnerShipped: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      shipmentType: shipmentTypeEnum.SP.value,
      shipmentTypeEnum: shipmentTypeEnum,
      carrier: carrierEnum.UNITED_PARCEL_SERVICE_INC.value,
      carrierEnum: carrierEnum,
      otherCarrierName: '',
      previewPickupDate: '',
      partnerTypes: [
        { value: true, text: 'Partner Carrier' },
        { value: false, text: 'Other' },
      ],
      partnerType: true,
      selectedCarrier: carrierEnum.UNITED_PARCEL_SERVICE_INC.text,
      // Current Transport Status
      transportStatus: transportStatusEnum.WORKING.value,
      estimateShipmentCharges: {
        currencyCode: '',
        value: 0.00,
      },
      voidDeadline: '',
      reload: true,
    }
  },
  computed: {
    shipmentTypes() {
      return getShipmentTypeEnumList()
    },
    carriers() {
      return getCarrierEnumList()
    },
    isPartnerSmallParcelDelivery() {
      return this.partnerType
    },
    isOtherCarrier() {
      return this.carrier === carrierEnum.OTHER.value
    },
  },
  watch: {
    batchId: {
      immediate: true,
      async handler(current, previous) {
        if (current && current > 0) {
          this.getBatch(current)
        }
      },
    },
    shipmentBatchId: {
      immediate: true,
      async handler(current, previous) {
        if (current && current > 0) {
          if (this.shipmentBatchTransportStatus !== transportStatusEnum.WORKING.value) {
            this.getTransportDetails(current)
          }
          if (!this.shipmentPartnerShipped) {
            this.partnerType = false
            this.onValueChangedPartnerType()
            this.onValueChangedCarrier()
          }
        }
      },
    },
    otherCarrierName(current, previous) {
      if (current) {
        this.selectedCarrier = current
      }
    },
  },
  methods: {
    onEmitGetTransportDetail() {
      this.getTransportDetails(this.shipmentBatchId)
    },
    // Transport Detail
    getTransportDetails(id) {
      fbaShipmentService.getTransportDetails(id)
        .then(result => {
          const { data } = result
          if (data) {
            const { transportDetails, transportResult, transportHeader } = data.payload.transportContent
            this.transportStatus = this.getTransportStatus(transportResult)
            this.partnerType = this.getIsPartnered(transportHeader)
            this.shipmentType = this.getShipmentType(transportHeader)
            this.carrier = this.getParcelData(transportDetails)
            this.previewPickupDate = this.getPreviewPickupDate(transportDetails)
            this.otherCarrierName = this.getParcelData(transportDetails)
            this.estimateShipmentCharges = this.getPartneredEstimate(transportDetails)
            this.voidDeadline = this.getVoidDeadline(transportDetails)
          }
        })
    },
    getTransportStatus(transportResult) {
      if (transportResult && transportResult.transportStatus) {
        return transportResult.transportStatus
      }
      return transportStatusEnum.WORKING.value
    },
    getIsPartnered(transportHeader) {
      if (transportHeader && transportHeader.isPartnered) {
        return transportHeader.isPartnered
      }
      if (transportHeader && transportHeader.isPartnered === null) {
        return false
      }
      return true
    },
    getShipmentType(transportHeader) {
      if (transportHeader && transportHeader.shipmentType) {
        return transportHeader.shipmentType
      }
      return shipmentTypeEnum.SP.value
    },
    getParcelData(transportDetails) {
      if (transportDetails) {
        if (transportDetails.partneredSmallParcelData && transportDetails.partneredSmallParcelData.PackageList) {
          return transportDetails.partneredSmallParcelData.packageList[0].carrierName
        } if (transportDetails.nonPartneredSmallParcelData && transportDetails.nonPartneredSmallParcelData.PackageList) {
          return transportDetails.nonPartneredSmallParcelData.packageList[0].carrierName
        } if (transportDetails.partneredLtlData && transportDetails.partneredLtlData.PackageList) {
          return transportDetails.partneredLtlData.packageList[0].carrierName
        } if (transportDetails.nonPartneredLtlData) {
          const carrier = transportDetails.nonPartneredLtlData.carrierName ? transportDetails.nonPartneredLtlData.carrierName : carrierEnum.OTHER.value
          return carrier
        }
      }
      return carrierEnum.UNITED_PARCEL_SERVICE_INC.value
    },
    getPartneredEstimate(transportDetails) {
      if (this.shipmentType === shipmentTypeEnum.SP.value && transportDetails.partneredSmallParcelData && transportDetails.partneredSmallParcelData.partneredEstimate) {
        const { partneredEstimate } = transportDetails.partneredSmallParcelData
        if (partneredEstimate) {
          return partneredEstimate.amount
        }
      } if (this.shipmentType === shipmentTypeEnum.LTL.value && transportDetails.partneredLtlData && transportDetails.partneredLtlData.partneredEstimate) {
        const { partneredEstimate } = transportDetails.partneredLtlData
        if (partneredEstimate) {
          return partneredEstimate.amount
        }
      }
      const estimateShipmentCharges = {
        currencyCode: '',
        value: 0.00,
      }
      return estimateShipmentCharges
    },
    getVoidDeadline(transportDetails) {
      if (transportDetails.partneredSmallParcelData) {
        const { partneredEstimate } = transportDetails.partneredSmallParcelData
        if (partneredEstimate) {
          return partneredEstimate.voidDeadline
        }
      }
      return ''
    },
    getPreviewPickupDate(transportDetails) {
      if (transportDetails) {
        if (transportDetails.partneredLtlData && transportDetails.partneredLtlData.previewPickupDate) {
          return transportDetails.partneredLtlData.previewPickupDate
        }
      }
      return ''
    },
    // end transport detail
    getBatch(id) {
      const parsed = parseInt(id, 10)
      fbaShipmentService.findBatchParentById(parsed).then(result => {
        const { shipmentType } = result.data
        this.shipmentType = shipmentType
      })
    },
    onValueChangedPartnerType() {
      if (this.isPartnerSmallParcelDelivery) {
        this.carrier = carrierEnum.UNITED_PARCEL_SERVICE_INC.value
      }

      if (this.partnerType === false) {
        this.carrier = carrierEnum.OTHER.value
      }
    },
    onValueChangedCarrier() {
      if (this.carrier !== carrierEnum.OTHER.value) {
        this.otherCarrierName = carrierEnum.OTHER.value
        const carrier = getCarrierEnum(this.carrier)
        this.selectedCarrier = carrier.text
      }

      if (this.carrier === carrierEnum.OTHER.value) {
        this.otherCarrierName = carrierEnum.OTHER.value
        const carrier = getCarrierEnum(this.carrier)
        this.selectedCarrier = carrier.text
      }
    },
    addCharges() {
      store.dispatch('charge/setCharges', this.shipmentBatchId)
      const chargeItems = store.getters['charge/getCharges']
      const getChargeErrors = store.getters['charge/getChargeErrors']
      if (getChargeErrors.length > 0) {
        Notify.danger(getChargeErrors.join(','))
      } else {
        const charges = [...chargeItems]
        itemChargeService.create(this.shipmentBatchId, charges)
          .then(() => {
            store.dispatch('charge/clearCharges')
            this.reload = false
          }).then(() => {
            this.reload = true
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .badge-item {
    display: flex;
    justify-content: start;
    align-items: center;
    line-height: normal;
    font-weight: 500;
    height: 60px !important;
  }
</style>
